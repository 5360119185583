import React from "react";
import PropTypes from "prop-types";
import { Modal, Form, Row, Col, Button, Card, Table } from "react-bootstrap";
import { formatDate } from "util/CommonUtil";

const ActivityDetails = ({ details, viewDetails, setViewDetails }) => {
  const [oldValues, setOldValues] = React.useState([]);
  const [newValues, setNewValues] = React.useState([]);

  React.useEffect(() => {
    for (const key in details?.old_values) {
      if (Object.hasOwnProperty.call(details?.old_values, key)) {
        setOldValues(Object.entries(details?.old_values));
      }
    }
    for (const key in details?.new_values) {
      if (Object.hasOwnProperty.call(details?.new_values, key)) {
        setNewValues(Object.entries(details?.new_values));
      }
    }
  }, []);

  return (
    <Modal show={viewDetails} onHide={() => setViewDetails(false)} size="lg">
      <Modal.Body>
        <Modal.Title>View Activity Details</Modal.Title>
        <Card>
          <Card.Body>
            <Modal.Title as="h5">User Details</Modal.Title>
            <Row>
              <Col>
                <Form.Group>
                  <label>Name</label>
                  <Form.Control
                    type="text"
                    value={details?.user?.name}
                    placeholder="Name"
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <label>User Name</label>
                  <Form.Control
                    type="text"
                    value={details?.user?.username}
                    placeholder="User Name"
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <label>Email</label>
                  <Form.Control
                    type="text"
                    value={details?.user?.email}
                    placeholder="Email"
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <label>Designation</label>
                  <Form.Control
                    type="text"
                    value={details?.user?.designation}
                    placeholder="Designation"
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Modal.Title as="h5">Activity Details</Modal.Title>
            <Row>
              <Col md="2">
                <Form.Group>
                  <label>Action</label>
                  <Form.Control
                    type="text"
                    value={details?.event}
                    placeholder="Action"
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md="5">
                <Form.Group>
                  <label>Modal</label>
                  <Form.Control
                    type="text"
                    value={details?.auditable_type}
                    placeholder="Modal"
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md="5">
                <Form.Group>
                  <label>Date and Time</label>
                  <Form.Control
                    type="text"
                    value={formatDate(details?.created_at)}
                    placeholder="User Name"
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>Old Values</th>
                      <th>New Values</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {oldValues.map((item, index) => (
                          <div key={index}>
                            {item[0]} : {item[1]}
                          </div>
                        ))}
                      </td>
                      <td>
                        {newValues.map((item, index) => (
                          <div key={index}>
                            {item[0]} : {item[1]}
                          </div>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col md="3">
            <Button
              variant="danger"
              className="btn-outline"
              onClick={() => setViewDetails(false)}
              block
            >
              Close
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

ActivityDetails.propType = {
  details: PropTypes.object.isRequired,
  viewDetails: PropTypes.bool.isRequired,
  setViewDetails: PropTypes.func,
};

export default ActivityDetails;
