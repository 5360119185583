import React from "react";
import { Form, Row, Col, Modal, Button, Card, Table } from "react-bootstrap";
import Select from "react-select";
import NotificationAlert from "ui/NotificationAlert";
import PropTypes from "prop-types";
import Route from "routes/Route";
import { newExtensions, existngExtensions, checkPermission } from "util/MasterUtil";
import { dynamicHandle } from "util/CommonUtil";
import { useSelector } from "react-redux";

const EditRegion = ({
  edit,
  setEdit,
  details,
  responseHandle,
  setResponseTitle,
}) => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [newDetails, setNewDetails] = React.useState({
    id: details?.id,
    name: details?.name,
    dzongkhag_id: details.dzongkhag_id,
    dzongkhag: details.dzongkhag.name,
    description: details?.description,
    extensions: details?.extensions,
  });
  const [showNotification, setShowNotification] = React.useState(false);
  const [dzongkhags, setDzongkhags] = React.useState([]);
  const [addedRow, setAddedRow] = React.useState([]);
  const [rows, setRows] = React.useState(0);
  const [addedExtensionDetails, setAddedExtensionDetails] = React.useState([]);
  const token = localStorage.getItem("token");
  const fetchDzongkhag = async () => {
    const response = await Route.fetch("dzongkhags", token);
    if (response.status === 200) {
      setDzongkhags(response?.data?.dzongkhag);
    }
  };

  React.useEffect(() => {
    fetchDzongkhag();
  }, [details]);

  React.useEffect(() => {
    setNewDetails({
      ...newDetails,
      id: details?.id,
      name: details?.name,
      dzongkhag_id: details.dzongkhag_id,
      dzongkhag: details.dzongkhag.name,
      description: details?.description,
      extensions: details?.extensions,
    });
  }, [details]);
  const regionHandle = (e) => {
    setNewDetails({
      ...newDetails,
      name: e.target.value,
    });
  };
  const dzongkhagHandle = (e) => {
    setNewDetails({
      ...newDetails,
      dzongkhag_id: e.value,
    });
  };
  const descriptionHandle = (e) => {
    setNewDetails({
      ...newDetails,
      description: e.target.value,
    });
  };
  const newExtensionNameHandle = (id, e) => {
    dynamicHandle(id, "name", e.target.value, setAddedExtensionDetails);
  };
  const newExtensionsDescriptionHandle = (id, e) => {
    dynamicHandle(id, "description", e.target.value, setAddedExtensionDetails);
  };
  const deleteRowHandle = (id) => {
    setAddedExtensionDetails((prevSubCatDetails) => {
      return prevSubCatDetails.filter((item) => item.id !== id);
    });
    setAddedRow((prev) => prev.filter((row) => row.props.id !== id));
  };
  const row = (id) => (
    <tr key={id} id={id}>
      <td>
        <Form.Group>
          <Form.Control
            placeholder="Name"
            type="text"
            onChange={(e) => newExtensionNameHandle(id, e)}
          ></Form.Control>
        </Form.Group>
      </td>
      <td>
        <Form.Group>
          <Form.Control
            placeholder="Description"
            type="text"
            onChange={(e) => newExtensionsDescriptionHandle(id, e)}
          ></Form.Control>
        </Form.Group>
      </td>
      <td>
        <Button
          variant="danger"
          className="btn-link remove text-danger"
          onClick={() => deleteRowHandle(id)}
        >
          <i className="fa fa-trash" />
        </Button>
      </td>
    </tr>
  );
  const addNewRowHandle = () => {
    const newId = rows + 1;
    setRows(newId);
    setAddedRow((prev) => [...prev, row(newId)]);
  };
  const deleteDefaultRowHandle= (id) => {
    setNewDetails({
      ...newDetails,
      extensions: newDetails?.extensions.filter((item) => item.id !== id),
    });
  };
  const onConfirm = async () => {
    if (!checkPermission("regions.update", permissionsArray)) {
      return setShowNotification(true);
    };
    const data = {
      name: newDetails?.name,
      dzongkhag_id: newDetails?.dzongkhag_id,
      description: newDetails?.description,
      extensions: [...newExtensions(addedExtensionDetails), ...existngExtensions(newDetails?.extensions)],
    };
    const response = await Route.update(
      "regions",
      token,
      details.id,
      data
    );
    if (response.status === 200) {
      setResponseTitle(response?.data?.message);
      setEdit(false);
      responseHandle(true);
    } else {
      setResponseTitle(response?.data?.message);
      setEdit(false);
      responseHandle(false);
    }
  };
  return (
    <>
    <Modal
      show={edit}
      onHide={() => setEdit(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body>
        <Modal.Title>Edit Region</Modal.Title>
        <Form>
          <Form.Group>
            <Row>
              <Col className="control-label" md="4">
                Region <span className="star">*</span>
              </Col>
              <Col md="8">
                <Form.Control
                  placeholder="Region"
                  type="text"
                  defaultValue={newDetails?.name}
                  onChange={regionHandle}
                ></Form.Control>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col className="control-label" md="4">
                Dzongkhag <span className="star">*</span>
              </Col>
              <Col md="8">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={dzongkhagHandle}
                  defaultValue={{
                    value: `${details.dzongkhag_id}`,
                    label: `${details.dzongkhag.name}`,
                  }}
                  name="singleSelect"
                  options={dzongkhags?.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.name}`,
                  }))}
                  placeholder="Select Dzongkhag"
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col className="control-label" md="4">
                Description
              </Col>
              <Col md="8">
                <Form.Control
                  placeholder="Description"
                  type="text"
                  defaultValue={newDetails?.description}
                  onChange={descriptionHandle}
                ></Form.Control>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col>
                <Card.Title>Extensions</Card.Title>
                <Table className="table-hover w-full">
                  <thead>
                    <tr>
                      <th>Name <span className="star">*</span></th>
                      <th>Description</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {newDetails?.extensions.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Group>
                            <Form.Control
                              placeholder="Name"
                              type="text"
                              onChange={(e) => newExtensionNameHandle(index, e)}
                              value={item?.name}
                              readOnly
                            ></Form.Control>
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Control
                              placeholder="Description"
                              type="text"
                              // as="textarea"
                              // rows={2}
                              onChange={(e) =>
                                newExtensionsDescriptionHandle(index, e)
                              }
                              value={item?.description}
                              readOnly
                            ></Form.Control>
                          </Form.Group>
                        </td>
                        <td>
                          <Button
                            variant="danger"
                            // size="sm"
                            className="btn-link remove text-danger"
                            onClick={() => deleteDefaultRowHandle(item.id)}
                          >
                            <i className="fa fa-trash" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                    {addedRow.length > 0 && addedRow?.map((item) => item)}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <div className="card-category form-category">
              <span className="star">*</span>
              Required fields
            </div>
            <Row>
              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  className="btn-outline"
                  type="button"
                  variant="default"
                  onClick={addNewRowHandle}
                >
                  Add New <i className="fa fa-plus"></i>
                </Button>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row className="mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Col md="2">
                <Button variant="primary" onClick={onConfirm} block>
                  Update
                </Button>
              </Col>
              <Col md="2">
                <Button
                  className="btn-outline"
                  onClick={() => setEdit(false)}
                  block
                  variant="danger"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
    { showNotification && <NotificationAlert setAlert={setShowNotification} /> }
    </>
  );
};
EditRegion.propTypes = {
  edit: PropTypes.bool,
  setEdit: PropTypes.func,
  details: PropTypes.object.isRequired,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default EditRegion;
