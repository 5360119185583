import React from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import ReceivedProduct from "./Components/regional_store/acknowledge_transfer/ReceivedProduct";
import AcknowledgeProduct from "./Components/regional_store/acknowledge_transfer/AcknowledgeProduct";

const AcknowledgeRegionalTransfer = () => {
  return (
    <>   
      <Card>
        <Card.Header>
          <Card.Title as="h4">Acknowledge Regional Transfer</Card.Title>
        </Card.Header>
        <Card.Body>
          <Tab.Container
            id="plain-tabs-example"
            defaultActiveKey="index-history"
          >
            <Nav role="tablist" variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="index-history">Products Transaction</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Transfer-index">Total Products Received</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="index-history">
                <AcknowledgeProduct />
              </Tab.Pane>
              <Tab.Pane eventKey="Transfer-index">
                <ReceivedProduct />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
    </>
  );
};

export default AcknowledgeRegionalTransfer;
