import React from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import Alert from "ui/Alert";
import NotificationAlert from "ui/NotificationAlert";
import DeleteAlert from "ui/DeleteAlert";
import AddCustomer from "./Components/dealer/customer/AddCustomer";
import EditCustomer from "./Components/dealer/customer/EditCustomer";
import { customerTableHeader } from "data/dummy";
import Route from "routes/Route";
import { useQuery } from "react-query";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const Customers = () => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [customerType, setCustomerType] = React.useState([]);
  const [addNew, setAddNew] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const token = localStorage.getItem("token");
  const editHandle = async (id) => {
    if (!checkPermission("customers.edit-customers", permissionsArray)) {
      return setShowNotification(true);
    }
    const response = await Route.fetchDetails("edit-customers", token, id);
    if (response.status === 200) {
      setDetails(response?.data?.customer);
      setEdit(true);
    } else {
      setNotificationText("Couldn't Fetch Customer Details");
      setShowNotification(true);
    }
  };
  const { isLoading, data: res } = useQuery(
    "customers",
    () => Route.fetch("customers", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const [tempData, setTempData] = React.useState([]);
  const [customerId, setCustomerId] = React.useState("");
  const [deleteCustomer, setDeleteCustomer] = React.useState(false);
  const deleteCustomerHandle = async () => {
    setDeleteCustomer(false);
    const res = await Route.delete("customers", token, customerId);
    if (res.status === 200) {
      setResponse(true);
      setResponseTitle(res?.data?.message);
      setShowAlert(true);
    } else {
      setResponse(false);
      setResponseTitle(res?.data?.message);
      setShowAlert(true);
    }
  };
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.customer);
      if (tempData !== res?.data?.customer) {
        setCustomerType(
          res?.data?.customer?.map((item) => {
            return {
              id: item?.id,
              name: item?.customer_name,
              contact: item?.contact_no,
              address: item?.address,
              description: item?.description,
              customerType: item?.customer_type?.name,
              actions: (
                <div className="actions-right">
                  <Button
                    onClick={() => editHandle(item?.id)}
                    variant="warning"
                    size="sm"
                    className="text-dark btn-link edit"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    onClick={() => {
                      setCustomerId(item?.id);
                      setDeleteCustomer(true);
                    }}
                    variant="danger"
                    size="sm"
                    className="btn-link remove text-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  const addNewHandle = () => {
    !checkPermission("customers.store", permissionsArray)
      ? setShowNotification(true)
      : setAddNew(true);
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Customers</h4>
            <Card>
              <Card.Body>
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="primary"
                    className="btn-outline"
                    onClick={addNewHandle}
                  >
                    Add New Customer <i className="fa fa-plus"></i>
                  </Button>
                </Col>
                <ReactTable
                  data={customerType}
                  columns={customerTableHeader}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {addNew && (
        <AddCustomer
          setAddNew={setAddNew}
          addNew={addNew}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      )}
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
      {edit && (
        <EditCustomer
          details={details}
          setEdit={setEdit}
          edit={edit}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      )}
      {deleteCustomer && (
        <DeleteAlert
          deleteHandle={deleteCustomerHandle}
          setAlert={setDeleteCustomer}
        />
      )}
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      )}
    </>
  );
};

export default Customers;
