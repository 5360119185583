import React from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import { userEmiTableHeader } from "data/dummy";
import ReactTable from "components/ReactTable/ReactTable.js";
import UserApplyEmi from "./Components/soms/emi/UserApplyEmi";
import ViewEmi from "./Components/soms/emi/ViewEmi";
import EmiProductUpdate from "./Components/soms/emi/EmiProductUpdate";
import Route from "routes/Route";
import Alert from "ui/Alert";
import { useQuery } from "react-query";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const Emi = () => {
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [products, setProducts] = React.useState([]);
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [emiDetails, setEmiDetails] = React.useState([]);
  const [tempData, setTempData] = React.useState([]);
  const [applyEmi, setApplyEmi] = React.useState(false);
  const [viewDetail, setViewDetail] = React.useState({});
  const [emiProductUpdate, setEmiProductUpdate] = React.useState(false);
  const [view, setView] = React.useState(false);
  const token = localStorage.getItem("token");
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const { isLoading, data: res } = useQuery(
    "emiData",
    () => Route.fetch("apply-emi", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const viewHandle = (detail) => {
    setViewDetail(detail);
    setView(true);
  };
  const aprroveHandle = (detail) => {
    setViewDetail(detail);
    setEmiProductUpdate(true);
  };
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.emi);
      if (tempData !== res?.data?.emi) {
        setProducts(res?.data?.product);
        setEmiDetails(
          res?.data?.emi?.map((item) => {
            return {
              id: item?.id,
              emi_no: item?.emi_no,
              name: item?.user?.name,
              item_number: item?.item_number,
              request_date: item?.request_date,
              emi_duration: item?.emi_duration,
              monthly_emi: item?.monthly_emi,
              total: item?.total,
              deduction_from: item?.deduction_from,
              status: (
                <div className="">
                  <Button
                    variant={
                      `${item?.status}` === "pending" ? "warning" : `${item?.status}` === "rejected" ? "danger" : "success"
                    }
                    size="sm"
                    className="btn-round"
                  >
                    {item?.status}
                  </Button>{" "}
                </div>
              ),
              actions: (
                <div className="actions-right" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    className="text-primary btn-link edit"
                    onClick={() => viewHandle(item)}
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                  {
                    (checkPermission("apply-emi.update-product", permissionsArray) && `${item?.status}` === "approved")  ? <div className="actions-right">
                    <Button
                      onClick={() => aprroveHandle(item)}
                      variant="info"
                      size="sm"
                      className=""
                    >
                      Sell <i className="fa fa-arrow-right"></i>
                    </Button>{" "}
                  </div> : null
                  }
                </div>
              ),
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  return (
    <>
      {applyEmi ? (
        <UserApplyEmi setApplyEmi={setApplyEmi} responseHandle={responseHandle} setResponseTitle={setResponseTitle}  products={products} />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <h4 className="title">Employee EMI</h4>
              <Card>
                <Card.Body>
                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      className="btn-outline"
                      onClick={() => setApplyEmi(true)}
                    >
                      Apply EMI <i className="fa fa-plus"></i>
                    </Button>
                  </Col>
                  <ReactTable
                    data={emiDetails}
                    columns={userEmiTableHeader}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {view && (
        <ViewEmi
          view={view}
          setView={setView}
          viewDetail={viewDetail}
        />
      )}
      {emiProductUpdate && (
        <EmiProductUpdate
          view={emiProductUpdate}
          setView={setEmiProductUpdate}
          viewDetail={viewDetail}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      )}
      {showAlert && (
        <Alert
          response={response}
          responseTitle={responseTitle}
          setShowAlert={setShowAlert}
        />
      )}
    </>
  );
};

export default Emi;
