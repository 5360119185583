import React from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import NotificationAlert from "ui/NotificationAlert";
import DeleteAlert from "ui/DeleteAlert";
import Alert from "ui/Alert";
import ReactTable from "components/ReactTable/ReactTable.js";
import { userTableHeader } from "data/dummy";
import AddNewUser from "./Components/system_setting/user/AddNewUser";
import EditUserDetails from "./Components/system_setting/user/EditUserDetails";
import { useQuery } from "react-query";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";
import Route from "routes/Route";

const Users = () => {
  const token = localStorage.getItem("token");
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [addNewUser, setAddNewUser] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [userDetail, setUserDetail] = React.useState({});
  const [editUserDetails, setEditUserDetails] = React.useState(false);
  const [deleteUser, setDeleteUser] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [userId, setUserId] = React.useState("");
  const [responseTitle, setResponseTitle] = React.useState("");
  // const fetchUsers = () => {
  //   const response = Route.fetch("users", token);
  //   if (response?.status === 200) {
  //     setUsers(
  //       res?.data?.user?.map((item) => {
  //         return {
  //           id: item?.id,
  //           name: item?.name,
  //           username: item?.username,
  //           email: item?.email,
  //           designation: item?.designation,
  //           actions: (
  //             <div className="actions-right">
  //               <Button
  //                 onClick={() => editUserHandle(item.id)}
  //                 variant="warning"
  //                 size="sm"
  //                 className="text-dark btn-link edit"
  //               >
  //                 <i className="fa fa-edit" />
  //               </Button>{" "}
  //               <Button
  //                 onClick={() => {
  //                   setUserId(item?.id);
  //                   setDeleteUser(true);
  //                 }}
  //                 variant="danger"
  //                 size="sm"
  //                 className="btn-link remove text-danger"
  //               >
  //                 <i className="fa fa-trash"></i>
  //               </Button>
  //             </div>
  //           ),
  //         };
  //       })
  //     );
  //   };
  // };
  // React.useEffect(() => {
  //   fetchUsers();
  // },[]);
  const { isLoading, data: res } = useQuery(
    "userData",
    () => Route.fetch("users", token),
    // {
    //   refetchInterval: 2000,
    //   refetchIntervalInBackground: true,
    // }
  );
  const addNewUserHandle = () => {
    if(!checkPermission("users.store", permissionsArray)) {
      setNotificationText("You Don't Have Permission");
      setShowNotification(true)
    } else {
      setAddNewUser(true);
    }
  };
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const editUserHandle = async (id) => {
    if (!checkPermission("users.edit-users", permissionsArray)) {
      setNotificationText("You Don't Have Permission");
      setShowNotification(true);
    } else {
      const response = await Route.fetchDetails("edit-users", token, id);
      if (response.status === 200) {
        setUserDetail(response?.data?.user);
        setEditUserDetails(true);
      } else {
        setNotificationText("Error While Fetching Details");
        setShowNotification(true);
      }
    }
  };
  const deletingUserHandle = async () => {
    const response = await Route.delete("users", token, userId);
    setDeleteUser(false);
    if (response.status === 200) {
      setResponseTitle(response?.data?.message);
      responseHandle(true)
    } else if (response.status === 202) {
      setResponseTitle(response?.data?.message);
      responseHandle(false)
    } else {
      setResponseTitle(response?.data?.message);
      responseHandle(true)
    }
  };
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setUsers(
        res?.data?.user?.map((item) => {
          return {
            id: item?.id,
            name: item?.name,
            username: item?.username,
            email: item?.email,
            designation: item?.designation,
            actions: (
              <div className="actions-right">
                <Button
                  onClick={() => editUserHandle(item.id)}
                  variant="warning"
                  size="sm"
                  className="text-dark btn-link edit"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                <Button
                  onClick={() => {
                    setUserId(item?.id);
                    setDeleteUser(true);
                  }}
                  variant="danger"
                  size="sm"
                  className="btn-link remove text-danger"
                >
                  <i className="fa fa-trash"></i>
                </Button>
              </div>
            ),
          };
        })
      );
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  return (
    <>
      {addNewUser ? (
        <AddNewUser
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
          setAddNewUser={setAddNewUser}
          // fetchUsers={fetchUsers}
        />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <h4 className="title">Users List</h4>
              <Card>
                <Card.Body>
                  <Row>
                    <Col
                      md="12"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button onClick={addNewUserHandle} className="btn-outline">Add New User <i className="fa fa-plus"></i></Button>
                      {"  "}
                      {/* <Button
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="success"
                        style={{ marginLeft: "5px" }}
                      >
                        Download
                      </Button> */}
                    </Col>
                  </Row>
                  <ReactTable
                    data={users}
                    columns={userTableHeader}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {editUserDetails && (
        <EditUserDetails
          show={editUserDetails}
          setEditUserDetails={setEditUserDetails}
          responseHandle={responseHandle}
          userDetail={userDetail}
          setResponseTitle={setResponseTitle}
          // fetchUsers={fetchUsers}
        />
      )}
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
      {deleteUser && (
        <DeleteAlert
          deleteHandle={deletingUserHandle}
          setAlert={setDeleteUser}
        />
      )}
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      )}
    </>
  );
};

export default Users;
