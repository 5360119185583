import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import ReactDatetime from "react-datetime";

const UpdateProfile = ({ onCancel, responseHandle }) => {
  const [singleSelect, setSingleSelect] = React.useState("");
  const onConfirm = () => {
    responseHandle(false);
    onCancel();
  };
  return (
    <Container fluid>
      <SweetAlert
        title={"Edit User Profile"}
        onConfirm={onConfirm}
        onCancel={onCancel}
        showConfirm={false}
        confirmBtnText="Update"
        cancelBtnBsStyle="default"
        style={{maxHeight:'700px'}}
      >
        <Row>
          <Col>
            <Row>
              <Col className="pr-1" md="6">
                <Form.Group>
                  <Form.Label style={{ display:'flex', justifyContent:'start', fontSize: '14px', color: '#73777B' }}>Email</Form.Label>
                  <Form.Control
                    defaultValue="manager.asprojects@tashicell.com"
                    placeholder="Email"
                    type="email"
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col className="pl-1" md="6">
                <Form.Group>
                  <Form.Label style={{ display:'flex', justifyContent:'start', fontSize: '14px', color: '#73777B' }}>Birthday</Form.Label>
                  <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Select Birth Date",
                      }}
                      timeFormat={false}
                    ></ReactDatetime>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="6">
                <Form.Group>
                  <Form.Label style={{ display:'flex', justifyContent:'start', fontSize: '14px', color: '#73777B' }}>DOJ</Form.Label>
                  <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Select DOJ",
                      }}
                      timeFormat={false}
                    ></ReactDatetime>
                </Form.Group>
              </Col>
              <Col className="pl-1" md="6">
                <Form.Group>
                <Form.Label style={{ display:'flex', justifyContent:'start', fontSize: '14px', color: '#73777B' }}>Grade</Form.Label>
                  <Form.Control
                    defaultValue="P1"
                    placeholder="Grade"
                    type="text"
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="6">
                <Form.Label style={{ display:'flex', justifyContent:'start', fontSize: '14px', color: '#73777B' }}>Role</Form.Label>
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  value={singleSelect}
                  onChange={(value) => setSingleSelect(value)}
                  options={[
                    { value: "2", label: "Administrator" },
                    { value: "3", label: "User" },
                  ]}
                  placeholder="Role"
                />
              </Col>
              <Col className="pl-1" md="6">
                <Form.Group>
                  <Form.Label style={{ display:'flex', justifyContent:'start', fontSize: '14px', color: '#73777B' }}>Section</Form.Label>
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={singleSelect}
                    onChange={(value) => setSingleSelect(value)}
                    options={[
                      { value: "2", label: "Application & Software" },
                      { value: "3", label: "Marketing" },
                    ]}
                    placeholder="Section"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="6">
                <Form.Group>
                  <Form.Label style={{ display:'flex', justifyContent:'start', fontSize: '14px', color: '#73777B' }}>Designation</Form.Label>
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={singleSelect}
                    onChange={(value) => setSingleSelect(value)}
                    options={[
                      { value: "2", label: "Manager, A & S" },
                      { value: "3", label: "Developer" },
                    ]}
                    placeholder="Designation"
                  />
                </Form.Group>
              </Col>
              <Col className="pl-1" md="6">
                <Form.Group>
                  <Form.Label style={{ display:'flex', justifyContent:'start', fontSize: '14px', color: '#73777B' }}>Gender</Form.Label>
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={singleSelect}
                    onChange={(value) => setSingleSelect(value)}
                    options={[
                      { value: "2", label: "Male" },
                      { value: "3", label: "Female" },
                    ]}
                    placeholder="Gender"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col className="pr-1" md="6">
                <Form.Group>
                  <Form.Label style={{ display:'flex', justifyContent:'start', fontSize: '14px', color: '#73777B' }}>Employment Type</Form.Label>
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={singleSelect}
                    onChange={(value) => setSingleSelect(value)}
                    options={[
                      { value: "2", label: "Regular" },
                      { value: "3", label: "Contact" },
                    ]}
                    placeholder="Employment Type"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="6">
                <Button block onClick={onConfirm}>Update</Button>
              </Col>
              <Col className="pl-1" md="6">
                <Button variant="primary" className="btn-outline" block onClick={onCancel}>Cancel</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </SweetAlert>
    </Container>
  );
};

export default UpdateProfile;
