import axios from "axios";
const base_url = process.env.REACT_APP_BASE_URL;

class AuthRoute {
  userLogIn(username, password) {
    //eslint-disable-line
    return axios
      .post(
        `${base_url}/login`,
        {},
        {
          params: {
            username,
            password,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error);
  }
}

export default new AuthRoute();
