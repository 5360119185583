import React from "react";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import Route from "routes/Route";
import RequiredLabel from "ui/RequiredLabel";
import { formValidation, formatDateOnly } from "util/CommonUtil";

const AddDiscount = ({
  addNew,
  setAddNew,
  responseHandle,
  setResponseTitle,
}) => {
  const [newDetails, setNewDetails] = React.useState({
    category: "",
    sub_category: "",
    discount_name: "",
    discount_type: "",
    discount_value: "",
    applicable_to: "",
    region: "",
    extension: "",
    start_date: "",
    end_date: "",
    description: "",
  });
  const [categoryState, setCategoryState] = React.useState(true);
  const [discountNameState, setDiscountNameState] = React.useState(true);
  const [discountTypeState, setDiscountTypeState] = React.useState(true);
  const [discountValueState, setDiscountValueState] = React.useState(true);
  const [category, setCategory] = React.useState([]);
  const [subCategory, setSubCategory] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [regionApplicable, setRegionApplicable] = React.useState(false);
  const [regions, setRegions] = React.useState([]);
  const [extensions, setExtensions] = React.useState([]);
  const token = localStorage.getItem("token");
  const fetchCategory = async () => {
    const response = await Route.fetch("categories", token);
    if (response.status === 200) {
      setCategory(response?.data?.category);
    }
  };
  const fetchSubCategory = async (id) => {
    const response = await Route.fetchDetails("edit-categories", token, id);
    if (response?.status === 200) {
      setSubCategory(response?.data?.category?.sub_categories);
    }
  };
  // const fetchProduct = async (id) => {
  //   const response = await Route.fetchDetails("get-products", token, id);
  //   if (response?.status === 200) {
  //     setProducts(response?.data?.products)
  //   };
  // };
  const fetchRegion = async () => {
    const response = await Route.fetch("regions", token);
    if (response?.status === 200) {
      setRegions(response?.data?.region);
    }
  };
  React.useEffect(() => {
    fetchCategory();
    fetchRegion();
  }, []);
  const discountType = [
    {
      value: "Percentage",
    },
    {
      value: "Lump Sum",
    },
  ];
  const selectOptions = [
    {
      value: "All",
    },
    {
      value: "Main"
    },
    {
      value: "Region",
    },
    {
      value: "Extension",
    }
  ];
  const applicableHandle = (e) => {
    setNewDetails({
      ...newDetails,
      "applicable_to": e.value,
    });
    e.value === "Region" || e.value === "Extension" ? setRegionApplicable(true) : setRegionApplicable(false);
  };
  const fetchExtensions = async (id) => {
    const response = await Route.fetchDetails("edit-regions", token, id);
    if (response?.status === 200) {
      setExtensions(response?.data?.Region?.extensions);
    };
  };
  const inputFiledHandle = (name, value) => {
    name === "category" ? fetchSubCategory(value) : name === "region" ? fetchExtensions(value) : null;
    name === "category"
      ? setCategoryState(true)
      : name === "discount_name"
      ? setDiscountNameState(true)
      : name === "discount_type"
      ? setDiscountTypeState(true)
      : name === "discount_value"
      ? setDiscountValueState(true)
      : null;
    setNewDetails({
      ...newDetails,
      [name]: value,
    });
  };
  const validationConditions = [
    {
      field: "category",
      condition: newDetails.category === "",
      setState: setCategoryState,
    },
    {
      field: "discountName",
      condition: newDetails.discount_name === "",
      setState: setDiscountNameState,
    },
    {
      field: "discountType",
      condition: newDetails.discount_type === "",
      setState: setDiscountTypeState,
    },
    {
      field: "discountValue",
      condition: newDetails.discount_value === "",
      setState: setDiscountValueState,
    },
  ];
  const onConfirm = async () => {
    if (formValidation(validationConditions)) {
      const response = await Route.addNew("discount-types", token, newDetails);
      if (response.status === 200) {
        setResponseTitle(response?.data?.message);
        responseHandle(true);
        setAddNew(false);
      } else {
        setResponseTitle("Failed To Create New Discount");
        responseHandle(false);
        setAddNew(false);
      }
    }
  };
  return (
    <Modal
      show={addNew}
      onHide={() => setAddNew(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body>
        <Modal.Title>Add New Discount</Modal.Title>
        <Form>
          <Row>
            <Col md="6">
              <Form.Group>
                <label>
                  Category <span className="star">*</span>
                </label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => inputFiledHandle("category", e.value)}
                  name="categorySelect"
                  options={category?.map((item) => ({
                    value: `${item?.sale_type?.id}`,
                    label: `${item?.sale_type?.name}`,
                  }))}
                  placeholder="Select Category"
                />
                {categoryState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <label>Sub-Category</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => inputFiledHandle("sub_category", e.value)}
                  name="subCategorySelect"
                  options={subCategory?.map((item) => ({
                    value: `${item?.id}`,
                    label: `${item?.name}`,
                  }))}
                  placeholder="Select Sub-Category"
                />
              </Form.Group>
            </Col>
            {/* <Col md="4">
              <Form.Group>
                <label>Product</label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => inputFiledHandle("products", e.value)}
                  name="productsSelect"
                  options={products?.map((item) => ({
                    value: `${item?.id}`,
                    label: `${item?.description}`,
                  }))}
                  placeholder="Select Product"
                />
              </Form.Group>
            </Col> */}
          </Row>
          <Row>
            <Col md="4">
              <Form.Group>
                <label>
                  Discount Name <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Enter Discount Name"
                  type="text"
                  name="discount_name"
                  onChange={(e) =>
                    inputFiledHandle(e.target.name, e.target.value)
                  }
                ></Form.Control>
                {discountNameState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <label>
                  Discount Type <span className="star">*</span>
                </label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => inputFiledHandle("discount_type", e.value)}
                  name="discountTypeSelect"
                  options={discountType?.map((item) => ({
                    value: `${item?.value}`,
                    label: `${item?.value}`,
                  }))}
                  placeholder="Select Discount Type"
                />
                {discountTypeState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <label>
                  Discount Value<span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Enter Discount Value"
                  type="number"
                  name="discount_value"
                  onChange={(e) =>
                    inputFiledHandle(e.target.name, e.target.value)
                  }
                ></Form.Control>
                {discountValueState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Form.Group>
                <label>
                  Applicable To
                </label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={applicableHandle}
                  name="applicabelTypeSelect"
                  options={selectOptions?.map((item) => ({
                    value: `${item?.value}`,
                    label: `${item?.value}`,
                  }))}
                  placeholder="Select"
                />
              </Form.Group>
            </Col>
            {
              regionApplicable && <>
                <Col md="4">
              <Form.Group>
                <label>
                  Region
                </label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => inputFiledHandle("region", e.value)}
                  name="regionTypeSelect"
                  options={regions?.map((item) => ({
                    value: `${item?.id}`,
                    label: `${item?.name}`,
                  }))}
                  placeholder="Select Region"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <label>
                  Extension
                </label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => inputFiledHandle("extension", e.value)}
                  name="extensionTypeSelect"
                  options={extensions?.map((item) => ({
                    value: `${item?.id}`,
                    label: `${item?.name}`,
                  }))}
                  placeholder="Select Extension"
                />
              </Form.Group>
            </Col>
            </>
            }
          </Row>
          <Row>
            <Col md="6">
              <Form.Group>
                <label>
                  Start Date
                </label>
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Start Date",
                  }}
                  timeFormat={false}
                  closeOnSelect
                  onChange={(e) =>
                    inputFiledHandle("start_date", formatDateOnly(e._d))
                  }
                ></ReactDatetime>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <label>
                  End Date
                </label>
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "End Date",
                  }}
                  timeFormat={false}
                  closeOnSelect
                  onChange={(e) =>
                    inputFiledHandle("end_date", formatDateOnly(e._d))
                  }
                ></ReactDatetime>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>Description</label>
                <Form.Control
                  placeholder="Enter Description"
                  type="text"
                  as="textarea"
                  rows={3}
                  name="description"
                  onChange={(e) =>
                    inputFiledHandle(e.target.name, e.target.value)
                  }
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <div className="card-category form-category">
                <span className="star">*</span>
                Required fields
              </div>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="2">
              <Button variant="primary" onClick={onConfirm} block>
                Add
              </Button>
            </Col>
            <Col md="2">
              <Button
                className="btn-outline"
                variant="danger"
                onClick={() => setAddNew(false)}
                block
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
AddDiscount.propTypes = {
  setAddNew: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddDiscount;
