import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  Button,
  Row,
  Col,
  Table,
  Form,
  Container,
} from "react-bootstrap";
import Select from "react-select";
import RequiredLabel from "ui/RequiredLabel";
import { newCategories } from "util/MasterUtil";
import {
  formValidation,
  dynamicHandle,
  dynamicDeleteRowHandle,
} from "util/CommonUtil";
import Route from "routes/Route";

const AddNewCategory = ({ setAddNew, responseHandle, setResponseTitle }) => {
  const token = localStorage.getItem("token");
  const [nameState, setNameState] = React.useState(true);
  const [codeState, setCodeState] = React.useState(true);
  const [newDetails, setNewDetails] = React.useState({
    sale_type: "",
    code: "",
    description: "",
    sub_category: "",
  });
  const inputFieldHandle = (name, value) => {
    name === "sale_type"
      ? setNameState(true)
      : name === "code"
      ? setCodeState(true)
      : null;
    setNewDetails({
      ...newDetails,
      [name]: value,
    });
  };
  const [addedRow, setAddedRow] = React.useState([]);
  const [addedSubCategoryDetails, setAddedSubCategoryDetails] = React.useState(
    []
  );

  const subCategoryInputFieldHandle = (id, e) => {
    dynamicHandle(
      id,
      e.target.name,
      e.target.value,
      setAddedSubCategoryDetails
    );
  };
  const [productTypes, setProductTypes] = React.useState([]);
  const fetchProductTypes = async () => {
    const response = await Route.fetch("sale-types", token);
    if (response.status === 200) {
      setProductTypes(response?.data?.saleType);
    }
  };
  React.useEffect(() => {
    fetchProductTypes();
  }, []);

  const [rows, setRows] = React.useState(0);
  const row = (id) => (
    <tr key={id} id={id}>
      <td>
        <Form.Group>
          <Form.Control
            placeholder="Name"
            type="text"
            name="name"
            onChange={(e) => subCategoryInputFieldHandle(id, e)}
          ></Form.Control>
        </Form.Group>
      </td>
      <td>
        <Form.Group>
          <Form.Control
            placeholder="Code"
            type="text"
            name="code"
            onChange={(e) => subCategoryInputFieldHandle(id, e)}
          ></Form.Control>
        </Form.Group>
      </td>
      <td>
        <Form.Group>
          <Form.Control
            placeholder="Description"
            type="text"
            name="description"
            onChange={(e) => subCategoryInputFieldHandle(id, e)}
          ></Form.Control>
        </Form.Group>
      </td>
      <td>
        <Button
          variant="danger"
          className="btn-link remove text-danger"
          onClick={() =>
            dynamicDeleteRowHandle(id, setAddedSubCategoryDetails, setAddedRow)
          }
        >
          <i className="fa fa-trash" />
        </Button>
      </td>
    </tr>
  );
  const addNewRowHandle = () => {
    const newId = rows + 1;
    setRows(newId);
    setAddedRow((prev) => [...prev, row(newId)]);
  };
  const validationConditions = [
    {
      field: "categoryName",
      condition: newDetails.sale_type === "",
      setState: setNameState,
    },
    {
      field: "code",
      condition: newDetails.code === "",
      setState: setCodeState,
    },
  ];
  const updateHandle = async () => {
    if (formValidation(validationConditions)) {
      const data = {
        sale_type: newDetails?.sale_type,
        code: newDetails?.code,
        description: newDetails?.description,
        subCategories: newCategories(addedSubCategoryDetails),
      };
      const response = await Route.addNew("categories", token, data);
      if (response.status === 200) {
        setAddNew(false);
        responseHandle(true);
        setResponseTitle(response.data.message);
      } else {
        setResponseTitle("Failed To Add!");
        responseHandle(false);
      }
    }
  };
  return (
    <Container>
      <Card>
        <Card.Header>
          <Card.Title>Add New Category and Sub-Category</Card.Title>
        </Card.Header>
        <Card.Body>
          <Card.Title>Category</Card.Title>
          <Row className="mt-2">
            <Col md="4">
              <label>
                Product Type <span className="star">*</span>
              </label>
              <Form.Group>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  onChange={(e) => inputFieldHandle("sale_type", e.value)}
                  options={productTypes?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  placeholder="Select"
                />
                {nameState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col md="4">
              <label>
                Code <span className="star">*</span>
              </label>
              <Form.Group>
                <Form.Control
                  placeholder="Code"
                  type="text"
                  value={newDetails?.code}
                  name="code"
                  onChange={(e) =>
                    inputFieldHandle(e.target.name, e.target.value)
                  }
                ></Form.Control>
                {codeState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col md="4">
              <label>Description</label>
              <Form.Group>
                <Form.Control
                  placeholder="Description"
                  type="text"
                  name="description"
                  value={newDetails?.description}
                  onChange={(e) =>
                    inputFieldHandle(e.target.name, e.target.value)
                  }
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card.Title>Sub-Category</Card.Title>
              <Table className="table-hover w-full">
                <thead>
                  <tr>
                    <th>
                      Name <span className="star">*</span>
                    </th>
                    <th>Code <span className="star">*</span></th>
                    <th>Description</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {addedRow.length > 0 && addedRow?.map((item) => item)}
                </tbody>
              </Table>
            </Col>
          </Row>
          <div className="card-category form-category">
            <span className="star">*</span>
            Required fields
          </div>
          <Row>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className="btn-outline"
                type="button"
                variant="default"
                onClick={addNewRowHandle}
              >
                Add New <i className="fa fa-plus"></i>
              </Button>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="3">
              <Button onClick={updateHandle} variant="primary" block>
                Add
              </Button>
            </Col>
            <Col md="3">
              <Button
                onClick={() => setAddNew(false)}
                block
                variant="danger"
                className="btn-outline"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Container>
  );
};

AddNewCategory.propTypes = {
  setAddNew: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddNewCategory;
