import React from "react";
import { Container, Card, Form, Row, Col, Button } from "react-bootstrap";
import RequiredLabel from "ui/RequiredLabel";
import NotificationAlert from "ui/NotificationAlert";
import PropTypes from "prop-types";
import Select from "react-select";
import { formValidation } from "util/CommonUtil";
import Route from "routes/Route";

const UploadFile = ({ setUploadFile, responseHandle, setResponseTitle }) => {
  const token = localStorage.getItem("token");
  const [categoryState, setCategoryState] = React.useState(true);
  const [subCategoryState, setSubCategoryState] = React.useState(true);
  const [fileState, setFileState] = React.useState(true);
  const [category, setCategory] = React.useState([]);
  const [subCategory, setSubCategory] = React.useState([]);
  const [color, setColor] = React.useState([]);
  const [btnClicked, setBtnClicked] = React.useState(false);
  const [showNotification, setShowNotificaion] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");

  const fetchCategory = async () => {
    const response = await Route.fetch("categories", token);
    if (response?.status === 200) {
      setCategory(response?.data?.category);
    }
  };
  const fetchColor = async () => {
    const response = await Route.fetch("colors", token);
    if (response?.status === 200) {
      setColor(response?.data?.color);
    }
  };
  React.useEffect(() => {
    fetchCategory();
    fetchColor();
  }, []);
  const [productDetails, setProductDetails] = React.useState({
    product_category: "",
    product_category_name: "",
    product_sub_category: "",
    product_sub_category_name: "",
    color: "",
    attachment: "",
  });
  const fetchSubCategory = async (id) => {
    const response = await Route.fetchDetails("edit-categories", token, id);
    if (response.status === 200) {
      setSubCategory(response?.data?.category?.sub_categories);
    } else {
      setNotificationText("Error While Fetching Sub-Categories");
      setShowNotificaion(true);
    }
  };
  const categoryHandle = (e) => {
    setBtnClicked(false);
    e.name === "product_category" ? setCategoryState(true) : null;
    e.name === "product_category" ? fetchSubCategory(e.value) : null;
    setProductDetails({
      ...productDetails,
      [e.name]: e.value,
      product_category_name: e.label,
    });
  };
  const subCategoryHandle = (e) => {
    setBtnClicked(false);
    e.name === "product_sub_category"
      ? setSubCategoryState(true)
      : null;
    setProductDetails({
      ...productDetails,
      [e.name]: e.value,
      product_sub_category_name: e.label,
    });
  };
  const colorHandle = (e) => {
    setBtnClicked(false);
    setProductDetails({
      ...productDetails,
      [e.name]: e.value,
    });
  };
  const fileHandle = (e) => {
    setFileState(true);
    setBtnClicked(false);
    setProductDetails({
      ...productDetails,
      attachment: e.target.files[0],
    });
  };
  const validationConditions = [
    {
      field: "product_category",
      condition: productDetails.product_category === "",
      setState: setCategoryState,
    },
    {
      field: "product_sub_category",
      condition: productDetails.product_sub_category === "",
      setState: setSubCategoryState,
    },
    {
      field: "file",
      condition: productDetails.attachment === "",
      setState: setFileState,
    },
  ];
  const submitHandle = async () => {
    setBtnClicked(true);
    if (formValidation(validationConditions)) {
      let data = new FormData();
      data.append("attachment", productDetails.attachment);
      data.append("product_category", productDetails.product_category);
      data.append("product_category_name", productDetails.product_category_name);
      data.append("product_sub_category", productDetails.product_sub_category);
      data.append("product_sub_category_name", productDetails.product_sub_category_name);
      data.append("color", productDetails.color);
      const response = await Route.uploadFile("uploads", token, data);
      setUploadFile(false);
      if (response?.status === 200) {
        setResponseTitle(response?.data?.message);
        responseHandle(true);
      } else if (response?.status === 201) {
        setResponseTitle(response?.data?.message);
        responseHandle(false);
      } else {
        setResponseTitle(response?.data?.message);
        responseHandle(false);
      }
    }
  };
  return (
    <Container>
      <Card.Title as="h4">Upload File</Card.Title>
      <Row>
        <Col md="4" sm="12">
          <Form.Group>
            <label>
              Category <span className="star">*</span>
            </label>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              name="categorySelect"
              onChange={categoryHandle}
              options={category?.map((item) => ({
                value: `${item?.id}`,
                label: `${item?.sale_type?.name}`,
                name: "product_category",
              }))}
              placeholder="Select"
            />
            {categoryState ? null : <RequiredLabel />}
          </Form.Group>
        </Col>
        <Col md="4" sm="12">
          <Form.Group>
            <label>
              Sub-Category <span className="star">*</span>
            </label>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              name="subCategorySelect"
              onChange={subCategoryHandle}
              options={subCategory?.map((item) => ({
                value: `${item?.id}`,
                label: `${item?.name}`,
                name: "product_sub_category",
              }))}
              placeholder="Select"
            />
            {subCategoryState ? null : <RequiredLabel />}
          </Form.Group>
        </Col>
        <Col md="4" sm="12">
          <Form.Group>
            <label>Color</label>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              name="colorSelect"
              onChange={colorHandle}
              options={color?.map((item) => ({
                value: `${item?.id}`,
                label: `${item?.name}`,
                name: "color",
              }))}
              placeholder="Select"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="12" sm="12">
          <Form.Group>
            <label>
              File input <span className="star">*</span>
            </label>
            <Form.File id="uploadFile" onChange={fileHandle} />
            {fileState ? null : <RequiredLabel />}
          </Form.Group>
        </Col>
      </Row>
      <div className="card-category form-category">
        <span className="star">*</span>
        Required fields (Color Need To Be Selected For Phone and Accessories)
      </div>
      <Row style={{ display: "flex", justifyContent: "flex-end" }}>
        <Col className="mt-4" md="3">
          <Button
            onClick={submitHandle}
            className={btnClicked ? "btn-outline" : "btn-fill"}
            variant="primary"
            disabled={btnClicked}
            block
          >
            Upload
          </Button>
        </Col>
        <Col className="mt-4" md="3">
          <Button
            onClick={() => setUploadFile(false)}
            variant="danger"
            className="btn-outline"
            block
          >
            Cancel
          </Button>
        </Col>
      </Row>
      {showNotification && (
        <NotificationAlert
          setAlert={setShowNotificaion}
          title={notificationText}
        />
      )}
    </Container>
  );
};
UploadFile.propTypes = {
  setUploadFile: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default UploadFile;
