import React from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import RegionalIndexHistory from "./Components/regional_store/regional_transfer/RegionalIndexHistory";
import RegionalProductRequistions from "./Components/regional_store/regional_transfer/RegionalProductRequisition";

const RegionalStoreTransaction = () => {
  return (
    <>   
      <Card>
        <Card.Header>
          <Card.Title as="h4">Regional Store Transaction</Card.Title>
        </Card.Header>
        <Card.Body>
          <Tab.Container
            id="plain-tabs-example"
            defaultActiveKey="index-history"
          >
            <Nav role="tablist" variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="index-history">Index History</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Transfer-index">Product Requisition</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="index-history">
                <RegionalIndexHistory />
              </Tab.Pane>
              <Tab.Pane eventKey="Transfer-index">
                <RegionalProductRequistions />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
    </>
  );
};

export default RegionalStoreTransaction;
