import React from "react";
import { Button, Card, Container, Row, Col, Modal } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import AddBank from "./Components/masters/bank/AddBank";
import EditBank from "./Components/masters/bank/EditBank";
import ViewBank from "./Components/ViewBank";
import Alert from "ui/Alert";
import NotificationAlert from "ui/NotificationAlert";
import DeleteAlert from "ui/DeleteAlert";
import { bankTableHeader } from "data/dummy";
import Route from "routes/Route";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { checkPermission } from "util/MasterUtil";

const Banks = () => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showAlert, setShowAlert] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const [response, setResponse] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [banks, setBanks] = React.useState([]);
  const [addNew, setAddNew] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const token = localStorage.getItem("token");
  const editHandle = async (id) => {
    if (!checkPermission("banks.edit-banks", permissionsArray)) {
      return setShowNotification(true);
    }
    const response = await Route.fetchDetails("edit-banks", token, id);
    if (response.status === 200) {
      setDetails(response?.data);
      setEdit(true);
    } else {
      setNotificationText("Couldn't Fetch Details");
      setShowNotification(true);
    }
  };
  const { isLoading, data: res } = useQuery(
    "banksData",
    () => Route.fetch("banks", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const viewDetailsHandle = async (id) => {
    try {
      const { data } = await Route.fetchDetails("edit-banks", token, id);
      if (data) {
        setDetails(data);
        setView(true);
      } else {
        setNotificationText("Error: banks not found");
        setShowNotification(true);
      }
    } catch (error) {
      setNotificationText("Couldn't Fetch User Details");
      setShowNotification(true);
    }
  };
  const [tempData, setTempData] = React.useState([]);
  const [bankId, setBankId] = React.useState("");
  const [deleteBank, setDeleteBank] = React.useState(false);
  const deleteHandle = (id) => {
    setBankId(id);
    setDeleteBank(true);
  };
  const deleteBankHandle = async () => {
    setDeleteBank(false);
    const res = await Route.delete("banks", token, bankId);
    if (res.status === 200) {
      setResponse(true);
      setResponseTitle(res?.data?.message);
      setShowAlert(true);
    } else {
      setResponse(false);
      setResponseTitle("Failed To Delete Bank ❌");
      setShowAlert(true);
    }
  };
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.bank);
      if (tempData !== res?.data?.bank) {
        setBanks(
          res?.data?.bank?.map((item) => {
            return {
              id: item?.id,
              name: item?.name,
              code: item?.code,
              accNo: item?.account_number,
              actions: (
                <div className="actions-right">
                  <Button
                    className="text-primary btn-link edit"
                    onClick={() => viewDetailsHandle(item?.id)}
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                  <Button
                    onClick={() => editHandle(item.id)}
                    variant="warning"
                    size="sm"
                    className="text-dark btn-link edit"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    onClick={() => deleteHandle(item.id)}
                    variant="danger"
                    size="sm"
                    className="btn-link remove text-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  const addNewRegionHandle = () => {
    !checkPermission("banks.store", permissionsArray)
      ? setShowNotification(true)
      : setAddNew(true);
  };
  return (
    <>
      {addNew ? (
        <AddBank
          setAddNew={setAddNew}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <h4 className="title">Banks</h4>
              <Card>
                <Card.Body>
                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button className="btn-outline" onClick={addNewRegionHandle}>
                      Add New Banks <i className="fa fa-plus"></i>
                    </Button>
                  </Col>
                  <ReactTable
                    data={banks}
                    columns={bankTableHeader}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {view && (
        <Modal
          show={view}
          onHide={() => setView(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Body>
            <ViewBank setView={setView} details={details} />
          </Modal.Body>
        </Modal>
      )}
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
      {edit && (
        <Modal
          show={edit}
          onHide={() => setEdit(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Body>
            <EditBank
              setEdit={setEdit}
              details={details}
              responseHandle={responseHandle}
              setResponseTitle={setResponseTitle}
            />
          </Modal.Body>
        </Modal>
      )}
      {deleteBank && (
        <DeleteAlert deleteHandle={deleteBankHandle} setAlert={setDeleteBank} />
      )}
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      )}
    </>
  );
};

export default Banks;
