import React from "react";
import { BrowserRouter, Route as AppRoute, Switch } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import LoginPage from "./views/Pages/LoginPage";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <AppRoute path="/admin" render={(props) => <AdminLayout {...props} />} />
          <AppRoute path="/" render={(props) => <LoginPage {...props} />} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
