import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types";

const DeleteAlert = ({ deleteHandle, setAlert }) => {
  return (
    <>
      <SweetAlert
        danger
        showCancel
        confirmBtnText="Delete"
        cancelBtnBsStyle="default"
        title="Are You Sure?"
        onConfirm={deleteHandle}
        onCancel={() => setAlert(false)}
        focusCancelBtn
      ></SweetAlert>
    </>
  );
};
DeleteAlert.propType = {
  deleteHandle: PropTypes.func,
  setAlert: PropTypes.func,
};

export default DeleteAlert;
