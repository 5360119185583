import React from 'react';
import { Card, Row, Col } from "react-bootstrap";
import ChartistGraph from "react-chartist";
import Route from 'routes/Route';
import { phoneSalesSeries } from 'util/CommonUtil';

const SalesChart = () => {
  const [year, setYear] = React.useState(new Date().getFullYear())
  const token = localStorage.getItem("token");
  const [salesData, setSalesData] = React.useState([]);
  const fetchSales = async()=> {
    const response = await Route.fetchSalesWithParam("sales", token, year);
    if (response?.status === 200) {
      setSalesData(phoneSalesSeries(response?.data?.sales));
    };
  };
  React.useEffect(() => {
    fetchSales();
  },[]);
  return (
    <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Sales</Card.Title>
              </Card.Header>
              <Card.Body>
                <ChartistGraph
                  data={{
                    labels: [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "Mai",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec"
                    ],
                    series: salesData
                  }}
                  type="Bar"
                  options={{
                    seriesBarDistance: 10,
                    axisX: {
                      showGrid: false
                    },
                    height: "245px"
                  }}
                  responsiveOptions={[
                    [
                      "screen and (max-width: 640px)",
                      {
                        seriesBarDistance: 5,
                        axisX: {
                          labelInterpolationFnc: function (value) {
                            return value[0];
                          }
                        }
                      }
                    ]
                  ]}
                />
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle mr-1 text-info"></i>
                  Mobile Phones{" "}
                  <i className="fas fa-circle mr-1 text-danger"></i>
                  Accessories
                  <i className="fas fa-circle mr-1 text-warning"></i>
                  SIM
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
  )
}

export default SalesChart;

