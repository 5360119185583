import React from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import RequiredLabel from "ui/RequiredLabel";
import { formValidation } from "util/CommonUtil";
import Route from "routes/Route";
import NotificationAlert from "ui/NotificationAlert";

const Replace = ({
  setShowReplace,
  details,
  customerType,
  customerName,
  customerNumber,
}) => {
  const [notificationText, setNotificationText] = React.useState("");
  const [showNotification, setShowNotification] = React.useState(false);
  const [invoiceNo, setInvoiceNo] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [subCategories, setSubCategories] = React.useState([]);
  const [replaceProductDetails, setReplaceProductDetials] = React.useState({
    category: "",
    subCategory: "",
    itemNumber: "",
    serialNumber: "",
    price: "",
    qty: "",
    subInventory: "",
    locator: "",
    iccid: "",
    description: "",
  });
  const [categoryState, setCategoryState] = React.useState(true);
  const [subCategoryState, setSubCategoryState] = React.useState(true);
  const [itemNumberState, setItemNumberState] = React.useState(true);
  const [serialNumberState, setSerialNumberState] = React.useState(true);
  const [priceState, setPriceState] = React.useState(true);
  const [qtyState, setQtyState] = React.useState(true);
  const [descriptionState, setDescriptionState] = React.useState(true);
  const token = localStorage.getItem("token");
  const fetchCategory = async () => {
    const response = await Route.fetch("categories", token);
    if (response.status === 200) {
      setCategories(response?.data?.category);
    }
  };
  const fetchSubCategory = async (e) => {
    const response = await Route.fetchDetails(
      "edit-categories",
      token,
      e.value
    );
    if (response.status === 200) {
      setSubCategories(response?.data?.category?.sub_categories);
    }
  };
  React.useEffect(() => {
    fetchCategory();
  }, []);
  const customerTypeHandle = (e) => {
    setCategoryState(true);
    fetchSubCategory(e);
    setReplaceProductDetials({
      ...replaceProductDetails,
      category: e.value,
    });
  };
  const subCategoryHandle = (e) => {
    setSubCategoryState(true);
    setReplaceProductDetials({
      ...replaceProductDetails,
      subCategory: e.value,
    });
  };
  const itemNoHanlde = (e) => {
    setItemNumberState(true);
    setReplaceProductDetials({
      ...replaceProductDetails,
      itemNumber: e.target.value,
    });
  };
  const serialNumberHandle = (e) => {
    setSerialNumberState(true);
    setReplaceProductDetials({
      ...replaceProductDetails,
      serialNumber: e.target.value,
    });
  };
  const priceHandle = (e) => {
    setPriceState(true);
    setReplaceProductDetials({
      ...replaceProductDetails,
      price: e.target.value,
    });
  };
  const qtyHandle = (e) => {
    setQtyState(true);
    setReplaceProductDetials({
      ...replaceProductDetails,
      qty: e.target.value,
    });
  };
  const subInventoryHandle = (e) => {
    setReplaceProductDetials({
      ...replaceProductDetails,
      subInventory: e.target.value,
    });
  };
  const locatorHandle = (e) => {
    setReplaceProductDetials({
      ...replaceProductDetails,
      locator: e.target.value,
    });
  };
  const iccidHandle = (e) => {
    setReplaceProductDetials({
      ...replaceProductDetails,
      iccid: e.target.value,
    });
  };
  const descriptionHandle = (e) => {
    setDescriptionState(true);
    setReplaceProductDetials({
      ...replaceProductDetails,
      description: e.target.value,
    });
  };
  const validationConditions = [
    {
      field: "category",
      condition: replaceProductDetails?.category === "",
      setState: setCategoryState,
    },
    {
      field: "subCategory",
      condition: replaceProductDetails?.subCategory === "",
      setState: setSubCategoryState,
    },
    {
      field: "itemNo",
      condition: replaceProductDetails?.itemNumber === "",
      setState: setItemNumberState,
    },
    {
      field: "serialNo",
      condition: replaceProductDetails?.serialNumber === "",
      setState: setSerialNumberState,
    },
    {
      field: "price",
      condition: replaceProductDetails?.price === "",
      setState: setPriceState,
    },
    {
      field: "qty",
      condition: replaceProductDetails?.qty === "",
      setState: setQtyState,
    },
    {
      field: "description",
      condition: replaceProductDetails?.description === "",
      setState: setDescriptionState,
    },
  ];
  const user = JSON.parse(localStorage.getItem("user"));
  const replaceHandle = async () => {
    if (formValidation(validationConditions)) {
      const data = {
        customerType: customerType,
        customerName: customerName,
        customerNo: customerNumber,
        oldDetails: details[0],
        newDetails: replaceProductDetails,
        region: user?.assign_and_employee?.regional_id,
        extension: user?.assign_and_employee?.extension_id,
      };
      const response = await Route.addNew("replace", token, data);
      if (response?.status === 200) {
        setInvoiceNo(response?.data?.invoice_no);
        setShowModal(true);
      };
      if (response?.status == 203) {
        setNotificationText(response?.data?.message);
        setShowNotification(true);
      };
    }
  };
  return (
    <>
      <Card className="stacked-form">
        <Card.Header>
          <Card.Title as="h4">Replace</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md="3">
              <Form.Group>
                <label>
                  Category <span className="star">*</span>
                </label>
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="categorySelect"
                  onChange={(e) => customerTypeHandle(e)}
                  options={categories?.map((item) => ({
                    value: `${item?.id}`,
                    label: `${item?.sale_type?.name}`,
                  }))}
                  placeholder="Select"
                />
                {categoryState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>
                  Sub-Category <span className="star">*</span>
                </label>
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="subCategorySelect"
                  onChange={(e) => subCategoryHandle(e)}
                  options={subCategories?.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.name}`,
                  }))}
                  placeholder="Select"
                />
                {subCategoryState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>
                  Item No. <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Enter Item No."
                  type="text"
                  onChange={itemNoHanlde}
                ></Form.Control>
                {itemNumberState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>
                  Serial No. <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Enter Serial No."
                  type="text"
                  onChange={serialNumberHandle}
                ></Form.Control>
                {serialNumberState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Form.Group>
                <label>
                  Price <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Enter Price"
                  type="text"
                  onChange={priceHandle}
                ></Form.Control>
                {priceState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group>
                <label>
                  Quantity <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Enter Quantity"
                  type="text"
                  onChange={qtyHandle}
                ></Form.Control>
                {qtyState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Sub-Inventory</label>
                <Form.Control
                  placeholder="Enter Sub-inventory"
                  type="text"
                  onChange={subInventoryHandle}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group>
                <label>Locator</label>
                <Form.Control
                  placeholder="Enter Locator"
                  type="text"
                  onChange={locatorHandle}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group>
                <label>ICCID</label>
                <Form.Control
                  placeholder="Enter Price"
                  type="text"
                  onChange={iccidHandle}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Form.Group controlId="descriptionTextarea">
                <label>
                  Description <span className="star">*</span>
                </label>
                <Form.Control
                  onChange={descriptionHandle}
                  as="textarea"
                  rows={3}
                />
                {descriptionState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="3">
              <Button
                className="btn-fill"
                type="submit"
                variant="primary"
                block
                onClick={replaceHandle}
              >
                Replace
              </Button>
            </Col>
            <Col md="3">
              <Button
                className="btn-outline"
                type="button"
                variant="danger"
                block
                onClick={() => setShowReplace(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      {showModal ? (
        <Modal
          show={showModal}
          onHide={() => {
            setShowReplace(false);
            setShowModal(false);
          }}
        >
          <Modal.Body>
            <Row>
              <Col>New Invoice No. {invoiceNo}</Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <Col md="3">
                <Button
                  variant="danger"
                  onClick={() => {
                    setShowReplace(false);
                    setShowModal(false);
                  }}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      ) : null}
      {showNotification ? (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      ) : null}
    </>
  );
};

Replace.propType = {
  setShowReplace: PropTypes.func,
  details: PropTypes.array.isRequired,
  customerType: PropTypes.number,
  customerName: PropTypes.string,
  customerNumber: PropTypes.string,
};

export default Replace;
