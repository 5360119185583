import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  Button,
  Row,
  Col,
  Table,
  Form,
  Modal
} from "react-bootstrap";
import NotificationAlert from "ui/NotificationAlert";
import Select from "react-select";
import NewSubCategory from "./NewSubCategory";
import { subCategories } from "util/MasterUtil";
import Route from "routes/Route";
import { checkPermission } from "util/MasterUtil";
import { dynamicHandle } from "util/CommonUtil";
import { useSelector } from "react-redux";

const EditCategory = ({ edit, setEdit, details, responseHandle, setResponseTitle }) => {
  const token = localStorage.getItem("token");
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showNotification, setShowNotificaion] = React.useState(false);
  const [productType, setProductType] = React.useState([]);
  const fetchProductTypes = async() => {
    const response = await Route.fetch("sale-types", token);
    if (response.status === 200) {
      setProductType(response?.data?.saleType);
    };
  };
  React.useEffect(() => {
    fetchProductTypes();
  },[])
  const [newDetails, setNewDetails] = React.useState({
    sale_type: details?.sale_type_id,
    code: details?.code,
    description: details?.description,
    sub_categories: details?.sub_categories,
  });
  const categoryInputFieldHandle = (name, value) => {
    setNewDetails({
      ...newDetails,
      [name]: value,
    });
  };
  const deleteSubCategoryHandle = (id) => {
    const updatedSubCategory = newDetails?.sub_categories.filter(
      (item) => item.id !== id
    );
    setNewDetails({
      ...newDetails,
      sub_categories: updatedSubCategory,
    });
  };
  const subCategoryHandle = (e, id) => {
    const updatedSubCategory = newDetails?.sub_categories.map((item) =>
      item.id === id ? { ...item, [e.target.name]: e.target.value } : item
    );
    setNewDetails({
      ...newDetails,
      sub_categories: updatedSubCategory,
    });
  };
  const [addedRow, setAddedRow] = React.useState([]);
  const [addedSubCategoryDetails, setAddedSubCategoryDetails] = React.useState(
    []
  );
  const subCategoryInputFieldHandle = (id, e) => {
    dynamicHandle(id, e.target.name, e.target.value, setAddedSubCategoryDetails);
  };
  const [rows, setRows] = React.useState(0);
  const row = (id) => (<NewSubCategory id={id} subCategoryInputFieldHandle={subCategoryInputFieldHandle} setAddedSubCategoryDetails={setAddedSubCategoryDetails} setAddedRow={setAddedRow} />)
  const addNewRowHandle = () => {
    const newId = rows + 1;
    setRows(newId);
    setAddedRow((prev) => [...prev, row(newId)]);
  };
  const updateHandle = async () => {
    if (!checkPermission("categories.update", permissionsArray)) {
      return setShowNotificaion(true);
    } 
    const subCateoriesData = subCategories(
      addedSubCategoryDetails,
      newDetails?.sub_categories
    );
    const data = {
      sale_type: newDetails?.sale_type,
      code: newDetails?.code,
      description: newDetails?.description,
      subCategories: subCateoriesData,
    };
    const response = await Route.update("categories", token, details.id, data);
    if (response.status === 200) {
      setEdit(false);
      setResponseTitle(response?.data?.message);
      responseHandle(true);
    } else {
      setEdit(false);
      setResponseTitle("Failed To Update");
      responseHandle(false);
    }
  };
  return (
    <Modal show={edit} onHide={() => setEdit(false)} size="lg">
        <Card.Body>
        <Modal.Title>Edit Category and Sub-Category</Modal.Title>
          <Card.Title>Category</Card.Title>
          <Row className="mt-2">
            <Col md="4">
              <Form.Group>
              <label>Product Type <span className="star">*</span></label>
                <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="sale_type"
                    defaultValue={[
                      {
                        value: details?.sale_type_id,
                        label: details?.sale_type?.name,
                      }]}
                    onChange={(e) => categoryInputFieldHandle("sale_type", e.value)}
                    options={productType?.map((item) => ({
                      value: item?.id,
                      label: item?.name,
                    }))}
                    placeholder="Select"
                  />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
              <label>Code <span className="star">*</span></label>
                <Form.Control
                  placeholder="Code"
                  type="text"
                  name="code"
                  defaultValue={newDetails?.code}
                  onChange={(e) => categoryInputFieldHandle(e.target.name, e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
              <label>Description</label>
                <Form.Control
                  placeholder="Description"
                  type="text"
                  name="description"
                  defaultValue={newDetails?.description}
                  onChange={(e) => categoryInputFieldHandle(e.target.name, e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card.Title>Sub-Category</Card.Title>
              <Table className="table-hover w-full">
                <thead>
                  <tr>
                    <th>Name <span className="star">*</span></th>
                    <th>Code <span className="star">*</span></th>
                    <th>Description</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {newDetails?.sub_categories?.map((item) => (
                    <tr key={item?.id}>
                      <td>
                        <Form.Group>
                          <Form.Control
                            placeholder="Description"
                            type="text"
                            defaultValue={item?.name}
                            name="name"
                            onChange={(e) => subCategoryHandle(e, item?.id)}
                          ></Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            placeholder="Description"
                            type="text"
                            defaultValue={item?.code}
                            name="code"
                            onChange={(e) => subCategoryHandle(e, item?.id)}
                          ></Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            placeholder="Description"
                            type="text"
                            defaultValue={item?.description}
                            name="description"
                            onChange={(e) => subCategoryHandle(e, item?.id)}
                          ></Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Button
                          variant="danger"
                          // size="sm"
                          className="btn-link remove text-danger"
                          onClick={() => deleteSubCategoryHandle(item?.id)}
                        >
                          <i className="fa fa-trash"></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                  {addedRow.length > 0 && addedRow?.map((item) => item)}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className="btn-outline"
                type="button"
                variant="default"
                onClick={addNewRowHandle}
              >
                Add New <i className="fa fa-plus"></i>
              </Button>
            </Col>
          </Row>
          <div className="card-category form-category">
            <span className="star">*</span>
            Required fields
          </div>
          <Row className="mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="3">
              <Button onClick={updateHandle} variant="primary" block>
                Update
              </Button>
            </Col>
            <Col md="3">
              <Button onClick={() => setEdit(false)} block className="btn-outline" variant="danger">
                Cancel
              </Button>
            </Col>
          </Row>
        </Card.Body>
      { showNotification && <NotificationAlert setAlert={setShowNotificaion}  /> }
    </Modal>
  );
};

EditCategory.propTypes = {
  edit: PropTypes.bool,
  setEdit: PropTypes.func,
  details: PropTypes.object.isRequired,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default EditCategory;
