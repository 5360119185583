import React from 'react';
import { Form, Button } from 'react-bootstrap';
import PropTypes from "prop-types";
import { dynamicDeleteRowHandle } from 'util/CommonUtil';

const NewSubCategory = ({id, subCategoryInputFieldHandle, setAddedSubCategoryDetails, setAddedRow}) => {
  return (
     <tr key={id} id={id}>
      <td>
        <Form.Group>
          <Form.Control
            placeholder="Name"
            type="text"
            name="name"
            onChange={(e) => subCategoryInputFieldHandle(id, e)}
          ></Form.Control>
        </Form.Group>
      </td>
      <td>
        <Form.Group>
          <Form.Control
            placeholder="Code"
            type="text"
            name="code"
            onChange={(e) => subCategoryInputFieldHandle(id, e)}
          ></Form.Control>
        </Form.Group>
      </td>
      <td>
        <Form.Group>
          <Form.Control
            placeholder="Description"
            type="text"
            name="description"
            onChange={(e) => subCategoryInputFieldHandle(id, e)}
          ></Form.Control>
        </Form.Group>
      </td>
      <td>
        <Button
          variant="danger"
          className="btn-link remove text-danger"
          onClick={() => dynamicDeleteRowHandle(id, setAddedSubCategoryDetails, setAddedRow)}
        >
          <i className="fa fa-trash"></i>
        </Button>
      </td>
    </tr> 
  )
}
NewSubCategory.propTypes = {
  id: PropTypes.number,
  subCategoryInputFieldHandle: PropTypes.func,
  dynamicDeleteRowHandle: PropTypes.func,
  setAddedSubCategoryDetails: PropTypes.func,
  setAddedRow: PropTypes.func,
};

export default NewSubCategory;
