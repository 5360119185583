import React from "react";

const Background = () => {
  return (
    <div
      className="full-page-background"
      style={{
        backgroundImage:
          "url(" + require("assets/img/background-image.jpg") + ")",
      }}
    ></div>
  );
};

export default Background;
