import React from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import Alert from "ui/Alert";
import NotificationAlert from "ui/NotificationAlert";
import DeleteAlert from "ui/DeleteAlert";
import AddDiscount from "./Components/dealer/discount_type/AddDiscount";
import EditDiscount from "./Components/dealer/discount_type/EditDiscount";
import { discountTypeTableHeader } from "data/dummy";
import Route from "routes/Route";
import { useQuery } from "react-query";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const DiscountType = () => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [discountType, setDiscountType] = React.useState([]);
  const [addNew, setAddNew] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const token = localStorage.getItem("token");
  const editHandle = async (id) => {
    if (!checkPermission("discount-types.edit-discounts", permissionsArray)) {
      return setShowNotification(true);
    }
    const response = await Route.fetchDetails("edit-discounts", token, id);
    if (response.status === 200) {
      setDetails(response?.data?.discountType);
      setEdit(true);
    } else {
      setNotificationText("Couldn't Fetch Promotion Details");
      setShowNotification(true);
    }
  };
  const { isLoading, data: res } = useQuery(
    "discount-types",
    () => Route.fetch("discount-types", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const [tempData, setTempData] = React.useState([]);
  const [discountTypeId, setDiscountTypeId] = React.useState("");
  const [deleteDiscountType, setDeleteDiscountType] = React.useState(false);
  const deleteDiscountTypeHandle = async () => {
    setDeleteDiscountType(false);
    const res = await Route.delete("discount-types", token, discountTypeId);
    if (res.status === 200) {
      setResponse(true);
      setResponseTitle(res?.data?.message);
      setShowAlert(true);
    } else {
      setResponse(false);
      setResponseTitle("Failed To Delete Discount Type ❌");
      setShowAlert(true);
    }
  };
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.discountType);
      if (tempData !== res?.data?.discountType) {
        setDiscountType(
          res?.data?.discountType?.map((item) => {
            return {
              id: item?.id,
              discountName: item?.discount_name,
              discountType: item?.discount_type,
              discountValue: item?.discount_value,
              startDate: item?.start_date,
              endDate: item?.end_date,
              description: item?.description,
              actions: (
                <div className="actions-right">
                  <Button
                    onClick={() => editHandle(item.id)}
                    variant="warning"
                    size="sm"
                    className="text-dark btn-link edit"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    onClick={() => {
                      setDiscountTypeId(item?.id);
                      setDeleteDiscountType(true);
                    }}
                    variant="danger"
                    size="sm"
                    className="btn-link remove text-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      }
    }
  }, [res]);
  if (isLoading) return "Loading...";
  const addNewHandle = () => {
    !checkPermission("discount-types.store", permissionsArray)
      ? setShowNotification(true)
      : setAddNew(true);
  };
  return (
    <>
      {addNew ? (
        <AddDiscount
          addNew={addNew}
          setAddNew={setAddNew}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <h4 className="title">Discount Type</h4>
              <Card>
                <Card.Body>
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button variant="primary" onClick={addNewHandle} className="btn-outline">
                    Add New Discount <i className="fa fa-plus"></i>
                  </Button>
                </Col>
                  <ReactTable
                    data={discountType}
                    columns={discountTypeTableHeader}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
      {edit && (
        <EditDiscount
          edit={edit}
          details={details}
          setEdit={setEdit}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      )}
      {deleteDiscountType && (
        <DeleteAlert
          deleteHandle={deleteDiscountTypeHandle}
          setAlert={setDeleteDiscountType}
        />
      )}
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      )}
    </>
  );
};

export default DiscountType;
