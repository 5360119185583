import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import Route from "routes/Route";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const RepairTable = () => {
  const token = localStorage.getItem("token");
  const [repairData, setRepairData] = React.useState([]);
  const fetchRepair = async () => {
    const response = await Route.fetch("repair-list", token);
    if (response?.status === 200) {
      setRepairData(
        response?.data?.repair?.map((item, index) => ({
          id: index,
          serial_no: item?.product,
          sales_types: item?.sales_types,
          sub_categories: item?.sub_categories,
          replaced_parts: item?.replaced_parts,
          store_name: item?.store_name,
          total_quantity: parseInt(item?.total_quantity),
        }))
      );
    }
  };
  React.useEffect(() => {
    fetchRepair();
  }, []);
  const columns = [
    {
      dataField: "serial_no",
      text: "Serial No.",
      sort: true,
    },
    {
      dataField: "sales_types",
      text: "Category",
      sort: true,
    },
    {
      dataField: "sub_categories",
      text: "Sub-Category",
      sort: true,
    },
    {
      dataField: "replaced_parts",
      text: "Replaced Parts",
      sort: true,
    },
    {
      dataField: "store_name",
      text: "Store",
      sort: true,
    },
    {
      dataField: "total_quantity",
      text: "Qty",
      sort: true,
    },
  ];
  const defaultSorted = [
    {
      dataField: "user",
      order: "desc",
    },
  ];
  return (
    <Row>
      <Col md="12">
        <Card>
          <Card.Header>
            <Card.Title as="h4">Repair</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md="12">
                <BootstrapTable
                  keyField="id"
                  data={repairData}
                  columns={columns}
                  noDataIndication="Table is Empty"
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default RepairTable;
