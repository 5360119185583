import React from "react";
import { Button, Card, Container, Row, Col, Modal } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import { villageTableHeader } from "data/dummy";
import Alert from "ui/Alert";
import NotificationAlert from "ui/NotificationAlert";
import DeleteAlert from "ui/DeleteAlert";
import { useQuery } from "react-query";
import Route from "routes/Route";
import AddNewVillage from "./Components/masters/village/AddNewVillage";
import EditVillage from "./Components/masters/village/EditVillage";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const Villages = () => {
  const token = localStorage.getItem("token");
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [addNew, setAddNew] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState(
    "You Don't Have Permission"
  );
  const [edit, setEdit] = React.useState(false);
  const [details, setDetails] = React.useState({
    id: "",
    name: "",
    gewog_id: "",
    gewog: "",
    dzongkhag_id: "",
    dzongkhag: "",
  });
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const editVillageHandle = async (id) => {
    if (!checkPermission("villages.edit-villages", permissionsArray)) {
      return setShowNotification(true);
    }
    if (edit) {
      setEdit(false);
    }
    const response = await Route.fetchDetails("edit-villages", token, id);
    if (response.status === 200) {
      setDetails({
        ...details,
        id: response?.data?.village?.id,
        name: response?.data?.village?.name,
        gewog_id: response?.data?.village?.gewog?.id,
        gewog: response?.data?.village?.gewog?.name,
        dzongkhag_id: response?.data?.village?.gewog?.dzongkhag?.id,
        dzongkhag: response?.data?.village?.gewog?.dzongkhag?.name,
        code: response?.data?.village?.code,
      });
      setEdit(true);
    } else {
      setNotificationText("Error While Fetching Brand Detail");
      setShowNotification(true);
    }
  };
  const [tempData, setTempData] = React.useState([]);
  const [villagesData, setVillagesData] = React.useState([]);
  const { isLoading, data: res } = useQuery(
    "villagesData",
    () => Route.fetch("villages", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const [villageId, setVillageId] = React.useState("");
  const [deleteVillage, setDeleteVillage] = React.useState(false);
  const deleteHandle = (id) => {
    setVillageId(id);
    setDeleteVillage(true);
  };
  const deleteVillageHandle = async () => {
    setDeleteVillage(false);
    const res = await Route.delete("villages", token, villageId);
    if (res.status === 200) {
      setShowAlert(true);
      setResponse(true);
      setResponseTitle(res?.data?.message);
    } else {
      setShowAlert(true);
      setResponse(false);
      setResponseTitle("Failed To Delete Village");
    }
  };
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.villages);
      if (tempData !== res?.data?.villages) {
        setVillagesData(
          res?.data?.villages?.map((item) => {
            return {
              id: item.id,
              village: item.name,
              gewog: item?.gewog?.name,
              dzongkhag: item?.gewog?.dzongkhag?.name,
              actions: (
                <div className="actions-right">
                  <Button
                    onClick={() => editVillageHandle(item.id)}
                    variant="warning"
                    size="sm"
                    className="text-dark btn-link edit"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    onClick={() => deleteHandle(item?.id)}
                    variant="danger"
                    size="sm"
                    className="btn-link remove text-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      }
    }
  }, [res]);
  if (isLoading) return "Loading...";
  const addNewVillageHandle = () => {
    !checkPermission("villages.store", permissionsArray)
      ? setShowNotification(true)
      : setAddNew(true);
  };
  return (
    <>
      <Container fluid>
        {addNew ? (
          <AddNewVillage
            setAddNew={setAddNew}
            responseHandle={responseHandle}
            setResponseTitle={setResponseTitle}
          />
        ) : (
          <Row>
            <Col md="12">
              <h4 className="title">Villages</h4>
              <Card>
                <Card.Body>
                  <Col
                    md="12"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      variant="primary"
                      onClick={addNewVillageHandle}
                      className="btn-outline"
                    >
                      Add New Village <i className="fa fa-plus"></i>
                    </Button>
                  </Col>
                  <Col md="12">
                    <ReactTable
                      data={villagesData}
                      columns={villageTableHeader}
                      className="-striped -highlight primary-pagination"
                    />
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        {edit && (
          <Modal
            show={edit}
            onHide={() => setEdit(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Body>
              <EditVillage
                setEdit={setEdit}
                details={details}
                responseHandle={responseHandle}
                setResponseTitle={setResponseTitle}
              />
            </Modal.Body>
          </Modal>
        )}
      </Container>
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
      {deleteVillage && (
        <DeleteAlert
          deleteHandle={deleteVillageHandle}
          setAlert={setDeleteVillage}
        />
      )}
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      )}
    </>
  );
};

export default Villages;
