import React from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import RequiredLabel from "ui/RequiredLabel";
import PropTypes from "prop-types";
import { formValidation } from "util/CommonUtil";
import Route from "routes/Route";

const AddNewGewog = ({
  setAddNewGewog,
  responseHandle,
  setResponseTitle,
}) => {
  const token = localStorage.getItem("token");
  const [dzongkhags, setDzongkhags] = React.useState([]);
  const [gewogState, setGewogState] = React.useState(true);
  const [dzongkhagState, setDzongkhagState] = React.useState(true);
  const [newDetails, setNewDetails] = React.useState({
    name: "",
    dzongkhag_id: "",
    code: "",
  });
  const fetchDzongkhag = async() => {
    const response = await Route.fetch('dzongkhags',token);
    if (response.status === 200) {
        setDzongkhags(response?.data?.dzongkhag);
    };
  };
  React.useEffect(() => {
    fetchDzongkhag();
  },[])
  const gewogNameHandle = (e) => {
    setGewogState(true);
    setNewDetails({
      ...newDetails,
      name: e.target.value,
    });
  };
  const dzongkhagSelectHandle = (e) => {
    setDzongkhagState(true);
    setNewDetails({
        ...newDetails,
        dzongkhag_id: parseInt(e.value),
      });
  }
  const codeHandle = (e) => {
    setNewDetails({
      ...newDetails,
      code: e.target.value,
    });
  };
  const validationConditions = [
    { field: 'gewog', condition: newDetails.name === '', setState: setGewogState },
    { field: 'dzongkhag', condition: newDetails.dzongkhag_id === '', setState: setDzongkhagState },
  ];
  const onConfirm = async() => {
    if (formValidation(validationConditions)) {
      const response = await Route.addNew('gewogs', token, newDetails);
      if (response.status === 200) {
        setResponseTitle(response.data.message);
        setAddNewGewog(false);
        responseHandle(true);
      } else {
        setResponseTitle(response?.data?.message);
        setAddNewGewog(false);
        responseHandle(false);
      }
    }
  };
  return (
    <Card className="stacked-form">
      <Card.Header>
        <Card.Title as="h4">Add New Gewog</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group>
            <label>Gewog Name <span className="star">*</span></label>
            <Form.Control
              placeholder="Enter Gewog Name"
              type="text"
              name="gewog"
              onChange={gewogNameHandle}
            ></Form.Control>
            { gewogState ? null : <RequiredLabel />}
          </Form.Group>
          <Form.Group>
            <label>Select Dzongkhag <span className="star">*</span></label>
                <Select
                className="react-select"
                classNamePrefix="react-select"
                name="dzongkhag"
                onChange={dzongkhagSelectHandle}
                options={dzongkhags?.map((item) => ({ value: `${item.id}`, label: `${item.name}` }))}
                placeholder="Select Dzongkhag"
                />
                { dzongkhagState ? null : <RequiredLabel />}
          </Form.Group>
          <Form.Group>
            <label>Code</label>
            <Form.Control placeholder="code" type="text" onChange={codeHandle}></Form.Control>
          </Form.Group>
        </Form>
        <div className="card-category form-category">
          <span className="star">*</span>
          Required fields
        </div>
      </Card.Body>
      <Card.Footer>
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
        <Col md="3">
            <Button className="btn-fill" type="submit" variant="primary" block onClick={onConfirm}>
              Add
            </Button>
          </Col>
          <Col md="3">
            <Button className="btn-outline" type="button" variant="danger" block onClick={() =>setAddNewGewog(false)}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};
AddNewGewog.propType = {
  setAddNewGewog: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddNewGewog;
