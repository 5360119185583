import React from 'react';
import { Card, Table } from 'react-bootstrap';
import PropTypes from "prop-types";

const RegionalSaleVoucherDetail = ({saleVoucher}) => {
  return (
    <Card>
    <Card.Header>
      <Card.Title as="h5">Sale Voucher Details</Card.Title>
    </Card.Header>
    <Card.Body>
      <Table hover responsive className="mt-2">
        <thead>
          <tr>
            <th>Product</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {saleVoucher?.sale_voucher_details?.map((item) => (
            <tr key={item?.id}>
              <td>{item?.product?.item_number}</td>
              <td>{item?.quantity}</td>
              <td>{item?.price}</td>
              <td>{item?.total}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card.Body>
  </Card>
  )
};
RegionalSaleVoucherDetail.propType = {
  saleVoucher: PropTypes.object.isRequired,
};

export default RegionalSaleVoucherDetail;
