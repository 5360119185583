import React from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import Alert from "ui/Alert";
import NotificationAlert from "ui/NotificationAlert";
import DeleteAlert from "ui/DeleteAlert";
import AddNewColor from "./Components/masters/color/AddNewColor";
import EditColor from "./Components/masters/color/EditColor";
import { colorTableHeader } from "data/dummy";
import Route from "routes/Route";
import { useQuery } from "react-query";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const Color = () => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [color, setColor] = React.useState([]);
  const [addNew, setAddNew] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const token = localStorage.getItem("token");
  const editHandle = async (id) => {
    if (!checkPermission("colors.edit-color", permissionsArray)) {
      return setShowNotification(true);
    }
    setEdit(false);
    const response = await Route.fetchDetails("edit-colors", token, id);
    if (response.status === 200) {
      setDetails(response?.data?.color);
      setEdit(true);
    } else {
      setNotificationText("Couldn't Fetch User Details");
      setShowNotification(true);
    }
  };
  const { isLoading, data: res } = useQuery(
    "colorsData",
    () => Route.fetch("colors", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const [tempData, setTempData] = React.useState([]);
  const [colorId, setColorId] = React.useState("");
  const [deleteColor, setDeleteColor] = React.useState(false);
  const deleteColorHandle = async () => {
    setDeleteColor(false);
    const res = await Route.delete("colors", token, colorId);
    if (res.status === 200) {
      setResponseTitle(res?.data?.message);
      responseHandle(true);
    } else if (res.status === 202) {
      setResponseTitle(res?.data?.message);
      responseHandle(false);
    } else {
      setResponseTitle(res?.data?.message);
      responseHandle(false);
    }
  };
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.color);
      if (tempData !== res?.data?.color) {
        setColor(
          res?.data?.color?.map((item) => {
            return {
              id: item?.id,
              color: item?.name,
              description: item?.description,
              actions: (
                <div className="actions-right">
                  <Button
                    onClick={() => editHandle(item.id)}
                    variant="warning"
                    className="text-dark btn-link edit"
                    size="sm"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    onClick={() => {
                      setColorId(item?.id);
                      setDeleteColor(true);
                    }}
                    variant="danger"
                    className="btn-link remove text-danger"
                    size="sm"
                  >
                    <i className="fa fa-trash"></i>
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  const addNewColor = () => {
    !checkPermission("colors.store", permissionsArray)
      ? setShowNotification(true)
      : setAddNew(true);
  };
  return (
    <>
      {addNew ? (
        <AddNewColor
          setAddNew={setAddNew}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <h4 className="title">Color</h4>
              <Card>
                <Card.Body>
                <Col md="12" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button variant="primary" className="btn-outline" onClick={addNewColor}>
                    Add New Color <i className="fa fa-plus"></i>
                  </Button>
                </Col>
                  <ReactTable
                    data={color}
                    columns={colorTableHeader}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
      {edit && (
        <EditColor
          edit={edit}
          details={details}
          setEdit={setEdit}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      )}
      {deleteColor && (
        <DeleteAlert
          deleteHandle={deleteColorHandle}
          setAlert={setDeleteColor}
        />
      )}
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      )}
    </>
  );
};

export default Color;
