import React from "react";
import PropTypes from "prop-types";
import { Container, Card, Button, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import RequiredLabel from "ui/RequiredLabel";
import Route from "routes/Route";
import ReactDatetime from "react-datetime";
import { formatDateOnly, formValidation } from "util/CommonUtil";

const EditRequisition = ({
  setEdit,
  details,
  responseHandle,
  setResponseTitle,
}) => {
  const token = localStorage.getItem("token");
  const [productState, setProductState] = React.useState(true);
  const [requestDateState, setRequestDateState] = React.useState(true);
  const [requestQuantityState, setRequestQuantityState] = React.useState(true);
  const [detail, setDetail] = React.useState({
    product_item_number: "",
    request_date: "",
    request_quantity: "",
    sale_type: "",
    description: "",
  });
  React.useEffect(() => {
    console.log(details)
  },[])
  React.useEffect(() => {
    setDetail({
      ...detail,
      product_item_number: details?.requisition?.product_item_number,
      request_date: details?.requisition?.request_date,
      request_quantity: details?.requisition?.request_quantity,
      sale_type: details?.requisition?.sale_type_id,
      description: details?.requisition?.description,
    });
  }, [details]);
  const productSelectHandle = (e) => {
    setDetail({
      ...detail,
      product_item_number: e.value,
      sale_type: e.sale_type,
      description: e.description,
    });
  };
  const requestDateHandle = (e) => {
    setDetail({
      ...detail,
      request_date: formatDateOnly(e._d),
    });
  };
  const requestQuantityHandle = (e) => {
    setDetail({
      ...detail,
      request_quantity: e.target.value,
    });
  };
  const validationConditions = [
    {
      field: "productName",
      condition: detail.products === "",
      setState: setProductState,
    },
    {
      field: "request_date",
      condition: detail.request_date === "",
      setState: setRequestDateState,
    },
    {
      field: "request_quantity",
      condition: detail.request_quantity === "",
      setState: setRequestQuantityState,
    },
  ];
  const updateHandle = async () => {
    if (formValidation(validationConditions)) {
      const response = await Route.update(
        "requisitions",
        token,
        details?.requisition?.id,
        detail
      );
      if (response.status === 200) {
        responseHandle(true);
        setResponseTitle(response?.data?.message);
        setEdit(false);
      } else {
        responseHandle(false);
        setResponseTitle(response?.data?.message);
        setEdit(false);
      }
    }
  };
  return (
    <>
      <Container fluid>
        <Card.Title as="h4">Edit Requisition</Card.Title>
        <Form action="#" method="#">
          <Row>
            <Col md="12">
              <Form.Group>
                <label>
                  Products <span className="star">*</span>
                </label>
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="userSelect"
                  onChange={productSelectHandle}
                  defaultValue={{
                    value: `${details?.requisition?.product_item_number}`,
                    sale_type: details?.requisition?.sale_type_id,
                    description: details?.requisition?.description,
                    label: `${details?.requisition?.description}(${details?.requisition?.product_item_number})`,
                  }}
                  options={details?.products?.map((item) => ({
                    value: `${item.item_number}`,
                    sale_type: item?.sale_type?.id,
                    description: item?.description,
                    label: `${item?.description}(${item.item_number})`,
                  }))}
                  placeholder="Select Product"
                />
                {productState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>       
          </Row>
          <Row>
          <Col md="6">
              <label>
                Request Date <span className="star">*</span>
              </label>
              <Form.Group>
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Select Request Date",
                  }}
                  timeFormat={false}
                  initialValue={new Date(details?.requisition?.request_date)}
                  onChange={requestDateHandle}
                ></ReactDatetime>
                {requestDateState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <label>
                  Request Quantity <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Request Quantity"
                  type="number"
                  defaultValue={parseInt(
                    details?.requisition?.request_quantity
                  )}
                  onChange={requestQuantityHandle}
                ></Form.Control>
                {requestQuantityState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <div className="card-category form-category">
            <span className="star">*</span>
            Required fields
          </div>
        </Form>
        <Row className="mt-4 mb-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Col md="3">
            <Button
              className="btn-fill"
              type="button"
              variant="primary"
              block
              onClick={updateHandle}
            >
              Update
            </Button>
          </Col>
          <Col md="3">
            <Button
              className="btn-outline"
              type="button"
              variant="danger"
              block
              onClick={() => setEdit(false)}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

EditRequisition.propTypes = {
  setEdit: PropTypes.func,
  details: PropTypes.object.isRequired,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default EditRequisition;
