import React from "react";
import PropTypes from "prop-types";
import { Card, Form, Container, Row, Col, Button } from "react-bootstrap";
import RequiredLabel from "ui/RequiredLabel";
import Route from "routes/Route";
import Select from "react-select";

const AddAssign = ({
  id,
  name,
  setAddNew,
  responseHandle,
  setResponseTitle,
}) => {
  const token = localStorage.getItem("token");
  const [regions, setRegions] = React.useState([]);
  const [disableRegionSelect, setDisableRegionSelect] = React.useState(true);
  const [disableExtensionSelect, setDisableExtensionSelect] =
    React.useState(true);
  const [selectedRegion, setSelectedRegion] = React.useState("");
  const [extension, setExtension] = React.useState([]);
  const fetchRegions = async () => {
    const response = await Route.fetch("regions", token);
    if (response.status === 200) {
      setRegions(response?.data?.region);
    }
  };
  const assignType = [
    // { value: "assign both", name: "Assign Both" },
    { value: "assign regional", name: "Assign Regional" },
    { value: "assign extension", name: "Assign Extension" },
  ];
  React.useEffect(() => {
    fetchRegions();
  }, []);
  const [detail, setDetail] = React.useState({
    assign_type: "",
    region: "",
    extension: "",
  });
  const assignTypeSelectHandle = (e) => {
    setDetail({
      ...detail,
      assign_type: e.value,
    });
  };
  React.useEffect(() => {
    if (detail.assign_type === "assign both") {
      setDisableRegionSelect(false);
      setDisableExtensionSelect(false);
    } else if (detail.assign_type === "assign regional") {
      setDisableRegionSelect(false);
      setDisableExtensionSelect(true);
    } else if (detail.assign_type === "assign extension") {
      setDisableRegionSelect(false);
      setDisableExtensionSelect(false);
    } else {
      setDisableRegionSelect(true);
      setDisableExtensionSelect(true);
    }
  }, [detail.assign_type]);
  const regionSelectHandle = (e) => {
    setDetail({
      ...detail,
      region: e.value,
    });
    setSelectedRegion(parseInt(e.value));
  };
  const fetchExtensionBasedOnRegion = async () => {
    const response = await Route.fetchDetails(
      "edit-regions",
      token,
      selectedRegion
    );
    if (response.status === 200) {
      setExtension(response?.data?.Region?.extensions);
    }
  };
  React.useEffect(() => {
    if (selectedRegion !== "") {
      fetchExtensionBasedOnRegion();
    }
  }, [selectedRegion]);
  const extensionSelectHandle = (e) => {
    setDetail({
      ...detail,
      extension: e.value,
    });
  };
  const [regionState, setRegionState] = React.useState(true);
  const [extensionState, setExtensionState] = React.useState(true);

  const addNewAssignHandle = async () => {
    if (
      (detail.assign_type === "assign both" ||
        detail.assign_type === "assign regional") &&
      detail?.region === ""
    ) {
      return setRegionState(false);
    }
    if (
      (detail.assign_type === "assign both" ||
        detail.assign_type === "assign extension") &&
      detail?.extension === ""
    ) {
      return setExtensionState(false);
    }
    const response = await Route.update("assignings", token, id, detail);
    if (response.status === 200) {
      responseHandle(true);
      setResponseTitle(response?.data?.message);
      setAddNew(false);
    } else {
      responseHandle(false);
      setResponseTitle(response?.data?.message);
      setAddNew(false);
    }
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Header>
                <Card.Title as="h4">Assign</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form action="#" method="#">
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          User <span className="star">*</span>
                        </label>
                        <Form.Control
                          placeholder={name}
                          type="text"
                          readOnly
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          Assign Type <span className="star">*</span>
                        </label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="assignSelect"
                          onChange={assignTypeSelectHandle}
                          options={assignType?.map((item) => ({
                            value: `${item.value}`,
                            label: `${item.name}`,
                          }))}
                          placeholder="Select Assign Type"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>Region</label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="regionSelect"
                          onChange={regionSelectHandle}
                          options={regions?.map((item) => ({
                            value: `${item.id}`,
                            label: `${item.name}`,
                          }))}
                          placeholder="Select Region"
                          isDisabled={disableRegionSelect}
                        />
                        {regionState ? null : <RequiredLabel />}
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <label>Extension</label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="extensionSelect"
                          onChange={extensionSelectHandle}
                          options={extension?.map((item) => ({
                            value: `${item.id}`,
                            label: `${item.name}`,
                          }))}
                          placeholder="Select Extension"
                          isDisabled={disableExtensionSelect}
                        />
                        {extensionState ? null : <RequiredLabel />}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="card-category form-category">
                        <span className="star">*</span>
                        Required fields
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="card-category form-category">
                        <Form.Text className="text-muted">
                          <span className="star">*</span>
                          Region: Required when Assign Type is "ASSIGN REGIONAL".
                        </Form.Text>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="card-category form-category">
                        <Form.Text className="text-muted">
                          <span className="star">*</span>
                          Extension: Required when Assign Type is "ASSIGN EXTENSION".
                        </Form.Text>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
              <Card.Footer>
                <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Col md="3">
                    <Button
                      className="btn-fill"
                      type="button"
                      variant="primary"
                      block
                      onClick={addNewAssignHandle}
                    >
                      Assign
                    </Button>
                  </Col>
                  <Col md="3">
                    <Button
                      className="btn-outline"
                      type="button"
                      variant="danger"
                      block
                      onClick={() => setAddNew(false)}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

AddAssign.propTypes = {
  setAddNew: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddAssign;
