import React from "react";
import { Button, Card, Container, Row, Col, Modal } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import Alert from "ui/Alert";
import NotificationAlert from "ui/NotificationAlert";
import DeleteAlert from "ui/DeleteAlert";
import AddNewRequisition from "./Components/requisition/AddNewRequisition";
import EditRequisition from "./Components/requisition/EditRequisition";
import ViewRequisition from "./Components/requisition/ViewRequisition";
import { requisitionTableHeader } from "data/dummy";
import Route from "routes/Route";
import { useQuery } from "react-query";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const Requisition = () => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [requistionData, setRequisitionData] = React.useState([]);
  const [addNew, setAddNew] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [notifcationText, setNotificationText] = React.useState("");
  const [showNotification, setShowNotification] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [id, setId] = React.useState("");
  const [deleteRequest, setDeleteRequest] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const token = localStorage.getItem("token");
  const { isLoading, data: res } = useQuery(
    "requisitionData",
    () => Route.fetch("requisitions", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const fetchDetails = async(id, type) => {
    const response =  await Route.fetchDetails("edit-requisitions", token, id);
    if (response.status === 200) {
      setDetails(response?.data);
      type === "view" ? setView(true) : setEdit(true);
    } else {
      setNotificationText("Error While Fetching Data");
      setShowNotification(true);
    }
  };
  const editHandle = (id) => {
    if (!checkPermission("requisitions.view", permissionsArray)) {
      setShowNotification(true)
    } else {
      fetchDetails(id, "edit");
    }
  };
  const viewHandle = (id) => {
    if (!checkPermission("requisitions.view", permissionsArray)) {
      setShowNotification(true)
    } else {
      fetchDetails(id, "view");
    }
  };
  const deleteHandle = (id) => {
    setId(id);
    setDeleteRequest(true);
  };
  const deleteRequestHandle = async() => {
    const response = await Route.delete("requisitions", token, id);
    setDeleteRequest(false);
    if (response.status === 200) {
      setResponseTitle(response?.data?.message);
      responseHandle(true);
    } else {
      setResponseTitle(response?.data?.message);
      responseHandle(false);
    }
  }
  const [tempData, setTempData] = React.useState([]);
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.requisitions);
      if (tempData !== res?.data?.requisitions) {
        console.log(res?.data?.requisitions)
        setRequisitionData(
          res?.data?.requisitions.map((item) => {
            return {
              id: item?.id,
              item_number:  (
                <div className="mr-4">
                  {item?.product_item_number}
                </div>
                ),
              request_date: item?.request_date,
              request_quantity: parseInt(item?.request_quantity),
              transfer_quantity: parseInt(item?.transfer_quantity),
              description: item?.description,
                status: (
                  <div>
                    <Button
                        className="btn-outline btn-round"
                        type="button"
                        size="sm"
                        variant={`${item?.status === "requested" ? "info" : "success"}`}
                      >
                        {item?.status}
                      </Button>
                  </div>
                  ),
              actions: (
                <div className="actions-right">
                  <Button
                    onClick={() => viewHandle(item.id)}
                    variant="primary"
                    size="sm"
                    className="text-primary btn-link edit"
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                  <Button
                    onClick={() => editHandle(item.id)}
                    variant="warning"
                    size="sm"
                    className="text-dark btn-link edit"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    onClick={() => deleteHandle(item?.id)}
                    variant="danger"
                    size="sm"
                    className="btn-link remove text-danger"
                  >
                    <i className="fa fa-trash" />
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      };
    };
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  const addNewRegionHandle = () => {
    !checkPermission("requisitions.store", permissionsArray) ? setShowNotification(true) : setAddNew(true);
  };
  return (
    <>
      {addNew ? (
        <AddNewRequisition
          setAddNew={setAddNew}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <h4 className="title">Requisition</h4>
              <Card>
                <Card.Body>
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button variant="primary" onClick={addNewRegionHandle} className="btn-outline">
                    Add New Requisition <i className="fa fa-plus"></i>
                  </Button>
                </Col>
                  <ReactTable
                    data={requistionData}
                    columns={requisitionTableHeader}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      { showAlert && <Alert response={response} responseTitle={responseTitle} setShowAlert={setShowAlert}  /> }
      {edit && 
        <Modal
          show={edit}
          onHide={() => setEdit(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Body>
            <EditRequisition setEdit={setEdit} details={details}  responseHandle={responseHandle} setResponseTitle={setResponseTitle} />
          </Modal.Body>
        </Modal>
      }
      { view && <ViewRequisition view={view} setView={setView} details={details} />}
      { showNotification && <NotificationAlert setAlert={setShowNotification}  title={notifcationText} /> }
      { deleteRequest && <DeleteAlert  deleteHandle={deleteRequestHandle} setAlert={setDeleteRequest} /> }
    </>
  );
};

export default Requisition;
