import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Button,
  Table,
} from "react-bootstrap";
import Route from "routes/Route";
import RequiredLabel from "ui/RequiredLabel";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import NotificationAlert from "ui/NotificationAlert";
import ProductRequistion from "./ProductRequistion";
import ExtensionProductRequisition from "./ExtensionProductRequisition";
import { formatDateOnly, dynamicHandle } from "util/CommonUtil";
import { createRequisiton } from "util/MasterUtil";
import ExtensionProductList from "./ExtensionProductList";
import ItemDetails from "./ItemDetails";
import { useQuery } from "react-query";

const AddExtNewRequisition = ({
  setAddNew,
  responseHandle,
  setResponseTitle,
}) => {
  const token = localStorage.getItem("token");
  const [extensionProducts, setExtensionProducts] = React.useState([]);
  const [requested, setRequested] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const [notifcationText, setNotificationText] = React.useState("");
  const [products, setProducts] = React.useState([]);
  const [addedRow, setAddedRow] = React.useState([]);
  const [rows, setRows] = React.useState(0);
  const [addedProduct, setAddedProduct] = React.useState([]);
  const [requestDateState, setRequestDateState] = React.useState(true);
  const [requestDate, setRequestDate] = React.useState(new Date());
  const [storeState, setStoreState] = React.useState(true);
  const [store, setStore] = React.useState(1);
  const { isLoading, data: res } = useQuery(
    "extRequisitionData",
    () => Route.fetch("extension-requisitions", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const [showView, setShowView] = React.useState(false);
  const [itemDetails, setItemDetails] = React.useState({});
  const viewHandle = (item) => {
    setItemDetails(item);
    setShowView(true);
  };
  const [tempData, setTempData] = React.useState([]);
  const [extensionsArray, setExtensionsArray] = React.useState([]);
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.products);
      if (tempData !== res?.data?.products) {
        setExtensionProducts(
          res?.data?.products.map((item) => {
            return {
              id: item?.id,
              category: item?.category,
              sub_category: item?.sub_category,
              extension: item?.extension,
              qty: parseInt(item?.qty),
              description: item?.description,
              actions: (
                <div className="actions-right">
                  <Button
                    onClick={() => viewHandle(item)}
                    variant="primary"
                    size="sm"
                    className="text-primary btn-link edit"
                  >
                    <i className="fa fa-eye" />
                  </Button>
                </div>
              ),
            };
          })
        );
        setExtensionsArray(
          res?.data?.products
            ?.filter((item, index, self) => {
              // Filter unique items based on extension_id
              return (
                index ===
                self.findIndex((t) => t.extension_id === item.extension_id)
              );
            })
            .map((item) => {
              return {
                value: item.extension_id,
                label: item.extension,
              };
            })
        );
      }
    }
  }, [isLoading, res]);
  const fetchProducts = async (extension) => {
    const response = await Route.fetchDetails("extension-requisitions", token, extension);
    if (response?.status === 200) {
      setProducts(response?.data?.products);
    };
  };
  const productListHandle = (id, prop, value) => {
    addedProduct.filter((item) => {
      if (item.product_item_number === value) {
        setNotificationText("Product Already Selected");
        setShowNotification(true);
      }
    });
    dynamicHandle(id, prop, value, setAddedProduct);
  };
  const deleteRowHandle = (id) => {
    setAddedProduct((prevProductDetails) => {
      return prevProductDetails.filter((item) => item.id !== id);
    });
    setAddedRow((prev) => prev.filter((row) => row.props.id !== id));
  };
  const addNewRowHandle = () => {
    const newId = rows + 1;
    setRows(newId);
    setAddedRow((prev) => [...prev, row(newId)]);
  };
  const requestDateHandle = (e) => {
    setRequestDateState(true);
    setRequestDate(formatDateOnly(e._d));
  };
  const extensionHandle = (item) => {
    fetchProducts(item?.value);
    setStoreState(true);
    setStore(item?.value);
    setAddedRow([]);
  };
  const addNewRequisitionHandle = async () => {
    setRequested(true);
    if (requestDate === "") {
      return setRequestDate(false);
    };
    if (store === "") {
      return setStoreState(false);
    };
    const data = {
      request_date: requestDate,
      requested_extension: store,
      requisition_to: 3,
      productDetails: createRequisiton(addedProduct),
    };
    // console.log(data);  
    const response = await Route.addNew("extension-requisitions", token, data);
    if (response.status === 200) {
      responseHandle(true);
      setResponseTitle(response?.data?.message);
      setAddNew(false);
    } else {
      responseHandle(false);
      setResponseTitle(response?.data?.message);
      setAddNew(false);
    }
  };
  const row = (id) => (
    <ExtensionProductRequisition
      key={id}
      id={id}
      productListHandle={productListHandle}
      deleteRowHandle={deleteRowHandle}
      products={products}
    />
  );
  if (isLoading) return "Loading...";
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Body>
                <Card.Title as="h4">New Ext. Requisition</Card.Title>
                <ExtensionProductList extensionProducts={extensionProducts} />
                <Card>
                  <Card.Body>
                    <Row>
                      <Col md="6">
                        <label>
                          Request Date <span className="star">*</span>
                        </label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Select Request Date",
                            }}
                            timeFormat={false}
                            closeOnSelect
                            onChange={requestDateHandle}
                            initialValue={new Date()}
                          ></ReactDatetime>
                          {requestDateState ? null : <RequiredLabel />}
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <label>
                          Extension <span className="star">*</span>
                        </label>
                        <Form.Group>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="productSelect"
                            onChange={(item) => extensionHandle(item)}
                            options={extensionsArray?.map((item) => ({
                              value: item?.value,
                              label: item?.label,
                            }))}
                            placeholder="Select Extension"
                          />
                          {storeState ? null : <RequiredLabel />}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Table className="table-hover w-full">
                          <thead>
                            <tr>
                              <th style={{ width: "70%" }}>
                                Description (Item Number){" "}
                                <span className="star">*</span>
                              </th>
                              <th>Available Qty</th>
                              <th>
                                Qty <span className="star">*</span>
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {addedRow.length > 0 &&
                              addedRow?.map((item) => item)}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          className="btn-outline"
                          type="button"
                          variant="default"
                          onClick={addNewRowHandle}
                        >
                          Add New <i className="fa fa-plus"></i>
                        </Button>
                      </Col>
                    </Row>
                    <div className="card-category form-category">
                      <span className="star">*</span>
                      Required fields
                    </div>
                  </Card.Body>
                </Card>
              </Card.Body>
              <Card.Footer>
                <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Col md="3">
                    <Button
                      className="btn-fill"
                      type="button"
                      variant="primary"
                      block
                      onClick={addNewRequisitionHandle}
                      disabled={requested}
                    >
                      Request
                    </Button>
                  </Col>
                  <Col md="3">
                    <Button
                      className="btn-outline"
                      type="button"
                      variant="danger"
                      block
                      onClick={() => setAddNew(false)}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      {showNotification && (
        <NotificationAlert
          title={notifcationText}
          setAlert={setShowNotification}
        />
      )}
      {showView && (
        <ItemDetails
          showView={showView}
          setShowView={setShowView}
          itemDetails={itemDetails}
        />
      )}
    </>
  );
};

AddExtNewRequisition.propTypes = {
  setAddNew: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddExtNewRequisition;
