import React from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import RequiredLabel from "ui/RequiredLabel";
import PropTypes from "prop-types";
import NotificationAlert from "ui/NotificationAlert";
import Route from "routes/Route";
import { useSelector } from "react-redux";
import { checkPermission } from "util/MasterUtil";

const EditSalesType = ({
  setEdit,
  details,
  responseHandle,
  setResponseTitle,
}) => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showNotification, setShowNotification] = React.useState(false);
  const [emptyNameState, setEmptyNameState] = React.useState(false);
  const [btnClicked, setBtnClicked] = React.useState(false);
  const [newDetails, setNewDetails] = React.useState({
    name: "",
    description: "",
  });
  const token = localStorage.getItem("token");
  React.useEffect(() => {
    setNewDetails({
      ...newDetails,
      id: details?.id,
      name: details?.name,
      description: details?.description,
    });
  }, [details]);
  const inputFieldHandle = (e) => {
    e.target.name === "name" ? setEmptyNameState(false) : null;
    setBtnClicked(false);
    setNewDetails({
      ...newDetails,
      [e.target.name]: e.target.value,
    });
  };
  const onConfirm = async () => {
    setBtnClicked(true);
    if (!checkPermission("sale-types.update", permissionsArray)) {
      return setShowNotification(true);
    }
    if (newDetails.name === "") {
      setEmptyNameState(true);
    } else {
      const response = await Route.update(
        "sale-types",
        token,
        details.id,
        newDetails
      );
      if (response.status === 200) {
        setResponseTitle(response.data.message);
        setEdit(false);
        responseHandle(true);
      } else {
        setResponseTitle(response.data.message);
        setEdit(false);
        responseHandle(false);
      }
    }
  };
  return (
    <div className="stacked-form">
      <Card.Title as="h4">Edit Product Type</Card.Title>
      <Form>
        <Row>
          <Col>
            <Form.Group>
              <label>
                Name <span className="star">*</span>
              </label>
              <Form.Control
                placeholder="Store"
                type="text"
                name="name"
                defaultValue={details?.name}
                onChange={inputFieldHandle}
              ></Form.Control>
              {emptyNameState ? <RequiredLabel /> : null}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Description</label>
              <Form.Control
                placeholder="Description"
                type="text"
                name="description"
                as="textarea"
                rows={3}
                defaultValue={details?.description}
                onChange={inputFieldHandle}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <div className="card-category form-category">
          <span className="star">*</span>
          Required fields
        </div>
        <Row
          className="mt-4"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Col md="3">
            <Button
              className={btnClicked ? "btn-outline" : "btn-fill"}
              type="button"
              variant="primary"
              block
              onClick={onConfirm}
              disabled={btnClicked}
            >
              Update
            </Button>
          </Col>
          <Col md="3">
            <Button
              className="btn-outline"
              type="button"
              block
              variant="danger"
              onClick={() => setEdit(false)}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
      {showNotification && <NotificationAlert setAlert={setShowNotification} />}
    </div>
  );
};
EditSalesType.propTypes = {
  setEdit: PropTypes.func,
  details: PropTypes.object.isRequired,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default EditSalesType;
