export const getGewogs = (id, data) => {
  const selectedDzongkhag = data.find((item) => item.id === id);
  return selectedDzongkhag ? selectedDzongkhag.gewogs : [];
};

export const subCategories = (userData, data) => {
  const output = {};
  // Loop through userData array and map each object to output format
  userData.forEach((user) => {
    output[user.code] = {
      name: user.name,
      code: user.code,
      description: user.description,
      sub_category_id: "",
    };
  });
  // Loop through data object and merge with output object
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      output[key] = {
        name: data[key].name,
        code: data[key].code,
        description: data[key].description,
        sub_category_id: data[key].id,
      };
    }
  }
  return output;
};

export const newCategories = (userData) => {
  const output = {};
  userData.forEach((user) => {
    output[user.code] = {
      name: user.name,
      code: user.code,
      description: user.description,
      sub_category_id: "",
    };
  });
  return output;
};
export const newExtension = (userData) => {
  const output = {};
  userData.forEach((user, index) => {
    output[index] = {
      name: user.name,
      description: user.description,
    };
  });
  return output;
};
export const newExtensions = (userData) => {
  const output = [];
  userData.forEach((user, index) => {
    output[index] = {
      name: user.name,
      description: user.description,
    };
  });
  return output;
};
export const existngExtensions = (userData) => {
  const output = [];
  userData.forEach((user, index) => {
    output[index] = {
      name: user.name,
      description: user.description,
      extension_id: user.id,
    };
  });
  return output;
};
export const productsData = (data) => {
  const output = [];
  data.forEach((item, index) => {
    output.push({
      item_number: item?.itemNo,
      serial_no: item?.serial,
      category: item?.category,
      sub_category: item?.subCategory,
      unit: item?.unit,
      price: item?.price,
      brand: item?.brand,
      type: item?.productType,
      sub_inventory: item?.sub_inventory,
      locator: item?.locator,
      iccid: item?.iccid,
      description: item?.description,
      quantity: item?.quantity,
      store: item?.store,
    });
  });
  return output;
};
// ============================================  SORTING PERMISSION VIEW LIST =============================================================
export const getPermissionList = (permission) => {
  let array = [];
  permission.map((item) => {
    if (item?.name.endsWith(".view") || item?.name.endsWith(".index")) {
      const [permission, access] = item?.name.split(".");
      array.push(permission);
    }
  });
  return array;
};
// ================================================ CHECK PERMISSION ===================================================
export const checkPermission = (permission, permissionArray) => {
  const roles = JSON.parse(localStorage.getItem("roles"));
  if (roles[0].name === "Developer") {
    return true;
  } else {
    const permissionList = permissionArray.map((item) => item?.name);
    return permissionList.includes(permission);
  }
};
//   ======================================== CREATE REQUISTION ===========================================
export const createRequisiton = (data) => {
  const output = {};
  data.forEach((item, index) => {
    output[index] = {
      product_item_number: item?.product_item_number,
      request_quantity: item?.request_quantity,
      sale_type: item?.sale_type,
      description: item?.description,
    };
  });
  return output;
};
//   ======================================== CREATE PURCAHSE MAIN STORE ===========================================
export const mainStoreCreatePurchase = (data) => {
  const output = {};
  data.forEach((item, index) => {
    output[index] = {
      product: item?.product,
      quantity: item?.quantity,
      discount_type_id: item?.discount_type_id,
      product_cost: item?.product_cost,
      total_amount: item?.total_amount,
    };
  });
  return output;
};

//   ======================================== CREATE PRODUCT REQUISITION DETAILS ===========================================
export const requistionProductDetails = (requisitions, products) => {
  const output = [];
  requisitions?.map((item) => {
    products?.map((element) => {
      if (
        item?.product_item_number === element?.item_number &&
        item?.sale_type_id === element?.sale_type_id &&
        element?.category_id === 2
      ) {
        output.push({
          requisitionId: item?.id,
          product_item_number: item?.product_item_number,
          sale_type: item?.sale_type_id,
          request_date: item?.request_date,
          request_quantity: item?.request_quantity,
          quantity: element?.main_store_qty,
          serial_no: element?.serial_no,
          description: item?.description,
        });
      }
    });
  });
  return output;
};
//   ======================================== CREATE PRODUCT REQUISITION DETAILS FOR REGION ===========================================
export const regionRequistionProductDetails = (requisitions, products) => {
  const output = [];
  requisitions?.map((item) => {
    products?.map((element) => {
      if (
        item?.product_item_number === element?.product?.item_number &&
        item?.sale_type_id === element?.product?.sale_type_id &&
        element?.product?.category_id === 2
      ) {
        output.push({
          requisitionId: item?.id,
          product_item_number: item?.product_item_number,
          sale_type: item?.sale_type_id,
          request_date: item?.request_date,
          request_quantity: item?.request_quantity,
          quantity: element?.region_store_quantity,
          serial_no: element?.product?.serial_no,
          description: item?.description,
        });
      }
    });
  });
  return output;
};
//   ======================================== CREATE PRODUCT REQUISITION DETAILS FOR REGION ===========================================
export const extensionRequistionProductDetails = (requisitions, products) => {
  const output = [];
  requisitions?.map((item) => {
    products?.map((element) => {
      if (
        item?.product_item_number === element?.product?.item_number &&
        item?.sale_type_id === element?.product?.sale_type_id &&
        element?.product?.category_id === 2
      ) {
        output.push({
          requisitionId: item?.id,
          product_item_number: item?.product_item_number,
          sale_type: item?.sale_type_id,
          request_date: item?.request_date,
          request_quantity: item?.request_quantity,
          quantity: element?.store_quantity,
          serial_no: element?.product?.serial_no,
          description: item?.description,
        });
      }
    });
  });
  return output;
};
//   ======================================== CREATE PHONE REQUISITION DETAILS ===========================================
export const requistionPhoneDetails = (requisitions, products) => {
  const serialNo = [];
  requisitions?.map((item) => {
    products?.map((element) => {
      if (
        item?.product_item_number === element?.item_number &&
        item?.sale_type_id === element?.sale_type_id &&
        element?.category_id === 1
      ) {
        serialNo.push({
          serialNo: element.serial_no,
        });
      }
    });
  });
  const output = [];
  requisitions?.map((item) => {
    if (item?.sale_type_id == 1) {
      let requestNo = parseInt(item?.request_quantity);
      while (requestNo > 0) {
        output.push({
          requisitionId: item?.id,
          product_item_number: item?.product_item_number,
          sale_type: item?.sale_type_id,
          request_date: item?.request_date,
          request_quantity: 1,
          serialNo: serialNo,
          description: item?.description,
        });
        requestNo--;
      }
    }
  });
  return output;
};
//   ======================================== CREATE PHONE REQUISITION DETAILS FOR REGION ===========================================
export const regionRequistionPhoneDetails = (requisitions, products) => {
  const serialNo = [];
  requisitions?.map((item) => {
    products?.map((element) => {
      if (
        item?.product_item_number === element?.product?.item_number &&
        item?.sale_type_id === element?.product?.sale_type_id &&
        element?.product?.category_id === 1
      ) {
        serialNo.push({
          serialNo: element?.product?.serial_no,
        });
      }
    });
  });
  const output = [];
  requisitions?.map((item) => {
    if (item?.sale_type_id == 1) {
      let requestNo = parseInt(item?.request_quantity);
      while (requestNo > 0) {
        output.push({
          requisitionId: item?.id,
          product_item_number: item?.product_item_number,
          sale_type: item?.sale_type_id,
          request_date: item?.request_date,
          request_quantity: 1,
          serialNo: serialNo,
          description: item?.description,
        });
        requestNo--;
      }
    }
  });
  return output;
};
//   ======================================== CREATE SIM REQUISITION DETAILS ===========================================
export const requistionSimDetails = (requisitions, products) => {
  const serialNo = [];
  requisitions?.map((item) => {
    products?.map((element) => {
      if (
        item?.product_item_number === element?.item_number &&
        item?.sale_type_id === element?.sale_type_id &&
        element?.category_id === 3
      ) {
        serialNo.push({
          serialNo: element.serial_no,
        });
      }
    });
  });
  const output = [];
  requisitions?.map((item) => {
    if (item?.sale_type_id == 3) {
      let requestNo = parseInt(item?.request_quantity);
      while (requestNo > 0) {
        output.push({
          requisitionId: item?.id,
          product_item_number: item?.product_item_number,
          sale_type: item?.sale_type_id,
          request_date: item?.request_date,
          request_quantity: 1,
          serialNo: serialNo,
          description: item?.description,
        });
        requestNo--;
      }
    }
  });
  return output;
};
//   ======================================== CREATE SIM REQUISITION DETAILS FOR REGION ===========================================
export const regionRequistionSimDetails = (requisitions, products) => {
  const serialNo = [];
  requisitions?.map((item) => {
    products?.map((element) => {
      if (
        item?.product_item_number === element?.product?.item_number &&
        item?.sale_type_id === element?.product?.sale_type_id &&
        element?.product?.category_id === 3
      ) {
        serialNo.push({
          serialNo: element?.product?.serial_no,
        });
      }
    });
  });
  const output = [];
  requisitions?.map((item) => {
    if (item?.sale_type_id == 3) {
      let requestNo = parseInt(item?.request_quantity);
      while (requestNo > 0) {
        output.push({
          requisitionId: item?.id,
          product_item_number: item?.product_item_number,
          sale_type: item?.sale_type_id,
          request_date: item?.request_date,
          request_quantity: 1,
          serialNo: serialNo,
          description: item?.description,
        });
        requestNo--;
      }
    }
  });
  return output;
};
//   ======================================== APPROVED REQUEST DATA  ===========================================
export const aprrovedRequestData = (data) => {
  const output = {};
  data.forEach((item, index) => {
    output[index] = {
      serial_no: item?.serial_no !== null ? item?.serial_no : "",
      requisition_id: item?.requisition_id,
      transfer_quantity: item?.transfer_quantity,
      description: item?.description,
    };
  });
  return output;
};
//   ======================================== TRANSFER APPROVED DATA  ===========================================
export const transferAprrovedData = (data) => {
  const output = {};
  data.forEach((item, index) => {
    output[index] = {
      product_id: item?.id,
    };
  });
  return output;
};

// ========================================== DATE DIFFERENCE =================================================
export const dateDiff = (soldDate) => {
  const date = new Date(soldDate);
  const currentDate = new Date();
  // Calculate the difference in milliseconds
  const diffTime = Math.abs(currentDate - date);
  // Convert milliseconds to years
  const diffYears = diffTime / (1000 * 60 * 60 * 24 * 365.25);
  // Round diffYears to two decimal points
  return diffYears.toFixed(2);
};
