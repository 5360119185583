import React from "react";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import NotificationAlert from "ui/NotificationAlert";
import RequiredLabel from "ui/RequiredLabel";
import Route from "routes/Route";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const EditCustomerType = ({
  edit,
  setEdit,
  details,
  responseHandle,
  setResponseTitle,
}) => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [newDetails, setNewDetails] = React.useState({
    name: "",
    description: "",
  });
  const [nameState, setNameState] = React.useState(true);
  const [showNotification, setShowNotification] = React.useState(false);
  const token = localStorage.getItem("token");
  React.useEffect(() => {
    setNewDetails({
      ...newDetails,
      name: details?.name,
      description: details?.description,
    });
  }, [details]);
  const inputFieldHandle = (e) => {
    e.target.name === "name" ? setNameState(true) : null;
    setNewDetails({
      ...newDetails,
      [e.target.name]: e.target.value,
    });
  };
  const onConfirm = async () => {
    if (!checkPermission("customer-types.update", permissionsArray)) {
      return setShowNotification(true);
    }
    if (newDetails.name === "") {
      return setNameState(false);
    }
    const response = await Route.update(
      "customer-types",
      token,
      details?.id,
      newDetails
    );
    if (response.status === 200) {
      setResponseTitle(response?.data?.message);
      responseHandle(true);
      setEdit(false);
    } else {
      setResponseTitle(response?.message);
      responseHandle(false);
      setEdit(false);
    }
  };
  return (
    <>
      <Modal show={edit} onHide={() => setEdit(false)}>
        <Modal.Body>
          <Modal.Title>Edit Customer Type</Modal.Title>
          <Row>
            <Col md="12">
              <Form.Group>
                <label>
                  Customer Type <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Customer Type"
                  type="text"
                  name="name"
                  defaultValue={details?.name}
                  onChange={inputFieldHandle}
                ></Form.Control>
                {nameState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Form.Group>
                <label>Description</label>
                <Form.Control
                  placeholder="Description"
                  type="text"
                  as="textarea"
                  rows={3}
                  name="description"
                  defaultValue={details?.description}
                  onChange={inputFieldHandle}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <div className="card-category form-category">
            <span className="star">*</span>
            Required fields
          </div>
          <Row className="mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="3">
              <Button onClick={onConfirm} block>
                Update
              </Button>
            </Col>
            <Col md="3">
              <Button
                className="btn-outline"
                variant="danger"
                onClick={() => setEdit(false)}
                block
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {showNotification && <NotificationAlert setAlert={setShowNotification} />}
    </>
  );
};
EditCustomerType.propTypes = {
  edit: PropTypes.bool,
  setEdit: PropTypes.func,
  details: PropTypes.array.isRequired,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default EditCustomerType;
