import axios from "axios";
const base_url = process.env.REACT_APP_BASE_URL;

class SystemSettingRoute {
  // ================================================= DELETE ROLE =======================================================
  deleteUserRole(token, id) {
    //eslint-disable-line
    return axios
      .delete(
        `${base_url}/roles/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error);
  };
    // ======================================================= FETCH DETAILS ===========================================================
    fetchDetails(endpoint, token, id) {
      //eslint-disable-line
      return axios
        .get(
          `${base_url}/${endpoint}/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => response)
        .catch((error) => error);
    };
};

export default new SystemSettingRoute();
