import React from 'react';
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ActivityDetails from './Components/ActivityDetails';
import Route from 'routes/Route';
import { useQuery } from "react-query";
import { formatDate } from 'util/CommonUtil';

const ActivityLogs = () => {
  const token = localStorage.getItem('token');
  const [details, setDetails] = React.useState([]);
  const [viewDetails, setViewDetails] = React.useState(false);
  const [activityData, setActivityData] = React.useState([]);
  const { isLoading, data: res } = useQuery('activityLogs', () => Route.fetch('activity-logs', token), {
    refetchInterval: 2000,
    refetchIntervalInBackground: true,
  });
  
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setActivityData(res?.data?.audit?.map((item) => (
        {
          id: item?.id,
          user: item?.user?.username,
          action: item?.event,
          model: item?.auditable_type,
          dateTime: `${formatDate(item?.created_at)}`,
          view: (<div style={{ textAlign: "center" }}>
          <Button
            variant="primary"
            size="sm"
            className="btn-outline"
            onClick={() => {
              setDetails(item);
              setViewDetails(true);
            }}
          >
            <i className="fa fa-eye"></i>
          </Button>{" "}
        </div>)
        })))
    }
  }, [isLoading, res]);
  if (isLoading) return 'Loading...';
  
  const columns = [{
    dataField: 'user',
    text: 'User',
    sort: true,
  }, {
    dataField: 'action',
    text: 'Action',
    sort: true
  }, {
    dataField: 'model',
    text: 'Model',
    sort: true
  },
  {
    dataField: 'dateTime',
    text: 'Date and Time',
    sort: true
  },
  {
    dataField: 'view',
    text: 'View Details',
    sort: true
  },
];
const defaultSorted = [{
  dataField: 'user',
  order: 'desc'
}];
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Body className="table-responsive p-0 mt-3">
              <Card.Title as="h4" className='mb-4'>Activity Logs</Card.Title>
                <BootstrapTable
                  keyField='id'
                  data={activityData}
                  columns={columns}
                  noDataIndication="Table is Empty" 
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()} 
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      { viewDetails && <ActivityDetails details={details} viewDetails={viewDetails} setViewDetails={setViewDetails} /> }
    </>
  )
}

export default ActivityLogs
