import React from "react";
import { Button, Card, Container, Row, Col, Modal } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import { productTableHeader } from "data/dummy";
import { useQuery } from "react-query";
import Alert from "ui/Alert";
import DeleteAlert from "ui/DeleteAlert";
import NotificationAlert from "ui/NotificationAlert";
import Route from "routes/Route";
import AddNewProduct from "./Components/inventory/product/AddNewProduct";
import UploadFile from "./Components/UploadFile";
import ViewProduct from "./Components/inventory/product/ViewProduct";
import EditProduct from "./Components/inventory/product/EditProduct";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const Product = () => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const token = localStorage.getItem("token");
  const [addNew, setAddNew] = React.useState(false);
  const [showNotification, setShowNotificaion] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const [uploadFile, setUploadFile] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [view, setView] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [details, setDetails] = React.useState({});
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const viewDetailsHandle = async (id) => {
    try {
      const { data } = await Route.fetchDetails("edit-products", token, id);
      if (data?.product) {
        setDetails(data?.product);
        setView(true);
      } else {
        setNotificationText("Error: Product not found");
        setShowNotificaion(true);
      }
    } catch (error) {
      setNotificationText("Error while fetching details");
      setShowNotificaion(true);
    }
  };
  const editHandle = async (id) => {
    if (!checkPermission("products.edit-products", permissionsArray)) {
      return setShowNotificaion(true);
    }
    const response = await Route.fetchDetails("edit-products", token, id);
    if (response.status === 200) {
      setDetails(response?.data?.product);
      setEdit(true);
    } else {
      setNotificationText("Error while fetching details");
      setShowNotificaion(true);
    }
  };
  const [data, setData] = React.useState([]);
  const { isLoading, data: res } = useQuery(
    "productData",
    () => Route.fetch("products", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );

  const [productId, setProductId] = React.useState("");
  const [tempData, setTempData] = React.useState([]);
  const [deleteProduct, setDeleteProduct] = React.useState(false);
  const deleteHandle = (id) => {
    setProductId(id);
    setDeleteProduct(true);
  };
  const deletingHandle = async () => {
    setDeleteProduct(false);
    const response = await Route.delete("products", token, productId);
    if (response.status === 200) {
      setShowAlert(true);
      setResponse(true);
      setResponseTitle(response?.data?.message);
    } else if (response.status === 202) {
      setShowAlert(true);
      setResponse(false);
      setResponseTitle(response?.data?.message);
    } else {
      setShowAlert(true);
      setResponse(true);
      setResponseTitle(response?.data?.message);
    }
  };
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.product);
      if (tempData !== res?.data?.product) {
        setData(
          res?.data?.product?.map((item) => {
            return {
              id: item?.id,
              itemNumber: item?.item_number,
              serialNo: item?.serial_no,
              batch_no: item?.batch_no,
              price: `Nu. ${item?.price}`,
              actions: (
                <div className="actions-right">
                  <Button
                    className="text-primary btn-link edit"
                    onClick={() => viewDetailsHandle(item?.id)}
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                  <Button
                    onClick={() => editHandle(item?.id)}
                    variant="warning"
                    size="sm"
                    className="text-dark btn-link edit"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    onClick={() => deleteHandle(item?.id)}
                    variant="danger"
                    size="sm"
                    className="btn-link remove text-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  const addNewHandle = () => {
    !checkPermission("products.store", permissionsArray)
      ? setShowNotificaion(true)
      : setAddNew(true);
  };
  const uploadHandle = () => {
    !checkPermission("products.update", permissionsArray)
      ? setShowNotificaion(true)
      : setUploadFile(true);
  };
  return (
    <>
      <Container fluid>
        {addNew ? (
          <AddNewProduct
            setAddNew={setAddNew}
            responseHandle={responseHandle}
            setResponseTitle={setResponseTitle}
          />
        ) : (
          <Row>
            <Col md="12">
              <h4 className="title">Product</h4>
              <Card>
                <Card.Body>
                  <Col>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="success"
                        onClick={uploadHandle}
                        className="mr-2 btn-outline"
                      >
                        Upload Product <i className="fa fa-upload"></i>
                      </Button>
                      <Button variant="primary" onClick={addNewHandle} className="btn-outline">
                        Add New Product <i className="fa fa-plus"></i>
                      </Button>
                    </div>
                  </Col>
                  <ReactTable
                    data={data}
                    columns={productTableHeader}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        {view && (
          <Modal
            show={view}
            onHide={() => setView(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-90w"
          >
            <Modal.Body>
              <ViewProduct setView={setView} details={details} />
            </Modal.Body>
          </Modal>
        )}
        {uploadFile && (
          <Modal
            show={uploadFile}
            onHide={() => setUploadFile(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-90w"
          >
            <Modal.Body>
              <UploadFile
                setUploadFile={setUploadFile}
                responseHandle={responseHandle}
                setResponseTitle={setResponseTitle}
              />
            </Modal.Body>
          </Modal>
        )}
        {edit && (
          <Modal
            show={edit}
            onHide={() => setEdit(false)}
            size="lg"
            // aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-90w"
          >
            <Modal.Body>
              <EditProduct
                setEdit={setEdit}
                details={details}
                responseHandle={responseHandle}
                setResponseTitle={setResponseTitle}
              />
            </Modal.Body>
          </Modal>
        )}
      </Container>
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
      {deleteProduct && (
        <DeleteAlert
          deleteHandle={deletingHandle}
          setAlert={setDeleteProduct}
        />
      )}
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotificaion}
        />
      )}
    </>
  );
};

export default Product;
