import React from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";

const ItemDetails = ({ showView, setShowView, itemDetails }) => {
  return (
    <Modal
      show={showView}
      onHide={() => setShowView(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <Modal.Title>Item Details</Modal.Title>
        <Row>
          <Col md="6">
            <Form.Group>
              <label>Category</label>
              <Form.Control
                value={itemDetails?.category}
                readOnly
                type="text"
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <label>Sub-Category</label>
              <Form.Control
                value={itemDetails?.sub_category}
                type="text"
                readOnly
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Form.Group>
              <label>Extension</label>
              <Form.Control
                value={itemDetails?.extension}
                type="text"
                readOnly
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <label>Available Qty</label>
              <Form.Control
                value={itemDetails?.qty}
                type="text"
                readOnly
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Description</label>
              <Form.Control
                value={itemDetails?.description}
                type="text"
                as="textarea"
                readOnly
                rows={3}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="primary" onClick={() => setShowView(false)} className="btn-outline">
              Close
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

ItemDetails.propTypes = {
  showView: PropTypes.bool,
  setShowView: PropTypes.func,
  itemDetails: PropTypes.object,
};

export default ItemDetails;
