import React from "react";
import { Table } from "react-bootstrap";

const ProductRequistionsList = ({ details }) => {
  return (
    <>
      <Table striped hover responsive>
        <thead>
          <tr>
            <th>Product Item Number</th>
            <th>Product</th>
            <th>Request Date</th>
            <th>Region / Extension</th>
            <th>Description</th>
            <th>Request Qty</th>
            <th>Requisition Number</th>
          </tr>
        </thead>
        <tbody>
          {details?.requisitions.map((item, index) => (
            <tr key={index}>
              <td>{item?.product_item_number}</td>
              <td>{item?.sale_type?.name}</td>
              <td>{item?.request_date}</td>
              <td>
                {item?.region !== null
                  ? item?.region?.name
                  : item?.extension?.name}
              </td>
              <td>{item?.description}</td>
              <td>{parseInt(item?.request_quantity)}</td>
              <td>{item?.requisition_number}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ProductRequistionsList;
