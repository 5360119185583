import React from 'react';
import { Card, Row, Col} from "react-bootstrap";

const CardComponent = ({icon, title, number}) => {
  return (
    <Card className="card-stats">
      <Card.Body>
        <Row>
          <Col xs="5">
            <div className="icon-big text-center icon-warning">
              <i className={icon}></i>
            </div>
          </Col>
          <Col xs="7">
            <div className="numbers">
              <p className="card-category">{title}</p>
              <Card.Title as="h4">{number}</Card.Title>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default CardComponent;

