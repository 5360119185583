import React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import ReactTable from '../components/ReactTable/ReactTable';
import Route from "routes/Route";
import SweetAlert from "react-bootstrap-sweetalert";

const Permission = () => {
  const [permissions, setPermissions] = React.useState([]);
  const [postFailed, setPostFailed] = React.useState(false);
  const token = localStorage.getItem('token');
  const fetchPermissionHandle = async () => {
    const response = await Route.fetch("permission", token);
    if (response?.status === 200) {
      setPermissions(response?.data?.permission?.map((item) => {
        return {
          permission: `${item.name.split('.')[0]} >> ${item.name.split('.')[1]}`,
          roles:(
            <div className="actions-right">
              {item.roles.map((element) => (<span className="pt-1 pb-1 pr-2 pl-2" key={element.id} style={{background: '#35A29F', color: '#fff', fontSize: '70%', borderRadius:'15px'}}>{element.name}</span>))}
            </div>
          )
        }
      }));
    } else {
      setPostFailed(true);
    }
  }
  const generatePermissionHandle = async() => {
    const response = await Route.post("permission", token);
    if (response?.status === 200 && response?.data?.message === "The permissions table has been regenerated") {
      fetchPermissionHandle();
    };
  };
  // if (isLoading) return 'Loading...';
  return (
    <>
    <Row>
      <Col md="12">
        <Card.Title as="h4">Permission</Card.Title>
        <Card>
          <Card.Body>
            <Row>
            <Col>
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button variant="primary" className="btn-outline" onClick={generatePermissionHandle}>
                  Generate Permission <i className="fa fa-spinner"></i>
                </Button>
              </div>
            </Col>
              <Col md="12">
                <ReactTable
                  data={permissions}
                  columns={[
                    {
                      Header: "Permission",
                      accessor: "permission",
                    },
                    {
                      Header: "User Roles Assigned with Permission",
                      accessor: "roles",
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    {
      postFailed && <SweetAlert
        info
        confirmBtnText="Ok"
        cancelBtnBsStyle="info"
        title="The permissions table could not be regenerated"
        onConfirm={() => setPostFailed(false)}
      >
      </SweetAlert>
    }
    </>
  );
};

export default Permission;
