import React from "react";
// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import UpdateProfile from "views/Components/UpdateProfile";
import SweetAlert from "react-bootstrap-sweetalert";

function UserPage() {
  const [updateProfile, setUpdateProfile] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false)
  const [response, setResponse] = React.useState(false);
  const updateProfileHandle = (e) => {
    setUpdateProfile(true);
  };
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const onCancel = () => {
    setUpdateProfile(false);
  };
  const onConfirmHandle = () => {
    setShowAlert(false);
  };
  return (
    <>
      <Container fluid>
        <div className="section-image" data-image="../../assets/img/bg5.jpg">
          {/* you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " */}
          <Container>
            <Row>
              <Col md="8" sm="6">
                <Form className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Header>
                        <Card.Title as="h4">Profile</Card.Title>
                      </Card.Header>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Role (disabled)</label>
                            <Form.Control
                              defaultValue="Administrator"
                              disabled
                              placeholder="Role"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="px-1" md="6">
                          <Form.Group>
                            <label>Section</label>
                            <Form.Control
                              defaultValue="Application and Software"
                              placeholder="Section"
                              type="text"
                              disabled
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Designation</label>
                            <Form.Control
                              defaultValue="Manager,A&S"
                              placeholder="Designation"
                              type="text"
                              disabled
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>Gender</label>
                            <Form.Control
                              defaultValue="Male"
                              placeholder="Gender"
                              type="text"
                              disabled
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <label>Email</label>
                            <Form.Control
                              defaultValue="manager.asprojects@tashicell.com"
                              placeholder="Email"
                              type="email"
                              disabled
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Birthday</label>
                            <Form.Control
                              defaultValue="14-Oct-1900"
                              placeholder="Birthday"
                              type="text"
                              disabled
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>DOJ</label>
                            <Form.Control
                              defaultValue="8-Oct-2007"
                              placeholder="DOJ"
                              type="text"
                              disabled
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Form.Group>
                            <label>Employment Type</label>
                            <Form.Control
                              cols="80"
                              defaultValue="Regular"
                              placeholder="Employment Type:"
                              rows="4"
                              disabled
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="px-1" md="6">
                          <Form.Group>
                            <label>Grade</label>
                            <Form.Control
                              defaultValue="P1"
                              placeholder="Grade"
                              type="text"
                              disabled
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Button
                        className="btn-fill pull-right"
                        type="button"
                        // variant="info"
                        onClick={updateProfileHandle}
                      >
                        Update Profile
                      </Button>
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
              <Col md="4">
                <Card className="card-user">
                  <Card.Header className="no-padding">
                    <div className="card-image">
                      <img
                        alt="..."
                        src={require("assets/img/background-image.jpg")}
                      ></img>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="author mb-2">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="avatar border-gray"
                          src={require("assets/img/faces/face-0.jpg")}
                        ></img>
                        <Card.Title as="h5">Tania Keatley</Card.Title>
                      </a>
                    </div>
                    <p className="card-description text-center">
                      Employee Id: E0000
                    </p>
                    <p className="card-description text-center">
                      Region: Thimphu
                    </p>
                    <p className="card-description text-center">
                      Store : TICL_Thimphu_Main_Store
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {updateProfile && (
          <UpdateProfile onCancel={onCancel} responseHandle={responseHandle} />
        )}
        { showAlert && (response ? (
          <SweetAlert
            onConfirm={onConfirmHandle}
            closeAnim={{ name: 'hideSweetAlert', duration: 300 }}
            success
          >
            Profile Updated Successfully!
          </SweetAlert>
        ):  (
          <SweetAlert
            onConfirm={onConfirmHandle}
            closeAnim={{ name: 'hideSweetAlert', duration: 300 }}
            error
          >
            Profile Updated Failed!
          </SweetAlert>
        ))}
      </Container>
    </>
  );
}

export default UserPage;
