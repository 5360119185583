import React from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import RequiredLabel from "ui/RequiredLabel";
import PropTypes from "prop-types";
import Route from "routes/Route";

const AddCustomerType = ({addNew, setAddNew, responseHandle, setResponseTitle }) => {
  const [customerNameState, setCustomerNameState] = React.useState(true);
  const [newDetails, setNewDetails] = React.useState({
    name: "",
    description: "",
  });
  const token = localStorage.getItem("token");
  const inputFieldHandle = (e) => {
    e.target.name === "name" ? setCustomerNameState(true) : null;
    setNewDetails({
      ...newDetails,
      [e.target.name]: e.target.value,
    });
  }
  const onConfirm = async () => {
    if (newDetails.name === "") {
      setCustomerNameState(false);
    } else {
      const response = await Route.addNew(
        "customer-types",
        token,
        newDetails
      );
      if (response.status === 200) {
        setResponseTitle(response?.data?.message);
        responseHandle(true);
        setAddNew(false);
      } else {
        setResponseTitle("Failed To Create New Customer Type");
        responseHandle(false);
        setAddNew(false);
      };
    };
  };
  return (
    <Modal show={addNew} onHide={() => setAddNew(false)}>
      <Modal.Body>
        <Modal.Title>Add New Customer Type</Modal.Title>
          <Row>
            <Col md="12">
            <Form.Group>
              <label> Customer Type <span className="star">*</span></label>
              <Form.Control
                placeholder="Customer Type"
                type="text"
                name="name"
                onChange={inputFieldHandle}
              ></Form.Control>
              {customerNameState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
            <Form.Group>
              <label>Description</label>
              <Form.Control
                placeholder="Description"
                type="text"
                as="textarea"
                rows={3}
                name="description"
                onChange={inputFieldHandle}
              ></Form.Control>
               </Form.Group>
            </Col>
          </Row>
        <div className="card-category form-category">
          <span className="star">*</span>
          Required fields
        </div>
        <Row className="mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col md="3">
            <Button variant="primary" onClick={onConfirm} block>Add</Button>
          </Col>
          <Col md="3">
          <Button className="btn-outline" variant="danger" onClick={() => setAddNew(false)} block>Cancel</Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
AddCustomerType.propTypes = {
  addNew: PropTypes.bool,
  setAddNew: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddCustomerType;
