import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import Route from "routes/Route";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const InvoiceTable = () => {
  const token = localStorage.getItem("token");
  const [invoiceData, setInvoiceData] = React.useState([]);
  const fetchInvoices = async () => {
    const response = await Route.fetch("invoices", token);
    if (response?.status === 200) {
      setInvoiceData(
        response?.data?.invoices?.map((item) => ({
          id: item?.id,
          invoice_no: item?.invoice_no,
          client: item?.customer_id === null ? item?.walk_in_customer : item?.customer?.customer_name,
          invoice_date: item?.invoice_date,
          net_payable: item?.net_payable,
          status: item?.status,
        }))
      );
    }
  };
  React.useEffect(() => {
    fetchInvoices();
  }, []);
  const columns = [
    {
      dataField: "invoice_no",
      text: "Invoice Id",
      sort: true,
    },
    {
      dataField: "client",
      text: "Client",
      sort: true,
    },
    {
      dataField: "invoice_date",
      text: "Due Date",
      sort: true,
    },
    {
      dataField: "net_payable",
      text: "Total(Nu.)",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
  ];
  const defaultSorted = [
    {
      dataField: "user",
      order: "desc",
    },
  ];
  return (
    <Row>
      <Col md="12">
        <Card>
          <Card.Header>
            <Card.Title as="h4">Invoices</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md="12">
                <BootstrapTable
                  keyField="id"
                  data={invoiceData}
                  columns={columns}
                  noDataIndication="Table is Empty"
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default InvoiceTable;
