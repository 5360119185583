import React from "react";
import PropTypes from "prop-types";
import { Card, Button, Row, Col, Table, Form } from "react-bootstrap";

const ViewCategory = ({ setView, details }) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>View Category and Sub-Category</Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Title>Category</Card.Title>
        <Row className="mt-2">
          <Col md="4">
            <h6 className="title mb-1">Name</h6>
            <Form.Group>
              <Form.Control
                placeholder="Name"
                type="text"
                defaultValue={details?.sale_type?.name}
                readOnly
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md="4">
            <h6 className="title mb-1">Code</h6>
            <Form.Group>
              <Form.Control
                placeholder="Code"
                type="text"
                defaultValue={details?.code}
                readOnly
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md="4">
            <h6 className="title mb-1">Description</h6>
            <Form.Group>
              <Form.Control
                placeholder="Description"
                type="text"
                readOnly
                defaultValue={details?.description}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card.Title>Sub-Category</Card.Title>
            <Table className="table-hover table-striped w-full">
              <thead>
                <tr>
                  <th>Sl. #</th>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {details?.sub_categories?.map((item, index) => (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.code}</td>
                    <td>{item?.description}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <Button onClick={() => setView(false)} variant="default">Go Back</Button>
      </Card.Footer>
    </Card>
  );
};

ViewCategory.propTypes = {
  setView: PropTypes.func,
  details: PropTypes.object.isRequired,
};

export default ViewCategory;
