import React from "react";
import { useHistory } from "react-router-dom";
import { Nav, Navbar, Button, Dropdown, Container } from "react-bootstrap";
import Route from "routes/Route";

function AdminNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const history = useHistory();
  const logOutHandle = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("roles");
    localStorage.removeItem("permissions");
    history.push("/");
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const fetchNotification = async () => {
    const response = await Route.fetchDetails(
      "get-notifications",
      token,
      user?.id
    );
    if (response?.status == 200) {
      setNotifications(response?.data?.notification);
    }
  };
  React.useEffect(() => {
    fetchNotification();
  }, []);
  const seenNotficationHandle = async (id) => {
    const response = await Route.update("notifications", token, id, {});
    if (response?.status == 200) {
      fetchNotification();
    };
  };
  return (
    <>
      <Navbar expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-fill btn-round btn-icon d-none d-lg-block bg-dark border-dark"
                variant="dark"
                onClick={() => document.body.classList.toggle("sidebar-mini")}
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
              <Button
                className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
                variant="dark"
                onClick={() =>
                  document.documentElement.classList.toggle("nav-open")
                }
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
            </div>
            <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
              Menu
            </Navbar.Brand>
          </div>
          <button
            className="navbar-toggler navbar-toggler-right border-0"
            type="button"
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </button>
          <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
            <Nav navbar>
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="dropdown-414718872"
                  variant="default"
                >
                  <i className="nc-icon nc-bell-55 mr-1"></i>
                  {notifications?.length > 0 ? (
                    <span className="notification">
                      {notifications?.length}
                    </span>
                  ) : null}
                  <span className="d-lg-none">Notification</span>
                </Dropdown.Toggle>
                {notifications?.length > 0 ? (
                  <Dropdown.Menu alignRight>
                    {notifications?.map((item) => (
                      <Dropdown.Item
                        key={item?.id}
                        onClick={() => seenNotficationHandle(item?.id)}
                        style={{ width: '550px', wordWrap: 'break-word', whiteSpace: 'normal' }}
                      >
                        {parseInt(item?.quantity)} {item?.name} unit(s): Transfered from {item?.extension_from}{" "}
                        to {item?.extension_to}.
                      </Dropdown.Item>    
                    ))}
                  </Dropdown.Menu>
                ) : null}
              </Dropdown>
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="dropdown-41471887333"
                  variant="default"
                >
                  <i className="nc-icon nc-single-02 mr-1"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  alignRight
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <Dropdown.Item className="" href="#pablo">
                    {user?.name} ({user?.username})
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="text-danger"
                    href="#pablo"
                    onClick={logOutHandle}
                  >
                    <i className="nc-icon nc-button-power"></i>
                    Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
