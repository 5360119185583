import React from "react";
import { Button, Card, Container, Row, Col, Modal } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import Alert from "ui/Alert";
import NotificationAlert from "ui/NotificationAlert";
import AddAssign from "./Components/masters/assign/AddAssign";
import EditAssign from "./Components/masters/assign/EditAssign";
import { assignTableHeader } from "data/dummy";
import Route from "routes/Route";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { checkPermission } from "util/MasterUtil";

const Assign = () => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const [notifcationText, setNotificationText] = React.useState("");
  const [id, setId] = React.useState("");
  const [name, setName] = React.useState("");
  const [view, setView] = React.useState(false);
  const [assign, setAssign] = React.useState([]);
  const [addNew, setAddNew] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const [user, setUser] = React.useState("");
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const token = localStorage.getItem("token");
  const assignHandle = (id, name) => {
    if (!checkPermission("assignings.update", permissionsArray)) {
      return setShowNotification(true);
    }
    setId(id);
    setName(name);
    setAddNew(true);
  };
  const editHandle = async (id, user) => {
    if (!checkPermission("assignings.edit-assignings", permissionsArray)) {
      return setShowNotification(true);
    }
    const response = await Route.fetchDetails("assignings", token, id);
    if (response.status === 200) {
      setDetails(response?.data);
      setUser(user);
      setEdit(true);
    } else {
      setNotificationText("Couldn't Fetch User Assign Details");
      setShowNotification(true);
    }
  };
  const { isLoading, data: res } = useQuery(
    "assignData",
    () => Route.fetch("assignings", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setAssign(
        res?.data.map((item) => {
          return {
            id: item?.id,
            name: item?.name,
            designation: item?.designation,
            assign_type:
              item?.assign_and_employee !== null
                ? item?.assign_and_employee?.assign_type
                : "",
            region: item?.assign_and_employee?.region?.name,
            extension: item?.assign_and_employee?.extension?.name,
            actions:
              item?.assign_and_employee === null ? (
                <div className="actions-right">
                  <Button
                    onClick={() => assignHandle(item?.id, item?.name)}
                    variant="warning"
                    size="sm"
                    className="text-primary btn-link edit"
                  >
                    Add <i className="fa fa-plus" />
                  </Button>
                </div>
              ) : (
                <div className="actions-right">
                  <Button
                    onClick={() =>
                      editHandle(item?.assign_and_employee?.id, item?.name)
                    }
                    variant="warning"
                    size="sm"
                    className="text-dark btn-link edit"
                  >
                    Edit <i className="fa fa-edit" />
                  </Button>
                </div>
              ),
          };
        })
      );
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  return (
    <>
      {addNew ? (
        <AddAssign
          id={id}
          name={name}
          setAddNew={setAddNew}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      ) : (
        <Container fluid>
          <Row>
            {/* <Col md="12">
              <Button variant="primary" onClick={() =>  setAddNew(true)}>
                Add New Assgin
              </Button>
            </Col> */}
            <Col md="12">
              <h4 className="title">Assigns</h4>
              <Card>
                <Card.Body>
                  <ReactTable
                    data={assign}
                    columns={assignTableHeader}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {/* <Container fluid>
          <Row>
            <Col md="12">
              <h4 className="title">Assigns</h4>
              <Card>
                <Card.Body>
                  <ReactTable
                    data={assign}
                    columns={assignTableHeader}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container> */}
      {edit && (
        <Modal
          show={edit}
          onHide={() => setEdit(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Body>
            <EditAssign
              setEdit={setEdit}
              user={user}
              details={details}
              responseHandle={responseHandle}
              setResponseTitle={setResponseTitle}
            />
          </Modal.Body>
        </Modal>
      )}
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
      {showNotification && (
        <NotificationAlert
          title={notifcationText}
          setAlert={setShowNotification}
        />
      )}
    </>
  );
};

export default Assign;
