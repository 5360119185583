import React from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import RequiredLabel from "ui/RequiredLabel";
import PropTypes from "prop-types";
import Route from "routes/Route";

const AddNewSalesType = ({
  onCancel,
  setAddNew,
  responseHandle,
  setResponseTitle,
}) => {
  const token = localStorage.getItem("token");
  const [newDetails, setNewDetails] = React.useState({
    name: "",
    description: "",
  });
  const [emptyNameState, setEmptyNameState] = React.useState(false);
  const [btnClicked, setBtnClicked] = React.useState(false);
  const inputFieldHandle = (e) => {
    e.target.name === "name" ? setEmptyNameState(false) : null;
    setBtnClicked(false);
    setNewDetails({
      ...newDetails,
      [e.target.name]: e.target.value,
    });
  };
  const onConfirm = async () => {
    setBtnClicked(true);
    if (newDetails.name === "") {
      setEmptyNameState(true);
    } else {
      const response = await Route.addNew("sale-types", token, newDetails);
      if (response.status === 200) {
        setResponseTitle(response.data.message);
        setAddNew(false);
        responseHandle(true);
      } else {
        setResponseTitle(response.data.message);
        setAddNew(false);
        responseHandle(false);
      }
    }
  };
  return (
    <Card className="stacked-form">
      <Card.Header>
        <Card.Title as="h4">Add New Product Type</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form>
          <Row>
            <Col>
              <Form.Group>
                <label>
                  Name <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Enter Name"
                  type="text"
                  name="name"
                  onChange={inputFieldHandle}
                ></Form.Control>
                {emptyNameState ? <RequiredLabel /> : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>Description</label>
                <Form.Control
                  placeholder="Enter Description"
                  type="text"
                  name="description"
                  as="textarea"
                  rows={3}
                  onChange={inputFieldHandle}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <div className="card-category form-category">
          <span className="star">*</span>
          Required fields
        </div>
      </Card.Body>
      <Card.Footer>
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col md="2">
            <Button
              className={btnClicked ? "btn-outline" : "btn-fill"}
              type="submit"
              variant="primary"
              block
              onClick={onConfirm}
              disabled={btnClicked}
            >
              Add
            </Button>
          </Col>
          <Col md="2">
            <Button
              className="btn-outline"
              type="button"
              variant="danger"
              block
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};
AddNewSalesType.propType = {
  onCancel: PropTypes.func,
  setAddNew: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddNewSalesType;
