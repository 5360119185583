import React from "react";
import { Button, Card, Container, Row, Col, Modal } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import Alert from "ui/Alert";
import NotificationAlert from "ui/NotificationAlert";
import DeleteAlert from "ui/DeleteAlert";
import AddNewRegion from "./Components/masters/regions/AddNewRegion";
import EditRegion from "./Components/masters/regions/EditRegion";
import { regionsTableHeader } from "data/dummy";
import Route from "routes/Route";
import { useQuery } from "react-query";
import ViewRegionAndExtensions from "./Components/masters/regions/ViewRegionAndExtensions";
import { useSelector } from "react-redux";
import { checkPermission } from "util/MasterUtil";

const Regions = () => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const [tempData, setTempData] = React.useState([]);
  const [regions, setRegions] = React.useState([]);
  const [addNew, setAddNew] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const [deleteRegion, setDeleteRegion] = React.useState(false);
  const [regionId, setRegionId] = React.useState("");
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const token = localStorage.getItem("token");
  const editRegionHandle = async (id) => {
    const response = await Route.fetchDetails("edit-regions", token, id);
    if (response.status === 200) {
      setDetails(response?.data?.Region);
      setEdit(true);
    } else {
      setNotificationText("Couldn't Fetch Region & Extensions");
      setShowNotification(true);
    }
  };

  const { isLoading, data: res } = useQuery(
    "regionsData",
    () => Route.fetch("regions", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const viewDetailsHandle = async (id) => {
    if (!checkPermission("regions.edit-regions", permissionsArray)) {
      return setShowNotification(true);
    };
    try {
      const { data } = await Route.fetchDetails("edit-regions", token, id);
      if (data.Region) {
        setDetails(data?.Region);
        setView(true);
      } else {
        setNotificationText("Error: Region not found");
        setShowNotification(true);
      }
    } catch (error) {
      setNotificationText("Error while fetching details");
      setShowNotification(true);
    }
  };
  const deleteRegionHandle = (id) => {
    setRegionId(id);
    setDeleteRegion(true);
  };
  const deletingRegionHandle = async () => {
    setDeleteRegion(false);
    const response = await Route.delete("regions", token, regionId);
    if (response.status === 200) {
      setResponse(true);
      setResponseTitle(response?.data?.message);
      setShowAlert(true);
    } else if (response.status === 202) {
      setResponse(false);
      setResponseTitle(response?.data?.message);
      setShowAlert(true);
    } else {
      setResponse(true);
      setResponseTitle(response?.data?.message);
      setShowAlert(true);
    }
  };
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.region);
      if (tempData !== res?.data?.region) {
        setRegions(
          res?.data?.region?.map((item) => {
            return {
              id: item.id,
              region: item.name,
              dzongkhag: item?.dzongkhag?.name,
              actions: (
                <div className="actions-right">
                  <Button
                    className="text-primary btn-link edit"
                    onClick={() => viewDetailsHandle(item?.id)}
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                  <Button
                    onClick={() => editRegionHandle(item?.id)}
                    variant="warning"
                    size="sm"
                    className="text-dark btn-link edit"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    onClick={() => deleteRegionHandle(item?.id)}
                    variant="danger"
                    size="sm"
                    className="btn-link remove text-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  const addNewRegionHandle = () => {
    !checkPermission("regions.store", permissionsArray)
      ? setShowNotification(true)
      : setAddNew(true);
  };
  return (
    <>
      {addNew ? (
        <AddNewRegion
          setAddNew={setAddNew}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <h4 className="title">Regions & Extensions</h4>
              <Card>
                <Card.Body>
                <Col md="12" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button variant="primary" onClick={addNewRegionHandle} className="btn-outline">
                    Add New Region <i className="fa fa-plus"></i>
                  </Button>
                </Col>
                  <ReactTable
                    data={regions}
                    columns={regionsTableHeader}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      <Modal
        show={view}
        onHide={() => setView(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <ViewRegionAndExtensions setView={setView} details={details} />
        </Modal.Body>
      </Modal>
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
      {edit && (
        <EditRegion
          details={details}
          edit={edit}
          setEdit={setEdit}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      )}
      {deleteRegion && (
        <DeleteAlert
          deleteHandle={deletingRegionHandle}
          setAlert={setDeleteRegion}
        />
      )}
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      )}
    </>
  );
};

export default Regions;
