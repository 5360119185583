import React from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import Alert from "ui/Alert";
import NotificationAlert from "ui/NotificationAlert";
import { acknowledgeProductTableHeader } from "data/dummy";
import { useQuery } from "react-query";
import Route from "routes/Route";
import ReceiveProduct from "./ReceiveProduct";

const AcknowledgeProduct = () => {
  const token = localStorage.getItem("token");
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [showNotification, setShowNotification] = React.useState(false);
  const [notifcationText, setNotificationText] = React.useState("");
  const [view, setView] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const [requisitionNumber, setRequisitionNumber] = React.useState("");
  const [totalQty, setTotalQty] = React.useState("");
  const receiveHandle = async (requisition_number,  qty) => {
    setRequisitionNumber(requisition_number);
    setTotalQty(qty);
    const response = await Route.fetchDetails("regional-stores", token, requisition_number);
    if (response.status === 200) {
      setDetails(response?.data?.receiveProduct);
      setView(true);
    } else {
      setNotificationText("Error while fetching Details");
      setShowNotification(true);
    }
  };
  const [data, setData] = React.useState([]);
  const { isLoading, data: res } = useQuery(
    "regionalsStoresData",
    () => Route.fetch("regional-stores", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );

  const [tempData, setTempData] = React.useState([]);
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.transaction);
      if (tempData !== res?.data?.transaction) {
        setData(
          res?.data?.transaction?.map((item) => {
            return {
              id: item?.requisition_number,
              requisition_number: item?.requisition_number,
              regional_transfer_id: item?.regional_transfer_id,
              receive: parseInt(item?.total_qty),
              status: (
                <div className="">
                  <Button
                    variant={
                      `${item?.status}` === "process" ? "warning" : "success"
                    }
                    size="sm"
                    className="btn-round"
                  >
                    {item?.status=== "process" ? "pending" : item?.status}
                  </Button>{" "}
                </div>
              ),
              actions:
                item?.status === "process" ? (
                  <div className="actions-right">
                    <Button
                      onClick={() => receiveHandle(item?.requisition_number, parseInt(item?.total_qty))}
                      variant="info"
                      size="sm"
                      className=""
                    >
                      Acknowledge <i className="fa fa-arrow-right"></i>
                    </Button>{" "}
                  </div>
                ) : (
                  ""
                ),
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  return (
    <>
      <Container fluid>
        {view ? (
          <ReceiveProduct
            setView={setView}
            details={details}
            setResponse={setResponse}
            setResponseTitle={setResponseTitle}
            responseHandle={responseHandle}
            requisitionNumber={requisitionNumber}
            totalQty={totalQty}
          />
        ) : (
          <Row className="mt-4">
            <Col md="12">
              <Card>
                <Card.Body>
                  <ReactTable
                    data={data}
                    columns={acknowledgeProductTableHeader}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        {showAlert && (
          <Alert
            response={response}
            setShowAlert={setShowAlert}
            responseTitle={responseTitle}
          />
        )}
        {showNotification && (
          <NotificationAlert
            title={notifcationText}
            setAlert={setShowNotification}
          />
        )}
      </Container>
    </>
  );
};

export default AcknowledgeProduct;
