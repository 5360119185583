import React from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Modal
} from "react-bootstrap";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import Route from "routes/Route";
import ReactTable from "components/ReactTable/ReactTable.js";
import { salesOrderListTableHeader } from "data/dummy";
import { formatDateForReport } from "util/CommonUtil";
import { exportToExcel  } from "react-json-to-excel";

const SalesOrderList = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [salesAndOrders, setSalesAndOrders] = React.useState([]);
  const [exportData, setExportData] = React.useState([]);
  const [productTypes, setProductTypes] = React.useState([]);
  const [productTypeSelected, setProdutTypeSelected] = React.useState({
    value: "ALL",
    label: "ALL",
  });
  const [storeSelected, setStoreSelected] = React.useState({
    value: "ALL",
    label: "ALL",
  });
  const [regionSelected, setRegionSelected] = React.useState({
    value: "ALL",
    label: "ALL",
  });
  const [extensionSelected, setExtensionSelected] = React.useState({
    value: "ALL",
    label: "ALL",
  });
  const [showRegionSelect, setShowRegionSelect] = React.useState(false);
  const [regions, setRegions] = React.useState([]);
  const [extensions, setExtensions] = React.useState([]);
  const fromDateRef = React.useRef(null);
  const endDateRef = React.useRef(null);
  const [role, setRole] = React.useState(user?.roles[0]?.id);
  const [data, setData] = React.useState({
    category_id: "ALL",
    regional_id:
     role === 8
        ? user?.assign_and_employee?.regional_id
        : "ALL",
    region_extension_id:
      role === 6 || role === 11 ? user?.assign_and_employee?.extension_id : "ALL",
    from_date: "2023-01-01",
    to_date: formatDateForReport(new Date()),
  });
  const storesData = [
    {
      value: "ALL",
      label: "ALL",
    },
    {
      value: "main",
      label: "Main",
    },
    {
      value: "Region",
      label: "Region",
    },
  ];
  const [view, setView] = React.useState(false);
  const [itemDetail, setItemDetail] = React.useState({});
  const viewHandle = (item) => {
    setItemDetail(item);
    setView(true);
  };
  const token = localStorage.getItem("token");
  const fetchSalesOrderList = async () => {
    const response = await Route.fetchReports("salesorderlist", token, data);
    if (response?.status === 200) {
      setExportData(response?.data?.orderlist);
      setSalesAndOrders(response?.data?.orderlist?.map((item) => {
        return {
          invoice_no: item?.invoice_no,
          invoice_date: item?.invoice_date,
          customerName: item?.customerName,
          contactNo: item?.contactNo,
          paymentMode: item?.paymentMode,
          netPayable: item?.netPayable,
          createdBy: item?.createdBy,
          status: item?.status,
          action: (
            <div className="actions-right">
              <Button
                variant="primary"
                size="sm"
                className="text-primary btn-link"
                onClick={() => viewHandle(item)}
              >
                <i className="fa fa-eye"></i>
              </Button>
            </div>
          )
        };
      }));
    }
  };
  const fetchProductTypes = async () => {
    const response = await Route.fetch("sale-types", token);
    if (response?.status === 200) {
      setProductTypes(response?.data?.saleType);
    }
  };
  const storeHandle = (e) => {
    setStoreSelected((prev) => ({
      ...prev,
      value: e.value,
      label: e.label,
    }));
    if (e.value === "ALL") {
      setShowRegionSelect(false);
      setData((prev) => ({
        ...prev,
        regional_id: e.value,
        region_extension_id: e.value,
      }));
    } else if (e.value === "main") {
      setShowRegionSelect(false);
      setData((prev) => ({
        ...prev,
        regional_id: "",
        region_extension_id: "",
      }));
    } else {
      setShowRegionSelect(true);
    }
  };
  const fetchRegions = async () => {
    const response = await Route.fetch("regions", token);
    if (response?.status === 200) {
      setRegions(response?.data?.region);
    }
  };
  React.useEffect(() => {
    fetchSalesOrderList();
    fetchProductTypes();
    fetchRegions();
  }, []);
  const productTypeHandle = (e) => {
    setProdutTypeSelected({
      value: e.value,
      label: e.label,
    });
    setData((prev) => ({
      ...prev,
      category_id: e.value,
    }));
  };
  const regionHandle = (e) => {
    setRegionSelected((prev) => ({
      ...prev,
      value: e.value,
      label: e.label,
    }));
    setData((prev) => ({
      ...prev,
      regional_id: e.value,
    }));
  };
  const fetchExtensionBasedOnRegion = async () => {
    const response = await Route.fetchDetails(
      "edit-regions",
      token,
      data?.regional_id
    );
    if (response.status === 200) {
      setExtensions(response?.data?.Region?.extensions);
    }
  };
  React.useEffect(() => {
    if (data?.regional_id !== "" && data?.regional_id !== "ALL") {
      fetchExtensionBasedOnRegion();
    }
  }, [data?.regional_id]);
  const extensionHandle = (e) => {
    setExtensionSelected((prev) => ({
      ...prev,
      value: e.value,
      label: e.label,
    }));
    setData((prev) => ({
      ...prev,
      regional_id: "ALL",
      region_extension_id: e.value,
    }));
  };
  const startDateHandle = (e) => {
    setData((prev) => ({
      ...prev,
      from_date: formatDateForReport(e._d),
    }));
  };
  const endDateHandle = (e) => {
    setData((prev) => ({
      ...prev,
      to_date: formatDateForReport(e._d),
    }));
  };
  const clearHandle = () => {
    setProdutTypeSelected((prev) => ({
      ...prev,
      value: "ALL",
      label: "ALL",
    }));
    setStoreSelected((prev) => ({
      ...prev,
      value: "ALL",
      label: "ALL",
    }));
    setShowRegionSelect(false);
    setRegionSelected((prev) => ({
      ...prev,
      value: "ALL",
      label: "ALL",
    }));
    setExtensionSelected((prev) => ({
      ...prev,
      value: "ALL",
      label: "ALL",
    }));
    if (fromDateRef.current) {
      fromDateRef.current.state.selectedDate = null;
      fromDateRef.current.setState({ inputValue: "" });
    }
    if (endDateRef.current) {
      endDateRef.current.state.selectedDate = null;
      endDateRef.current.setState({ inputValue: "" });
    }
    setData((prev) => ({
      ...prev,
      category_id: "ALL",
      regional_id: "ALL",
      region_extension_id: "ALL",
      from_date: "2023-01-01",
      to_date: formatDateForReport(new Date()),
    }));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Body>
                <Card.Title as="h4">Sales Order List</Card.Title>
                <Form>
                <Row>
                    <Col md="2">
                      <Form.Group>
                        <label>Product Type</label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="productTypeSelect"
                          value={productTypeSelected}
                          onChange={(e) => productTypeHandle(e)}
                          options={productTypes?.map((item) => ({
                            value: item?.id,
                            label: item?.name,
                          }))}
                          placeholder="Product Type"
                        />
                      </Form.Group>
                    </Col>
                    {role !== 6 && role !== 8 && role !== 11 && (
                      <Col md="2">
                        <Form.Group>
                          <label>Stores</label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="storeSelect"
                            value={storeSelected}
                            onChange={(e) => storeHandle(e)}
                            options={storesData?.map((item) => ({
                              value: item?.value,
                              label: item?.label,
                            }))}
                            placeholder="Store"
                          />
                        </Form.Group>
                      </Col>
                    )}
                    {showRegionSelect && (
                      <>
                        <Col md="2">
                          <Form.Group>
                            <label>Region</label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              name="regionSelect"
                              value={regionSelected}
                              onChange={(e) => regionHandle(e)}
                              options={regions?.map((item) => ({
                                value: item?.id,
                                label: item?.name,
                              }))}
                              placeholder="Region"
                            />
                          </Form.Group>
                        </Col>
                        <Col md="2">
                          <Form.Group>
                            <label>Extension</label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              name="regionSelect"
                              value={extensionSelected}
                              onChange={(e) => extensionHandle(e)}
                              options={extensions?.map((item) => ({
                                value: item?.id,
                                label: item?.name,
                              }))}
                              placeholder="Extension"
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}
                    <Col md="2">
                      <Form.Group>
                        <label>From Date</label>
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: "Select From Date",
                          }}
                          closeOnSelect
                          timeFormat={false}
                          ref={fromDateRef}
                          onChange={startDateHandle}
                        ></ReactDatetime>
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <Form.Group>
                        <label>To Date</label>
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: "Select To Date",
                          }}
                          closeOnSelect
                          timeFormat={false}
                          ref={endDateRef}
                          onChange={endDateHandle}
                        ></ReactDatetime>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Col md="2" style={{ marginTop: "28px" }}>
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="primary"
                        onClick={fetchSalesOrderList}
                        block
                      >
                        Search
                      </Button>
                    </Col>
                    <Col md="2" style={{ marginTop: "28px" }}>
                      <Button
                        className="btn-outline"
                        type="button"
                        variant="info"
                        onClick={clearHandle}
                        block
                      >
                        Clear
                      </Button>
                    </Col>
                    <Col md="2" style={{ marginTop: "28px" }}>
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="success"
                        onClick={() => exportToExcel(exportData, "Sales Order List Report")}
                        block
                      >
                        Download
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    {
                    salesAndOrders?.length > 0 ? 
                    <ReactTable
                        data={salesAndOrders}
                        columns={salesOrderListTableHeader}
                        className="-striped -highlight primary-pagination"
                      />
                    : <Alert variant="primary">
                    <span>No Information to Display</span>
                  </Alert>
                    }
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {view && (
        <Modal show={view} onHide={() => setView(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Sales Order Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md="6">
                <label>Customer Name</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Customer Name"
                    type="text"
                    value={itemDetail?.customerName}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="6">
                <label>Contact Number</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Contact No."
                    type="text"
                    value={itemDetail?.contactNo}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <label>Invoice Number</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Online Amount Paid"
                    type="text"
                    value={itemDetail?.invoice_no}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="6">
                <label>Invoice Date</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Total Amount Paid"
                    type="text"
                    value={itemDetail?.invoice_date}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <label>Status</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Status"
                    type="text"
                    value={itemDetail?.status}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="6">
                <label>Created By</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Created By"
                    type="text"
                    value={itemDetail?.name}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "flex-end" }} className="my-4">
              <Col md="6">
                <Button
                  variant="danger"
                  className="btn-outline"
                  onClick={() => setView(false)}
                  block
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default SalesOrderList;
