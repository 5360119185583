import React from "react";
import PropTypes from "prop-types";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import RequiredLabel from "ui/RequiredLabel";
import Route from "routes/Route";
import { formValidation } from "util/CommonUtil";

const EditWarranty = ({ setEdit, details, responseHandle, setResponseTitle }) => {
  const token = localStorage.getItem("token");
  const [productTypes, setProductTypes] = React.useState([]);
  const [newDetails, setNewDetails] = React.useState({
    sale_type_id: details?.sale_type_id,
    no_of_years: details?.no_of_years,
  });
  const year = [
    {
      id: 0.5,
      name: "Half Year",
    },
    {
      id: 1.0,
      name: "One Year",
    },
    {
      id: 1.5,
      name: "One and Half Year",
    },
    {
      id: 2.0,
      name: "Two Years",
    },
  ];
  const [productState, setProductState] = React.useState(true);
  const [yearState, setYearState] = React.useState(true);
  const fetchProductTypes = async () => {
    const response = await Route.fetch("sale-types", token);
    if (response.status === 200) {
      setProductTypes(response?.data?.saleType);
    }
  };
  React.useEffect(() => {
    fetchProductTypes();
  }, []);

  const productTypesSelectHandle = (e) => {
    setProductState(true);
    setNewDetails({
      ...newDetails,
      sale_type_id: parseInt(e.value),
    });
  };
  const yearSelectHandle = (e) => {
    setYearState(true);
    setNewDetails({
      ...newDetails,
      no_of_years: parseFloat(e.value),
    });
  };
  const validationConditions = [
    {
      field: "productType",
      condition: newDetails.sale_type_id === "",
      setState: setProductState,
    },
    {
      field: "noOfYears",
      condition: newDetails.no_of_years === "",
      setState: setYearState,
    },
  ];
  const submitHandle = async () => {
    if (formValidation(validationConditions)) {
      const response = await Route.update(
        "master-warranties",
        token,
        details?.id,
        newDetails
      );
      if (response.status === 200) {
        responseHandle(true);
        setResponseTitle(response?.data?.message);
        setEdit(false);
      } else {
        responseHandle(false);
        setResponseTitle(response?.data?.message);
        setEdit(false);
      }
    }
  };
  return (
    <>
      <Card.Body>
      <Card.Title>Edit Warranty</Card.Title>
        <Row>
          <Col>
            <Form.Group>
              <label>
                Product Type <span className="star">*</span>
              </label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="regionSelect"
                onChange={productTypesSelectHandle}
                defaultValue={{
                  label: `${
                    details?.sale_type?.name
                  }`,
                }}
                options={productTypes?.map((item) => ({
                  value: `${item.id}`,
                  label: `${item.name}`,
                }))}
                placeholder="Select Product Type"
              />
              {productState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <label>
                No of Years <span className="star">*</span>
              </label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="extensionSelect"
                onChange={yearSelectHandle}
                defaultValue={{
                  label: `${
                    details?.no_of_years === "0.50"
                      ? "Half Year"
                      : details?.no_of_years === "1.00"
                      ? "One Year"
                      :  details?.no_of_years === "1.50"
                      ? "One and Half Year"
                      : "Two Years"
                  }`,
                }}
                options={year?.map((item) => ({
                  value: `${item.id}`,
                  label: `${item.name}`,
                }))}
                placeholder="Select Year"
              />
              {yearState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
        </Row>
        <div className="card-category form-category">
          <span className="star">*</span>
          Required fields
        </div>
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col md="3">
            <Button onClick={submitHandle} block>
              Update
            </Button>
          </Col>
          <Col md="3">
            <Button
              onClick={() => setEdit(false)}
              className="btn-outline"
              variant="danger"
              block
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </>
  );
};

EditWarranty.propTypes = {
  setEdit: PropTypes.func,
  details: PropTypes.object,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default EditWarranty;
