import React from "react";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import RequiredLabel from "ui/RequiredLabel";
import NotificationAlert from "ui/NotificationAlert";
import Route from "routes/Route";
import { checkPermission } from "util/MasterUtil";
import {
  formValidation,
  formatDateOnly,
  formatDateForCalender,
} from "util/CommonUtil";
import { useSelector } from "react-redux";

const EditDiscount = ({
  edit,
  setEdit,
  details,
  responseHandle,
  setResponseTitle,
}) => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showNotification, setShowNotification] = React.useState(false);
  const [newDetails, setNewDetails] = React.useState({
    category: "",
    sub_category: "",
    discount_name: "",
    discount_type: "",
    discount_value: "",
    region: "",
    extension: "",
    start_date: "",
    end_date: "",
    description: "",
  });
  const [categoryState, setCategoryState] = React.useState(true);
  const [discountNameState, setDiscountNameState] = React.useState(true);
  const [discountTypeState, setDiscountTypeState] = React.useState(true);
  const [discountValueState, setDiscountValueState] = React.useState(true);
  const [category, setCategory] = React.useState([]);
  const [subCategory, setSubCategory] = React.useState([]);
  const [regionApplicable, setRegionApplicable] = React.useState(false);
  const [regions, setRegions] = React.useState([]);
  const [extensions, setExtensions] = React.useState([]);
  const token = localStorage.getItem("token");
  React.useEffect(() => {
    setNewDetails({
      ...newDetails,
      category: details?.category_id,
      sub_category: details?.sub_category_id,
      discount_name: details?.discount_name,
      discount_type: details?.discount_type,
      discount_value: details?.discount_value,
      region: details?.region === null ? null : details?.region?.id,
      extension: details?.extension === null ? null : details?.extension?.id,
      start_date: details?.start_date,
      end_date: details?.end_date,
      description: details?.description,
      applicable_to: details?.applicable_to
    });
  }, [details]);
  const fetchCategory = async () => {
    const response = await Route.fetch("categories", token);
    if (response.status === 200) {
      setCategory(response?.data?.category);
    }
  };
  const fetchSubCategory = async (id) => {
    const response = await Route.fetchDetails("edit-categories", token, id);
    if (response.status === 200) {
      setSubCategory(response?.data?.category?.sub_categories);
    }
  };
  const discountType = [
    {
      value: "Percentage",
    },
    {
      value: "Lump Sum",
    },
  ];
 
  const selectOptions = [
    {
      value: "All",
    },
    {
      value: "Main"
    },
    {
      value: "Region",
    },
    {
      value: "Extension",
    }
  ];
  const applicableHandle = (e) => {
    setNewDetails({
      ...newDetails,
      "applicable_to": e.value,
    });
    e.value === "Region" || e.value === "Extension" 
      ? setRegionApplicable(true)
      : setRegionApplicable(false);
  };
  const fetchRegion = async () => {
    const response = await Route.fetch("regions", token);
    if (response?.status === 200) {
      setRegions(response?.data?.region);
    }
  };
  const fetchExtensions = async (id) => {
    const response = await Route.fetchDetails("edit-regions", token, id);
    if (response?.status === 200) {
      setExtensions(response?.data?.Region?.extensions);
    }
  };
  React.useEffect(() => {
    fetchCategory();
    fetchRegion();
  }, []);
  const inputFiledHandle = (name, value) => {
    name === "category"
      ? fetchSubCategory(value)
      : name === "region"
      ? fetchExtensions(value)
      : null;
    name === "category"
      ? setCategoryState(true)
      : name === "discount_name"
      ? setDiscountNameState(true)
      : name === "discount_type"
      ? setDiscountTypeState(true)
      : name === "discount_value"
      ? setDiscountValueState(true)
      : null;
    setNewDetails({
      ...newDetails,
      [name]: value,
    });
  };
  const validationConditions = [
    {
      field: "category",
      condition: newDetails.category === "",
      setState: setCategoryState,
    },
    {
      field: "discountName",
      condition: newDetails.discount_name === "",
      setState: setDiscountNameState,
    },
    {
      field: "discountType",
      condition: newDetails.discount_type === "",
      setState: setDiscountTypeState,
    },
    {
      field: "discountValue",
      condition: newDetails.discount_value === "",
      setState: setDiscountValueState,
    },
  ];
  const onConfirm = async () => {
    if (!checkPermission("discount-types.update", permissionsArray)) {
      return <NotificationAlert setAlert={setShowNotification} />;
    }
    if (formValidation(validationConditions)) {
      const response = await Route.update(
        "discount-types",
        token,
        details?.id,
        newDetails
      );
      if (response.status === 200) {
        setResponseTitle(response?.data?.message);
        responseHandle(true);
        setEdit(false);
      } else {
        setResponseTitle("Failed To Update Discount Type");
        responseHandle(false);
        setEdit(false);
      }
    }
  };
  return (
    <Modal show={edit} onHide={() => setEdit(false)} size="lg">
      <Modal.Body>
        <Modal.Title>Edit Discount Type</Modal.Title>
        <Row>
          <Col md="6">
            <Form.Group>
              <label>
                Category <span className="star">*</span>
              </label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                onChange={(e) => inputFiledHandle("category", e.value)}
                name="categorySelect"
                defaultValue={{
                  value: `${details?.category?.id}`,
                  label: `${details?.category?.description}`,
                }}
                options={category?.map((item) => ({
                  value: `${item?.sale_type?.id}`,
                  label: `${item?.sale_type?.name}`,
                }))}
                placeholder="Select Category"
              />
              {categoryState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <label>Sub-Category</label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                onChange={(e) => inputFiledHandle("sub_category", e.value)}
                name="subCategorySelect"
                defaultValue={{
                  value: `${details?.sub_category?.id}`,
                  label: `${details?.sub_category?.name}`,
                }}
                options={subCategory?.map((item) => ({
                  value: `${item?.id}`,
                  label: `${item?.name}`,
                }))}
                placeholder="Select Sub-Category"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Form.Group>
              <label>
                Discount Name <span className="star">*</span>
              </label>
              <Form.Control
                placeholder="Enter Discount Name"
                type="text"
                name="discount_name"
                defaultValue={details?.discount_name}
                onChange={(e) =>
                  inputFiledHandle(e.target.name, e.target.value)
                }
              ></Form.Control>
              {discountNameState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <label>
                Discount Type <span className="star">*</span>
              </label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                onChange={(e) => inputFiledHandle("discount_type", e.value)}
                name="discountTypeSelect"
                defaultValue={{
                  value: `${details?.discount_type}`,
                  label: `${details?.discount_type}`,
                }}
                options={discountType?.map((item) => ({
                  value: `${item?.value}`,
                  label: `${item?.value}`,
                }))}
                placeholder="Select Discount Type"
              />
              {discountTypeState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <label>
                Discount Value<span className="star">*</span>
              </label>
              <Form.Control
                placeholder="Enter Discount Value"
                type="number"
                name="discount_value"
                defaultValue={details?.discount_value}
                onChange={(e) =>
                  inputFiledHandle(e.target.name, e.target.value)
                }
              ></Form.Control>
              {discountValueState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Form.Group>
              <label>Applicable To</label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                onChange={applicableHandle}
                name="applicabelTypeSelect"
                options={selectOptions?.map((item) => ({
                  value: `${item?.value}`,
                  label: `${item?.value}`,
                }))}
                placeholder="Select"
              />
            </Form.Group>
          </Col>
          {regionApplicable && (
            <>
              <Col md="4">
                <Form.Group>
                  <label>Region</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    onChange={(e) => inputFiledHandle("region", e.value)}
                    name="regionTypeSelect"
                    defaultValue={details?.region?.name}
                    options={regions?.map((item) => ({
                      value: `${item?.id}`,
                      label: `${item?.name}`,
                    }))}
                    placeholder="Select"
                  />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>Extension</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    onChange={(e) => inputFiledHandle("extension", e.value)}
                    name="extensionTypeSelect"
                    defaultValue={details?.extension?.name}
                    options={extensions?.map((item) => ({
                      value: `${item?.id}`,
                      label: `${item?.name}`,
                    }))}
                    placeholder="Select"
                  />
                </Form.Group>
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col md="6">
            <Form.Group>
              <label>
                Start Date <span className="star">*</span>
              </label>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Start Date",
                }}
                initialValue={
                  details?.start_date === null
                    ? null
                    : formatDateForCalender(details?.start_date)
                }
                timeFormat={false}
                closeOnSelect
                onChange={(e) =>
                  inputFiledHandle("start_date", formatDateOnly(e._d))
                }
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <label>
                End Date <span className="star">*</span>
              </label>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "End Date",
                }}
                initialValue={
                  details?.end_date === null
                    ? null
                    : formatDateForCalender(details?.end_date)
                }
                timeFormat={false}
                closeOnSelect
                onChange={(e) =>
                  inputFiledHandle("end_date", formatDateOnly(e._d))
                }
              ></ReactDatetime>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Description</label>
              <Form.Control
                placeholder="Enter Description"
                type="text"
                as="textarea"
                rows={3}
                name="description"
                defaultValue={details?.description}
                onChange={(e) =>
                  inputFiledHandle(e.target.name, e.target.value)
                }
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <div className="card-category form-category">
            <span className="star">*</span>
            Required fields
          </div>
        </Form.Group>
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col md="2">
            <Button variant="primary" onClick={onConfirm} block>
              Update
            </Button>
          </Col>
          <Col md="2">
            <Button
              variant="danger"
              className="btn-outline"
              onClick={() => setEdit(false)}
              block
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
EditDiscount.propTypes = {
  setEdit: PropTypes.func,
  details: PropTypes.object.isRequired,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default EditDiscount;
