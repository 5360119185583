import React from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import ViewEmi from "./Components/soms/emi/ViewEmi";
import EmiAction from "./Components/soms/emi/EmiAction";
import Alert from "ui/Alert";
import NotificationAlert from "ui/NotificationAlert";
import { userEmiTableHeader } from "data/dummy";
import Route from "routes/Route";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

const ApproveEmi = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [emiDetails, setEmiDetails] = React.useState([]);
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showAlert, setShowAlert] = React.useState(false);
  const [action, setAction] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const [response, setResponse] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [details, setDetails] = React.useState([]);
  const token = localStorage.getItem("token");
  const { isLoading, data: res } = useQuery(
    "appliedEmiData",
    () => Route.fetch("approve-emi", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const viewHandle = (item) => {
    setDetails(item);
    setView(true);
  };
  const aprroveHandle = (item) => {
    setDetails(item);
    setAction(true);
  };
  const [tempData, setTempData] = React.useState([]);
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.emi);
      if (tempData !== res?.data?.emi) {
        setEmiDetails(
          res?.data?.emi?.map((item) => {
            return {
              id: item?.id,
              emi_no: item?.emi_no,
              name: item?.user?.name,
              item_number: item?.item_number,
              request_date: item?.request_date,
              emi_duration: item?.emi_duration,
              monthly_emi: item?.monthly_emi,
              total: item?.total,
              deduction_from: item?.deduction_from,
              status: (
                <div className="">
                  <Button
                    variant={
                      `${item?.status}` === "pending" ? "warning" : `${item?.status}` === "rejected" ? "danger" : "success"
                    }
                    size="sm"
                    className="btn-round"
                  >
                    {item?.status}
                  </Button>{" "}
                </div>
              ),
              actions: (
                <div className="actions-right" style={{ display: "flex", justifyContent:"space-evenly" }}>
                  <Button
                    className="text-primary btn-link edit"
                    onClick={() => viewHandle(item)}
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                  {
                    `${item?.status}` === "pending" ? <div className="actions-right">
                    <Button
                      onClick={() => aprroveHandle(item)}
                      variant="info"
                      size="sm"
                      className=""
                    >
                      Approve <i className="fa fa-arrow-right"></i>
                    </Button>{" "}
                  </div> : null
                  }
                  
                </div>
              ),
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Approve EMI</h4>
            <Card>
              <Card.Body>
                <ReactTable
                  data={emiDetails}
                  columns={userEmiTableHeader}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {view && <ViewEmi view={view} setView={setView} viewDetail={details} />}
      {action && (
        <EmiAction
          view={action}
          setView={setAction}
          viewDetail={details}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      )}
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      )}
    </>
  );
};

export default ApproveEmi;
