import React from "react";
import PropTypes from "prop-types";
import { Container, Card, Row, Col, Button, Table } from "react-bootstrap";
import { ToWords } from "to-words";
import Header from "../assets/img/header.png";
import LogoImg from "../assets/img/logo.png";
import HeaderText from "../assets/img/header word.png";
import TashiGroupLogo from "../assets/img/tashi group logo.png";
import ReactToPrint from "react-to-print";
import Route from "routes/Route";
// import logo from "../../../../assets/img/logo.svg";

const Invoice = ({
  saleVoucher,
  setShowInvoice,
  paymentHistory,
  receiptNo,
  invoicePayment,
}) => {
  // const [warranties, setWarranties] = React.useState([]);
  const token = localStorage.getItem("token");
  const fetchWarrantis = async () => {
    const response = await Route.fetch("warranties", token);
    if (response?.status === 200) {
      setWarranties(response?.data?.warranties);
    }
  };

  // React.useEffect(() => {
  //   fetchWarrantis();
  // }, []);
  const componentRef = React.useRef();
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Ngultrum",
        plural: "Ngultrum",
        symbol: "Nu.",
        fractionalUnit: {
          name: "Chhertum",
          plural: "Chhertum",
          symbol: "",
        },
      },
    },
  });

  React.useEffect(() => {
    console.log(saleVoucher)
  },[])
  const tableStyle = {
    // borderCollapse: "collapse", // To ensure borders collapse together
    border: "2px solid #000",
  };
  const headerStyle = {
    border: "2px solid #000", // You can adjust the border style and color here
    padding: "8px", // Optional: Add padding for better spacing
    fontWeight: "bold",
    color: "#000"
  };
  
  const cellStyle = {
    border: "2px solid #000", // You can adjust the border style and color here
    padding: "8px", // Optional: Add padding for better spacing
  };
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  return (
    <Container fluid>
      <Row>
        <Col>
          <Container ref={componentRef}>
            <Row className="mt-4">
              <Col>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img src={LogoImg} alt="logo" style={{ width: "180px" }} />
                  <img
                    src={HeaderText}
                    alt="header"
                    style={{ width: "300px" }}
                  />
                  <img
                    src={TashiGroupLogo}
                    alt="Tashi Group Logo"
                    style={{ width: "150px" }}
                  />
                </div>
              </Col>
            </Row>
            <hr style={{ border: "1px solid #000" }} />
            <Card.Body>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <p
                    style={{ margin: "0", paddingTop: "0", fontSize: "0.8em" }}
                  >
                    Receipt No: {paymentHistory[0]?.receipt_no}
                  </p>
                  <p
                    style={{ margin: "0", paddingTop: "0", fontSize: "0.8em" }}
                  >
                    Invoice No: {saleVoucher?.invoice_no}
                  </p>
                </div>
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "0.8em" }}>
                    CASH RECEIPT
                  </p>
                </div>
                <div>
                  <p style={{ fontSize: "0.8em" }}>
                    Date: {saleVoucher?.invoice_date}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "3em",
                }}
              >
                <div>
                  <p
                    style={{ margin: "0", paddingTop: "0", fontSize: "0.8em" }}
                  >
                    Customer Name:{" "}
                    {saleVoucher?.customer_id === null
                      ? saleVoucher?.walk_in_customer
                      : saleVoucher?.customer?.customer_name}
                  </p>
                  <p
                    style={{ margin: "0", paddingTop: "0", fontSize: "0.8em" }}
                  >
                    Phone:{" "}
                    {saleVoucher?.customer_id === null
                      ? saleVoucher?.contact_no
                      :  saleVoucher?.customer?.contact_no}
                  </p>
                </div>
                <div>
                  <table className="table" style={tableStyle}>
                    <thead>
                      <tr>
                        <th style={headerStyle}>Payment Mode</th>
                        <th style={headerStyle}>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={cellStyle}>{paymentHistory[0]?.payment_mode}</td>
                        <td style={cellStyle}>{paymentHistory[0]?.total_amount_paid}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <table bordered className="table" style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={headerStyle}>Serial No.</th>
                      <th style={headerStyle}>Description</th>
                      <th style={headerStyle}>Qty</th>
                      <th style={headerStyle}>Unit Price</th>
                      <th style={headerStyle}>Discount</th>
                      <th style={headerStyle}>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {saleVoucher?.sale_voucher_details.map((item, index) => (
                      <tr key={index}>
                        <td style={cellStyle}>{item?.product?.serial_no}</td>
                        <td style={cellStyle}>{item?.product?.description}</td>
                        <td style={cellStyle}>
                          {item?.quantity}
                        </td>
                        <td style={cellStyle}>{item?.product?.price}</td>
                        <td style={cellStyle}>
                          {item?.discount === null
                            ? null
                            : item?.discount?.discount_type === "Percentage"
                            ? `${item?.discount?.discount_value} %`
                            : `Nu.${item?.discount?.discount_value}`}
                        </td>
                        <td style={cellStyle}>{item?.total}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="5" style={cellStyle}>
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          Service Charge
                        </span>
                      </td>
                      <td style={cellStyle}>{saleVoucher?.service_charge}</td>
                    </tr>
                    <tr>
                      <td colSpan="5" style={cellStyle}>
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          Gross
                        </span>
                      </td>
                      <td style={cellStyle}>{saleVoucher?.gross_payable}</td>
                    </tr>
                    <tr>
                      <td colSpan="5" style={cellStyle}>
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          Net Payable
                        </span>
                      </td>
                      <td>{saleVoucher?.net_payable}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Row>
                <Col>
                  <p style={{ fontSize: "0.8em" }}>
                    Amounts in Words:{" "}
                    {toWords.convert(saleVoucher?.net_payable, {
                      currency: true,
                    })}
                  </p>
                </Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "2em",
                }}
              >
                <Col md="4">
                  <p
                    style={{ margin: "0", paddingTop: "0", fontSize: "0.8em" }}
                  >
                    Created By: {saleVoucher?.user?.name}
                  </p>
                  <p
                    style={{ margin: "0", paddingTop: "0", fontSize: "0.8em" }}
                  >
                    Store:{" "}
                    {saleVoucher?.user?.assign_and_employee === null
                      ? "Main Store"
                      : saleVoucher?.user?.assign_and_employee?.region === null
                      ? saleVoucher?.user?.assign_and_employee?.extension?.name
                      : saleVoucher?.user?.assign_and_employee?.region?.name}
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  {/* {warranties?.length > 0 &&
                    warranties?.map((item) => (
                      <p style={{ fontSize: "0.8em", lineHeight: 0.1, fontStyle: 'italic' }}>
                        *The applicable warranty for{" "}
                        {item?.sale_type_id === 1 ? "Phone" : "Accessory"} shall
                        be valid for{" "}
                        {item?.no_of_years === "1.00" ? "1 Years" : "1/2 year"}.
                      </p>
                    ))} */}
                  <p style={{ fontSize: "0.8em", lineHeight: 0.1 }}>
                    Declaration:
                  </p>
                  <p style={{ fontSize: "0.8em" }}>
                    1/We declare that the items in this case memo/invoice shows
                    its actual current value and caries manufacturer warranty as
                    per manufacturers T&C(warranty liability is solely with
                    Tashi Electronics.). A)Any defect on the product may be
                    brought to the nearest Tashi Electronics authorized service
                    centers. B) Goods one sold shall not be taken back or
                    exchanged.
                  </p>
                </Col>
              </Row>
              <hr style={{ border: "1px solid #000" }} />
              <Row style={{ textAlign: "center" }}>
                <Col>
                  <p
                    style={{ margin: "0", paddingTop: "0", fontSize: "0.7em" }}
                  >
                    Address: Tashi Mall, Opposite to Pemako Hotel, Thimphu,
                    Bhutan
                  </p>
                  <p
                    style={{ margin: "0", paddingTop: "0", fontSize: "0.7em" }}
                  >
                    Phone: +975 77812900, website:
                    https://www.tashielectronics.com
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Container>
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <Col md="4">
          <ReactToPrint
            trigger={() => (
              <Button variant="primary" block>
                <i className="fa fa-print"></i> Print
              </Button>
            )}
            documentTitle="Invoice"
            content={reactToPrintContent}
          />
        </Col>
        <Col md="4">
          <Button
            variant="danger"
            block
            className="btn-outline"
            onClick={() => setShowInvoice(false)}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

Invoice.propType = {
  saleVoucher: PropTypes.object.isRequired,
  setShowInvoice: PropTypes.func,
  paymentHistory: PropTypes.object,
  receiptNo: PropTypes.string,
  invoicePayment: PropTypes.number,
};

export default Invoice;
