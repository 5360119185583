import React from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import Route from "routes/Route";

const RepairItem = ({ id, deleteRowHandle, inputFieldHandle }) => {
  const [category, setCategory] = React.useState([]);
  const [subCategory, setSubCategory] = React.useState([]);
  const [salesType, setSalesType] = React.useState([]);
  const token = localStorage.getItem("token");
  const fetchCategory = async () => {
    const response = await Route.fetch("categories", token);
    if (response.status === 200) {
      setCategory(response?.data?.category);
    }
  };
  const fetchSalesType = async () => {
    const response = await Route.fetch("sale-types", token);
    if (response.status === 200) {
      setSalesType(response?.data?.saleType);
    }
  };
  React.useEffect(() => {
    fetchCategory();
    fetchSalesType();
  }, []);

  const settingSubCategorySelectHandle = (e) => {
    category.map((item) => {
      if (item?.id === parseInt(e.value)) {
        setSubCategory(item?.sub_categories);
      }
    });
  };
  return (
    <Card key={id}>
      <Card.Body>
        <Form>
          <Row>
            <Col md="3">
              <Form.Group>
                <label>
                  Category <span className="star">*</span>
                </label>
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="categorySelect"
                  onChange={(e) => {
                    inputFieldHandle(id, "category", e.value);
                    settingSubCategorySelectHandle(e);
                  }}
                  options={category?.map((item) => ({
                    value: `${item?.id}`,
                    label: `${item?.sale_type?.name}`,
                  }))}
                  placeholder="Select"
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>
                  Sub-Category <span className="star">*</span>
                </label>
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="subCategorySelect"
                  onChange={(e) => inputFieldHandle(id, "subCategory", e.value)}
                  options={subCategory?.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.name}`,
                  }))}
                  placeholder="Select"
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>
                  Item No. <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Enter Item No."
                  type="text"
                  onChange={(e) =>
                    inputFieldHandle(id, "itemNo", e.target.value)
                  }
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>
                  Serial No. <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Enter Serial No."
                  type="text"
                  onChange={(e) =>
                    inputFieldHandle(id, "serial", e.target.value)
                  }
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Form.Group>
                <label>
                  Price <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Enter Price"
                  type="text"
                  onChange={(e) =>
                    inputFieldHandle(id, "price", e.target.value)
                  }
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group>
                <label>
                  Quantity <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Enter Quantity"
                  type="text"
                  onChange={(e) =>
                    inputFieldHandle(id, "quantity", e.target.value)
                  }
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Sub-Inventory</label>
                <Form.Control
                  placeholder="Enter Sub-inventory"
                  type="text"
                  onChange={(e) =>
                    inputFieldHandle(id, "sub_inventory", e.target.value)
                  }
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group>
                <label>Locator</label>
                <Form.Control
                  placeholder="Enter Locator"
                  type="text"
                  onChange={(e) =>
                    inputFieldHandle(id, "locator", e.target.value)
                  }
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group>
                <label>ICCID</label>
                <Form.Control
                  placeholder="Enter Price"
                  type="text"
                  onChange={(e) =>
                    inputFieldHandle(id, "iccid", e.target.value)
                  }
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="1">
              <Button
                className="btn-fill"
                type="button"
                variant="danger"
                block
                onClick={() => deleteRowHandle(id)}
              >
                <i className="fa fa-trash"></i>
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};
RepairItem.propType = {
  id: PropTypes.string,
  newExtensionHandle: PropTypes.func,
  inputFieldHandle: PropTypes.func,
  deleteRowHandle: PropTypes.func,
};

export default RepairItem;
