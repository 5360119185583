import React from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import RequiredLabel from "ui/RequiredLabel";
import PropTypes from "prop-types";
import Route from "routes/Route";

const AddRole = ({ setAddNewRole, responseHandle, setResponseTitle }) => {
  const [roleNameState, setRoleNameState] = React.useState(true);
  const [newRoleDetails, setNewRoleDetails] = React.useState({
    role_name: "",
    description: "",
  });
  const inputHandle = (e) => {
    setNewRoleDetails({
      ...newRoleDetails,
      [e.target.name]: e.target.value
    });
    e.target.name === "role_name" && setRoleNameState(true);
  };
  const token = localStorage.getItem('token')
  const addRoleHandle = async () => {
    if (newRoleDetails.role_name === "") {
      setRoleNameState(false);
    } else {
      const response = await Route.addNew("roles", token, newRoleDetails);
      if (response.status === 200) {
        setResponseTitle(response.data.message);
        setAddNewRole(false);
        responseHandle(true);
      } else {
        setResponseTitle(response.data.message);
        setAddNewRole(false);
        responseHandle(false);
      };
    }
  };
  return (
    <Modal show={setAddNewRole} onHide={() => setAddNewRole(false)}>
      <Modal.Body>
        <Card.Body>
          <Card.Title as="h5">Add New Role</Card.Title>
          <Form className="form-horizontal">
            <Form.Group>
              <label>
                Role <span className="star">*</span>
              </label>
              <Form.Control
                placeholder="Enter Role"
                type="text"
                name="role_name"
                onChange={inputHandle}
              ></Form.Control>
              {roleNameState ? null : <RequiredLabel />}
            </Form.Group>
            <Form.Group>
              <label>Role Description</label>
              <Form.Control
                placeholder="Write Description"
                type="text"
                as="textarea"
                name="description"
                rows={3}
                onChange={inputHandle}
              ></Form.Control>
            </Form.Group>
            <div className="card-category form-category">
              <span className="star">*</span>
              Required fields
            </div>
            <Row className="mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Col md="4">
                <Button
                  variant="primary"
                  onClick={addRoleHandle}
                  block
                  className="mt-2"
                >
                  Add
                </Button>
              </Col>
              <Col md="4">
                <Button
                  variant="danger"
                  onClick={() => setAddNewRole(false)}
                  block
                  className="mt-2 btn-outline"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Modal.Body>
    </Modal>
  );
};

AddRole.propType = {
  setAddNewRole: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddRole;
