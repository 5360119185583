import React from "react";
import { Card, Form, Button, Row, Col, Table } from "react-bootstrap";
import Select from "react-select";
import RequiredLabel from "ui/RequiredLabel";
import NotificationAlert from "ui/NotificationAlert";
import PropTypes from "prop-types";
import Route from "routes/Route";
import { newExtension, checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";
import { formValidation, dynamicHandle } from "util/CommonUtil";

const AddNewRegion = ({
  setAddNew,
  responseHandle,
  setResponseTitle,
}) => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showNotification, setShowNotification] = React.useState(false);
  const [dzongkhags, setDzongkhags] = React.useState([]);
  const [addedRow, setAddedRow] = React.useState([]);
  const [rows, setRows] = React.useState(0);
  const [addedExtensionDetails, setAddedExtensionDetails] = React.useState(
    []
  );
  const [regionNameState, setRegionNameState] = React.useState(true);
  const [dzongkhagState, setDzongkhagState] = React.useState(true);
  const token = localStorage.getItem("token");
  const fetchDzongkhag = async () => {
    const response = await Route.fetch('dzongkhags',token);
    if (response.status === 200) {
      setDzongkhags(response?.data?.dzongkhag);
    }
  };
  React.useEffect(() => {
    fetchDzongkhag();
  }, []);
  const [newDetails, setNewDetails] = React.useState({
    name: "",
    dzongkhag_id: "",
    description: "",
    extensions: "",
  });
  const regionNameHandle = (e) => {
    setRegionNameState(true);
    setNewDetails({
      ...newDetails,
      name: e.target.value,
    });
  };
  const dzongkhagSelectHandle = (e) => {
    setDzongkhagState(true);
    setNewDetails({
        ...newDetails,
        dzongkhag_id : parseInt(e.value),
    });
  };
  const descriptionHandle = (e) => {
    setNewDetails({
      ...newDetails,
      description: e.target.value,
    });
  };
  const newExtensionNameHandle = (id, e) => {
    dynamicHandle(id, "name", e.target.value, setAddedExtensionDetails);
  };
  const newExtensionsDescriptionHandle = (id, e) => {
    dynamicHandle(id, "description", e.target.value, setAddedExtensionDetails);
  };
  const deleteRowHandle = (id) => {
    setAddedExtensionDetails((prevSubCatDetails) => {
      return prevSubCatDetails.filter((item) => item.id !== id);
    });
    setAddedRow((prev) => prev.filter((row) => row.props.id !== id));
  };
  const row = (id) => (
    <tr key={id} id={id}>
      <td>
        <Form.Group>
          <Form.Control
            placeholder="Name"
            type="text"
            onChange={(e) => newExtensionNameHandle(id, e)}
          ></Form.Control>
        </Form.Group>
      </td>
      <td>
        <Form.Group>
          <Form.Control
            placeholder="Description"
            type="text"
            onChange={(e) => newExtensionsDescriptionHandle(id, e)}
          ></Form.Control>
        </Form.Group>
      </td>
      <td>
        <Button
          variant="danger"
          className="btn-link remove text-danger"
          onClick={() => deleteRowHandle(id)}
        >
          <i className="fa fa-trash" />
        </Button>
      </td>
    </tr>
  );
  const addNewRowHandle = () => {
    const newId = rows + 1;
    setRows(newId);
    setAddedRow((prev) => [...prev, row(newId)]);
  };
  const validationConditions = [
    { field: 'regionName', condition: newDetails.name === '', setState: setRegionNameState },
    { field: 'dzongkhag', condition: newDetails.dzongkhag_id === '', setState: setDzongkhagState },
  ];
  const onConfirm = async () => {
    if (!checkPermission("regions.store", permissionsArray)) {
      return setShowNotification(true);
    }
    if (formValidation(validationConditions)) {
      const data = {
        name: newDetails?.name,
        dzongkhag_id: newDetails?.dzongkhag_id,
        description: newDetails?.description,
        extensions: newExtension(addedExtensionDetails),
      };
      console.log(data)
      const response = await Route.addNew('regions', token, data);
      if (response.status === 200) {
        setResponseTitle(response?.data?.message);
        setAddNew(false);
        responseHandle(true);
      } else {
        setResponseTitle("Failed To Create!");
        setAddNew(false);
        responseHandle(false);
      };
    };
  };
  return (
    <>
    <Card className="stacked-form">
      <Card.Header>
        <Card.Title as="h4">Add New Region</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form>
          <Row>
            <Col md="6">
            <Form.Group>
              <label>Region Name <span className="star">*</span></label>
              <Form.Control
                placeholder="Enter Region Name"
                type="text"
                onChange={regionNameHandle}
              ></Form.Control>
              {regionNameState ? null : <RequiredLabel />}
            </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <label>Dzongkhag <span className="star">*</span></label>
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="dzongkhagSelect"
                  onChange={dzongkhagSelectHandle}
                  options={dzongkhags?.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.name}`,
                  }))}
                  placeholder="Select Dzongkhag"
                />
                {dzongkhagState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <label>Description</label>
            <Form.Control
              placeholder="Description"
              type="text"
              as="textarea"
              rows={3}
              onChange={descriptionHandle}
            ></Form.Control>
          </Form.Group>
        </Form>
        <Row>
            <Col>
              <Card.Title>Extensions</Card.Title>
              <Table className="table-hover w-full">
                <thead>
                  <tr>
                    <th>Name <span className="star">*</span></th>
                    <th className="th-description">Description</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {addedRow.length > 0 && addedRow?.map((item) => item)}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className="btn-outline"
                type="button"
                variant="default"
                onClick={addNewRowHandle}
              >
                Add New <i class="fa fa-plus"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="card-category form-category">
                <span className="star">*</span>
                Required fields
              </div>
            </Col>
          </Row>
      </Card.Body>
      <Card.Footer>
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col md="2">
            <Button
              className="btn-fill"
              type="submit"
              variant="primary"
              block
              onClick={onConfirm}
            >
              Add
            </Button>
          </Col>
          <Col md="2">
            <Button
              className="btn-outline"
              type="button"
              block
              variant="danger"
              onClick={() => setAddNew(false)}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
    {showNotification && <NotificationAlert setAlert={setShowNotification}/>}
    </>
  );
};
AddNewRegion.propType = {
  setAddNew: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddNewRegion;
