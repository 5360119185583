import SystemSettingRoute from "routes/SystemSettingRoute";

const token = localStorage.getItem("token");

export const fetchRoles = async () => {
  let roleList;
  const response = await SystemSettingRoute.fetchRoles(token);
  if (response.status === 200) {
    roleList = response?.data?.role?.map((item) => [
      item.name,
      item.description,
      item.users.length,
      item.id,
    ]);
  }
  return roleList;
};

export const usersPermission = (data, rolePermission) => {
  const result = [];
  if (Object.keys(data).length > 0) {
    const categoryName = Object.keys(data);
    for (let i = 0; i < categoryName.length; i++) {
      const permissionCategory = categoryName[i];
      const permissions = [];
      if (data.hasOwnProperty(permissionCategory)) {
        for (let j = 0; j < data[permissionCategory].length; j++) {
          let defaultChecked = false;
          if (rolePermission.length > 0) {
            for (let k = 0; k < rolePermission.length; k++) {
              if(data[permissionCategory][j].id === rolePermission[k]) {
                defaultChecked = true;
              } 
            }
          }
          permissions.push({ id: data[permissionCategory][j].id, name: data[permissionCategory][j].name, checked: defaultChecked });
        }
        result.push({ permission: permissionCategory, permissionType: permissions });
      }
    }
  }
  return result;
};
