export const roleTableHeader = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Users",
    accessor: "nosOfUsers",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const userTableHeader = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Employee ID",
    accessor: "username",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Designation",
    accessor: "designation",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const brandTableHeader = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const dzongkhagsTableHeader = [
  {
    Header: "Dzongkhag",
    accessor: "dzongkhag",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const gewogsTableHeader = [
  {
    Header: "Gewog",
    accessor: "gewog",
  },
  {
    Header: "Dzongkhag",
    accessor: "dzongkhag_id",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const villageTableHeader = [
  {
    Header: "Village",
    accessor: "village",
  },
  {
    Header: "Gewog",
    accessor: "gewog",
  },
  {
    Header: "Dzongkhag",
    accessor: "dzongkhag",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const regionsTableHeader = [
  {
    Header: "Region",
    accessor: "region",
  },
  {
    Header: "Dzongkhag",
    accessor: "dzongkhag",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const extensionTableHeader = [
  {
    Header: "Extension",
    accessor: "extension",
  },
  {
    Header: "Region",
    accessor: "region",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const categoryTableHeader = [
  {
    Header: "Category",
    accessor: "category",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const storeTableHeader = [
  {
    Header: "Name",
    accessor: "store",
  },
  {
    Header: "Code",
    accessor: "code",
  },
  {
    Header: "Dzongkhag",
    accessor: 'dzongkhag',
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const unitTableHeader = [
  {
    Header: "Name",
    accessor: "unit",
  },
  {
    Header: "Code",
    accessor: "code",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const bankTableHeader = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Code",
    accessor: "code",
  },
  {
    Header: "Account Number",
    accessor: "accNo",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const employeeTableHeader = [
  {
    Header: "Name",
    accessor: "employee_name",
  },
  {
    Header: "Employee ID",
    accessor: "employee_id",
  },
  {
    Header: "Contact Number",
    accessor: "contact_no",
  },
  {
    Header: "Customer Type",
    accessor: "customer_type",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const promotionTypeTableHeader = [
  {
    Header: "Promotion Type",
    accessor: "promotionType",
  },
  {
    Header: "Promotion Percentage",
    accessor: "promotionPercentage",
  },
  {
    Header: "Start Date",
    accessor: "startDate",
  },
  {
    Header: "End Date",
    accessor: "endDate",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const discountTypeTableHeader = [
  {
    Header: "Discount Name",
    accessor: "discountName",
  },
   {
    Header: "Discount Type",
    accessor: "discountType",
  },
  {
    Header: "Discount Value",
    accessor: "discountValue",
  },
  {
    Header: "Start Date",
    accessor: "startDate",
  },
  {
    Header: "End Date",
    accessor: "endDate",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const customerTypeTableHeader = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const customerTableHeader = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Contact No",
    accessor: "contact",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Customer Type",
    accessor: "customerType",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const productTableHeader = [
  {
    Header: "Item Number",
    accessor: "itemNumber",
  },
  {
    Header: "Serial Number",
    accessor: "serialNo",
  },
  {
    Header: "Batch No.",
    accessor: "batch_no",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const assignTableHeader = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Designation",
    accessor: "designation",
  },
  {
    Header: "Assign Type",
    accessor: "assign_type",
  },
  {
    Header: "Region",
    accessor: "region",
  },
  {
    Header: "Extension",
    accessor: "extension",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const salesTypeTableHeader = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const productIndexTableHeader = [
  {
    Header: "Item Number",
    accessor: "itemNumber",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "Total Qty",
    accessor: 'quantity',
  },
  {
    Header: "Discription",
    accessor: 'description',
  },
  {
    Header: "Action",
    accessor: 'action',
  }
];

export const mainStoreProductRequisitionTableHeader = [
  {
    Header: "Region/Extension",
    accessor: "from",
  },
  {
    Header: "Requisition No.",
    accessor: "requisition_number",
  },
  {
    Header: "Quantity",
    accessor: 'quantity',
  },
  {
    Header: "Action",
    accessor: 'action',
  }
];
export const transferIndexTableHeader = [
  {
    Header: "Item Number",
    accessor: "itemNumber",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  // {
  //   Header: "Brand",
  //   accessor: 'brand',
  // },
  // {
  //   Header: "Category",
  //   accessor: 'category',
  // },
  {
    Header: "Quantity",
    accessor: 'quantity',
  },
  {
    Header: "Transfer",
    accessor: 'action',
  }
];

export const requisitionTableHeader = [
  {
    Header: "Item Number",
    accessor: "item_number",
  },
  {
    Header: "Request Date",
    accessor: "request_date",
  },
  {
    Header: "Request Qty",
    accessor: 'request_quantity',
  },
  {
    Header: "Transfer Qty",
    accessor: 'transfer_quantity',
  },
  {
    Header: "Status",
    accessor: 'status',
  },
  // {
  //   Header: "Description",
  //   accessor: 'description',
  // },
  {
    Header: "Actions",
    accessor: 'actions',
  }
];

export const receivedProductTableHeader = [
  {
    Header: "Product",
    accessor: "product",
  },
  {
    Header: "Regional",
    accessor: 'regional_id',
  },
  {
    Header: "Received From",
    accessor: "received_from",
  },
  {
    Header: "serial No.",
    accessor: 'serial_no',
  },
  {
    Header: "Received Date",
    accessor: "received_date",
  },
  {
    Header: "Qty",
    accessor: "receive",
  },
  // {
  //   Header: "Transfer Qty",
  //   accessor: "give_back",
  // },
  {
    Header: "Status",
    accessor: 'status',
  },
  // {
  //   Header: "Movement Date",
  //   accessor: 'movement_date',
  // }
];

export const acknowledgeProductTableHeader = [
  {
    Header: "Requisition No.",
    accessor: "requisition_number",
  },
  {
    Header: "Received From",
    accessor: 'regional_transfer_id',
  },
  {
    Header: "Quantity",
    accessor: "receive",
  },
  {
    Header: "Status",
    accessor: 'status',
  },
  {
    Header: "Actions",
    accessor: 'actions',
  }
];

export const regionalTransferTableHeader = [
  {
    Header: "Product",
    accessor: "product_id",
  },
  {
    Header: "Regional",
    accessor: 'regional_id',
  },
  {
    Header: "Received Date",
    accessor: "received_date",
  },
  {
    Header: "Quantity",
    accessor: "receive",
  },
  {
    Header: "Transfer Quantity",
    accessor: "give_back",
  },
  {
    Header: "Status",
    accessor: 'status',
  },
  {
    Header: "Movement Date",
    accessor: 'movement_date',
  },
  {
    Header: "Action",
    accessor: 'action',
  },
];


export const acknowledgeExtensionProductTableHeader = [
  {
    Header: "Requisition No.",
    accessor: "requisition_number",
  },
  {
    Header: "Received From",
    accessor: 'regional_transfer_id',
  },
  {
    Header: "Quantity",
    accessor: "receive",
  },
  {
    Header: "Status",
    accessor: 'status',
  },
  {
    Header: "Actions",
    accessor: 'actions',
  }
];

export const receivedExtProductTableHeader = [
  {
    Header: "Product",
    accessor: "product_id",
  },
  {
    Header: "Extension",
    accessor: 'extension',
  },
  {
    Header: "Received From",
    accessor: 'received_from',
  },
  {
    Header: "serial No.",
    accessor: 'serial_no',
  },
  {
    Header: "Received Date",
    accessor: "received_date",
  },
  {
    Header: "Qty",
    accessor: "receive",
  },
  {
    Header: "Status",
    accessor: 'status',
  },
];

export const extensionTransferTableHeader = [
  {
    Header: "Product",
    accessor: "product_id",
  },
  {
    Header: "Extension",
    accessor: 'extension',
  },
  {
    Header: "Received Date",
    accessor: "received_date",
  },
  {
    Header: "Quantity",
    accessor: "receive",
  },
  {
    Header: "Transfer Quantity",
    accessor: "give_back",
  },
  {
    Header: "Status",
    accessor: 'status',
  },
  {
    Header: "Movement Date",
    accessor: 'movement_date',
  },
  {
    Header: "Action",
    accessor: 'action',
  },
];

export const mainStoreSalesTableHeader = [
  {
    Header: "Invoice No.",
    accessor: "invoice_no",
  },
  {
    Header: "Invoice Date",
    accessor: "invoice_date"
  },
  {
    Header: "Net Payable (Nu)",
    accessor: 'net_payable',
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: 'action',
  },
];

export const colorTableHeader = [
  {
    Header: "Color",
    accessor: "color",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];

export const mainStoreReqListTableHeader = [
  {
    Header: "Item Number",
    accessor: "item_number",
  },
  {
    Header: "Serial Number",
    accessor: "serial_no",
  },
  {
    Header: "Qty",
    accessor: 'quantity',
  },
  {
    Header: "Region/Extension",
    accessor: 'place',
  },
  {
    Header: "Description",
    accessor: "description",
  },
];

export const postedSalesInvoiceTableHeader = [
  {
    Header: "Invoice No",
    accessor: "invoice_no",
  },
  {
    Header: "Invoice Date",
    accessor: "invoice_date",
  },
  {
    Header: "Customer Name",
    accessor: 'customerName',
  },
  {
    Header: "Customer No.",
    accessor: 'contactNo',
  },
  {
    Header: "Payment Mode",
    accessor: 'payment_mode',
  },
  {
    Header: "Online",
    accessor: 'online_amount',
  },
  {
    Header: "Cash",
    accessor: "cash_amount",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

export const onHandItemTableHeader = [
  {
    Header: "Item No",
    accessor: "item_number",
  },
  {
    Header: "Serial No",
    accessor: "serial_no",
  },
  {
    Header: "Created Date",
    accessor: 'created_date',
  },
  {
    Header: "Sub-inventory",
    accessor: 'subinventory',
  },
  {
    Header: "Locator",
    accessor: 'locator',
  },
  {
    Header: "ICCID",
    accessor: "iccid",
  },
  {
    Header: "Total Qty",
    accessor: "total_qty",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

export const salesAndStocksTableHeader = [
  {
    Header: "Item No",
    accessor: "item_number",
  },
  {
    Header: "Serial No",
    accessor: "serial_no",
  },
  {
    Header: "Price (Nu.)",
    accessor: 'price',
  },
  {
    Header: "Total Qty",
    accessor: "total_quantity",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

export const salesOrderListTableHeader = [
  {
    Header: "Invoice No",
    accessor: "invoice_no",
  },
  {
    Header: "Invoice Date",
    accessor: "invoice_date",
  },
  {
    Header: "Customer Name",
    accessor: 'customerName',
  },
  {
    Header: "Contact No.",
    accessor: "contactNo",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

export const cashReceiptTableHeader = [
  {
    Header: "Receipt No.",
    accessor: "receipt_no",
  },
  // {
  //   Header: "Cash Amount Paid (Nu.)",
  //   accessor: "cash_amount_paid",
  // },
  // {
  //   Header: "Online Amount paid (Nu.)",
  //   accessor: "online_amount_paid",
  // },
  {
    Header: "Total Amount Paid (Nu.)",
    accessor: 'total_amount_paid',
  },
  // {
  //   Header: "Payment Mode",
  //   accessor: "payment_mode",
  // },
  // {
  //   Header: "Bank",
  //   accessor: "bankName",
  // },
  // {
  //   Header: "Ref No",
  //   accessor: "reference_no",
  // },
  {
    Header: "Invoice Date",
    accessor: "invoice_date",
  },
  {
    Header: "Created By",
    accessor: "createdBy",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

export const onlineReceiptTableHeader = [
  {
    Header: "Receipt No.",
    accessor: "receipt_no",
  },
  // {
  //   Header: "Cash Amount Paid (Nu.)",
  //   accessor: "cash_amount_paid",
  // },
  // {
  //   Header: "Online Amount paid (Nu.)",
  //   accessor: "online_amount_paid",
  // },
  {
    Header: "Total Amount Paid (Nu.)",
    accessor: 'total_amount_paid',
  },
  // {
  //   Header: "Payment Mode",
  //   accessor: "payment_mode",
  // },
  {
    Header: "Bank",
    accessor: "bankName",
  },
  {
    Header: "Ref No",
    accessor: "reference_no",
  },
  {
    Header: "Invoice Date",
    accessor: "invoice_date",
  },
  {
    Header: "Created By",
    accessor: "createdBy",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

export const warrantyTableHeader = [
  {
    Header: "Product Type",
    accessor: "name",
  },
  {
    Header: "No Of Years",
    accessor: "no_of_years",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];

export const extensionProductsTableHeader = [
  {
    Header: "Product",
    accessor: "category",
  },
  {
    Header: "Sub-Category",
    accessor: "sub_category",
  },
  {
    Header: "Extension",
    accessor: "extension",
  },
  {
    Header: "Qty",
    accessor: 'qty',
  },
  {
    Header: "Description",
    accessor: 'description',
  },
  {
    Header: "Actions",
    accessor: 'actions',
  }
];
export const userEmiTableHeader = [
  {
    Header: "EMI no.",
    accessor: "emi_no",
  },
  {
    Header: "Employee Name",
    accessor: "name",
  },
  // {
  //   Header: "Item Number",
  //   accessor: "item_number",
  // },
  {
    Header: "Request Date",
    accessor: "request_date",
  },
  // {
  //   Header: "EMI Duration",
  //   accessor: "emi_duration",
  // },
  // {
  //   Header: "Monthly EMI",
  //   accessor: "monthly_emi",
  // },
  {
    Header: "Amount",
    accessor: "total",
  },
  // {
  //   Header: "Deduction From",
  //   accessor: "deduction_from",
  // },
  {
    Header: "Status",
    accessor: 'status',
  },
  {
    Header: "Actions",
    accessor: 'actions',
  }
];

export const emiReportHeader = [
  {
    Header: "Employee",
    accessor: "employee",
  },
  {
    Header: "EMI No.",
    accessor: "emi_no",
  },
  {
    Header: "Invoice No",
    accessor: 'invoice_no',
  },
  {
    Header: "Request Date",
    accessor: 'request_date',
  },
  {
    Header: "EMI Duration",
    accessor: "emi_duration",
  },
  {
    Header: "Deduction from",
    accessor: "deduction_from",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

export const transferProductTableHeader = [
  {
    Header: "Transfer From",
    accessor: "regional_transfer_id",
  },
  {
    Header: "Transfer To",
    accessor: 'transfer_to',
  },
  {
    Header: "Serial No.",
    accessor: "serial_no",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Created By",
    accessor: "created_by",
  },
  {
    Header: "Updated By",
    accessor: "updated_by",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];