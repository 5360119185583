import React from "react";
import {
  Alert,
  Card,
  Container,
  Row,
  Col,
  Button,
  Form,
  Table,
  Modal
} from "react-bootstrap";
import Route from "routes/Route";
import Select from "react-select";
import PropTypes from "prop-types";
import AddProduct from "./AddProduct";
import { mainStoreCreatePurchase } from "util/MasterUtil";
import NotificationAlert from "ui/NotificationAlert";
import { dynamicHandle, getDiscountsRegionAndExt } from "util/CommonUtil";

const SaleProduct = ({
  setSaleProduct,
  setResponseTitle,
  responseHandle,
  products,
  discountData,
}) => {
  const token = localStorage.getItem("token");
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const [customerType, setCustomerType] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);
  const [customerName, setCustomerName] = React.useState("");
  const [customerNo, setCustomerNo] = React.useState("");
  const [uploadFileState, setUploadFileState] = React.useState(false);
  const [showNotificationModal, setShowNotificationModal] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = React.useState("");
  const [missingSerialNo, setMissingSerialNo] = React.useState([]);
  const fetchCustomerType = async () => {
    const response = await Route.fetch("customer-types", token);
    if (response.status === 200) {
      setCustomerType(response?.data?.customerType);
    }
  };
  const fetchUsers = async (id) => {
    const response = await Route.fetch("users", token, id);
    if (response.status === 200) {
      setCustomers(response?.data?.user);
    }
  };
  const fetchCustomerBasedOnId = async (id) => {
    const response = await Route.fetchDetails("get-customers", token, id);
    if (response.status === 200) {
      setCustomers(response?.data?.customer);
    }
  };
  React.useEffect(() => {
    fetchCustomerType();
  }, []);
  const [addedRow, setAddedRow] = React.useState([]);
  const [rows, setRows] = React.useState(0);
  const [addedProduct, setAddedProduct] = React.useState([]);
  const productListHandle = (id, prop, value) => {
    dynamicHandle(id, prop, value, setAddedProduct);
  };
  const deleteRowHandle = (id) => {
    setAddedProduct((prevProductDetails) => {
      return prevProductDetails.filter((item) => item.id !== id);
    });
    setAddedRow((prev) => prev.filter((row) => row.props.id !== id));
  };
  const row = (id) => (
    <AddProduct
      key={id}
      id={id}
      productListHandle={productListHandle}
      deleteRowHandle={deleteRowHandle}
      products={products}
      discountData={getDiscountsRegionAndExt(discountData)}
    />
  );
  const addNewRowHandle = () => {
    const newId = rows + 1;
    setRows(newId);
    setAddedRow((prev) => [...prev, row(newId)]);
  };
  const [customerTypeSelected, setCustomerTypeSelected] = React.useState("");
  const [customer, setCustomer] = React.useState("");
  const [remarks, setRemark] = React.useState("");
  const [netPayable, setNetPayable] = React.useState("");
  const [showInputFields, setShowInputFields] = React.useState(false);
  const customerTypeHandle = (e) => {
    setShowInputFields(false);
    setCustomers([]);
    setCustomer("");
    e.value === 1 ? fetchUsers(e.value) : setUploadFileState(false);
    e.value === 2 ? fetchCustomerBasedOnId(e.value) && setUploadFileState(true) : setUploadFileState(false);
    e.value === 3 ? setShowInputFields(true) : null;
    setCustomerTypeSelected(e.value);
  };
  const customerHandle = (e) => {
    setCustomer(e.value);
  };
  const remarkHandle = (e) => {
    setRemark(e.target.value);
  };
  const customerNameHandle = (e) => {
    setCustomerName(e.target.value);
  };
  const customerNoHandle = (e) => {
    setCustomerNo(e.target.value);
  };
  React.useEffect(() => {
    setNetPayable(
      addedProduct?.reduce((accumulator, item) => {
        return accumulator + item?.total_amount;
      }, 0) 
    );
  }, [addedProduct]);
  const [attachment, setAttachment] = React.useState("");
  const fileHandle = (e) => {
    setAttachment(e.target.files[0]);
  };

  const submitHandle = async () => {
    if (customerTypeSelected === 2) {
      if (customerTypeSelected === "" || attachment === "") {
        setNotificationText("Can't Create Sale Voucher");
        setShowNotification(true);
      } else {
        let data = new FormData();
        data.append("attachment", attachment);
        data.append("customer", customer);
        data.append("customerType", customerTypeSelected);
        data.append("remarks", remarks);
        const response = await Route.uploadFile("main-store-sales", token, data);
        if (response.status === 200) {
          setSaleProduct(false);
          setResponseTitle(response.data.message);
          responseHandle(true);
        } else if(response?.status === 203) {
          setNotificationMessage(response?.data?.message);
          setMissingSerialNo(response?.data?.serialNumbers);
          setShowNotificationModal(true);
        } 
        else {
          setSaleProduct(false);
          setResponseTitle(response?.data?.message);
          responseHandle(false);
        }
      }
    } else {
      if (customerTypeSelected === "" || addedProduct.length === 0) {
        setNotificationText("Can't Create Sale Voucher");
        setShowNotification(true);
      } else {
        const data = {
          customer: customer,
          gross_payable: addedProduct?.reduce((accumulator, item) => {
            return accumulator + item?.actual_cost;
          }, 0),
          walk_in_customer: customerName,
          contact_no: customerNo,
          net_payable: netPayable,
          remarks: remarks,
          productDetails: mainStoreCreatePurchase(addedProduct),
        };
        const response = await Route.addNew("main-store-sales", token, data);
        if (response.status === 200) {
          setSaleProduct(false);
          setResponseTitle(response.data.message);
          responseHandle(true);
        } else {
          setSaleProduct(false);
          setResponseTitle(response?.message);
          responseHandle(false);
        } 
      }
    }
  };
  return (
    <>
    <Card>
      <Container fluid>
        <Card.Title as="h4" className="mt-3 mb-3">
          Create Sale Voucher
        </Card.Title>
        {discountData?.length > 0 ? (
          <Row>
            <Col md="12">
              <h5>
                <small>Available Discount(s)</small>
              </h5>
              <Alert variant="primary">
                <span data-notify="message">
                  {discountData?.map(
                    (item, index) => `  ${index + 1} : ${item?.discount_name}. `
                  )}
                </span>
              </Alert>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col md="4">
            <Form.Group>
              <label>
                Customer Type <span className="star">*</span>
              </label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                name="customerSelect"
                onChange={customerTypeHandle}
                options={customerType?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                }))}
                placeholder="Select"
              />
            </Form.Group>
          </Col>
        </Row>
        {showInputFields ? (
          <Row>
            <Col>
              <Form.Group>
                <label>
                  Customer Name <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Customer Name"
                  type="text"
                  onChange={customerNameHandle}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <label>
                  Contact No. <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Contact No."
                  type="text"
                  onChange={customerNoHandle}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md="4">
              <Form.Group>
                <label>
                  Customer <span className="star">*</span>
                </label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="customerSelect"
                  onChange={customerHandle}
                  options={customers?.map((item) => ({
                    value: item?.id,
                    label: item?.customer_name === undefined ? item?.name : item?.customer_name,
                  }))}
                  placeholder="Select"
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        {
          uploadFileState ? <Row>
          <Col md="4">
            <Form.Group>
              <Form.File id="excelUpload" label="Upload Excel file" onChange={fileHandle} />
            </Form.Group>
          </Col>
        </Row> :
        <>
          <Row className="mt-4">
          <Col md="12">
            <Card>
              <Table className="table-hover w-full">
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}>
                      Serial No. <span className="star">*</span>
                    </th>
                    <th style={{ width: "25%" }}>
                      Description (Item Number) <span className="star">*</span>
                    </th>
                    <th>
                      Product Cost
                    </th>
                    <th>
                      Qty <span className="star">*</span>
                    </th>
                    <th style={{ width: "20%" }}>
                      Discount Type
                    </th>
                    <th>
                      Total Cost
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {addedRow.length > 0 && addedRow?.map((item) => item)}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              className="btn-outline"
              type="button"
              variant="default"
              onClick={addNewRowHandle}
            >
              Add New <i className="fa fa-plus"></i>
            </Button>
          </Col>
        </Row>
        </>
        }
        
        <Row className="mt-2">
          <Col md="6">
            <Form.Group>
              <label>Remark</label>
              <Form.Control
                placeholder="Enter Remark"
                type="text"
                as="textarea"
                rows={6}
                onChange={remarkHandle}
              ></Form.Control>
            </Form.Group>
          </Col>
          {
            uploadFileState ? null : <>
              <Col md="6">
                <Row>
                  <Col className="mt-2">
                    <Form.Group>
                      <label>Gross Payable (Nu.)</label>
                      <Form.Control
                        placeholder="Gross Payable"
                        type="number"
                        readOnly
                        value={addedProduct?.reduce((accumulator, item) => {
                          return accumulator + item?.actual_cost;
                        }, 0)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-2">
                    <Form.Group>
                      <label> Net Payable (Nu.)</label>
                      <Form.Control
                        placeholder="Net Payable"
                        type="number"
                        readOnly
                        value={netPayable}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </>
          }
          
        </Row>
        <div className="card-category form-category">
          <span className="star">*</span>
          Required fields
        </div>
        <Row className="mt-4 mb-4" style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col md="2">
            <Button
              className="btn-fill"
              type="submit"
              variant="primary"
              block
              onClick={submitHandle}
            >
              Create
            </Button>
          </Col>
          <Col md="2">
            <Button
              className="btn-outline"
              type="button"
              variant="danger"
              block
              onClick={() => setSaleProduct(false)}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Container>
    </Card>
    {
      showNotificationModal ? 
      <Modal show={showNotificationModal} onHide={() => setShowNotificationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{notificationMessage}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Serial Numbers</th>
              </tr>
            </thead>
            <tbody>
              {
                missingSerialNo?.map((item, index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{item}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="primary" onClick={() => setShowNotificationModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> : null
    }
    { showNotification && <NotificationAlert  title={notificationText} setAlert={setShowNotification} /> }
    </>
  );
};

SaleProduct.propType = {
  setSaleProduct: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default SaleProduct;
