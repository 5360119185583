import React from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import ExtensionIndexHistory from "./Components/extension_store/extension_transfer/ExtensionIndexHistory";
import ExtensionProductRequistions from "./Components/extension_store/extension_transfer/ExtensionProductRequisition";

const ExtensionStoreTransaction = () => {
  return (
    <>   
      <Card>
        <Card.Header>
          <Card.Title as="h4">Extension Store Transaction</Card.Title>
        </Card.Header>
        <Card.Body>
          <Tab.Container
            id="plain-tabs-example"
            defaultActiveKey="index-history"
          >
            <Nav role="tablist" variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="index-history">Index History</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Transfer-index">Product Requisition</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="index-history">
                <ExtensionIndexHistory />
              </Tab.Pane>
              <Tab.Pane eventKey="Transfer-index">
                <ExtensionProductRequistions />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
    </>
  );
};

export default ExtensionStoreTransaction;
