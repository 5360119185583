import React from "react";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import RequiredLabel from "ui/RequiredLabel";
import NotificationAlert from "ui/NotificationAlert";
import Route from "routes/Route";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";
import { formValidation } from "util/CommonUtil";
import { toast } from "react-toastify";

const EditCustomer = ({
  setEdit,
  edit,
  details,
  responseHandle,
  setResponseTitle,
}) => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [newDetails, setNewDetails] = React.useState({
    customer_name: "",
    contact_no: "",
    address: "",
    license_no: "",
    customer_type: "",
    description: "",
  });
  const [customerNameState, setCustomerNameState] = React.useState(true);
  const [contactNoState, setContactNoState] = React.useState(true);
  const [customerTypeState, setCustomerTypeState] = React.useState(true);
  const [addressState, setAddressState] = React.useState(true);
  const [showNotification, setShowNotification] = React.useState(false);
  const token = localStorage.getItem("token");
  const [customerType, setCustomerType] = React.useState([]);
  const fetchCustomerType = async () => {
    const response = await Route.fetch("customer-types", token);
    if (response.status === 200) {
      setCustomerType(response?.data?.customerType);
    }
  };
  React.useEffect(() => {
    fetchCustomerType();
    setNewDetails({
      ...newDetails,
      customer_name: details?.customer_name,
      contact_no: details?.contact_no,
      address: details?.address,
      license_no: details?.license_no,
      customer_type: details?.customer_type_id,
      description: details?.description,
    });
  }, [details]);
  const inputFiledHandle = (name, value) => {
    name === "customer_name"
      ? setCustomerNameState(true)
      : name === "contact_no"
      ? setContactNoState(true)
      : name === "customer_type"
      ? setCustomerTypeState(true)
      : name === "address"
      ? setAddressState(true)
      : null;
    setNewDetails({
      ...newDetails,
      [name]: name === "customer_type" ? parseInt(value) : value,
    });
  };
  const validationConditions = [
    {
      field: "customerName",
      condition: newDetails.customer_name === "",
      setState: setCustomerNameState,
    },
    {
      field: "contactNo",
      condition: newDetails.contact_no === "",
      setState: setContactNoState,
    },
    {
      field: "customerType",
      condition: newDetails.customer_type === "",
      setState: setCustomerTypeState,
    },
    {
      field: "address",
      condition: newDetails.address === "",
      setState: setAddressState,
    },
  ];
  const onConfirm = async () => {
    if (!checkPermission("customers.update", permissionsArray)) {
      return setShowNotification(true);
    }
    if (formValidation(validationConditions)) {
      const response = await Route.update(
        "customers",
        token,
        details?.id,
        newDetails
      );
      if (response.status === 200) {
        setResponseTitle(response?.data?.message);
        responseHandle(true);
        setEdit(false);
      } else {
        setResponseTitle("Failed To Update Customer Details");
        responseHandle(false);
        setEdit(false);
      }
    }
  };
  return (
    <>
      <Modal show={edit} onHide={() => setEdit(false)}>
        <Modal.Body>
          <Modal.Title>Edit Customer Details</Modal.Title>
          <Row>
            <Col>
              <Form.Group>
                <label>
                  Customer Name <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Customer Name"
                  type="text"
                  name="customer_name"
                  defaultValue={newDetails?.customer_name}
                  onChange={(e) =>
                    inputFiledHandle(e.target.name, e.target.value)
                  }
                ></Form.Control>
                {customerNameState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <label>
                  Contact No. <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Contact No."
                  type="text"
                  name="contact_no"
                  defaultValue={newDetails?.contact_no}
                  onChange={(e) =>
                    inputFiledHandle(e.target.name, e.target.value)
                  }
                ></Form.Control>
                {contactNoState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>
                  Customer Type <span className="star">*</span>
                </label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => inputFiledHandle("customer_type", e.value)}
                  defaultValue={{
                    value: `${details?.customer_type_id}`,
                    label: `${details?.customer_type?.name}`,
                  }}
                  name="customerTypeSelect"
                  options={customerType?.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.name}`,
                  }))}
                  placeholder="Select Type"
                />
                {customerTypeState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <label>License No.</label>
                <Form.Control
                  placeholder="License No."
                  type="text"
                  name="license_no"
                  defaultValue={newDetails?.license_no}
                  onChange={(e) =>
                    inputFiledHandle(e.target.name, e.target.value)
                  }
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>
                  Address <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Address"
                  type="text"
                  as="textarea"
                  name="address"
                  rows={3}
                  defaultValue={newDetails?.address}
                  onChange={(e) =>
                    inputFiledHandle(e.target.name, e.target.value)
                  }
                ></Form.Control>
                {addressState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>Description</label>
                <Form.Control
                  placeholder="Description"
                  type="text"
                  name="description"
                  as="textarea"
                  rows={3}
                  defaultValue={newDetails?.description}
                  onChange={(e) =>
                    inputFiledHandle(e.target.name, e.target.value)
                  }
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <div className="card-category form-category">
            <span className="star">*</span>
            Required fields
          </div>
          <Row className="mt-4">
            <Col>
              <Button variant="primary" onClick={onConfirm} block>
                Update
              </Button>
            </Col>
            <Col>
              <Button
                className="btn-outline"
                onClick={() => setEdit(false)}
                block
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {showNotification && <NotificationAlert setAlert={setShowNotification} />}
    </>
  );
};
EditCustomer.propTypes = {
  setEdit: PropTypes.func,
  details: PropTypes.array.isRequired,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default EditCustomer;
