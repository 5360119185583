import React from "react";
import { Container, Card, Row, Col, Form, Button } from "react-bootstrap";
import PermissionCard from "./PermissionCard";
import NotificationAlert from "ui/NotificationAlert";
import Alert from "ui/Alert";
import PropTypes from "prop-types";
import Route from "routes/Route";
import { useSelector } from "react-redux";
import { checkPermission } from "util/MasterUtil";

const EditRole = ({ roleDetails, userRoles, permissions, setShowEditRole }) => {
  const [response, setResponse] = React.useState(false);
  const [userPermissionData, setUserPermissionData] = React.useState(userRoles);
  const [updatePermissions, setUpdatePermissions] = React.useState(permissions);
  const [showAlert, setShowAlert] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [showPermissionAlert, setShowPermissionAlert] = React.useState(false);
  const permissionsArray = useSelector((state) => state.permission.permission);
  const checkboxHandle = (e, id) => {
    e.target.checked
      ? setUpdatePermissions((prev) => [...prev, id])
      : setUpdatePermissions((prevState) =>
          prevState.filter((prevItem) => prevItem !== id)
        );
  };
  const token = localStorage.getItem("token");
  const updatePermissionHandle = async () => {
    if (!checkPermission("roles.update", permissionsArray)) {
      setShowPermissionAlert(true);
    } else {
      let data = {
        role_name: roleDetails?.role,
        description: roleDetails?.description,
        permissions: updatePermissions,
      };
      const response = await Route.update("roles", token, roleDetails.id, data);
      if (response.status === 200) {
        setResponseTitle(response?.data?.message);
        setResponse(true);
        setShowAlert(true);
      } else {
        setResponseTitle(response?.data?.message);
        setResponse(true);
        setShowAlert(true);
      }
      setTimeout(function () {
        setShowEditRole(false);
      }, 1000);
    }
  };
  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="stacked-form">
            <Card.Body>
              <Row>
                <Col md="6">
                  <Form.Group>
                    <label>Role</label>
                    <Form.Control
                      placeholder="Role"
                      type="text"
                      defaultValue={roleDetails?.role}
                      readOnly
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <label>Description</label>
                    <Form.Control
                      placeholder="Description"
                      type="text"
                      defaultValue={roleDetails?.description}
                      readOnly
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Card.Title as="h5" style={{ marginBottom: "8px" }}>
                Permissions for{" "}
                <span className="text-primary">{roleDetails?.role}</span>
              </Card.Title>
              <Row>
                {userPermissionData.length !== null &&
                  userPermissionData?.map((item, index) => (
                    <Col lg="3" md="4" sm="6" key={index}>
                      <PermissionCard
                        title={item.permission}
                        permissionType={item.permissionType}
                        checkboxHandle={checkboxHandle}
                      />
                    </Col>
                  ))}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row style={{ display: "flex", justifyContent: "flex-end" }}>
        <Col md="2">
          <Button
            className="btn-wd btn-fill mr-1"
            type="button"
            // variant="info"
            onClick={updatePermissionHandle}
            block
          >
            Update
          </Button>
        </Col>
        <Col md="2">
          <Button
            onClick={() => setShowEditRole(false)}
            className="btn-outline btn-wd mr-1"
            variant="danger"
            block
          >
            Cancel
          </Button>
        </Col>
      </Row>
      {showPermissionAlert && (
        <NotificationAlert
          setAlert={setShowPermissionAlert}
          title="You Don't Have Permission"
        />
      )}
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
    </Container>
  );
};

EditRole.propTypes = {
  roleDetails: PropTypes.shape({
    role: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  userRoles: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
  setShowEditRole: PropTypes.func,
};

export default EditRole;
