import React from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import Route from "routes/Route";
import Select from "react-select";

const EmiProductUpdate = ({
  view,
  setView,
  viewDetail,
  responseHandle,
  setResponseTitle,
}) => {
  const [productDetails, setProductDetails] = React.useState({
    user_id: viewDetail?.user_id,
    product: "",
    gross_payable: "",
    net_payable: "",
  });
  const [phoneState, setPhoneState] = React.useState(true);
  const [phones, setPhones] = React.useState([]);
  const token = localStorage.getItem("token");
  const fetchPhonesBasedOnItemNumber = async () => {
    const response = await Route.fetchPhones(
      "phone-details",
      token,
      viewDetail?.item_number
    );
    if (response?.status == 200) {
      setPhones(response?.data?.product);
    }
  };
  React.useEffect(() => {
    fetchPhonesBasedOnItemNumber();
  }, []);
  const phoneHandle = (e) => {
    setProductDetails({
      user_id: viewDetail?.user_id,
      product: e.id,
      gross_payable: e.price,
      net_payable: e.price,
    });
    setPhoneState(true);
  };

  const empActionHandle = async () => {
    const response = await Route.update(
      "update-product",
      token,
      viewDetail?.id,
      productDetails
    );
    if (response?.status === 200) {
      responseHandle(true);
      setResponseTitle(`${response?.data?.message}: ${response?.data?.invoice}`);
      setView(false);
    } else {
      responseHandle(false);
      setResponseTitle(response?.data?.message);
      setView(false);
    }
  };
  return (
    <>
      <Modal show={view} onHide={() => setView(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>EMI Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
            <Col md="6" sm="12">
              <Form.Group>
                <label>
                  Phone <span className="star">*</span>
                </label>
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="phoneSelect"
                  onChange={phoneHandle}
                  options={phones?.map((item) => ({
                    id: `${item?.id}`,
                    label: `${item?.serial_no}`,
                    price: `${item?.price}`,
                  }))}
                  placeholder="Select"
                />
                {phoneState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Form.Group>
                <label>Employee</label>
                <Form.Control
                  placeholder="Employee"
                  type="text"
                  value={viewDetail?.user?.name}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <label>Request Date</label>
                <Form.Control
                  placeholder="Request Date"
                  type="text"
                  value={viewDetail?.request_date}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <label>EMI Duration</label>
                <Form.Control
                  placeholder="EMI Duration"
                  type="text"
                  value={viewDetail?.emi_duration}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Form.Group>
                <label>Monthly EMI Amount</label>
                <Form.Control
                  placeholder="Monthly EMI Amount"
                  type="text"
                  value={viewDetail?.monthly_emi}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <label>Total Amount</label>
                <Form.Control
                  placeholder="Total Amount"
                  type="text"
                  value={viewDetail?.total}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <label>Deduction From</label>
                <Form.Control
                  placeholder="Deduction From"
                  type="text"
                  value={viewDetail?.deduction_from}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>Description</label>
                <Form.Control
                  placeholder="Description"
                  type="text"
                  value={viewDetail?.description}
                  as="textarea"
                  rows={3}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "flex-end" }}>
          <Row>
            <Col>
              <Button
                variant="primary"
                className="btn-fill"
                onClick={empActionHandle}
              >
                Sell
              </Button>
            </Col>
            <Col>
              <Button
                variant="danger"
                className="btn-outline"
                onClick={() => setView(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmiProductUpdate;
