import React from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import NotificationAlert from "ui/NotificationAlert";
import Route from "routes/Route";
import { getGewogs, checkPermission } from '../../../../util/MasterUtil';
import { useSelector } from "react-redux";

const EditVillage = ({
  setEdit,
  details,
  responseHandle,
  setResponseTitle,
}) => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [newDetails, setNewDetails] = React.useState({
    id: "",
    name: "",
    gewog_id: "",
    gewog: "",
    dzongkhag_id: "",
    dzongkhag: "",
    code: "",
  });
  const [dzongkhags, setDzongkhags] = React.useState([]);
  const [gewogs, setGewogs] = React.useState([]);
  const [showNotification, setShowNotification] = React.useState(false);
  const token = localStorage.getItem("token");
  const fetchDzongkhag = async () => {
    const response = await Route.fetch('dzongkhags',token);
    if (response.status === 200) {
      setDzongkhags(response?.data?.dzongkhag);
    }
  };
  React.useEffect(() => {
    fetchDzongkhag();
  }, []);
  React.useEffect(() => {
    setNewDetails({
      ...newDetails,
      id: details.id,
      name: details.name,
      dzongkhag_id: details.dzongkhag_id,
      dzongkhag: details.dzongkhag,
      gewog_id: details.gewog_id,
      gewog: details.gewog,
      code: details.code,
    });
  }, [details]);
  const villageHandle = (e) => {
    setNewDetails({
      ...newDetails,
      name: e.target.value,
    });
  };
  const dzongkhagHandle = (e) => {
    setGewogs(getGewogs(parseInt(e.value),dzongkhags));
  };
  const gewogHandle = (e) => {
    setNewDetails({
      ...newDetails,
      gewog_id: e.value,
    });
  };
  const codeHandle = (e) => {
    setNewDetails({
      ...newDetails,
      code: e.target.value,
    });
  };
  const onConfirm = async() => {
    if (!checkPermission("villages.update", permissionsArray)) {
       return setShowNotification(true);
    };
    const response = await Route.update(
      'villages',
      token,
      details.id,
      newDetails
    );
    if (response.status === 200) {
      setResponseTitle(response.data.message);
      setEdit(false);
      responseHandle(true);
    } else {
      setResponseTitle(response.data.message);
      setEdit(false);
      responseHandle(false);
    }
  };
  return (
    <div className="stacked-form">
      <Card.Title as="h4">Edit Village</Card.Title>
      <Form action="#" method="#">
        <Form.Group>
          <label>Village <span className="star">*</span></label>
          <Form.Control
            placeholder="Village"
            type="text"
            defaultValue={details.name}
            onChange={villageHandle}
          ></Form.Control>
        </Form.Group>
        <Row>
          <Col>
            <Form.Group>
              <label>Dzongkhag <span className="star">*</span></label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                onChange={dzongkhagHandle}
                defaultValue={{
                  value: `${details?.dzongkhag_id}`,
                  label: `${details?.dzongkhag}`,
                }}
                name="dzongkhagSelect"
                options={dzongkhags?.map((item) => ({
                  value: `${item.id}`,
                  label: `${item.name}`,
                }))}
                placeholder="Select Dzongkhag"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <label>Gewog <span className="star">*</span></label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                onChange={gewogHandle}
                defaultValue={{
                  value: `${details?.gewog_id}`,
                  label: `${details?.gewog}`,
                }}
                name="gewogSelect"
                options={gewogs?.map((item) => ({
                  value: `${item.id}`,
                  label: `${item.name}`,
                }))}
                placeholder="Select Gewog"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Code</label>
              <Form.Control
                placeholder="code"
                type="text"
                defaultValue={details?.code}
                onChange={codeHandle}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <div className="card-category form-category">
          <span className="star">*</span>
          Required fields
        </div>
        <Form.Group className="mt-4">
          <Row className="mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="3">
              <Button
                className="btn-fill"
                type="button"
                variant="primary"
                block
                onClick={onConfirm}
              >
                Update
              </Button>
            </Col>
            <Col md="3">
              <Button
                className="btn-outline"
                type="button"
                block
                variant="danger"
                onClick={() => setEdit(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </Form>
      { showNotification && <NotificationAlert setAlert={setShowNotification} /> }
    </div>
  );
};
EditVillage.propTypes = {
  setEdit: PropTypes.func,
  details: PropTypes.object.isRequired,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default EditVillage;
