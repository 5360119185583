import React from "react";
import { Card, Table, Row, Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";

const PaymentHistory = ({paymentHistory, invoicePayment}) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Payment History</Card.Title>
      </Card.Header>
      <Card.Body>
        {paymentHistory.length < 1 ? (
          <div>No Data To Display</div>
        ) : (<>
        <Row>
          <Col>
            <Table hover responsive className="mt-2">
              <thead>
                <tr>
                  <th>Receipt No.</th>
                  <th>Date</th>
                  <th>Payment Mode</th>
                  <th>Cash Payment (Nu.)</th>
                  <th>Online Payment (Nu.)</th>
                  <th>Total Payment (Nu.)</th>
                </tr>
              </thead>
              <tbody>
                {paymentHistory?.map((item) => (
                  <tr key={item?.id}>
                    <td>{item?.receipt_no}</td>
                    <td>{item?.paid_at}</td>
                    <td>{item?.payment_mode}</td>
                    <td>{item?.cash_amount_paid}</td>
                    <td>{item?.online_amount_paid}</td>
                    <td>{item?.total_amount_paid}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col md="7">
          </Col>
          <Col md="5">
            <Row>
            <Col className="mt-2" md="6">
              Total Amount Paid
            </Col>
            <Col md="6">
              <Form.Control
                placeholder="Invoice Payment"
                type="text"
                readOnly
                value={invoicePayment}
              ></Form.Control>
            </Col>
            </Row>
          </Col>
        </Row>
        </>
        )}
      </Card.Body>
    </Card>
  );
};

PaymentHistory.propType = {
  paymentHistory: PropTypes.object.isRequired,
  invoicePayment: PropTypes.number,
};

export default PaymentHistory;
