import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  Button,
  Row,
  Col,
  Form,
  Container,
} from "react-bootstrap";
import RequiredLabel from "ui/RequiredLabel";
import { formValidation } from "util/CommonUtil";
import Route from "routes/Route";

const AddNewColor = ({ setAddNew, responseHandle, setResponseTitle }) => {
  const [nameState, setNameState] = React.useState(true);
  const [newDetails, setNewDetails] = React.useState({
    name: "",
    description: "",
  });
  const inputFieldHandle = (e) => {
    e.target.name === "name" ? setNameState(true) : null;
    setNewDetails({
      ...newDetails,
      [e.target.name]: e.target.value,
    })
  }
 
const validationConditions = [
  { field: 'name', condition: newDetails.name === '', setState: setNameState },
];
  const token = localStorage.getItem("token");
  const updateHandle = async () => {
    if (formValidation(validationConditions)) {
      const response = await Route.addNew("colors", token, newDetails);
      if (response.status === 200) {
        setAddNew(false);
        responseHandle(true);
        setResponseTitle(response.data.message);
      } else {
        setResponseTitle("Failed To Add!");
        responseHandle(false);
      };
    };
  };
  return (
    <Container>
      <Card>
        <Card.Body>
          <Card.Title>Add New Color</Card.Title>
          <Row className="mt-2">
            <Col md="12">
              <label>Name <span className="star">*</span></label>
              <Form.Group>
                <Form.Control
                  placeholder="Name"
                  type="text"
                  value={newDetails?.name}
                  name="name"
                  onChange={inputFieldHandle}
                ></Form.Control>
                {nameState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col md="12">
              <label>Description</label>
              <Form.Group>
                <Form.Control
                  placeholder="Description"
                  type="text"
                  name="description"
                  as="textarea"
                  rows={3}
                  value={newDetails?.description}
                  onChange={inputFieldHandle}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <div className="card-category form-category">
            <span className="star">*</span>
            Required fields
          </div>
        </Card.Body>
        <Card.Footer>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="3">
              <Button onClick={updateHandle} variant="primary" block>
                Add
              </Button>
            </Col>
            <Col md="3">
              <Button onClick={() => setAddNew(false)} variant="danger" block className="btn-outline">
                Cancel
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Container>
  );
};

AddNewColor.propTypes = {
  setAddNew: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddNewColor;
