import React from 'react';
import { Container } from 'react-bootstrap';
import { Status, InvoiceTable, PaymentTable, SalesChart, Products, ReplaceTable, RepairTable } from './Dashboard/dashboard';

function Dashboard() {
  return (
    <>
      <Container fluid>
        <Status />
        <InvoiceTable />
        <PaymentTable />
        <Products />
        <ReplaceTable />
        <RepairTable />
        <SalesChart />
      </Container>
    </>
  );
}

export default Dashboard;
