import React from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import { dzongkhagsTableHeader } from "data/dummy";
import { useQuery } from "react-query";
import Route from "routes/Route";
import AddNewDzongkhag from "./Components/masters/dzongkhag/AddNewDzongkhag";
import EditDzongkhag from "./Components/masters/dzongkhag/EditDzongkhag";
import NotificationAlert from "ui/NotificationAlert";
import DeleteAlert from "ui/DeleteAlert";
import Alert from "ui/Alert";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const Dzongkhag = () => {
  const token = localStorage.getItem("token");
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [addNewDzongkhag, setAddNewDzongkhag] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const [editDzongkhag, setEditDzongkhag] = React.useState(false);
  const [dzongkhagDetails, setDzongkhagDetails] = React.useState({
    id: "",
    name: "",
    code: "",
  });
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const editDzongkhagHandle = async (id) => {
    if (!checkPermission("dzongkhags.edit-dzongkhags", permissionsArray)) {
      setNotificationText("You Don't Have permission");
      setShowNotification(true);
    } else {
      const response = await Route.fetchDetails("edit-dzongkhags", token, id);
      if (response.status === 200) {
        setDzongkhagDetails({
          ...dzongkhagDetails,
          id: response?.data?.dzongkhag?.id,
          name: response?.data?.dzongkhag?.name,
          code: response?.data?.dzongkhag?.code,
        });
        setEditDzongkhag(true);
      } else {
        setNotificationText(response?.data?.message);
        setShowNotification(true);
      }
    }
  };
  const [tempData, setTempData] = React.useState([]);
  const [dzongkhagData, setDzongkhagData] = React.useState([]);
  const { isLoading, data: res } = useQuery(
    "dzongkhagData",
    () => Route.fetch("dzongkhags", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const [dzongkhagId, setDzongkhagId] = React.useState("");
  const [deleteDzongkhag, setDeleteDzongkhag] = React.useState(false);
  const deleteHandle = (id) => {
    setDzongkhagId(id);
    setDeleteDzongkhag(true);
  };
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.dzongkhag);
      if (tempData !== res?.data?.dzongkhag) {
        setDzongkhagData(
          res?.data?.dzongkhag?.map((item) => {
            return {
              id: item.id,
              dzongkhag: item.name,
              actions: (
                <div className="actions-right">
                  <Button
                    onClick={() => editDzongkhagHandle(item?.id)}
                    variant="warning"
                    size="sm"
                    className="text-dark btn-link edit"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    onClick={() => deleteHandle(item?.id)}
                    variant="danger"
                    size="sm"
                    className="btn-link remove text-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      }
    }
  }, [res]);
  if (isLoading) return "Loading...";
  const addNewDzongkhagHandle = () => {
    if (!checkPermission("dzongkhags.store", permissionsArray)) {
      setNotificationText("You Don't Have permission");
      setShowNotification(true);
    } else {
      setAddNewDzongkhag(true);
    }
  };
  const deleteDzongkhagHandle = async () => {
    setDeleteDzongkhag(false);
    const res = await Route.delete("dzongkhags", token, dzongkhagId);
    if (res.status === 200) {
      setResponse(true);
      setResponseTitle(res?.data?.message);
      setShowAlert(true);
    } else {
      setResponse(false);
      setResponseTitle("Failed To Delete Dzongkhag");
      setShowAlert(true);
    }
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Dzongkhag</h4>
            <Card>
              <Card.Body>
                <Col
                md="12"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button variant="primary" className="btn-outline" onClick={addNewDzongkhagHandle}>
                    Add New Dzongkhag <i className="fa fa-plus"></i>
                  </Button>
                </Col>
                <Col md="12">
                <ReactTable
                  data={dzongkhagData}
                  columns={dzongkhagsTableHeader}
                  className="-striped -highlight primary-pagination"
                />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {addNewDzongkhag && (
        <AddNewDzongkhag
          addNewDzongkhag={addNewDzongkhag}
          setAddNewDzongkhag={setAddNewDzongkhag}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      )}
      {editDzongkhag && (
        <EditDzongkhag
          show={editDzongkhag}
          setEditDzongkhag={setEditDzongkhag}
          dzongkhagDetails={dzongkhagDetails}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      )}
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
      {deleteDzongkhag && (
        <DeleteAlert
          deleteHandle={deleteDzongkhagHandle}
          setAlert={() => setDeleteDzongkhag(false)}
        />
      )}
      {showNotification && (
        <NotificationAlert
          setAlert={setShowNotification}
          title={notificationText}
        />
      )}
    </>
  );
};

export default Dzongkhag;
