import React from "react";
import { Switch, Route } from "react-router-dom";
// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
// dinamically create dashboard routes
import image3 from "assets/img/full-screen-image-3.jpg";
import { sideBarLink } from "routes";
import { getPermissionList } from "util/MasterUtil";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { setPermission } from "app/permissionSlice";
import SystemSettingRoute from "routes/SystemSettingRoute";

function Admin() {
  const role = JSON.parse(localStorage.getItem('roles'));
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [sideNav, setSideNav] = React.useState([]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      } else {
        return null;
      }
    });
  };
  React.useEffect(() => {
    setSideNav(sideBarLink(role, getPermissionList(permissions)));
  }, []);
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const { isLoading, data:res } = useQuery("permission",() => SystemSettingRoute.fetchDetails("roles-base", token, role[0].id));
  const [tempPermissionData, setTempPermissionData] = React.useState([]);
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempPermissionData(res?.data?.roleBases);
      if (tempPermissionData !== res?.data?.roleBases) {
        dispatch(setPermission(res?.data?.roleBases?.permissions))
      }
    }
  },[isLoading, res])
  return (
    <>
      <div className="wrapper">
        <Sidebar
          routes={sideNav}
          image={image3}
          background="black"
        />
        <div className="main-panel">
          <AdminNavbar />
          <div className="content">
            <Switch>{getRoutes(sideNav)}</Switch>
          </div>
          <AdminFooter />
          <div
            className="close-layer"
            onClick={() =>
              document.documentElement.classList.toggle("nav-open")
            }
          />
        </div>
      </div>
    </>
  );
}

export default Admin;
