import React from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";

const ViewEmi = ({ view, setView, viewDetail }) => {
  return (
    <>
      <Modal show={view} onHide={() => setView(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>EMI Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="3">
              <Form.Group>
                <label>Employee</label>
                <Form.Control
                  placeholder="Employee"
                  type="text"
                  value={viewDetail?.user?.name}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Request Date</label>
                <Form.Control
                  placeholder="Request Date"
                  type="text"
                  value={viewDetail?.request_date}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>EMI Duration</label>
                <Form.Control
                  placeholder="EMI Duration"
                  type="text"
                  value={viewDetail?.emi_duration}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Status</label>
                <Form.Control
                  placeholder="Status"
                  type="text"
                  value={viewDetail?.status}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Form.Group>
                <label>Monthly EMI Amount</label>
                <Form.Control
                  placeholder="Monthly EMI Amount"
                  type="text"
                  value={viewDetail?.monthly_emi}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <label>Total Amount</label>
                <Form.Control
                  placeholder="Total Amount"
                  type="text"
                  value={viewDetail?.total}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <label>Deduction From</label>
                <Form.Control
                  placeholder="Deduction From"
                  type="text"
                  value={viewDetail?.deduction_from}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>Description</label>
                <Form.Control
                  placeholder="Description"
                  type="text"
                  value={viewDetail?.description}
                  as="textarea"
                  rows={3}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="danger"
            className="btn-outline"
            onClick={() => setView(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewEmi;
