import React from "react";
import PropTypes from "prop-types";
import { Card, Button, Row, Col, Form } from "react-bootstrap";

const ViewBank = ({ setView, details }) => {
  return (
    <>
      <Card.Body>
      <Card.Title>View Bank</Card.Title>
        <Row className="mt-2">
          <Col md="4">
            <label>Name</label>
            <Form.Group>
              <Form.Control
                placeholder="Name"
                type="text"
                defaultValue={details?.bank?.name}
                readOnly
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md="4">
            <label>Code</label>
            <Form.Group>
              <Form.Control
                placeholder="Code"
                type="text"
                defaultValue={details?.bank?.code}
                readOnly
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md="4">
            <label>Account Number</label>
            <Form.Group>
              <Form.Control
                placeholder="Account Number"
                type="text"
                readOnly
                defaultValue={details?.bank?.account_number}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Bank Description</label>
            <Form.Group>
              <Form.Control
                placeholder="Description"
                type="text"
                readOnly
                defaultValue={details?.bank?.description}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md="4">
            <label>Region Name</label>
            <Form.Group>
              <Form.Control
                placeholder="Region Name"
                type="text"
                defaultValue={details?.bank?.region?.name}
                readOnly
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md="8">
            <label>Region Description</label>
            <Form.Group>
              <Form.Control
                placeholder="Region Description"
                type="text"
                readOnly
                defaultValue={details?.bank?.region?.description}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md="4">
            <label>Extension Name</label>
            <Form.Group>
              <Form.Control
                placeholder="Name"
                type="text"
                defaultValue={details?.bank?.extension?.name}
                readOnly
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md="8">
            <label>Extension Description</label>
            <Form.Group>
              <Form.Control
                placeholder="Extension Description"
                type="text"
                readOnly
                defaultValue={details?.bank?.extension?.description}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ display: 'flex', justifyContent: 'flex-end' }} className="mt-4">
          <Col md="3">
            <Button onClick={() => setView(false)} variant="danger" className="btn-outline" block>Close</Button>
          </Col>
        </Row>
      </Card.Body>
    </>
  );
};

ViewBank.propTypes = {
  setView: PropTypes.func,
  details: PropTypes.object.isRequired,
};

export default ViewBank;
