import React from "react";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import NotificationAlert from "ui/NotificationAlert";
import PropTypes from "prop-types";
import RequiredLabel from "ui/RequiredLabel";
import Route from "routes/Route";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const EditDzongkhag = ({
  show,
  setEditDzongkhag,
  dzongkhagDetails,
  responseHandle,
  setResponseTitle,
}) => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const [newDetails, setNewDetails] = React.useState({
    name: "",
    code: "",
  });
  const [nameState, setNameState] = React.useState(true);
  React.useEffect(() => {
    setNewDetails({
      ...newDetails,
      name: dzongkhagDetails?.name,
      code: dzongkhagDetails?.code,
    });
  }, [dzongkhagDetails]);
  const inputFieldHandle = (e) => {
    e.target.name === "name" && setNameState(true);
    setNewDetails({
      ...newDetails,
      [e.target.name]: e.target.value,
    });
  };
  const onConfirm = async () => {
    if (newDetails?.name === "") {
      return setNameState(false);
    }
    if (!checkPermission("dzongkhags.update", permissionsArray)) {
      setNotificationText("You Don't Have Permission");
      setShowNotification(true);
    } else {
      const token = localStorage.getItem("token");
      const response = await Route.update(
        "dzongkhags",
        token,
        dzongkhagDetails.id,
        newDetails
      );
      if (response.status === 200) {
        setResponseTitle(response.data.message);
        setEditDzongkhag(false);
        responseHandle(true);
      } else {
        setResponseTitle(response.data.message);
        setEditDzongkhag(false);
        responseHandle(false);
      }
    }
  };
  return (
    <Modal show={show}>
      <Modal.Body>
        <Modal.Title>Edit Dzongkhag</Modal.Title>
        <Row>
          <Col>
            <Form.Group>
              <label>
                Name <span className="star">*</span>
              </label>
              <Form.Control
                placeholder="Enter Name"
                type="text"
                name="name"
                defaultValue={dzongkhagDetails?.name}
                onChange={inputFieldHandle}
              ></Form.Control>
              {nameState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Code</label>
              <Form.Control
                placeholder="Write Description"
                defaultValue={dzongkhagDetails?.code}
                type="text"
                name="description"
                onChange={inputFieldHandle}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="control-label" md="6">
            <div className="card-category form-category">
              <span className="star">*</span>
              Required fields
            </div>
          </Col>
        </Row>
        <Row className="mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col md="3">
            <Button block onClick={onConfirm}>
              Update
            </Button>
          </Col>
          <Col md="3">
            <Button
              block
              variant="danger"
              className="btn-outline"
              onClick={() => setEditDzongkhag(false)}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      )}
    </Modal>
  );
};
EditDzongkhag.propTypes = {
  setEditDzongkhag: PropTypes.func,
  dzongkhagDetails: PropTypes.object.isRequired,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default EditDzongkhag;
