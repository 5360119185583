import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import Route from "routes/Route";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const ReplaceTable = () => {
  const token = localStorage.getItem("token");
  const [replaceData, setReplaceData] = React.useState([]);
  const fetchReplace = async () => {
    const response = await Route.fetch("replace-list", token);
    if (response?.status === 200) {
      setReplaceData(
        response?.data?.repair?.map((item, index) => ({
          id: index,
          replacement: item?.replacement,
          sale_type: item?.sale_type,
          sub_category: item?.sub_category,
          store_name: item?.store_name,
          total_quantity: parseInt(item?.total_quantity),
          replaced_item: item?.replaced_item,
        }))
      );
    }
  };
  React.useEffect(() => {
    fetchReplace();
  }, []);
  const columns = [
    {
      dataField: "replaced_item",
      text: "Replaced Item(Serial No.)",
      sort: true,
    },
    {
      dataField: "replacement",
      text: "Replaced With",
      sort: true,
    },
    {
      dataField: "sale_type",
      text: "Category",
      sort: true,
    },
    {
      dataField: "sub_category",
      text: "Sub-Category",
      sort: true,
    },
    {
      dataField: "store_name",
      text: "Store",
      sort: true,
    },
    {
      dataField: "total_quantity",
      text: "Qty",
      sort: true,
    },
  ];
  const defaultSorted = [
    {
      dataField: "user",
      order: "desc",
    },
  ];
  return (
    <Row>
      <Col md="12">
        <Card>
          <Card.Header>
            <Card.Title as="h4">Replace</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md="12">
                <BootstrapTable
                  keyField="id"
                  data={replaceData}
                  columns={columns}
                  noDataIndication="Table is Empty"
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ReplaceTable;
