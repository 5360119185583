import React from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import Route from "routes/Route";
import ReactTable from "components/ReactTable/ReactTable.js";
import { emiReportHeader } from "data/dummy";
import { exportToExcel } from "react-json-to-excel";

const EmiReport = () => {
  const [emi, setEmi] = React.useState([]);
  const [exportData, setExportData] = React.useState([]);
  const token = localStorage.getItem("token");
  const [view, setView] = React.useState(false);
  const [itemDetail, setItemDetail] = React.useState({});
  const viewHandle = (item) => {
    setItemDetail(item);
    setView(true);
  };
  const fetchEmiReport = async () => {
    const response = await Route.fetch("staff-emi", token);
    if (response?.status === 200) {
      setExportData(response?.data?.emi?.map((item) => {
        return {
          employee: item?.user?.name,
          emi_no: item?.emi_no,
          invoice_no: item?.sale_voucher?.invoice_no,
          request_date: item?.request_date,
          emi_duration: item?.emi_duration,
          deduction_from: item?.deduction_from,
          monthly_emi: item?.monthly_emi,
          price: item?.total,
          serial_no: item?.product?.serial_no,
          product_description: item?.product?.description,
          remarks: item?.description,
          created_by: item?.creator?.name
        };
      }));
      setEmi(
        response?.data?.emi?.map((item) => {
          return {
            employee: item?.user?.name,
            emi_no: item?.emi_no,
            invoice_no: item?.sale_voucher?.invoice_no,
            request_date: item?.request_date,
            emi_duration: item?.emi_duration,
            deduction_from: item?.deduction_from,
            action: (
              <div className="actions-right">
                <Button
                  variant="primary"
                  size="sm"
                  className="text-primary btn-link"
                  onClick={() => viewHandle(item)}
                >
                  <i className="fa fa-eye"></i>
                </Button>
              </div>
            ),
          };
        })
      );
    }
  };;
  React.useEffect(() => {
    fetchEmiReport();
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Body>
                <Card.Title as="h4">EMI Report</Card.Title>
                <Form>
                  <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Col md="2" style={{ marginTop: "28px" }}>
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="success"
                        onClick={() =>
                          exportToExcel(exportData, "EMI Report")
                        }
                        block
                      >
                        Download
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {emi?.length > 0 ? (
                        <ReactTable
                          data={emi}
                          columns={emiReportHeader}
                          className="-striped -highlight primary-pagination"
                        />
                      ) : (
                        <Alert variant="primary">
                          <span>No Information to Display</span>
                        </Alert>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {view && (
        <Modal show={view} onHide={() => setView(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>EMI Detail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md="4">
                <label>Employee</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Employee"
                    type="text"
                    value={itemDetail?.user?.name}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <label>EMI No.</label>
                <Form.Group>
                  <Form.Control
                    placeholder="EMI No"
                    type="text"
                    value={itemDetail?.emi_no}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <label>Request Date</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Request Date"
                    type="text"
                    value={itemDetail?.request_date}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <label>EMI Duration</label>
                <Form.Group>
                  <Form.Control
                    placeholder="EMI Duration"
                    type="text"
                    value={itemDetail?.emi_duration}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <label>Monthly EMI (Nu.)</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Monthly EMI"
                    type="text"
                    value={itemDetail?.monthly_emi}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <label>Total</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Total"
                    type="text"
                    value={itemDetail?.total}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <label>Deduction From</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Deduction From"
                    type="text"
                    value={itemDetail?.deduction_from}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <label>Invoice No</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Invoice No"
                    type="text"
                    value={itemDetail?.sale_voucher?.invoice_no}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <label>Serial No.</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Total"
                    type="text"
                    value={itemDetail?.product?.serial_no}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="8">
                <label> Product Description</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Description"
                    type="text"
                    value={itemDetail?.product?.description}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Description</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Description"
                    type="text"
                    value={itemDetail?.description}
                    readOnly
                    as="textarea"
                    rows={3}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row
              style={{ display: "flex", justifyContent: "flex-end" }}
              className="my-4"
            >
              <Col md="6">
                <Button
                  variant="danger"
                  className="btn-outline"
                  onClick={() => setView(false)}
                  block
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default EmiReport;
