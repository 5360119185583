import React from "react";
import { Container, Card, Form, Row, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";
// import StringToJSX from "ui/StringToJSX";

const ViewProduct = ({ setView, details }) => {
  return (
    <Container>
      <Card.Title as="h4">View Product</Card.Title>
      <Row>
        <Col md="12" sm="12">
        <Row>
            <Col className="pr-1" md="4">
              <Form.Group>
                <label>Category</label>
                <Form.Control
                  value={details?.sale_type?.name}
                  readOnly
                  placeholder="Category"
                  type="text"
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col className="px-1" md="4">
              <Form.Group>
                <label>Sub-Category</label>
                <Form.Control
                  value={details?.sub_category?.name}
                  placeholder="Sub-Category"
                  type="text"
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col className="px-1" md="4">
              <Form.Group>
                <label>Color</label>
                <Form.Control
                  value={details?.color?.name}
                  placeholder="Color"
                  type="text"
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="pr-1" md="4">
              <Form.Group>
                <label>Item No.</label>
                <Form.Control
                  value={details?.item_number}
                  readOnly
                  placeholder="Item Number"
                  type="text"
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col className="px-1" md="4">
              <Form.Group>
                <label>Serial No.</label>
                <Form.Control
                  value={details?.serial_no}
                  placeholder="Serial Number"
                  type="text"
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col className="px-1" md="4">
              <Form.Group>
                <label>Sale Status</label>
                <Form.Control
                  value={details?.sale_status}
                  placeholder="Sale Status"
                  type="text"
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="pr-1" md="4">
              <Form.Group>
                <label>Total Quantity</label>
                <Form.Control
                  value={parseInt(details?.total_quantity)}
                  readOnly
                  placeholder="Total Quantity"
                  type="number"
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col className="px-1" md="4">
              <Form.Group>
                <label>Quantity</label>
                <Form.Control
                  value={parseInt(details?.main_store_qty)}
                  placeholder="Quantity"
                  type="number"
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col className="px-1" md="4">
              <Form.Group>
                <label>Distributed Quantity</label>
                <Form.Control
                  value={parseInt(details?.main_store_distributed_qty)}
                  placeholder="Distributed Quantity"
                  type="number"
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="pr-1" md="4">
              <Form.Group>
                <label>Damage Quantity</label>
                <Form.Control
                  value={parseInt(details?.damage_quantity)}
                  readOnly
                  placeholder="Damage Quantity"
                  type="number"
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col className="px-1" md="4">
              <Form.Group>
                <label>Batch Number</label>
                <Form.Control
                  value={details?.batch_no}
                  placeholder="Status"
                  type="text"
                  disabled
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col className="px-1" md="4">
              <Form.Group>
                <label>Price</label>
                <Form.Control
                  value={details?.price}
                  placeholder="Unit Code"
                  type="text"
                  disabled
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="pr-1" md="12">
              <Form.Group>
                <label>Description</label>
                <Form.Control
                  value={details?.description}
                  placeholder="Description"
                  type="text"
                  as="textarea"
                  rows={3}
                  disabled
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Row>
        <Col md="12">
          <StringToJSX htmlString={details?.content} />
        </Col>
      </Row> */}
      <Row style={{display: "flex", justifyContent: "flex-end"}}>
        <Col className="mt-4" md="3">
          <Button onClick={() => setView(false)} className="btn-outline" variant="danger" block>
            Close
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
ViewProduct.propTypes = {
  setView: PropTypes.func,
  details: PropTypes.object.isRequired,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default ViewProduct;
