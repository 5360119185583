import axios from "axios";
const base_url = process.env.REACT_APP_BASE_URL;

class Route {
  // ======================================================= FETCH ===========================================================
  fetch(endpoint, token) {
    //eslint-disable-line
    return axios
      .get(
        `${base_url}/${endpoint}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error);
  };
  // ================================================= POST WITHOUT DATA ==============================================================
  post(endpoint, token) {
    //eslint-disable-line
    return axios
      .post(
        `${base_url}/${endpoint}`,{},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error);
  };
  // ======================================================= ADD NEW ===========================================================
  addNew(endpoint, token, data) {
    //eslint-disable-line
    return axios
      .post(
        `${base_url}/${endpoint}`, data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error);
  };
  // ======================================================= POST DETAILS WITH ID ===========================================================
  postDetailWithID(endpoint, token, id, data) {
    //eslint-disable-line
    return axios
      .post(
        `${base_url}/${endpoint}/${id}`, data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error);
  };
  // ======================================================= FETCH DETAILS ===========================================================
  fetchDetails(endpoint, token, id) {
    //eslint-disable-line
    return axios
      .get(
        `${base_url}/${endpoint}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error);
  };
  // ======================================================= UPDATE DETAILS ===========================================================
  update(endpoint, token, id, data) {
    //eslint-disable-line
    return axios
      .put(
        `${base_url}/${endpoint}/${id}`, data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error);
  };
  // ================================================= DELETE =======================================================
  delete(endpoint, token, id) {
    //eslint-disable-line
    return axios
      .delete(
        `${base_url}/${endpoint}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error);
  };
   // ================================================= UPLOAD fILE =======================================================
   uploadFile(endpoint, token, data) {
    //eslint-disable-line
    return axios
      .post(
        `${base_url}/${endpoint}`, data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error);
  };
  // ================================================= GET DETAILS BASED ON DATA =======================================================
  fetchDetailsBasedOnData(endpoint, token, data) {
    //eslint-disable-line
    return axios
    .get(`${base_url}/${endpoint}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      data: data,
    })
    .then((response) => response)
    .catch((error) => error);
  };
  // ========================================================= fetch details ================================================
  fetchPhones(endpoint, token, item_number) {
    //eslint-disable-line
    return axios
      .get(
        `${base_url}/${endpoint}?item_no=${item_number}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error);
  };
  // ========================================================= fetch details ================================================
  fetchDetailsWithParam(endpoint, token, data) {
    //eslint-disable-line
    return axios
      .get(
        `${base_url}/${endpoint}?region_id=${data?.region}&extension_id=${data?.extension}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error);
  };
    // ========================================================= fetch details ================================================
    fetchSalesWithParam(endpoint, token, year) {
      //eslint-disable-line
      return axios
        .get(
          `${base_url}/${endpoint}?year=${year}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => response)
        .catch((error) => error);
    };
      // ========================================================= search warranty ================================================
      seacrchWarranties(endpoint, token, data) {
        //eslint-disable-line
        return axios
          .get(
            `${base_url}/${endpoint}?customer_type=${data?.customer_type}&customer_name=${data?.customer_name}&customer_number=${data?.customer_number}&product_type=${data?.product_type}&serial_no=${data?.serial_no}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => response)
          .catch((error) => error);
      };
    // ========================================================= REPORTS ================================================
    fetchReports(endpoint, token, data) {
      return axios
        .get(
          `${base_url}/${endpoint}?category_id=${data?.category_id}&regional_id=${data?.regional_id}&region_extension_id=${data?.region_extension_id}&from_date=${data?.from_date}&to_date=${data?.to_date}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => response)
        .catch((error) => error);
    }
};

export default new Route();
