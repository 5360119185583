import React from "react";
import { Container, Card, Row, Col, Form, Button } from "react-bootstrap";
import Alert from "ui/Alert";
import Route from "routes/Route";

const ChangePassword = () => {
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const newPasswordHandle = (e) => {
    setNewPassword(e.target.value);
  };
  const confirmPasswordHandle = (e) => {
    setConfirmPassword(e.target.value);
  };
  const cancelHandle = () => {
    setNewPassword("");
    setConfirmPassword("");
  };
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const submitHandle = async (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      const res = await Route.update("reset-password", token, user?.id, {
        password: newPassword,
      });
      if (res.status === 200) {
        setResponse(true);
        setResponseTitle(res?.data?.message);
        setShowAlert(true);
      } else {
        setResponse(false);
        setResponseTitle("Failed To Update Password");
        setShowAlert(true);
      }
    } else {
      setResponse(false);
      setResponseTitle("New Password & Confim Password Does Not Match!");
      setShowAlert(true);
    }
    setNewPassword("");
    setConfirmPassword("");
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Update Password</h4>
            <Card>
              <Card.Body>
                <cite card-title="Source card-title">
                  Your old password is securely encrypted and cannot be
                  recovered.
                </cite>
                <Form className="mt-2" onSubmit={submitHandle}>
                  <Form.Group className="mb-3" controlId="formNewPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="New Password"
                      onChange={newPasswordHandle}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formConfirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      onChange={confirmPasswordHandle}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Button variant="primary" type="submit">
                      Update
                    </Button>{" "}
                    <Button
                      className="btn-outline"
                      type="button"
                      onClick={cancelHandle}
                    >
                      Cancel
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
    </>
  );
};

export default ChangePassword;
