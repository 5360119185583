import dateFormat from "dateformat";

// ============================================== EMAIL FORMAT VERIFICATION ============================================================
export const isEmailValid = (email) => {
  const isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  if (!isValid) {
    return false;
  }
  return true;
};

// ================================================ FORMAT DATE AND TIME ================================================================
export const formatDate = (date) => dateFormat(date, "yyyy-mm-dd hh:mm:ss");
// ================================================ FORMAT DATE ONLY ================================================================
export const formatDateOnly = (date) => dateFormat(date, "mm/dd/yyyy");
// ================================================ FORMAT DATE FOR REPORTS ================================================================
export const formatDateForReport = (date) => dateFormat(date, "yyyy-mm-dd");
// ================================================ FORMAT DATE ONLY ================================================================
export const formatDateForCalender = (date) => {
  let parts = date.split("-");
  return new Date(parts[1] + "-" + parts[0] + "-" + parts[2]);
};
// ============================================= GET DISCOUTS ===================================================================
export const getDiscountsRegionAndExt = (array, region, extension) => {
  let currentDate = new Date();
  const discounts = [];
  array?.map((item) => {
    if (item?.start_date === null && item?.end_date === null) {
      discounts.push(item);
    } else {
      if (
        formatDateForCalender(item?.start_date) <= currentDate &&
        formatDateForCalender(item?.end_date) >= currentDate
      ) {
        discounts.push(item);
      }
    }
  });
  return discounts;
};
// // ===================================== GET DISCOUNT PRODUCT BASED ==========================================================
export const getDiscountsSaleTypeAndSub = (
  array,
  sale_type,
  sub_category,
  region,
  extension
) => {
  const discounts = [];
  array.map((item) => {
    if (
      item?.category_id === null &&
      item?.sub_category_id === null &&
      item?.start_date === null &&
      item?.end_date === null
    ) {
      discounts.push(item);
    } else {
      if (
        item?.region_id !== null ||
        item?.extension_id !== null ||
        item?.category?.sale_type_id !== null ||
        item?.sub_category_id !== null
      ) {
        if (
          item?.category?.sale_type_id !== null &&
          item?.sub_category_id !== null
        ) {
          if (item?.category?.sale_type_id === sale_type) {
            if (item?.sub_category_id === sub_category) {
              if (region === item?.region_id || region === null) {
                if (item?.extension_id === null) {
                  discounts.push(item);
                }
                if (
                  item?.extension_id !== null &&
                  item?.extension_id === extension
                ) {
                  discounts.push(item);
                }
              }
            }
          }
        } else if (
          item?.category?.sale_type_id !== null &&
          item?.sub_category_id === null
        ) {
          if (item?.category?.sale_type_id === sale_type) {
            if (region === item?.region_id || region === null) {
              if (item?.extension_id === null) {
                discounts.push(item);
              }
              if (
                item?.extension_id !== null &&
                item?.extension_id === extension
              ) {
                discounts.push(item);
              }
            }
          }
        } else {
        }
      }
    }
  });
  return discounts;
};

// ================================================ FORM VALIDATION ==============================================================
export const formValidation = (validationConditions) => {
  let isValid = true;
  for (const { condition, setState } of validationConditions) {
    if (condition) {
      setState(false);
      isValid = false;
    }
  }
  return isValid;
};
// =================================== UPDATING DYNAMIC STATE ==================================================
export const dynamicHandle = (id, prop, value, setState) => {
  return setState((prev) => {
    const index = prev.findIndex((item) => item.id === id);
    if (index === -1) {
      return [...prev, { id, [prop]: value }];
    } else {
      const item = prev[index];
      return [
        ...prev.slice(0, index),
        { ...item, [prop]: value },
        ...prev.slice(index + 1),
      ];
    }
  });
};
// ==================================== DELETE DYNAMIC ROW ===============================================
export const dynamicDeleteRowHandle = (id, setState, setAddedRow) => {
  setState((prevSubCatDetails) => {
    return prevSubCatDetails.filter((item) => item.id !== id);
  });
  setAddedRow((prev) => prev.filter((row) => row.props.id !== id));
};
//  ==================================  FILTER BANKS ===================================================
export const fetchBankBasedOnIds = (array, region, extension) => {
  if (region === null && extension === null) {
    return array.filter(
      (item) => item?.region_id === null && item?.extension_id === null
    );
  } else if (region !== null && extension === null) {
    return array.filter(
      (item) => item?.region_id === region && item?.extension_id === null
    );
  } else {
    return array.filter(
      (item) => item?.region_id === region || item?.extension_id === extension
    );
  }
};
// ================================ FILTER BANKS FOR EXTENSION =========================================
export const filteredBanks = (banks, region, extension) =>
  banks.filter(
    (bank) =>
      (bank.region_id === region &&
        (bank.extension_id === null || bank.extension_id === extension)) ||
      (bank.extension_id === extension && bank.region_id === null)
  );
// ========================================= SERIES DATA FOR PHONE ======================================
export const phoneSalesSeries = (salesArray) => {
  if (!salesArray || salesArray.length === 0) {
    return [];
  }

  const salesByCategory = {
    Phone: {},
    Accessory: {},
    SIM: {},
  };

  salesArray?.forEach((sale) => {
    const productCategory = sale?.name;
    const month = sale?.month;

    if (!salesByCategory[productCategory]) {
      salesByCategory[productCategory] = {};
    }

    if (!salesByCategory[productCategory][month]) {
      salesByCategory[productCategory][month] = 0;
    }

    salesByCategory[productCategory][month] += sale?.count;
  });

  // Convert the sales by category dictionary into a list of lists.
  const convertedSalesArray = [];
  const categories = ["Phone", "Accessory", "SIM"];

  for (const productCategory of categories) {
    const convertedSales = [];

    for (let month = 1; month <= 12; month++) {
      convertedSales.push(salesByCategory[productCategory][month] ?? 0);
    }

    convertedSalesArray.push(convertedSales);
  }

  return convertedSalesArray;
};
