import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import PropTypes from "prop-types";

const Alert = ({response, setShowAlert, responseTitle}) => {
  return (
   <>
    {response ? (
        <SweetAlert
        onConfirm={() => setShowAlert(false)}
        closeAnim={{ name: "hideSweetAlert", duration: 300 }}
        success
        title="Success"
        >
        {responseTitle}
        </SweetAlert>
    ) : (
        <SweetAlert
        onConfirm={() => setShowAlert(false)}
        closeAnim={{ name: "hideSweetAlert", duration: 300 }}
        error
        title="Failed"
        >
        {responseTitle}
        </SweetAlert>
    )}
   </> 
  )
}
Alert.propType = {
    response: PropTypes.func,
    responseHandle: PropTypes.func,
    responseTitle: PropTypes.string,
};

export default Alert;

