import React from "react";
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  Alert,
} from "react-bootstrap";
import Select from "react-select";
import Route from "routes/Route";
import RequiredLabel from "ui/RequiredLabel";
import Repair from "./Components/soms/warranty/Repair";
import Replace from "./Components/soms/warranty/Replace";
import { formValidation } from "util/CommonUtil";
import { dateDiff } from "util/MasterUtil";
import NotificationAlert from "ui/NotificationAlert";

const CheckWarranty = () => {
  const [notificationText, setNotificationText] = React.useState("");
  const [showNotification, setShowNotification] = React.useState(false);
  const token = localStorage.getItem("token");
  const [customerTypes, setCustomerTypes] = React.useState([]);
  const [customerType, setCustomerType] = React.useState("");
  const [customerName, setCustomerName] = React.useState("");
  const [customerNo, setCustomerNo] = React.useState("");
  const [productTypes, setProductTypes] = React.useState([]);
  const [productType, setProductType] = React.useState("");
  const [serialNo, setSerialNo] = React.useState("");
  const [customerTypeState, setCustomerTypeState] = React.useState(true);
  const [customerNameState, setCustomerNameState] = React.useState(true);
  const [customerNoState, setCustomerNoState] = React.useState(true);
  const [productTypeState, setProductTypeState] = React.useState(true);
  const [serialNoState, setSerialNoState] = React.useState(true);
  const [details, setDetails] = React.useState([]);
  const [inWarranty, setInWarranty] = React.useState(false);
  const [showReplace, setShowReplace] = React.useState(false);
  const [showRepair, setShowRepair] = React.useState(false);
  const fetchCustomerTypes = async () => {
    const response = await Route.fetch("customer-types", token);
    if (response?.status === 200) {
      setCustomerTypes(response?.data?.customerType);
    }
  };
  const fetchProductTypes = async () => {
    const response = await Route.fetch("sale-types", token);
    if (response?.status === 200) {
      setProductTypes(response?.data?.saleType);
    }
  };
  React.useEffect(() => {
    fetchCustomerTypes();
    fetchProductTypes();
  }, []);
  const customerTypeHandle = (e) => {
    setCustomerTypeState(true);
    setCustomerType(e.value);
  };
  const customerNameHandle = (e) => {
    setCustomerNameState(true);
    setCustomerName(e.target.value);
  };
  const customerNoHandle = (e) => {
    setCustomerNoState(true);
    setCustomerNo(e.target.value);
  };
  const productTypeHandle = (e) => {
    setProductTypeState(true);
    setProductType(e.value);
  };
  const serialNoHandle = (e) => {
    setSerialNoState(true);
    setSerialNo(e.target.value);
  };
  const validationConditions = [
    {
      field: "customerType",
      condition: customerType === "",
      setState: setCustomerTypeState,
    },
    {
      field: "customerName",
      condition: customerName === "",
      setState: setCustomerNameState,
    },
    {
      field: "customerNo",
      condition: customerNo === "",
      setState: setCustomerNoState,
    },
    {
      field: "productType",
      condition: productType === "",
      setState: setProductTypeState,
    },
    {
      field: "serialNo",
      condition: serialNo === "",
      setState: setSerialNoState,
    },
  ];
  const searchHandle = async () => {
    if (formValidation(validationConditions)) {
      setDetails([]);
      const data = {
        customer_type: customerType,
        customer_name: customerName,
        customer_number: customerNo,
        product_type: productType,
        serial_no: serialNo,
      };
      const response = await Route.seacrchWarranties(
        "search-warranties",
        token,
        data
      );
      if (response?.status === 200) {
        if (response?.data?.products.length == 0) {
          setNotificationText("Product Not Found");
          setShowNotification(true);
        } else {
          setDetails(response?.data?.products);
          const yearDiff = dateDiff(response?.data?.products[0]?.invoice_date);
          if (
            (productType === 1 && yearDiff <= 1) ||
            (productType === 2 && yearDiff <= 0.5)
          ) {
            setInWarranty(true);
          }
        }
      }
    }
  };
  const repairHandle = () => {
    setShowReplace(false);
    setShowRepair(true);
  };
  const replaceHandle = () => {
    setShowRepair(false);
    setShowReplace(true);
  };
  const clearHandle = () => {
    setCustomerType("");
    setCustomerName("");
    setCustomerNo("");
    setProductType("");
    setSerialNo("");
    setDetails([]);
  };
  return (
    <>
      {showReplace ? (
        <Replace
          setShowReplace={setShowReplace}
          details={details}
          customerType={customerType}
          customerName={customerName}
          customerNumber={customerNo}
        />
      ) : showRepair ? (
        <Repair
          setShowRepair={setShowRepair}
          details={details}
          customerType={customerType}
          customerName={customerName}
          customerNumber={customerNo}
        />
      ) : (
        <Container fluid>
          <Card>
            <Card.Body>
              <Card.Title>Warranty</Card.Title>
              <Row className="mt-2">
                <Col md="4">
                  <Form.Group>
                    <label>Customer Type</label>
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      name="customerTypeSelect"
                      defaultValue={customerType}
                      onChange={(e) => customerTypeHandle(e)}
                      options={customerTypes?.map((item) => ({
                        value: item?.id,
                        label: item?.name,
                      }))}
                      placeholder="Customer Type"
                    />
                    {customerTypeState ? null : <RequiredLabel />}
                  </Form.Group>
                </Col>
                <Col md="4">
                  <label>Customer Name</label>
                  <Form.Control
                    placeholder="Customer Name"
                    type="text"
                    value={customerName}
                    onChange={customerNameHandle}
                  ></Form.Control>
                  {customerNameState ? null : <RequiredLabel />}
                </Col>
                <Col md="4">
                  <label>Contact No</label>
                  <Form.Control
                    placeholder="Customer No"
                    type="text"
                    value={customerNo}
                    onChange={customerNoHandle}
                  ></Form.Control>
                  {customerNoState ? null : <RequiredLabel />}
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Form.Group>
                    <label>Product Type</label>
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      name="customerTypeSelect"
                      defaultValue={productType}
                      onChange={(e) => productTypeHandle(e)}
                      options={productTypes?.map((item) => ({
                        value: item?.id,
                        label: item?.name,
                      }))}
                      placeholder="Product Type"
                    />
                    {productTypeState ? null : <RequiredLabel />}
                  </Form.Group>
                </Col>
                <Col md="4">
                  <label>Serial No</label>
                  <Form.Control
                    placeholder="Serial No"
                    type="text"
                    value={serialNo}
                    onChange={serialNoHandle}
                  ></Form.Control>
                  {serialNoState ? null : <RequiredLabel />}
                </Col>
                <Col md="2" className="mt-4">
                  <Button
                    className="btn-fill mt-1"
                    type="button"
                    variant="primary"
                    onClick={searchHandle}
                    block
                  >
                    Search
                  </Button>
                </Col>
                <Col md="2" className="mt-4">
                  <Button
                    className="btn-outline mt-1"
                    type="button"
                    variant="info"
                    onClick={clearHandle}
                    block
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="mt-2">
                  {details?.length === 0 ? (
                    <Alert variant="primary">
                      <span>No Information to Display</span>
                    </Alert>
                  ) : (
                    <Form>
                      <Row>
                        <Col md="3">
                          <Form.Group>
                            <label>Customer Name</label>
                            <Form.Control
                              placeholder="Customer Name"
                              type="text"
                              readOnly
                              value={
                                details[0]?.customerName === null
                                  ? ""
                                  : details[0]?.customerName
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group>
                            <label>Customer No</label>
                            <Form.Control
                              placeholder="Customer No"
                              type="text"
                              readOnly
                              value={
                                details[0]?.contactNo === null
                                  ? ""
                                  : details[0]?.contactNo
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group>
                            <label>Invoice No</label>
                            <Form.Control
                              placeholder="Invoice No"
                              type="text"
                              readOnly
                              value={
                                details[0]?.invoice_no === null
                                  ? ""
                                  : details[0]?.invoice_no
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group>
                            <label>Invoice Date</label>
                            <Form.Control
                              placeholder="Invoice Date"
                              type="text"
                              readOnly
                              value={
                                details[0]?.invoice_date === null
                                  ? ""
                                  : details[0]?.invoice_date
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          <Form.Group>
                            <label>Serial Number</label>
                            <Form.Control
                              placeholder="Serial Number"
                              type="text"
                              value={details[0]?.serial_no}
                              readOnly
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group>
                            <label>Item Number</label>
                            <Form.Control
                              placeholder="Item Number"
                              type="text"
                              value={details[0]?.item_number}
                              readOnly
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group>
                            <label>Product Type</label>
                            <Form.Control
                              placeholder="Product Type"
                              type="text"
                              value={details[0]?.product_type}
                              readOnly
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group>
                            <label>Sub-Category</label>
                            <Form.Control
                              placeholder="Sub-Category"
                              type="text"
                              readOnly
                              value={
                                details[0]?.sub_category === null
                                  ? ""
                                  : details[0]?.sub_category
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          <Form.Group>
                            <label>Color</label>
                            <Form.Control
                              placeholder="Color"
                              type="text"
                              readOnly
                              value={
                                details[0]?.color === null
                                  ? ""
                                  : details[0]?.color
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group>
                            <label>Price</label>
                            <Form.Control
                              placeholder="Price"
                              type="text"
                              readOnly
                              value={details[0]?.price}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group>
                            <label>Payment Status</label>
                            <Form.Control
                              placeholder="Invoice Date"
                              type="text"
                              readOnly
                              value={
                                details[0]?.payment_status === null
                                  ? ""
                                  : details[0]?.payment_status
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group>
                            <label>Product Description</label>
                            <Form.Control
                              placeholder="Product Description"
                              type="text"
                              readOnly
                              value={details[0]?.product_description}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Sales Remarks</label>
                            <Form.Control
                              placeholder="Sales Remarks"
                              type="text"
                              as="textarea"
                              rows={3}
                              readOnly
                              value={
                                details[0]?.sales_remarks === null
                                  ? ""
                                  : details[0]?.sales_remarks
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      {inWarranty ? (
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Col md="3">
                            <Button
                              variant="primary"
                              block
                              onClick={repairHandle}
                            >
                              <i className="fa fa-hammer"></i> Repair
                            </Button>
                          </Col>
                          <Col md="3">
                            <Button
                              variant="primary"
                              block
                              onClick={replaceHandle}
                            >
                              <i className="fa fa-reply"></i> Replace
                            </Button>
                          </Col>
                        </Row>
                      ) : null}
                    </Form>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      )}
      {showNotification ? (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      ) : null}
    </>
  );
};

export default CheckWarranty;
