import React from "react";
import { Row, Col } from "react-bootstrap";
import CardComponent from "views/Components/CardComponent";
import Route from "routes/Route";

const Status = () => {
  const [statusData, setStatusData] = React.useState([]);
  const token = localStorage.getItem('token');
  const fetchStatus = async() => {
    const response = await Route.fetch("dashboards", token);
    if (response?.status === 200) {
      setStatusData(response?.data?.status)
    }
  } 
  React.useEffect(() => {
    fetchStatus();
  }, []);
  return (
    <Row>
      {statusData.map((item, index) => (
        <Col lg="3" sm="6" key={index}>
          <CardComponent
            icon={item.icon}
            title={item.title}
            number={item.number}
          />
        </Col>
      ))}
    </Row>
  );
};

export default Status;
