import { configureStore } from "@reduxjs/toolkit";
import authReducer from './authSlice';
import roleReducer from './roleSlice';
import permissionReducer from './permissionSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        role: roleReducer,
        permission: permissionReducer,
    },
});