import React from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";
// import RequiredLabel from "ui/RequiredLabel";
import Route from "routes/Route";
import ReactDatetime from "react-datetime";
import { formatDateOnly, formValidation } from "util/CommonUtil";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const ReceiveProduct = ({
  setView,
  details,
  setResponseTitle,
  responseHandle,
  requisitionNumber,
  totalQty,
}) => {
  const [receivedData, setReceivedData] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  React.useEffect(() => {
    setReceivedData(
      details?.map((item) => ({
        id: item?.id,
        product: item?.product?.sale_type?.name,
        sub_category: item?.product?.sub_category?.name,
        serial_no: item?.product?.serial_no,
        receive: item?.receive,
      }))
    );
  }, []);
  const columns = [
    {
      dataField: "product",
      text: "Product",
      sort: true,
    },
    {
      dataField: "sub_category",
      text: "Sub-Category",
      sort: true,
    },
    {
      dataField: "serial_no",
      text: "Serail Number",
      sort: true,
    },
    {
      dataField: "receive",
      text: "Qty",
      sort: true,
    },
  ];
  const defaultSorted = [
    {
      dataField: "user",
      order: "desc",
    },
  ];
  const [receiveDetails, setReceiveDetails] = React.useState({
    received_date: formatDateOnly(new Date()),
    transfer_description: "",
  });
  // const [receiveDateState, setReceiveDateState] = React.useState(true);
  const token = localStorage.getItem("token");
  const receiveDateHandle = (e) => {
    setReceiveDetails({
      ...receiveDetails,
      received_date: formatDateOnly(e._d),
    });
  };
  const descriptionHandle = (e) => {
    setReceiveDetails({
      ...receiveDetails,
      transfer_description: e.target.value,
    });
  };
  const handleRowSelect = (row, isSelect, rowIndex, e) => {
    // Update the selectedRows state based on row selection
    if (isSelect) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter(selectedRow => selectedRow.id !== row.id));
    }
  };
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: selectedRows.map(row => row.id), // Map selectedRows to an array of row keys
    onSelect: handleRowSelect,
    onSelectAll: (isSelect, rows, e) => {
      // Handle all rows selection
      if (isSelect) {
        setSelectedRows(rows);
      } else {
        setSelectedRows([]);
      }
    }
  };
  const [acknowledged, setAcknowledged] = React.useState(false);
  const receiveHandle = async () => {
    const data = selectedRows?.map((item) => ({
      id: item?.id,
      received_date: receiveDetails?.received_date,
      transfer_description: receiveDetails?.transfer_description,
    }))
    setAcknowledged(true);
    const response = await Route.update(
      "regional-stores",
      token,
      requisitionNumber,
      data
    );
    if (response.status === 200) {
      setView(false);
      setResponseTitle(response?.data?.message);
      responseHandle(true);
    } else {
      setView(false);
      setResponseTitle(response?.data?.message);
      responseHandle(false);
    }
  };
  return (
    <>
      <Card.Title as="h4">Acknowledgement</Card.Title>
      <Row className="mt-2">
        <Col md="6">
          <Form.Group>
            <p>Requisition Number: {requisitionNumber}</p>
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group>
            <p>Total Qty: {totalQty}</p>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <label>
            Received On <span className="star">*</span>
          </label>
          <Form.Group>
            <ReactDatetime
              inputProps={{
                className: "form-control",
                placeholder: "Received On",
              }}
              timeFormat={false}
              closeOnSelect
              initialValue={new Date()}
              onChange={receiveDateHandle}
            ></ReactDatetime>
            {/* {receiveDateState ? null : <RequiredLabel />} */}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <BootstrapTable
            keyField="id"
            data={receivedData}
            columns={columns}
            noDataIndication="Table is Empty"
            defaultSorted={defaultSorted}
            pagination={paginationFactory()}
            selectRow={ selectRow }
          />
        </Col>
      </Row>
      <Row>
        <Col className="pr-1">
          <Form.Group>
            <label>Description</label>
            <Form.Control
              placeholder="Description"
              type="text"
              as="textarea"
              rows={3}
              onChange={descriptionHandle}
            ></Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <div className="card-category form-category">
        <span className="star">*</span>
        Required fields
      </div>
      <Row
        className="mt-4"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Col md="3">
          <Button
            onClick={receiveHandle}
            variant="primary"
            block
            disabled={acknowledged}
          >
            Acknowledge
          </Button>
        </Col>
        <Col md="3">
          <Button
            onClick={() => setView(false)}
            block
            className="btn-outline"
            variant="danger"
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </>
  );
};
ReceiveProduct.propTypes = {
  setView: PropTypes.func,
  details: PropTypes.array.isRequired,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default ReceiveProduct;
