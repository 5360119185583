import React from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import Alert from "ui/Alert";
import { mainStoreProductRequisitionTableHeader } from "data/dummy";
import { useQuery } from "react-query";
import ProductRequisitionsAction from "./ProductRequisitionsAction";
import Route from "routes/Route";

const ProductRequistions = () => {
  const token = localStorage.getItem("token");
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [view, setView] = React.useState(false);
  const [details, setDetails] = React.useState({});
  const [data, setData] = React.useState([]);
  const [regionId, setRegionId] = React.useState("");
  const [extensionId, setExtensionId] = React.useState("");
  const [requisitionNumber, setRequisitionNumber] = React.useState("");
  const { isLoading, data: res } = useQuery(
    "mainStoresRequisitionData",
    () => Route.fetch("main-stores", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const requestHandle = async (id, regionId, extensionId) => {
    const response = await Route.fetchDetails("request-transfers", token, id);
    if (response.status === 200) {
      setRequisitionNumber(id);
      setRegionId(regionId);
      setExtensionId(extensionId)
      setDetails(response?.data);
      setView(true);
    } else {
      setResponse(false);
      setResponseTitle(res?.data?.message);
      setShowAlert(true);
    }
  };
  const [tempData, setTempData] = React.useState([]);
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data);
      if (tempData !== res?.data) {
        setData(
          res?.data?.requisitions?.map((item) => {
            return {
              id: item?.regional_id !== null ? item?.regional_id : item?.region_extension_id,
              from: item?.region !== null ? item?.region?.name : item?.extension?.name,
              requisition_number: item?.requisition_number,
              quantity: parseInt(item?.quantity),
              action: (
                <div className="actions-right">
                  <Button
                    onClick={() => requestHandle(item?.requisition_number, item?.regional_id, item?.region_extension_id)}
                    variant="primary"
                    size="sm"
                    className="btn-outline"
                  >
                   <i className="fa fa-arrow-right"></i>
                  </Button>
                </div>
              ),
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  return (
    <>
      <Container fluid>
        {view ? (
          <ProductRequisitionsAction
            view={view}
            requisitionNumber={requisitionNumber}
            regionId={regionId}
            extensionId={extensionId}
            setView={setView}
            details={details}
            setResponse={setResponse}
            setShowAlert={setShowAlert}
            setResponseTitle={setResponseTitle}
          />
        ) : <Row className="mt-4">
        <Col md="12">
          <Card>
            <Card.Body>
              <ReactTable
                data={data}
                columns={mainStoreProductRequisitionTableHeader}
                className="-striped -highlight primary-pagination"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>}
        {showAlert && (
          <Alert
            response={response}
            setShowAlert={setShowAlert}
            responseTitle={responseTitle}
          />
        )}
      </Container>
    </>
  );
};

export default ProductRequistions;
