import React from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import NotificationAlert from "ui/NotificationAlert";

const ExtensionProductRequisition = ({
  id,
  productListHandle,
  deleteRowHandle,
  products,
}) => {
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const [showNotification, setShowNotification] = React.useState(false);
  const [notifcationText, setNotificationText] = React.useState("")
  const [availableQty, setAvailableQty] = React.useState("");
  const productHandle = (id, e) => {
    productListHandle(id, "product_item_number", e.value);
    productListHandle(id, "sale_type", e.sale_type);
    productListHandle(id, "description", e.description);
    setAvailableQty(parseInt(e.total_quantity));
  };
  const quantityHandle = (e) => {
    if (parseInt(e.target.value) > availableQty) {
      setNotificationText("Qty entered is more than available Qty");
      setShowNotification(true);
    }
    productListHandle(id, "request_quantity", parseInt(e.target.value));
  };
  return (
    <>
      <tr key={id} id={id}>
        <td className="pt-3">
          <Form.Group>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="productSelect"
              onChange={(e) => productHandle(id, e)}
              options={products?.map((item) => ({
                value: item?.item_number,
                sale_type: item?.category_id,
                description: item?.description,
                label: `${item?.description}(${item?.item_number})`,
                total_quantity: item?.qty,
              }))}
              placeholder="Select Product"
            />
          </Form.Group>
        </td>
        <td className="pt-3">
          <Form.Group>
            <Form.Control
              placeholder="Available Qty"
              type="number"
              value={availableQty}
              readOnly
            ></Form.Control>
          </Form.Group>
        </td>
        <td className="pt-3">
          <Form.Group>
            <Form.Control
              placeholder="Quantity"
              type="number"
              onChange={quantityHandle}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            ></Form.Control>
          </Form.Group>
        </td>
        <td>
          <Button
            variant="danger"
            className="btn-link remove text-danger"
            style={{ padding: "0.2rem", marginTop: "0.4em" }}
            onClick={() => deleteRowHandle(id)}
          >
            <i className="fa fa-trash" />
          </Button>
        </td>
      </tr>
    { showNotification && <NotificationAlert title={notifcationText} setAlert={setShowNotification}  /> }
    </>
  );
};

ExtensionProductRequisition.propType = {
  id: PropTypes.number,
  products: PropTypes.object,
  productListHandle: PropTypes.func,
  deleteRowHandle: PropTypes.func,
};

export default ExtensionProductRequisition;
