import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Button,
  Table,
} from "react-bootstrap";
import Route from "routes/Route";
import RequiredLabel from "ui/RequiredLabel";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import NotificationAlert from "ui/NotificationAlert";
import ProductRequistion from "./ProductRequistion";
import { formatDateOnly, dynamicHandle } from "util/CommonUtil";
import { createRequisiton } from "util/MasterUtil";

const AddNewRequisition = ({ setAddNew, responseHandle, setResponseTitle }) => {
  const token = localStorage.getItem("token");
  const [requested, setRequested] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const [notifcationText, setNotificationText] = React.useState("");
  const [products, setProducts] = React.useState([]);
  const [assignType, setAssignType] = React.useState("region");
  const [extensionProduct, setExtensionProduct] = React.useState([]);
  const [addedRow, setAddedRow] = React.useState([]);
  const [rows, setRows] = React.useState(0);
  const [addedProduct, setAddedProduct] = React.useState([]);
  const [requestDateState, setRequestDateState] = React.useState(true);
  const [requestDate, setRequestDate] = React.useState(new Date());
  const [storeState, setStoreState] = React.useState(true);
  const [store, setStore] = React.useState(1);
  const storeArray = [
    {
      id: 1,
      name: "Main Store",
    },
    {
      id: 2,
      name: "Region Store",
    },
  ];
  const fetchProducts = async () => {
    const response = await Route.fetch("requisitions", token);
    if (response.status === 200) {
      setExtensionProduct(response?.data?.extensionProducts);
      setProducts(response?.data?.product);
      setAssignType(response?.data?.assignType);
    }
  };
  React.useEffect(() => {
    fetchProducts();
  }, []);
  const productListHandle = (id, prop, value) => {
    addedProduct.filter((item) => {
      if (item.product_item_number === value) {
        setNotificationText("Product Already Selected");
        setShowNotification(true);
      }
    });
    dynamicHandle(id, prop, value, setAddedProduct);
  };
  const deleteRowHandle = (id) => {
    setAddedProduct((prevProductDetails) => {
      return prevProductDetails.filter((item) => item.id !== id);
    });
    setAddedRow((prev) => prev.filter((row) => row.props.id !== id));
  };
  const addNewRowHandle = () => {
    const newId = rows + 1;
    setRows(newId);
    setAddedRow((prev) => [...prev, row(newId)]);
  };
  const requestDateHandle = (e) => {
    setRequestDateState(true);
    setRequestDate(formatDateOnly(e._d));
  };
  const storeHandle = (e) => {
    setStoreState(true);
    setStore(e.value);
    setAddedRow([]);
  };
  const addNewRequisitionHandle = async () => {
    setRequested(true);
    if (requestDate === "") {
      return setRequestDate(false);
    }
    if (store === "") {
      return setStoreState(false);
    }
    const data = {
      request_date: requestDate,
      requisition_to: store,
      productDetails: createRequisiton(addedProduct),
    };
    const response = await Route.addNew("requisitions", token, data);
    if (response.status === 200) {
      responseHandle(true);
      setResponseTitle(response?.data?.message);
      setAddNew(false);
    } else {
      responseHandle(false);
      setResponseTitle(response?.data?.message);
      setAddNew(false);
    }
  };
  const row = (id) => (
    <ProductRequistion
      key={id}
      id={id}
      productListHandle={productListHandle}
      deleteRowHandle={deleteRowHandle}
      products={store === 1 ? products : extensionProduct}
    />
  );

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Body>
                <Card.Title as="h4">New Requisition</Card.Title>
                <Row>
                  <Col md="6">
                    <label>
                      Request Date <span className="star">*</span>
                    </label>
                    <Form.Group>
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Select Request Date",
                        }}
                        timeFormat={false}
                        closeOnSelect
                        onChange={requestDateHandle}
                        initialValue={new Date()}
                      ></ReactDatetime>
                      {requestDateState ? null : <RequiredLabel />}
                    </Form.Group>
                  </Col>
                  {assignType === "extension" ? (
                    <Col md="6">
                      <label>
                        Store <span className="star">*</span>
                      </label>
                      <Form.Group>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="productSelect"
                          onChange={storeHandle}
                          options={storeArray?.map((item) => ({
                            value: item?.id,
                            label: item?.name,
                          }))}
                          placeholder="Select Product"
                        />
                        {storeState ? null : <RequiredLabel />}
                      </Form.Group>
                    </Col>
                  ) : null}
                </Row>
                <Row>
                  <Col>
                    <Table className="table-hover w-full">
                      <thead>
                        <tr>
                          <th style={{ width: "70%" }}>
                            Description (Item Number){" "}
                            <span className="star">*</span>
                          </th>
                          <th>Available Qty</th>
                          <th>
                            Qty <span className="star">*</span>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {addedRow.length > 0 && addedRow?.map((item) => item)}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      className="btn-outline"
                      type="button"
                      variant="default"
                      onClick={addNewRowHandle}
                    >
                      Add New <i className="fa fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
                <div className="card-category form-category">
                  <span className="star">*</span>
                  Required fields
                </div>
              </Card.Body>
              <Card.Footer>
                <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Col md="3">
                    <Button
                      className="btn-fill"
                      type="button"
                      variant="primary"
                      block
                      onClick={addNewRequisitionHandle}
                      disabled={requested}
                    >
                      Request
                    </Button>
                  </Col>
                  <Col md="3">
                    <Button
                      className="btn-outline"
                      type="button"
                      variant="danger"
                      block
                      onClick={() => setAddNew(false)}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      {showNotification && (
        <NotificationAlert
          title={notifcationText}
          setAlert={setShowNotification}
        />
      )}
    </>
  );
};

AddNewRequisition.propTypes = {
  setAddNew: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddNewRequisition;
