import React from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import RequiredLabel from "ui/RequiredLabel";
import { getGewogs } from "util/MasterUtil";
import { formValidation } from "util/CommonUtil";
import PropTypes from "prop-types";
import Route from "routes/Route";

const AddNewVillage = ({
  setAddNew,
  responseHandle,
  setResponseTitle,
}) => {
  const [dzongkhags, setDzongkhags] = React.useState([]);
  const [selectedDzongkhag, setSelectedDzongkhag] = React.useState('');
  const [gewogs, setGewogs] = React.useState([]);
  const token = localStorage.getItem("token");
  const fetchDzongkhag = async () => {
    const response = await Route.fetch('dzongkhags',token);
    if (response.status === 200) {
      setDzongkhags(response?.data?.dzongkhag);
    }
  };
  React.useEffect(() => {
    fetchDzongkhag();
  }, []);
  const [newDetails, setNewDetails] = React.useState({
    name: "",
    dzongkhag_id: "",
    gewog_id : "",
    code: "",
  });
  const [villageState, setVillageState] = React.useState(true);
  const [dzongkhagState, setDzongkhagState] = React.useState(true);
  const [gewogState, setGewogState] = React.useState(true);
  const VillageNameHandle = (e) => {
    setVillageState(true);
    setNewDetails({
      ...newDetails,
      name: e.target.value,
    });
  };
  const dzongkhagSelectHandle = (e) => {
    setDzongkhagState(true);
    setGewogs(getGewogs(parseInt(e.value), dzongkhags));
    setSelectedDzongkhag(e.value);
  };
  const gewogSelectHandle = (e) => {
    setGewogState(true);
    setNewDetails({
        ...newDetails,
        gewog_id : parseInt(e.value),
      });
  }
  const codeHandle = (e) => {
    setNewDetails({
      ...newDetails,
      code: e.target.value,
    });
  };
  const validationConditions = [
    { field: 'village', condition: newDetails.name === '', setState: setVillageState },
    { field: 'dzongkhag', condition: newDetails.dzongkhag_id === '', setState: setDzongkhagState },
    { field: 'gewog', condition: newDetails.gewog_id === '', setState: setGewogState },
  ];
  const onConfirm = async () => {
    if (formValidation(validationConditions)) {
      const response = await Route.addNew('villages', token, newDetails);
      if (response.status === 200) {
        setResponseTitle(response?.data?.message);
        setAddNew(false);
        responseHandle(true);
      } else {
        setResponseTitle(response?.data?.message);
        setAddNew(false);
        responseHandle(false);
      }
    }
  };
  return (
    <Card className="stacked-form">
      <Card.Header>
        <Card.Title as="h4">Add New Village</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group>
            <label>Village Name <span className="star">*</span></label>
            <Form.Control
              placeholder="Enter Village Name"
              type="text"
              onChange={VillageNameHandle}
            ></Form.Control>
            { villageState ? null : <RequiredLabel /> }
          </Form.Group>
          <Row>
            <Col>
              <Form.Group>
                <label>Dzongkhag <span className="star">*</span></label>
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="dzongkhagSelect"
                  onChange={dzongkhagSelectHandle}
                  options={dzongkhags?.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.name}`,
                  }))}
                  placeholder="Select Dzongkhag"
                />
                { dzongkhagState ? null :  <RequiredLabel /> }
              </Form.Group>
            </Col>
            <Col>
            <Form.Group>
                <label>Gewog <span className="star">*</span></label>
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="gewogSelect"
                  onChange={gewogSelectHandle}
                  options={gewogs?.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.name}`,
                  }))}
                  placeholder="Select Gewog"
                  key={selectedDzongkhag} 
                />
                { gewogState ? null : <RequiredLabel /> }
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <label>Code</label>
            <Form.Control
              placeholder="Code"
              type="text"
              onChange={codeHandle}
            ></Form.Control>
          </Form.Group>
        </Form>
        <div className="card-category form-category">
          <span className="star">*</span>
          Required fields
        </div>
      </Card.Body>
      <Card.Footer>
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col md="3">
            <Button
              className="btn-fill"
              type="submit"
              variant="primary"
              block
              onClick={onConfirm}
            >
              Add
            </Button>
          </Col>
          <Col md="3">
            <Button
              className="btn-outline"
              type="button"
              variant="danger"
              block
              onClick={() => setAddNew(false)}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};
AddNewVillage.propType = {
  setAddNew: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddNewVillage;
