import React from "react";
import { Button, Card, Container, Row, Col, Modal } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import Alert from "ui/Alert";
import { productIndexTableHeader } from "data/dummy";
import { useQuery } from "react-query";
import ViewProduct from "../../inventory/product/ViewProduct";
import Route from "routes/Route";

const RegionalIndexHistory = () => {
  const token = localStorage.getItem("token");
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [view, setView] = React.useState(false);
  const [details, setDetails] = React.useState({});
  const [data, setData] = React.useState([]);
  const { isLoading, data: res } = useQuery(
    "regionalStoresIndexHistory",
    () => Route.fetch("regional-transfers", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const viewHandle = async (id) => {
    const response = await Route.fetchDetails("regional-transfers", token, id);
    if (response.status === 200) {
      setDetails(response?.data?.product);
      setView(true);
    } else {
      setShowAlert(true);
      setResponse(false);
      setResponseTitle(response?.data?.message);
    }
  };
  const [tempData, setTempData] = React.useState([]);
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.product);
      if (tempData !== res?.data?.product) {
        setData(
          res?.data?.product?.map((item) => {
            return {
              id: item?.id,
              itemNumber: item?.product?.item_number,
              description: item?.product?.description,
              quantity: parseInt(item?.receive),
              price: `Nu. ${item?.product?.price}`,
              action: (
                <div className="actions-right">
                  <Button
                    onClick={() => viewHandle(item?.id)}
                    variant="primary"
                    size="sm"
                    className="text-primary btn-link edit"
                  >
                    <i className="fa fa-eye" />
                  </Button>
                </div>
              ),
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  return (
    <>
      <Container fluid>
        <Row className="mt-4">
          <Col md="12">
            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={productIndexTableHeader}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {view && (
          <Modal
            show={view}
            onHide={() => setView(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-90w"
          >
            <Modal.Body>
              <ViewProduct setView={setView} details={details} />
            </Modal.Body>
          </Modal>
        )}
      </Container>
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
    </>
  );
};

export default RegionalIndexHistory;
