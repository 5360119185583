import React from "react";
import PropTypes from "prop-types";
import { Card, Button, Row, Col, Form, Modal } from "react-bootstrap";
import RequiredLabel from "ui/RequiredLabel";
import NotificationAlert from "ui/NotificationAlert";
import Route from "routes/Route";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const EditColor = ({
  edit,
  setEdit,
  details,
  responseHandle,
  setResponseTitle,
}) => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showNotification, setShowNotificaion] = React.useState(false);
  const [newDetails, setNewDetails] = React.useState({
    name: details?.name,
    description: details?.description,
  });
  const [nameState, setNameState] = React.useState(true);
  const inputFieldHandle = (e) => {
    e.target.name === "name" ? setNameState(true) : null;
    setNewDetails({
      ...newDetails,
      [e.target.name]: e.target.value,
    });
  };

  const token = localStorage.getItem("token");
  const updateHandle = async () => {
    if (!checkPermission("colors.update", permissionsArray)) {
      return setShowNotificaion(true);
    }
    if (newDetails?.name === "") {
      return setNameState(false);
    }
    const response = await Route.update(
      "colors",
      token,
      details.id,
      newDetails
    );
    if (response.status === 200) {
      setEdit(false);
      setResponseTitle(response?.data?.message);
      responseHandle(true);
    } else {
      setEdit(false);
      setResponseTitle("Failed To Update");
      responseHandle(false);
    }
  };
  return (
    <Modal show={edit} onHide={() => setEdit(false)}>
      <Card.Body>
        <Card.Title>Edit Color</Card.Title>
        <Row className="mt-2">
          <Col md="12">
            <Form.Group>
              <label>
                Name <span className="star">*</span>
              </label>
              <Form.Control
                placeholder="Name"
                type="text"
                name="name"
                defaultValue={newDetails?.name}
                onChange={inputFieldHandle}
              ></Form.Control>
              {nameState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Form.Group>
              <label>Description</label>
              <Form.Control
                placeholder="Description"
                type="text"
                as="textarea"
                rows={3}
                name="description"
                defaultValue={newDetails?.description}
                onChange={inputFieldHandle}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <div className="card-category form-category">
          <span className="star">*</span>
          Required fields
        </div>
        <Row className="mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col md="3">
            <Button onClick={updateHandle} variant="primary" block>
              Update
            </Button>
          </Col>
          <Col md="3">
            <Button
              onClick={() => setEdit(false)}
              block
              className="btn-outline"
              variant="danger"
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Card.Body>
      {showNotification && <NotificationAlert setAlert={setShowNotificaion} />}
    </Modal>
  );
};

EditColor.propTypes = {
  edit: PropTypes.bool,
  setEdit: PropTypes.func,
  details: PropTypes.object.isRequired,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default EditColor;
