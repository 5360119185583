import React from "react";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import Route from "routes/Route";
import RequiredLabel from "ui/RequiredLabel";
import { formValidation } from "util/CommonUtil";

const AddCustomer = ({
  setAddNew,
  addNew,
  responseHandle,
  setResponseTitle,
}) => {
  const [customerNameState, setCustomerNameState] = React.useState(true);
  const [contactNoState, setContactNoState] = React.useState(true);
  const [customerTypeState, setCustomerTypeState] = React.useState(true);
  const [addressState, setAddressState] = React.useState(true);
  const [newDetails, setNewDetails] = React.useState({
    customer_name: "",
    contact_no: "",
    customer_type: "",
    license_no: "",
    address: "",
    description: "",
  });
  const token = localStorage.getItem("token");
  const [customerType, setCustomerType] = React.useState([]);
  const fetchCustomerType = async () => {
    const response = await Route.fetch("customer-types", token);
    if (response.status === 200) {
      setCustomerType(response?.data?.customerType);
    }
  };
  React.useEffect(() => {
    fetchCustomerType();
  }, []);
  const inputFiledHandle = (name, value) => {
    name === "customer_name"
      ? setCustomerNameState(true)
      : name === "contact_no"
      ? setContactNoState(true)
      : name === "customer_type"
      ? setCustomerTypeState(true)
      : name === "address"
      ? setAddressState(true)
      : null;
    setNewDetails({
      ...newDetails,
      [name]: name === "customer_type" ? parseInt(value) : value,
    });
  };
  const validationConditions = [
    {
      field: "customerName",
      condition: newDetails.customer_name === "",
      setState: setCustomerNameState,
    },
    {
      field: "contactNo",
      condition: newDetails.contact_no === "",
      setState: setContactNoState,
    },
    {
      field: "customerType",
      condition: newDetails.customer_type === "",
      setState: setCustomerTypeState,
    },
    {
      field: "address",
      condition: newDetails.address === "",
      setState: setAddressState,
    },
  ];
  const onConfirm = async () => {
    if (formValidation(validationConditions)) {
      const response = await Route.addNew("customers", token, newDetails);
      if (response.status === 200) {
        setAddNew(false);
        setResponseTitle(response?.data?.message);
        responseHandle(true);
      } else {
        setAddNew(false);
        setResponseTitle("Failed To Create New Customer");
        responseHandle(false);
      }
    }
  };
  return (
    <Modal show={addNew} onHide={() => setAddNew(false)}>
      <Modal.Body>
        <Modal.Title>Add Customer</Modal.Title>
        <Form>
          <Row>
            <Col md="6">
              <Form.Group>
                <label>
                  Customer Name <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Customer Name"
                  type="text"
                  name="customer_name"
                  onChange={(e) =>
                    inputFiledHandle(e.target.name, e.target.value)
                  }
                ></Form.Control>
                {customerNameState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <label>
                  Contact No. <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Contact No."
                  type="text"
                  name="contact_no"
                  onChange={(e) =>
                    inputFiledHandle(e.target.name, e.target.value)
                  }
                ></Form.Control>
                {contactNoState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group>
                <label>
                  Customer Type <span className="star">*</span>
                </label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  onChange={(e) => inputFiledHandle("customer_type", e.value)}
                  name="customerTypeSelect"
                  options={customerType?.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.name}`,
                  }))}
                  placeholder="Select Type"
                />
                {customerTypeState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <label>License No.</label>
                <Form.Control
                  placeholder="License No."
                  type="text"
                  name="license_no"
                  onChange={(e) =>
                    inputFiledHandle(e.target.name, e.target.value)
                  }
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>
                  Address <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Address"
                  type="text"
                  as="textarea"
                  rows={3}
                  name="address"
                  onChange={(e) =>
                    inputFiledHandle(e.target.name, e.target.value)
                  }
                ></Form.Control>
                {addressState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>Description</label>
                <Form.Control
                  placeholder="Description"
                  type="text"
                  as="textarea"
                  rows={3}
                  name="description"
                  onChange={(e) =>
                    inputFiledHandle(e.target.name, e.target.value)
                  }
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="card-category form-category">
                <span className="star">*</span>
                Required fields
              </div>
            </Col>
          </Row>
        </Form>
        <Row className="mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col md="3">
            <Button variant="primary" onClick={onConfirm} block>
              Add
            </Button>
          </Col>
          <Col md="3">
            <Button
              className="btn-outline"
              onClick={() => setAddNew(false)}
              variant="danger"
              block
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
AddCustomer.propTypes = {
  setAddNew: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddCustomer;
