import React from "react";
import { Button, Card, Container, Row, Col, Modal } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import { salesTypeTableHeader } from "data/dummy";
import { useQuery } from "react-query";
import Alert from "ui/Alert";
import DeleteAlert from "ui/DeleteAlert";
import NotificationAlert from "ui/NotificationAlert";
import Route from "routes/Route";
import AddNewSalesType from "./Components/masters/product_type/AddNewSalesType";
import EditSalesType from "./Components/masters/product_type/EditSalesType";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const SalesType = () => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const token = localStorage.getItem("token");
  const [addNew, setAddNew] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [details, setDetails] = React.useState({
    id: "",
    name: "",
    code: "",
    dzongkhag_id: "",
    dzongkhag: "",
  });
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const editHandle = async (id) => {
    if (!checkPermission("sale-types.edit-sale-types", permissionsArray)) {
      return setShowNotification(true);
    }
    if (edit) {
      setEdit(false);
    }
    const response = await Route.fetchDetails("edit-sale-types", token, id);
    if (response.status === 200) {
      setDetails({
        ...details,
        id: response?.data?.saleType?.id,
        name: response?.data?.saleType?.name,
        description: response?.data?.saleType?.description,
      });
      setEdit(true);
    } else {
      setNotificationText("Error While Fetching Sales Type Detail");
      setShowNotification(true);
    }
  };
  const [salesTypeData, setSalesTypeData] = React.useState([]);
  const { isLoading, data: res } = useQuery(
    "salesTypeData",
    () => Route.fetch("sale-types", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const [tempData, setTempData] = React.useState([]);
  const [saleTypeId, setSaleTypeId] = React.useState("");
  const [deleteSaleType, setDeleteSaleType] = React.useState(false);
  const deleteHandle = async () => {
    setDeleteSaleType(false);
    const res = await Route.delete("sale-types", token, saleTypeId);
    if (res.status === 200) {
      setResponse(true);
      setResponseTitle(res?.data?.message);
      setShowAlert(true);
    } else {
      setResponse(false);
      setResponseTitle("Failed To Delete Unit ❌");
      setShowAlert(true);
    }
  };
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.saleType);
      if (tempData !== res?.data?.saleType) {
        setSalesTypeData(
          res?.data?.saleType?.map((item) => {
            return {
              id: item?.id,
              name: item?.name,
              description: item?.description,
              actions: (
                <div className="actions-right">
                  <Button
                    onClick={() => editHandle(item.id)}
                    variant="warning"
                    size="sm"
                    className="text-dark btn-link edit"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    onClick={() => {
                      setSaleTypeId(item?.id);
                      setDeleteSaleType(true);
                    }}
                    variant="danger"
                    size="sm"
                    className="btn-link remove text-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  const addNewHandle = () => {
    !checkPermission("sale-types.store", permissionsArray)
      ? setShowNotification(true)
      : setAddNew(true);
  };
  return (
    <>
      <Container fluid>
        {addNew ? (
          <AddNewSalesType
            onCancel={() => setAddNew(false)}
            setAddNew={setAddNew}
            responseHandle={responseHandle}
            setResponseTitle={setResponseTitle}
          />
        ) : (
          <Row>
            <Col md="12">
              <h4 className="title">Product Type</h4>
              <Card>
                <Card.Body>
                  <Col
                    md="12"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      variant="primary"
                      className="btn-outline"
                      onClick={addNewHandle}
                    >
                      Add New Product Type <i className="fa fa-plus"></i>
                    </Button>
                  </Col>
                  <Col md="12">
                    <ReactTable
                      data={salesTypeData}
                      columns={salesTypeTableHeader}
                      className="-striped -highlight primary-pagination"
                    />
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        {edit && (
          <Modal
            show={edit}
            onHide={() => setEdit(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Body>
              <EditSalesType
                setEdit={setEdit}
                details={details}
                responseHandle={responseHandle}
                setResponseTitle={setResponseTitle}
              />
            </Modal.Body>
          </Modal>
        )}
      </Container>
      {showAlert && (
        <Alert
          response={response}
          responseTitle={responseTitle}
          setShowAlert={setShowAlert}
        />
      )}
      {deleteSaleType && (
        <DeleteAlert deleteHandle={deleteHandle} setAlert={setDeleteSaleType} />
      )}
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      )}
    </>
  );
};

export default SalesType;
