import React from "react";
import PropTypes from "prop-types";
import { Card, Button, Row, Col, Form, Table } from "react-bootstrap";

const ViewRegionAndExtensions = ({ setView, details }) => {
  return (
    <>
      <Card.Body>
        <Card.Title>View Region & Extensions</Card.Title>
        <Row className="mt-2">
          <Col md="6">
            <Form.Group>
              <label>Name</label>
              <Form.Control
                placeholder="Name"
                type="text"
                defaultValue={details?.name}
                readOnly
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <label>Dzongkhag</label>
              <Form.Control
                placeholder="Description"
                type="text"
                defaultValue={details?.dzongkhag?.name}
                readOnly
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Description</label>
              <Form.Control
                placeholder="Description"
                type="text"
                readOnly
                defaultValue={details?.description}
                as="textarea"
                rows={3}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {details?.extensions.length > 0 && (
          <Row className="mt-2">
            <Col>
              <Table className="table-hover w-full">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {details?.extensions.map((item) => (
                    <tr key={item?.id}>
                      <td>{item?.name}</td>
                      <td>{item?.description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
        <Row className="mt-2" style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col md="3">
            <Button onClick={() => setView(false)} className="btn-outline" block>
              <i className="fa fa-arrow-left"></i>  Go Back
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </>
  );
};

ViewRegionAndExtensions.propTypes = {
  setView: PropTypes.func,
  details: PropTypes.object.isRequired,
};

export default ViewRegionAndExtensions;
