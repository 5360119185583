import React from "react";
import { Card, Form, Row, Col, Button, } from "react-bootstrap";
import ReactDatetime from "react-datetime";
import ProductRequistionsList from "./ProductRequisitionList";
import ApprovePhoneRequisition from "./ApprovePhoneRequisition";
import ApproveAccessoryRequisition from "./ApproveAccessoryRequisition";
import ApproveSimRequisition from "./ApproveSimRequisition";
import PropTypes from "prop-types";
import {
  requistionProductDetails,
  requistionPhoneDetails,
  requistionSimDetails,
  aprrovedRequestData,
} from "util/MasterUtil";
import { formatDateOnly, dynamicHandle } from "util/CommonUtil";
import NotificationAlert from "ui/NotificationAlert";
import Route from "routes/Route";

const ProductRequisitionsAction = ({
  requisitionNumber,
  regionId,
  extensionId,
  setView,
  details,
  setResponse,
  setShowAlert,
  setResponseTitle,
}) => {
  const [data, setData] = React.useState([]);
  const [phoneRequestData, setPhoneRequestData] = React.useState([]);
  const [approvedData, setApprovedData] = React.useState({
    region: regionId,
    extension: extensionId,
    product_requisition: requisitionNumber,
    transfer_date: "",
    productDetails: {},
  });
  const [showNotification, setShowNotificaion] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [productDetails, setProductDetails] = React.useState([]);
  const [simRequisitionData, setSimRequisitionData] = React.useState([]);
  React.useEffect(() => {
    setData(requistionProductDetails(details?.requisitions, details?.products));
    setPhoneRequestData(
      requistionPhoneDetails(details?.requisitions, details?.products)
    );
    setSimRequisitionData(
      requistionSimDetails(details?.requisitions, details?.products)
    );
  }, [details]);
  const dateHandle = (e) => {
    setApprovedData({
      ...approvedData,
      transfer_date: formatDateOnly(e._d),
    });
  };
  const quantityHandle = (e, id, quantity) => {
    if (parseInt(e.target.value) > parseInt(quantity)) {
      setNotificationText(
        "Quantity Entered is Greater Than Quantity Available in Store"
      );
      setShowNotificaion(true);
    } else {
      dynamicHandle(id, "transfer_quantity", e.target.value, setProductDetails);
    }
  };
  const removeApprovedHandle = (id) => {
    setProductDetails((prevSubCatDetails) => {
      return prevSubCatDetails.filter((item) => item.id !== id);
    });
  };
  const approveHandle = (e, id, item) => {
    if (e.target.checked) {
      dynamicHandle(id, "serial_no", item?.serial_no, setProductDetails);
      dynamicHandle(id, "requisition_id", item?.requisitionId, setProductDetails);
      dynamicHandle(id, "description", item?.description, setProductDetails);
    } else {
      removeApprovedHandle(id);
    }
  };
  const phoneSerialNoHandle = (e, id) => {
    productDetails.filter((item) => {
      if (item?.serial_no === e.value) {
        setNotificationText("Product Already Selected");
        setShowNotificaion(true);
      }
    });
    dynamicHandle(id, "serial_no", e.value, setProductDetails);
  };
  const phoneApproveHandle = (e, id, item) => {
    if (e.target.checked) {
      dynamicHandle(
        id,
        "transfer_quantity",
        item?.request_quantity,
        setProductDetails
      );
      dynamicHandle(id, "requisition_id", item?.requisitionId, setProductDetails);
      dynamicHandle(id, "description", item?.description, setProductDetails);
    } else {
      removeApprovedHandle(id);
    }
  };
  const simSerialNoHandle = (e, id) => {
    productDetails.filter((item) => {
      if (item?.serial_no === e.value) {
        setNotificationText("Product Already Selected");
        setShowNotificaion(true);
      }
    });
    dynamicHandle(id, "serial_no", e.value, setProductDetails);
  };
  const simApproveHandle = (e, id, item) => {
    if (e.target.checked) {
      dynamicHandle(
        id,
        "transfer_quantity",
        item?.request_quantity,
        setProductDetails
      );
      dynamicHandle(id, "requisition_id", item?.requisitionId, setProductDetails);
      dynamicHandle(id, "description", item?.description, setProductDetails);
    } else {
      removeApprovedHandle(id);
    }
  };
  const submitHandle = async () => {
    setIsDisabled(true);
    setView(false);
    const token = localStorage.getItem("token");
    const data = {
      from: "Main Store",
      region: regionId,
      extension: extensionId,
      product_requisition: requisitionNumber,
      transfer_date: formatDateOnly(productDetails?.transfer_date),
      productDetails: aprrovedRequestData(productDetails),
    };
    const response = await Route.addNew("main-stores", token, data);
    if (response.status === 200) {
      setResponse(true);
      setResponseTitle(response?.data?.message);
      setShowAlert(true);
    } else if (response.status === 422) {
      setResponse(false);
      setResponseTitle(response?.data?.message);
      setShowAlert(true);
    } else {
      setResponse(false);
      setResponseTitle(response?.data?.message);
      setShowAlert(true);
    }
  };
  return (
    <>
      <Card className="mt-4">
        <Card.Body>
          <Row>
            <Col>
              <Card.Title as="h4">Product Requisition Details</Card.Title>
              <ProductRequistionsList details={details} />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Card.Title as="h4">Product Requisition Actions</Card.Title>
              <Row className="mt-4">
                <Col md="4">
                  <label>Approve Date</label>
                  <Form.Group>
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                      }}
                      timeFormat={false}
                      closeOnSelect
                      onChange={dateHandle}
                    ></ReactDatetime>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md="12">
                  <Card.Title as="h6">Phone</Card.Title>
                  {phoneRequestData.length > 0 ? (
                    <ApprovePhoneRequisition
                      phoneRequestData={phoneRequestData}
                      phoneSerialNoHandle={phoneSerialNoHandle}
                      phoneApproveHandle={phoneApproveHandle}
                    />
                  ) : (
                    <div className="mt-4">
                      <label>No Data To Approve</label>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md="12">
                  <Card.Title as="h6">Accessory</Card.Title>
                  {data.length > 0 ? (
                    <ApproveAccessoryRequisition
                      data={data}
                      quantityHandle={quantityHandle}
                      approveHandle={approveHandle}
                    />
                  ) : (
                    <div className="mt-4">
                      <label>No Data To Approve</label>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md="12">
                  <Card.Title as="h6">SIM</Card.Title>
                  {simRequisitionData.length > 0 ? (
                    <ApproveSimRequisition
                      simRequisitionData={simRequisitionData}
                      simSerialNoHandle={simSerialNoHandle}
                      simApproveHandle={simApproveHandle}
                    />
                  ) : (
                    <div className="mt-4">
                      <label>No Data To Approve</label>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col className="mt-4" md="2">
              <Button
                onClick={submitHandle}
                variant={isDisabled ? "default" : "primary"}
                block
                disabled={isDisabled}
              >
                Transfer
              </Button>
            </Col>
            <Col className="mt-4" md="2">
              <Button
                onClick={() => setView(false)}
                variant="danger"
                className="btn-outline"
                block
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotificaion}
        />
      )}
    </>
  );
};
ProductRequisitionsAction.propTypes = {
  setView: PropTypes.func,
  requisitionNumber: PropTypes.string,
  details: PropTypes.object.isRequired,
  setResponse: PropTypes.func,
  setShowAlert: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default ProductRequisitionsAction;
