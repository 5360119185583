import React from "react";
import {
  Button,
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import { roleTableHeader } from "data/dummy";
import AddRole from "./Components/system_setting/role/AddRole";
import EditRole from "./Components/system_setting/role/EditRole";
import Alert from "ui/Alert";
import DeleteAlert from "ui/DeleteAlert";
import NotificationAlert from "ui/NotificationAlert";
import { useQuery } from "react-query";
import SystemSettingRoute from "routes/SystemSettingRoute";
import Route from "routes/Route";
import { usersPermission } from "util/SystemSettingUtil";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const Role = () => {
  const [data, setData] = React.useState([]);
  const token = localStorage.getItem("token");
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [addNewRole, setAddNewRole] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [showEditRole, setShowEditRole] = React.useState(false);
  const [deleteRoleAlert, setDeleteRoleAlert] = React.useState(false);
  const [showPermissionAlert, setShowPermissionAlert] = React.useState(false);
  const [roleDetails, setRoleDetails] = React.useState({
    role: "",
    description: "",
  });
  const [userRoles, setUserRoles] = React.useState({});
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const [permissions, setPermissions] = React.useState([]);
  const [toDeleteRoleId, setToDeleteRoleId] = React.useState('');
  const fetchRoleDetails = async (id, name, description) => {
    setRoleDetails({
      id: id,
      role: name,
      description: description,
    });
    const response = await Route.fetchDetails('roles', token, id);
    if (response.status === 200) {
      setUserRoles(
        usersPermission(response.data.permissions, response.data.rolePermission)
      );
      setPermissions(response.data.rolePermission);
      setShowEditRole(true);
    } else {
      setResponseTitle(response.data.message);
      responseHandle(false);
    }
  };
  const { isLoading, data: res } = useQuery('roleData', () => Route.fetch('roles', token), {
    refetchInterval: 2000,
    refetchIntervalInBackground: true,
  });
  const editRoleHandle = async (id, name, description) => {
    !checkPermission("roles.edit-roles", permissionsArray) ? setShowPermissionAlert(true) : fetchRoleDetails(id, name, description);
  };
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setData(
        res?.data?.role?.map((item) => {
          return {
            id: item.id,
            name: item.name,
            description: item.description,
            nosOfUsers: item.users.length,
            actions: (
              <div className="actions-right">
                <Button
                  onClick={() =>
                    editRoleHandle(item.id, item.name, item.description)
                  }
                  variant="warning"
                  size="sm"
                  className="text-dark btn-link edit"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                <Button
                  onClick={() => {
                      setToDeleteRoleId(item.id);
                      setDeleteRoleAlert(true);
                    }
                  }
                  variant="danger"
                  size="sm"
                  className="text-danger btn-link edit"
                >
                 <i className="fa fa-trash"></i>
                </Button>{" "}
              </div>
            ),
          };
        })
      );
    }
  }, [isLoading, res]);
  if (isLoading) return 'Loading...';
  const addNewRoleHandle = () => {
    !checkPermission("roles.store", permissionsArray) ? setShowPermissionAlert(true) : setAddNewRole(true);
  };
  const deleteRoleConfirmHandle = async() => {
    const response = await SystemSettingRoute.deleteUserRole(token, toDeleteRoleId);
    if (response.status === 200) {
      setResponseTitle(response?.data?.message);
      responseHandle(true);
      setDeleteRoleAlert(false);
    } else {
      setResponseTitle(response?.data?.message);
      responseHandle(false);
    };
  }
  return (
    <>
      <Container fluid>
        {showEditRole ? (
          <>
            <Row>
              <EditRole
                roleDetails={roleDetails}
                userRoles={userRoles}
                setShowEditRole={setShowEditRole}
                permissions={permissions}
              />
            </Row>
          </>
        ) : (
          <Row>
            <Col md="12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <h4 className="title">Roles</h4>
                </span>
              </div>
              <Card>
                <Card.Body>
                  <Row>
                    <Col
                      md="12"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button onClick={addNewRoleHandle} className="btn-outline">Add New Role <i className="fa fa-plus"></i></Button>
                      {"  "}
                      {/* <Button
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="success"
                        style={{ marginLeft: "5px" }}
                      >
                        Download
                      </Button> */}
                    </Col>
                  </Row>
                  <ReactTable
                    data={data}
                    columns={roleTableHeader}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
      { addNewRole && <AddRole
        setAddNewRole={setAddNewRole}
        responseHandle={responseHandle}
        setResponseTitle={setResponseTitle} />
      }
      { deleteRoleAlert && <DeleteAlert deleteHandle={deleteRoleConfirmHandle} setAlert={setDeleteRoleAlert} /> }
      {showAlert && <Alert response={response} setShowAlert={setShowAlert} responseTitle={responseTitle} /> }
      { showPermissionAlert && <NotificationAlert setAlert={setShowPermissionAlert} title="You Don't Have Permission" /> }
    </>
  );
};

export default Role;
