import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import Route from "routes/Route";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const Products = () => {
  const token = localStorage.getItem("token");
  const [productsData, setProductsData] = React.useState([]);
  const fetchProducts = async()=> {
    const response = await Route.fetch("product-list", token);
    if (response?.status === 200) {
      setProductsData(
        response?.data?.products?.map((item) => ({
          category: item?.category,
          sub_category: item?.sub_category,
          store_name: item?.store_name,
          total_quantity: parseInt(item?.total_quantity),
        }))
      )
      console.log(response?.data?.products);
    };
  };
  React.useEffect(() => {
    fetchProducts();
  },[]);
  const columns = [
    {
      dataField: "category",
      text: "Category",
      sort: true,
    },
    {
        dataField: "sub_category",
        text: "Sub-Category",
        sort: true,
    },
    {
        dataField: "store_name",
        text: "Store",
        sort: true,
    },
    {
      dataField: "total_quantity",
      text: "Total Qty",
      sort: true,
    },
  ];
  const defaultSorted = [
    {
      dataField: "user",
      order: "desc",
    },
  ];
  return (
    <Row>
      <Col md="12">
        <Card>
          <Card.Header>
            <Card.Title as="h4">Products</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md="12">
                <BootstrapTable
                  keyField="id"
                  data={productsData}
                  columns={columns}
                  noDataIndication="Table is Empty"
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Products;
