import React from "react";
import PropTypes from "prop-types";
import { Card, Row, Col } from "react-bootstrap";

import { extensionProductsTableHeader } from "data/dummy";

import ReactTable from "components/ReactTable/ReactTable.js";

const ExtensionProductList = ({ extensionProducts }) => {
  return (
    <>
      <Row className="mt-4">
        <Col md="12">
          <Card className="stacked-form">
            <Card.Body>
              <Card.Title as="h5">Extension Product List</Card.Title>
              <ReactTable
                data={extensionProducts}
                columns={extensionProductsTableHeader}
                className="-striped -highlight primary-pagination"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

ExtensionProductList.propTypes = {
  extensionProducts: PropTypes.array,
};

export default ExtensionProductList;
