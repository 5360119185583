import React from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import Alert from "ui/Alert";
import NotificationAlert from "ui/NotificationAlert";
import DeleteAlert from "ui/DeleteAlert";
import AddNewCategory from "./Components/masters/category/AddNewCategory";
import ViewCategory from "./Components/masters/category/ViewCategory";
import EditCategory from "./Components/masters/category/EditCategory";
import { categoryTableHeader } from "data/dummy";
import Route from "routes/Route";
import { useQuery } from "react-query";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const Category = () => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [category, setCategory] = React.useState([]);
  const [addNew, setAddNew] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [view, setView] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const token = localStorage.getItem("token");
  const editHandle = async (id) => {
    if (!checkPermission("categories.edit-categories", permissionsArray)) {
      return setShowNotification(true);
    }
    setEdit(false);
    const response = await Route.fetchDetails("edit-categories", token, id);
    if (response.status === 200) {
      setDetails(response?.data?.category);
      setEdit(true);
    } else {
      setNotificationText("Couldn't Fetch User Details");
      setShowNotification(true);
    }
  };
  const { isLoading, data: res } = useQuery(
    "categoryData",
    () => Route.fetch("categories", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const viewDetailsHandle = async (id) => {
    try {
      const { data } = await Route.fetchDetails("edit-categories", token, id);
      if (data.category) {
        setDetails(data?.category);
        setView(true);
      } else {
        setNotificationText("Error: category not found");
        setShowNotification(true);
      }
    } catch (error) {
      setNotificationText("Error while fetching details");
      setShowNotification(true);
    }
  };
  const [tempData, setTempData] = React.useState([]);
  const [categoryId, setCategoryId] = React.useState("");
  const [deleteCategory, setDeleteCategory] = React.useState(false);
  const deleteHandle = (id) => {
    setCategoryId(id);
    setDeleteCategory(true);
  };
  const deleteCategoryHandle = async () => {
    setDeleteCategory(false);
    const res = await Route.delete("categories", token, categoryId);
    if (res.status === 200) {
      setResponse(true);
      setResponseTitle(res?.data?.message);
      setShowAlert(true);
    } else if (res.status === 202) {
      setResponse(false);
      setResponseTitle(res?.data?.message);
      setShowAlert(true);
    } else {
      setResponse(false);
      setResponseTitle(res?.data?.message);
      setShowAlert(true);
    }
  };
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.category);
      if (tempData !== res?.data?.category) {
        setCategory(
          res?.data?.category?.map((item) => {
            return {
              id: item?.id,
              category: item?.sale_type?.name,
              description: item?.description,
              actions: (
                <div className="actions-right">
                  <Button
                    className="text-primary btn-link edit"
                    onClick={() => viewDetailsHandle(item?.id)}
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                  <Button
                    onClick={() => editHandle(item.id)}
                    variant="warning"
                    size="sm"
                    className="text-dark btn-link edit"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    onClick={() => deleteHandle(item?.id)}
                    variant="danger"
                    size="sm"
                    className="btn-link remove text-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  const addNewCategoryHandle = () => {
    !checkPermission("categories.store", permissionsArray)
      ? setShowNotification(true)
      : setAddNew(true);
  };
  return (
    <>
      {addNew ? (
        <AddNewCategory
          setAddNew={setAddNew}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      ) : view ? (
        <ViewCategory setView={setView} details={details} />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <h4 className="title">Category & Sub-category</h4>
              <Card>
                <Card.Body>
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button variant="primary" onClick={addNewCategoryHandle} className="btn-outline">
                    Add New Category <i className="fa fa-plus"></i>
                  </Button>
                </Col>
                  <ReactTable
                    data={category}
                    columns={categoryTableHeader}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
      {edit && (
        <EditCategory
        edit={edit}
          details={details}
          setEdit={setEdit}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      )}
      {deleteCategory && (
        <DeleteAlert
          deleteHandle={deleteCategoryHandle}
          setAlert={setDeleteCategory}
        />
      )}
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      )}
    </>
  );
};

export default Category;
