import React from "react";
import { Button, Form, Card, Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import RequiredLabel from "ui/RequiredLabel";
import Route from "routes/Route";
import { formatDateOnly, formValidation } from "util/CommonUtil";

const UserApplyEmi = ({ setApplyEmi, setResponseTitle, products, responseHandle }) => {
  const [requestDateState, setRequestDateState] = React.useState(true);
  const [productState, setProductState] = React.useState(true);
  const [noOfEmiState, setNoOfEmiState] = React.useState(true);
  const [deductionFromState, setDeductionFromState] = React.useState(true);
  const [requestDate, setRequestDate] = React.useState(
    formatDateOnly(new Date())
  );
  const [product, setProduct] = React.useState("");
  const [amount, setAmount] = React.useState(0);
  const [emi, setEmi] = React.useState("");
  const [monthlyEmi, setMonthlyEmi] = React.useState(0);
  const [deductionFrom, setDeductionFrom] = React.useState(
    formatDateOnly(new Date())
  );
  const [description, setDescription] = React.useState("");
  const noOfEmi = [
    {
      value: 3,
    },
    {
      value: 6,
    },
    {
      value: 9,
    },
    {
      value: 12,
    },
  ];
  const requestDateHandle = (e) => {
    setRequestDateState(true);
    setRequestDate(formatDateOnly(e._d));
  };
  const productHandle = (e) => {
    setProductState(true);
    setProduct(e.value);
    setAmount(e.price);
  };
  const emiHandle = (e) => {
    setNoOfEmiState(true);
    setEmi(e.value);
    setMonthlyEmi((parseFloat(amount) / parseFloat(e.value)).toFixed(2));
  };
  const deductionHandle = (e) => {
    setDeductionFromState(true);
    setDeductionFrom(formatDateOnly(e._d));
  };
  const descriptionHandle = (e) => {
    setDescription(e.target.value);
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const validationConditions = [
    {
      field: "requestDate",
      condition: requestDate === "",
      setState: setRequestDateState,
    },
    {
      field: "product",
      condition: product === "",
      setState: setProductState,
    },
    {
      field: "emi",
      condition: emi === "",
      setState: setNoOfEmiState,
    },
    {
      field: "deductionFrom",
      condition: deductionFrom === "",
      setState: setDeductionFromState,
    },
  ];
  const applyHandle = async () => {
    if (formValidation(validationConditions)) {
      const data = {
        request_date: requestDate,
        user_id: user?.id,
        emi_duration: emi,
        item_number: product,
        monthly_emi: monthlyEmi,
        total: amount,
        deduction_from: deductionFrom,
        status: "pending",
        description: description,
      };
      const token = localStorage.getItem("token");
      const response = await Route.addNew("apply-emi", token, data);
      if (response?.status === 200) {
        setResponseTitle(response?.data?.message);
        responseHandle(true);
        setApplyEmi(false);
      } else {
        setResponseTitle(response?.data?.message);
        responseHandle(false);
        setApplyEmi(false);
      }
    }
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Employee EMI</h4>
            <Card>
              <Card.Body>
                <Row>
                  <Col md="4">
                    <label>
                      Request Date <span className="star">*</span>
                    </label>
                    <Form.Group>
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Select Request Date",
                        }}
                        timeFormat={false}
                        closeOnSelect
                        onChange={requestDateHandle}
                        initialValue={new Date()}
                      ></ReactDatetime>
                      {requestDateState ? null : <RequiredLabel />}
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <label>
                      Product <span className="star">*</span>
                    </label>
                    <Form.Group>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="productSelect"
                        onChange={productHandle}
                        options={products?.map((item) => ({
                          price: item?.price,
                          value: item?.item_number,
                          label: item?.description,
                        }))}
                        placeholder="Select Product"
                      />
                      {productState ? null : <RequiredLabel />}
                    </Form.Group>
                  </Col>
                  <Col md="2">
                    <Form.Group>
                      <label>
                        Amount <span className="star">*</span>
                      </label>
                      <Form.Control
                        placeholder="Amount"
                        type="number"
                        value={amount}
                        readOnly
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <label>
                      No. of EMI <span className="star">*</span>
                    </label>
                    <Form.Group>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="emiSelect"
                        onChange={emiHandle}
                        options={noOfEmi?.map((item) => ({
                          value: item?.value,
                          label: item?.value,
                        }))}
                        placeholder="Select No. of Months"
                      />
                      {noOfEmiState ? null : <RequiredLabel />}
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>
                        Monthly EMI <span className="star">*</span>
                      </label>
                      <Form.Control
                        placeholder="Monthly EMI"
                        type="number"
                        value={monthlyEmi}
                        readOnly
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <label>
                      Deduction From Period <span className="star">*</span>
                    </label>
                    <Form.Group>
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Select Request Date",
                        }}
                        timeFormat={false}
                        // dateFormat="YYYY-MM"
                        closeOnSelect
                        initialValue={new Date()}
                        onChange={deductionHandle}
                      ></ReactDatetime>
                      {deductionFromState ? null : <RequiredLabel />}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Form.Group>
                      <Form.Control
                        placeholder="Description"
                        type="text"
                        onChange={descriptionHandle}
                        as="textarea"
                        rows={3}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Col md="2">
                    <Button variant="primary" block onClick={applyHandle}>
                      Apply
                    </Button>
                  </Col>
                  <Col md="2">
                    <Button
                      variant="danger"
                      className="btn-outline"
                      onClick={() => setApplyEmi(false)}
                      block
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserApplyEmi;
