import React from "react";
import PropTypes from "prop-types";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import Route from "routes/Route";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";
import NotificationAlert from "ui/NotificationAlert";

const EditBank = ({ setEdit, details, responseHandle, setResponseTitle }) => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const token = localStorage.getItem("token");
  const [regions, setRegions] = React.useState([]);
  const [extensions, setExtensions] = React.useState([]);
  const [selectedRegion, setSelectedRegion] = React.useState("");
  const [notificationText, setNotificationText] = React.useState("You Don't Have Permission");
  const [showNotification, setShowNotificaion] = React.useState(false);
  const [newDetails, setNewDetails] = React.useState({
    bank_name: details?.bank?.name,
    code: details?.bank?.code,
    account_number: details?.bank?.account_number,
    region: details?.bank?.region?.id,
    extension: details?.bank?.extension?.id,
    description: details?.bank?.description,
  });
  const fetchRegions = async () => {
    const response = await Route.fetch("regions", token);
    if (response.status === 200) {
      setRegions(response?.data?.region);
    }
  };
  React.useEffect(() => {
    fetchRegions();
  }, []);
  const fetchExtensions = async () => {
    const response = await Route.fetchDetails(
      "edit-regions",
      token,
      selectedRegion
    );
    if (response.status === 200) {
      setExtensions(response?.data?.Region?.extensions);
    }
  };
  React.useEffect(() => {
    fetchExtensions();
  }, [selectedRegion]);
  const nameHandle = (e) => {
    setNewDetails({
      ...newDetails,
      bank_name: e.target.value,
    });
  };
  const codeHandle = (e) => {
    setNewDetails({
      ...newDetails,
      code: e.target.value,
    });
  };
  const accountNumberHandle = (e) => {
    setNewDetails({
      ...newDetails,
      account_number: e.target.value,
    });
  };
  const descriptionHandle = (e) => {
    setNewDetails({
      ...newDetails,
      description: e.target.value,
    });
  };
  const regionSelectHandle = (e) => {
    setNewDetails({
      ...newDetails,
      region: parseInt(e.value),
    });
    setSelectedRegion(parseInt(e.value));
  };
  const extensionSelectHandle = (e) => {
    setNewDetails({
      ...newDetails,
      extension: parseInt(e.value),
    });
  };
  const submitHandle = async () => {
    if (!checkPermission("banks.update", permissionsArray)) {
      setShowNotificaion(true);
    }
    const response = await Route.update(
      "banks",
      token,
      details?.bank?.id,
      newDetails
    );
    if (response?.status === 200) {
      responseHandle(true);
      setResponseTitle(response?.data?.message);
      setEdit(false);
    } else {
      responseHandle(false);
      setResponseTitle(response?.data?.message);
      setEdit(false);
    }
  };
  return (
    <>
      <Card.Body>
        <Card.Title>Edit Bank</Card.Title>
        <Row className="mt-2">
          <Col md="4">
            <Form.Group>
              <label>
                Name <span className="star">*</span>
              </label>
              <Form.Control
                placeholder="Name"
                type="text"
                defaultValue={details?.bank?.name}
                onChange={nameHandle}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <label>
                Code <span className="star">*</span>
              </label>
              <Form.Control
                placeholder="Code"
                type="text"
                defaultValue={details?.bank?.code}
                onChange={codeHandle}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <label>
                Account Number <span className="star">*</span>
              </label>
              <Form.Control
                placeholder="Account Number"
                type="text"
                defaultValue={details?.bank?.account_number}
                onChange={accountNumberHandle}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Description</label>
              <Form.Control
                placeholder="Description"
                type="text"
                defaultValue={details?.bank?.description}
                as="textarea"
                rows={3}
                onChange={descriptionHandle}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Region</label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="regionSelect"
                defaultValue={{
                  label: `${details?.bank?.region === null ? "Select" : details?.bank?.region?.name}`,
                }}
                onChange={regionSelectHandle}
                options={regions?.map((item) => ({
                  value: `${item.id}`,
                  label: `${item.name}`,
                }))}
                placeholder="Select Region"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <label>Extension</label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="extensionSelect"
                defaultValue={{
                  label: `${details?.bank?.extension === null ? "Select" : details?.bank?.extension?.name}`,
                }}
                onChange={extensionSelectHandle}
                options={extensions?.map((item) => ({
                  value: `${item.id}`,
                  label: `${item.name}`,
                }))}
                placeholder="Select Extension"
                key={selectedRegion}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="card-category form-category">
          <span className="star">*</span>
          Required fields
        </div>
        <Row className="mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col md="3">
            <Button onClick={submitHandle} block>
              Update
            </Button>
          </Col>
          <Col md="3">
            <Button onClick={() => setEdit(false)} className="btn-outline" variant="danger" block>
              Cancel
            </Button>
          </Col>
        </Row>
      </Card.Body>
      { showNotification && <NotificationAlert title={notificationText} setAlert={setShowNotificaion} /> }
    </>
  );
};

EditBank.propTypes = {
  setEdit: PropTypes.func,
  details: PropTypes.object.isRequired,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default EditBank;
