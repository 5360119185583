import React from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Button, Form, Modal } from "react-bootstrap";
import RepairItem from "./RepairItem";
import { dynamicHandle } from "util/CommonUtil";
import NotificationAlert from "ui/NotificationAlert";
import Route from "routes/Route";

const Repair = ({
  setShowRepair,
  details,
  customerType,
  customerName,
  customerNumber,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const [showNotification, setShowNotification] = React.useState(false);
  const [invoiceNo, setInvoiceNo] = React.useState("");
  const [addedRow, setAddedRow] = React.useState([]);
  const [addedProductDetails, setAddedProductDetails] = React.useState([]);
  const [description, setDescription] = React.useState("");
  const [rows, setRows] = React.useState(0);
  const inputFieldHandle = (id, param, value) => {
    dynamicHandle(id, param, value, setAddedProductDetails);
  };
  const deleteRowHandle = (id) => {
    setAddedProductDetails((prevSubCatDetails) => {
      return prevSubCatDetails.filter((item) => item.id !== id);
    });
    setAddedRow((prev) => prev.filter((row) => row.props.id !== id));
  };
  const row = (id) => (
    <RepairItem
      key={id}
      id={id}
      deleteRowHandle={deleteRowHandle}
      inputFieldHandle={inputFieldHandle}
    />
  );
  const addNewRowHandle = () => {
    const newId = rows + 1;
    setRows(newId);
    setAddedRow((prev) => [...prev, row(newId)]);
  };
  const descriptionHandle = (e) => {
    setDescription(e.target.value);
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem('token');
  const repairHandle = async() => {
    const data = {
      customerType: customerType,
      customerName: customerName,
      customerNo: customerNumber,
      oldDetails: details[0],
      newDetails: addedProductDetails,
      region: user?.assign_and_employee?.regional_id,
      extension: user?.assign_and_employee?.extension_id,
      description: description,
    };
    console.log(data);
    const response = await Route.addNew("repair", token, data);
    if (response?.status === 200) {
      setInvoiceNo(response?.data?.invoice_no);
      setShowModal(true);
    };
    if (response?.status == 203) {
      setNotificationText(response?.data?.message);
      setShowNotification(true);
    };
  };

  return (
    <>
    <Card className="stacked-form">
      <Card.Header>
        <Card.Title as="h4">Repair</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>{addedRow.length > 0 && addedRow?.map((item) => item)}</Col>
        </Row>
        <Row>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              className="btn-outline"
              type="button"
              variant="default"
              onClick={addNewRowHandle}
            >
              Add New <i className="fa fa-plus"></i>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Form.Group controlId="descriptionTextarea">
              <label>Description</label>
              <Form.Control
                onChange={descriptionHandle}
                as="textarea"
                rows={3}
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col md="3">
            <Button
              className="btn-fill"
              type="submit"
              variant="primary"
              block
              onClick={repairHandle}
            >
              Repair
            </Button>
          </Col>
          <Col md="3">
            <Button
              className="btn-outline"
              type="button"
              variant="danger"
              block
              onClick={() => setShowRepair(false)}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
    {showModal ? (
      <Modal
        show={showModal}
        onHide={() => {
          setShowRepair(false);
          setShowModal(false);
        }}
      >
        <Modal.Body>
          <Row>
            <Col>New Invoice No. {invoiceNo}</Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="3">
              <Button
                variant="danger"
                onClick={() => {
                  setShowRepair(false);
                  setShowModal(false);
                }}
              >
                Close
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    ) : null}
    {showNotification ? (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      ) : null}
    </>
  );
};

Repair.propType = {
  setShowRepair: PropTypes.func,
  details: PropTypes.array.isRequired,
  customerType: PropTypes.number,
  customerName: PropTypes.string,
  customerNumber: PropTypes.string,
};

export default Repair;
