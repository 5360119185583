import React from "react";
import { Table, Form } from "react-bootstrap";
import PropTypes from "prop-types";

const ApproveAccessoryRequisition = ({
  data,
  quantityHandle,
  approveHandle,
}) => {
  return (
    <>
      <Table striped hover size="sm" responsive>
        <thead>
          <tr>
            <th>Sl.No.</th>
            <th>Product Item Number</th>
            <th>Request Qty</th>
            <th>Available Qty</th>
            <th>Description</th>
            <th style={{ width: "10%" }}>Quantity Approved</th>
            <th>Approve</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item?.product_item_number}</td>
              <td>{parseInt(item?.request_quantity)}</td>
              <td>{parseInt(item?.quantity)}</td>
              <td>{item?.description}</td>
              <td>
                <Form.Control
                  type="number"
                  placeholder="Qty"
                  onChange={(e) =>
                    quantityHandle(e, `${item?.id} ${index}`, item?.quantity)
                  }
                />
              </td>
              <td>
                <div className="mt-1">
                  <Form.Check>
                    <Form.Check.Label>
                      <Form.Check.Input
                        defaultValue=""
                        type="checkbox"
                        onChange={(e) =>
                          approveHandle(e, `${item?.id} ${index}`, item)
                        }
                      ></Form.Check.Input>
                      <span className="form-check-sign"></span>
                    </Form.Check.Label>
                  </Form.Check>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

ApproveAccessoryRequisition.propTypes = {
  data: PropTypes.array,
  quantityHandle: PropTypes.func,
  approveHandle: PropTypes.func,
};

export default ApproveAccessoryRequisition;
