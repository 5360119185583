import React from "react";
import PropTypes from "prop-types";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import RequiredLabel from "ui/RequiredLabel";
import Route from "routes/Route";
import { formValidation } from "util/CommonUtil";

const AddBank = ({ setAddNew, responseHandle, setResponseTitle }) => {
  const token = localStorage.getItem("token");
  const [regions, setRegions] = React.useState([]);
  const [extensions, setExtensions] = React.useState([]);
  const [selectedRegion, setSelectedRegion] = React.useState("");
  const [nameState, setNameState] = React.useState(true);
  const [codeState, setCodeState] = React.useState(true);
  const [accNoState, setAccNoState] = React.useState(true);
  const [newDetails, setNewDetails] = React.useState({
    bank_name: "",
    code: "",
    account_number: "",
    region: "",
    extension: "",
    description: "",
  });
  const fetchRegions = async () => {
    const response = await Route.fetch("regions", token);
    if (response.status === 200) {
      setRegions(response?.data?.region);
    }
  };
  React.useEffect(() => {
    fetchRegions();
  }, []);
  const fetchExtensions = async () => {
    const response = await Route.fetchDetails(
      "edit-regions",
      token,
      selectedRegion
    );
    if (response.status === 200) {
      setExtensions(response?.data?.Region?.extensions);
    }
  };
  React.useEffect(() => {
    fetchExtensions();
  }, [selectedRegion]);

  const nameHandle = (e) => {
    setNewDetails({
      ...newDetails,
      bank_name: e.target.value,
    });
  };
  const codeHandle = (e) => {
    setNewDetails({
      ...newDetails,
      code: e.target.value,
    });
  };
  const accountNumberHandle = (e) => {
    setNewDetails({
      ...newDetails,
      account_number: e.target.value,
    });
  };
  const descriptionHandle = (e) => {
    setNewDetails({
      ...newDetails,
      description: e.target.value,
    });
  };
  const regionSelectHandle = (e) => {
    setNewDetails({
      ...newDetails,
      region: parseInt(e.value),
    });
    setSelectedRegion(parseInt(e.value));
  };
  const extensionSelectHandle = (e) => {
    setNewDetails({
      ...newDetails,
      extension: parseInt(e.value),
    });
  };
  const validationConditions = [
    {
      field: "bankName",
      condition: newDetails.bank_name === "",
      setState: setNameState,
    },
    {
      field: "code",
      condition: newDetails.code === "",
      setState: setCodeState,
    },
    {
      field: "accNo",
      condition: newDetails.account_number === "",
      setState: setAccNoState,
    },
  ];
  const submitHandle = async () => {
    if (formValidation(validationConditions)) {
      const response = await Route.addNew("banks", token, newDetails);
      if (response.status === 200) {
        responseHandle(true);
        setResponseTitle(response?.data?.message);
        setAddNew(false);
      } else {
        responseHandle(false);
        setResponseTitle(response?.data?.message);
        setAddNew(false);
      }
    }
  };
  return (
    <Card>
      <Card.Header>
        <Card.Title>Add Bank</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className="mt-2">
          <Col md="4">
            <Form.Group>
              <label>Name <span className="star">*</span></label>
              <Form.Control
                placeholder="Name"
                type="text"
                onChange={nameHandle}
              ></Form.Control>
              {nameState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <label>Code <span className="star">*</span></label>
              <Form.Control
                placeholder="Code"
                type="text"
                onChange={codeHandle}
              ></Form.Control>
              {codeState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <label>Account Number <span className="star">*</span></label>
              <Form.Control
                placeholder="Account Number"
                type="text"
                onChange={accountNumberHandle}
              ></Form.Control>
              {accNoState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Description</label>
              <Form.Control
                placeholder="Description"
                type="text"
                as="textarea"
                rows={3}
                onChange={descriptionHandle}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Region</label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="regionSelect"
                onChange={regionSelectHandle}
                options={regions?.map((item) => ({
                  value: `${item.id}`,
                  label: `${item.name}`,
                }))}
                placeholder="Select Region"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <label>Extension</label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="extensionSelect"
                onChange={extensionSelectHandle}
                options={extensions?.map((item) => ({
                  value: `${item.id}`,
                  label: `${item.name}`,
                }))}
                placeholder="Select Extension"
                key={selectedRegion}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="card-category form-category">
          <span className="star">*</span>
          Required fields
        </div>
      </Card.Body>
      <Card.Footer>
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col md="2">
            <Button onClick={submitHandle} block>
              Add
            </Button>
          </Col>
          <Col md="2">
            <Button
              onClick={() => setAddNew(false)}
              className="btn-outline"
              variant="danger"
              block
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

AddBank.propTypes = {
  setAddNew: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddBank;
