import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Form, Container, Col } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import RequiredLabel from "ui/RequiredLabel";
import Background from "ui/Background";
import Logo from "../../assets/img/logo.svg";
import LogoImg from '../../assets/img/logo.png'
import AuthRoute from "routes/AuthRoute";
import { useDispatch } from "react-redux";
import { logIn, logOut } from "app/authSlice";
import NotificationAlert from "ui/NotificationAlert";

function LoginPage() {
  const histroy = useHistory();
  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 500);
  });
  const [cardClasses, setCardClasses] = React.useState("card-hidden");
  const [userNameState, setUserNameState] = React.useState(true);
  const [passwordState, setPasswordState] = React.useState(true);
  const [loginFailed, setLoginFailed] = React.useState(false);
  const [state, setState] = React.useState({
    username: "",
    password: "",
  });
  const [notificationText, setNotificationText] = React.useState("");
  const inputHandle = (e) => {
    e.target.name === "username"
      ? setUserNameState(true)
      : setPasswordState(true);
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const loginHandle = async (e) => {
    e.preventDefault();
    if (state.username === "") {
      setUserNameState(false);
    }
    if (state.password === "") {
      setPasswordState(false);
    }
    if (state.username !== "" && state.password !== "") {
      const response = await AuthRoute.userLogIn(
        state.username,
        state.password
      );
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data?.user));
        localStorage.setItem(
          "roles",
          JSON.stringify(response.data?.user?.roles)
        );
        localStorage.setItem(
          "permissions",
          JSON.stringify(response.data?.user?.roles[0]?.permissions)
        );
        histroy.push("/admin/dashboard");
        window.location.reload();
      } else {
        setNotificationText(response?.response?.data?.message)
        setLoginFailed(true);
      }
    }
  };
  const dispatch = useDispatch();
  if (localStorage.getItem("token")) {
    dispatch(logIn());
  } else {
    dispatch(logOut());
  }
  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/background-image.jpg")}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <Form onSubmit={loginHandle} className="form" method="post">
                <Card className={"card-login " + cardClasses}>
                  <Card.Body>
                    <div style={{ textAlign: "center" }} className="mb-4">
                      <img
                        src={LogoImg}
                        style={{
                          width: "12rem",
                        }}
                      />
                    </div>
                    <Form.Group>
                      <label style={{ color: "#447df7" }}>Employee ID</label>
                      <Form.Control
                        placeholder="Enter Employee ID"
                        type="text"
                        name="username"
                        onChange={inputHandle}
                      ></Form.Control>
                      {userNameState ? null : <RequiredLabel />}
                    </Form.Group>
                    <Form.Group>
                      <label style={{ color: "#447df7" }}>Password</label>
                      <Form.Control
                        placeholder="Password"
                        type="password"
                        name="password"
                        onChange={inputHandle}
                        // isInvalid
                      ></Form.Control>
                      {passwordState ? null : <RequiredLabel />}
                    </Form.Group>
                    <div style={{ marginTop: "30px" }}>
                      <Button type="submit" variant="primary" block>
                        Login
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
        <Background />
      </div>
      {
        loginFailed && <NotificationAlert title={notificationText} setAlert={setLoginFailed} />
      }
    </>
  );
}

export default LoginPage;
