import React from "react";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import { receivedExtProductTableHeader } from "data/dummy";
import { useQuery } from "react-query";
import Route from "routes/Route";

const ReceivedExtProducts = () => {
  const token = localStorage.getItem("token");
  const [data, setData] = React.useState([]);
  const { isLoading, data: res } = useQuery('extensionsStoresData', () => Route.fetch('extension-stores', token), {
    refetchInterval: 2000,
    refetchIntervalInBackground: true,
  });
  
  const [tempData, setTempData] = React.useState([]);
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.receiveProduct);
      if (tempData !== res?.data?.receiveProduct) {
        setData(
          res?.data?.receiveProduct?.map((item) => {
            return {
              id: item?.id,
              product_id: item?.product?.item_number,
              extension: item?.extension?.name,
              received_from: item?.regional_transfer_id,
              serial_no: item?.product?.serial_no,
              received_date: item?.received_date,
              receive: parseInt(item?.receive),
              status: (
                <div className="actions-right">
                    <Button
                      variant="info"
                      size="sm"
                      className="btn-outline"
                    >
                      {item?.status === "receive" ? "Received" : item?.status}
                    </Button>{" "}
                  </div>
              ),
            };
          })
        );
      };
    };
  }, [isLoading, res]);
  if (isLoading) return 'Loading...';
  return (
    <>
      <Container fluid>
        <Row className="mt-4">
            <Col md="12">
                <Card>
                <Card.Body>
                    <ReactTable
                    data={data}
                    columns={receivedExtProductTableHeader}
                    className="-striped -highlight primary-pagination"
                    />
                </Card.Body>
                </Card>
            </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReceivedExtProducts;
