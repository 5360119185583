import React from "react";
import PropTypes from "prop-types";
import { Container, Card, Button, Row, Col, Form } from "react-bootstrap";
import RequiredLabel from "ui/RequiredLabel";
import Select from "react-select";
import Route from "routes/Route";

const EditAssign = ({
  setEdit,
  user,
  details,
  responseHandle,
  setResponseTitle,
}) => {
  const token = localStorage.getItem("token");
  const [disableRegionSelect, setDisableRegionSelect] = React.useState(true);
  const [disableExtensionSelect, setDisableExtensionSelect] =
    React.useState(true);
  const [extensions, setExtensions] = React.useState([]);
  const [selectedRegion, setSelectedRegion] = React.useState("");
  const [newDetails, setNewDetails] = React.useState({
    assign_type: "",
    region: "",
    extension: "",
  });
  const [regionState, setRegionState] = React.useState(true);
  const [extensionState, setExtensionState] = React.useState(true);
  const assignTypeSelectHandle = (e) => {
    setNewDetails({
      ...newDetails,
      assign_type: e.value,
    });
  };
  const assignType = [
    { value: "assign both", name: "ASSIGN BOTH" },
    { value: "assign regional", name: "ASSIGN REGIONAL" },
    { value: "assign extension", name: "ASSIGN EXTENSION" },
  ];

  React.useEffect(() => {
    if (newDetails.assign_type === "assign both") {
      setDisableRegionSelect(false);
      setDisableExtensionSelect(false);
    } else if (newDetails.assign_type === "assign regional") {
      setDisableRegionSelect(false);
      setDisableExtensionSelect(true);
    } else if (newDetails.assign_type === "assign extension") {
      setDisableRegionSelect(false);
      setDisableExtensionSelect(false);
    } else {
      setDisableRegionSelect(true);
      setDisableExtensionSelect(true);
    }
  }, [newDetails.assign_type]);
  const fetchExtensionBasedOnRegion = async () => {
    const response = await Route.fetchDetails(
      "edit-regions",
      token,
      selectedRegion
    );
    if (response.status === 200) {
      setExtensions(response?.data?.Region?.extensions);
    }
  };
  React.useEffect(() => {
    if (selectedRegion !== "") {
      fetchExtensionBasedOnRegion();
    }
  }, [selectedRegion]);
  const regionSelectHandle = (e) => {
    setRegionState(true);
    setNewDetails({
      ...newDetails,
      region: e.value,
    });
    setSelectedRegion(parseInt(e.value));
  };
  const extensionSelectHandle = (e) => {
    setExtensionState(true);
    setNewDetails({
      ...newDetails,
      extension: e.value,
    });
  };
  const submitHandle = async () => {
    if ((newDetails.assign_type === "assign both" || newDetails.assign_type === "assign regional") && newDetails?.region === "") {
      return setRegionState(false);
    }
    if ((newDetails.assign_type === "assign both" || newDetails.assign_type === "assign extension") && newDetails?.extension === "") {
      return setExtensionState(false);
    }
    const response = await Route.postDetailWithID(
      "edit-assignings",
      token,
      details?.assignAndEmployee?.id,
      newDetails
    );
    if (response.status === 200) {
      responseHandle(true);
      setResponseTitle(response?.data?.message);
      setEdit(false);
    } else {
      responseHandle(false);
      setResponseTitle(response?.data?.message);
      setEdit(false);
    }
  };
  return (
    <>
      <Container fluid>
        <Card.Body>
          <Card.Title as="h4">Assign </Card.Title>
          <Row>
            <Col md="6">
              <Form.Group>
                <label>
                  User <span className="star">*</span>
                </label>
                <Form.Control
                  name="name"
                  type="text"
                  value={user}
                  readOnly
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <label>
                  Assign Type <span className="star">*</span>
                </label>
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="assignTypeSelect"
                  onChange={assignTypeSelectHandle}
                  defaultValue={{
                    value: `${details?.assignAndEmployee?.assign_type}`,
                    label: `${details?.assignAndEmployee?.assign_type.toUpperCase()}`,
                  }}
                  options={assignType?.map((item) => ({
                    value: `${item.value}`,
                    label: `${item.name}`,
                  }))}
                  placeholder="Select Assign Type"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group>
                <label>Region</label>
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="regionSelect"
                  onChange={regionSelectHandle}
                  options={details?.regions?.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.name}`,
                  }))}
                  placeholder="Select Region"
                  isDisabled={disableRegionSelect}
                />
                {regionState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <label>Extension</label>
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="extensionSelect"
                  onChange={extensionSelectHandle}
                  options={extensions?.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.name}`,
                  }))}
                  placeholder="Select Extension"
                  isDisabled={disableExtensionSelect}
                />
                {extensionState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="card-category form-category">
                <span className="star">*</span>
                Required fields
              </div>
            </Col>
            <Col md="12">
              <div className="card-category form-category">
                <Form.Text className="text-muted">
                  <span className="star">*</span>
                  Region: Required when Assign Type is "ASSIGN BOTH" or "ASSIGN
                  REGIONAL".
                </Form.Text>
              </div>
            </Col>
            <Col md="12">
              <div className="card-category form-category">
                <Form.Text className="text-muted">
                  <span className="star">*</span>
                  Extension: Required when Assign Type is "ASSIGN BOTH" or
                  "ASSIGN EXTENSION".
                </Form.Text>
              </div>
            </Col>
          </Row>
          <Row className="mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="3">
              <Button
                className="btn-fill"
                type="button"
                variant="primary"
                block
                onClick={submitHandle}
              >
                Update
              </Button>
            </Col>
            <Col md="3">
              <Button
                className="btn-outline"
                type="button"
                variant="danger"
                block
                onClick={() => setEdit(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Container>
    </>
  );
};

EditAssign.propTypes = {
  setEdit: PropTypes.func,
  user: PropTypes.string,
  details: PropTypes.object.isRequired,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default EditAssign;
