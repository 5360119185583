import React from "react";
import { Form, Button, Row, Col, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import Route from "routes/Route";
import Select from "react-select";
import RequiredLabel from "ui/RequiredLabel";
import NotificationAlert from "ui/NotificationAlert";
import { useSelector } from "react-redux";
import { checkPermission } from "util/MasterUtil";
import { toast } from "react-toastify";

const EditProduct = ({
  setEdit,
  details,
  responseHandle,
  setResponseTitle,
}) => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showNotification, setShowNotificaion] = React.useState(false);
  const [category, setCategory] = React.useState([]);
  const [subCategory, setSubCategory] = React.useState([]);
  const [color, setColor] = React.useState([]);
  const [brands, setBrands] = React.useState([]);
  const [salesType, setSalesType] = React.useState([]);
  const [units, setUnits] = React.useState([]);
  const [newDetails, setNewDetail] = React.useState({
    item_number: "",
    serial_no: "",
    category: "",
    sub_category: "",
    unit: "",
    price: "",
    brand: "",
    type: "",
    description: "",
    quantity: "",
  });
  const [serialNoState, setSerialNoState] = React.useState(true);
  const [itemNoState, setItemNoState] = React.useState(true);
  const [categoryState, setCategoryState] = React.useState(true);
  const [subCategoryState, setSubCategoryState] = React.useState(true);
  const [priceState, setPriceState] = React.useState(true);
  const [quantityState, setQuantityState] = React.useState(true);
  React.useEffect(() => {
    setNewDetail({
      ...newDetails,
      item_number: details?.item_number,
      serial_no: details?.serial_no,
      category: details?.category?.id,
      sub_category: details?.sub_category?.id,
      unit: details?.unit?.id,
      price: details?.price,
      brand: details?.brand_id,
      type: details?.type,
      description: details?.description,
      quantity: details?.quantity,
    });
  }, [details]);
  const token = localStorage.getItem("token");
  const fetchCategory = async () => {
    const response = await Route.fetch("categories", token);
    if (response.status === 200) {
      setCategory(response?.data?.category);
    }
  };
  const fetchColor = async () => {
    const response = await Route.fetch("colors", token);
    if (response.status === 200) {
      setColor(response?.data?.color);
    }
  };
  const fetchBrands = async () => {
    const response = await Route.fetch("brands", token);
    if (response.status === 200) {
      setBrands(response?.data?.brand);
    }
  };
  const fetchSalesType = async () => {
    const response = await Route.fetch("sale-types", token);
    if (response.status === 200) {
      setSalesType(response?.data?.saleType);
    }
  };
  const fetchUnits = async () => {
    const response = await Route.fetch("units", token);
    if (response.status === 200) {
      setUnits(response?.data?.unit);
    }
  };
  const fetchSubCategories = async (id) => {
    const response = await Route.fetchDetails("edit-categories", token, id);
    if (response.status === 200) {
      setSubCategory(response?.data?.category?.sub_categories);
    }
  };
  React.useEffect(() => {
    fetchCategory();
    fetchColor();
    fetchBrands();
    fetchUnits();
    fetchSalesType();
  }, []);
  const fieldHandle = (name, value) => {
    name === "serial_no"
      ? setSerialNoState(true)
      : name === "item_number"
      ? setItemNoState(true)
      : name === "category"
      ? setCategoryState(true)
      : name === "sub_category"
      ? setSubCategoryState(true)
      : name === "price"
      ? setPriceState(true)
      : name === "quantity"
      ? setQuantityState(true)
      : null;
    name === "category" ? fetchSubCategories(value) : null;
    setNewDetail({
      ...newDetails,
      [name]: value,
    });
  };

  const onConfirm = async () => {
    if (!checkPermission("products.update", permissionsArray)) {
      return setShowNotificaion(true);
    }
    const response = await Route.update(
      "products",
      token,
      details?.id,
      newDetails
    );
    if (response.status === 200) {
      setResponseTitle(response.data.message);
      setEdit(false);
      responseHandle(true);
    } else {
      setResponseTitle(response.data.message);
      setEdit(false);
      responseHandle(false);
    }
  };
  return (
    <>
      <Modal.Body>
        <Modal.Title as="h4">Edit Product</Modal.Title>
        <Row>
          <Col md="4">
            <Form.Group>
              <label>
                Serial No. <span className="star">*</span>
              </label>
              <Form.Control
                placeholder="Enter Serial No."
                type="text"
                name="serial_no"
                defaultValue={details?.serial_no}
                onChange={(e) => fieldHandle(e.target.name, e.target.value)}
              ></Form.Control>
              {serialNoState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <label>
                Item No. <span className="star">*</span>
              </label>
              <Form.Control
                placeholder="Enter Item No."
                type="text"
                defaultValue={details?.item_number}
                name="item_number"
                onChange={(e) => fieldHandle(e.target.name, e.target.value)}
              ></Form.Control>
              {itemNoState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <label>
                Category <span className="star">*</span>
              </label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="categorySelect"
                onChange={(e) => fieldHandle("category", e.value)}
                defaultValue={{
                  value: `${details?.category?.id}`,
                  label: `${details?.category?.sale_type?.name}`,
                }}
                options={category?.map((item) => ({
                  value: `${item.sale_type?.id}`,
                  label: `${item.sale_type?.name}`,
                }))}
                placeholder="Select Category"
              />
              {categoryState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Form.Group>
              <label>
                Sub-Category <span className="star">*</span>
              </label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="subCategorySelect"
                onChange={(e) => fieldHandle("sub_category", e.value)}
                defaultValue={{
                  value: `${details?.sub_category?.id}`,
                  label: `${details?.sub_category?.name}`,
                }}
                options={subCategory?.map((item) => ({
                  value: `${item.id}`,
                  label: `${item.name}`,
                }))}
                placeholder="Select Sub-Category"
              />
              {subCategoryState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <label>Color</label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="colorSelect"
                onChange={(e) => fieldHandle("unit", e.value)}
                defaultValue={{
                  value: `${details?.color?.id}`,
                  label: `${
                    details?.color?.name !== null
                      ? details?.color?.name
                      : "Color Not Assigned"
                  }`,
                }}
                options={color?.map((item) => ({
                  value: `${item.id}`,
                  label: `${item.name}`,
                }))}
                placeholder="Select"
              />
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <label>
                Price <span className="star">*</span>
              </label>
              <Form.Control
                placeholder="Enter Price"
                type="text"
                defaultValue={details?.price}
                name="price"
                onChange={(e) => fieldHandle(e.target.name, e.target.value)}
              ></Form.Control>
              {priceState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Form.Group>
              <label>
                Quantity <span className="star">*</span>
              </label>
              <Form.Control
                placeholder="Enter Quantity"
                type="number"
                name="quantity"
                defaultValue={parseInt(details?.quantity)}
                onChange={(e) => fieldHandle(e.target.name, e.target.value)}
              ></Form.Control>
              {quantityState ? null : <RequiredLabel />}
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <label>Product Types</label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="typeSelect"
                onChange={(e) => fieldHandle("type", e.value)}
                defaultValue={{
                  value: `${details?.sale_type?.id}`,
                  label: `${details?.sale_type?.name}`,
                }}
                options={salesType?.map((item) => ({
                  value: `${item.id}`,
                  label: `${item.name}`,
                }))}
                placeholder="Select"
              />
            </Form.Group>
          </Col>
          {/* <Col md="4">
            <Form.Group>
              <label>Unit</label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="unitSelect"
                onChange={(e) => fieldHandle("unit", e.value)}
                defaultValue={{
                  value: `${details?.unit_id}`,
                  label: `${
                    details?.unit !== null ? details?.unit : "Unit Not Assigned"
                  }`,
                }}
                options={units?.map((item) => ({
                  value: `${item.id}`,
                  label: `${item.name}`,
                }))}
                placeholder="Select"
              />
            </Form.Group>
          </Col> */}
        </Row>
        {/* <Row>
          <Col md="4">
            <Form.Group>
              <label>Brand</label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="brandSelect"
                defaultValue={{
                  value: `${
                    details?.brand_id !== null ? details?.brand_id : null
                  }`,
                  label: `${
                    details?.brand !== null
                      ? details?.brand
                      : "Brand Not Assigned"
                  }`,
                }}
                onChange={(e) => fieldHandle("brand", e.value)}
                options={brands?.map((item) => ({
                  value: `${item.id}`,
                  label: `${item.name}`,
                }))}
                placeholder="Select"
              />
            </Form.Group>
          </Col>
        </Row> */}
        <Row>
          <Col md="12">
            <Form.Group controlId="descriptionTextarea">
              <label>Description</label>
              <Form.Control
                name="description"
                onChange={(e) => fieldHandle(e.target.name, e.target.value)}
                as="textarea"
                rows={3}
                defaultValue={details?.description}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="card-category form-category">
              <span className="star">*</span>
              Required fields
            </div>
          </Col>
        </Row>
        <Row className="mt-4" style={{display: "flex", justifyContent: "flex-end"}}>
          <Col md="2">
            <Button
              className="btn-fill"
              type="submit"
              variant="primary"
              block
              onClick={onConfirm}
            >
              Update
            </Button>
          </Col>
          <Col md="2">
            <Button
              className="btn-outline"
              type="button"
              variant="danger"
              block
              onClick={() => setEdit(false)}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      {showNotification && <NotificationAlert />}
    </>
  );
};
EditProduct.propType = {
  setEdit: PropTypes.func,
  details: PropTypes.object,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default EditProduct;
