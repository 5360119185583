import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import Route from "routes/Route";
import RegionalSaleVoucherInfo from "./RegionalSaleVoucherInfo";
import RegionalSaleVoucherDetail from "./RegionalSaleVoucherDetail";
import RegionalPaymentHistory from "./RegionalPaymentHistory";
import RequiredLabel from "ui/RequiredLabel";
import NotificationAlert from "ui/NotificationAlert";
import {
  formatDateOnly,
  formValidation,
  fetchBankBasedOnIds,
} from "util/CommonUtil";

const RegionalMakePayment = ({
  saleVoucher,
  setMakePayment,
  paymentHistory,
  setResponseTitle,
  responseHandle,
  receiptNo,
  invoicePayment,
}) => {
  const [showNotification, setShowNotificaion] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const [banks, setBanks] = React.useState([]);
  const [paymentMade, setPaymentMade] = React.useState(false);
  const [paymentDetail, setPaymentDetails] = React.useState({
    sale_voucher_id: saleVoucher?.id,
    receipt_no: receiptNo,
    payment_mode: "",
    reference_no: "",
    payment_status: "full",
    bank: "",
    cash_amount_paid: 0,
    online_amount_paid: 0,
    total_amount_paid: 0,
    amount_paid: "",
    payment_date: formatDateOnly(new Date()),
    remarks: "",
    attachment: {},
  });
  const [accountNo, setAccountNo] = React.useState("");
  const [paymentModeState, setPaymentModeState] = React.useState(true);
  const [paymentStatusState, setPaymentStatusState] = React.useState(true);
  const [bankState, setBankState] = React.useState(true);
  const [bankSelect, setBankSelect] = React.useState(false);
  const [paymentDateState, setPaymentDateState] = React.useState(true);
  const [payCash, setPayCash] = React.useState(false);
  const [payOnline, setPayOnline] = React.useState(false);

  const token = localStorage.getItem("token");
  const fetchBank = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let data = {
      region: null,
      extension: null,
    };
    if (user?.assign_and_employee != null) {
      data = {
        region:
          user?.assign_and_employee?.regional_id !== null
            ? user?.assign_and_employee?.regional_id
            : null,
        extension:
          user?.assign_and_employee?.extension_id !== null
            ? user?.assign_and_employee?.extension_id
            : null,
      };
    }
    const response = await Route.fetch("banks", token);
    if (response?.status === 200) {
      setBanks(
        fetchBankBasedOnIds(response?.data?.bank, data?.region, data?.extension)
      );
    }
  };

  React.useEffect(() => {
    fetchBank();
  }, []);

  const paymentModeHandle = (e) => {
    setPaymentModeState(true);
    setPaymentDetails({
      ...paymentDetail,
      payment_mode: e.value,
      cash_amount_paid: 0,
      online_amount_paid: 0,
    });
    setAccountNo("");
    if (e.value === "both") {
      setPayOnline(true);
      setPayCash(true);
      setBankSelect(true);
    } else if (e.value === "cash") {
      setPayCash(true);
      setPayOnline(false);
      setBankSelect(false);
    } else {
      setPayOnline(true);
      setPayCash(false);
      setBankSelect(true);
    }
  };
  const cashPaymentHandle = (e) => {
    setPaymentDetails({
      ...paymentDetail,
      cash_amount_paid: parseFloat(e.target.value),
      total_amount_paid:
        parseFloat(e.target.value) +
        parseFloat(paymentDetail.online_amount_paid),
    });
  };
  const onlinePaymentHandle = (e) => {
    setPaymentDetails({
      ...paymentDetail,
      online_amount_paid: parseFloat(e.target.value),
      total_amount_paid:
        parseFloat(paymentDetail.cash_amount_paid) + parseFloat(e.target.value),
    });
  };
  const referenceNoHandle = (e) => {
    setPaymentDetails({
      ...paymentDetail,
      reference_no: e.target.value,
    });
  };
  const paymentStatusHandle = (e) => {
    setPaymentStatusState(true);
    setPaymentDetails({
      ...paymentDetail,
      payment_status: e.value,
    });
  };
  const bankHandle = (e) => {
    setBankState(true);
    setPaymentDetails({
      ...paymentDetail,
      bank: e.value,
    });
    setAccountNo(e.account_number);
  };
  const paymentDateHandle = (e) => {
    setPaymentDateState(true);
    setPaymentDetails({
      ...paymentDetail,
      payment_date: formatDateOnly(e._d),
    });
  };
  const remarkHandle = (e) => {
    setPaymentDetails({
      ...paymentDetail,
      remarks: e.target.value,
    });
  };
  const fileHandle = (e) => {
    setPaymentDetails({
      ...paymentDetail,
      attachment: e.target.files[0],
    });
  };
  const validationConditions = [
    {
      field: "paymentMode",
      condition: paymentDetail.payment_mode === "",
      setState: setPaymentModeState,
    },
    {
      field: "paymentStatus",
      condition: paymentDetail.payment_status === "",
      setState: setPaymentStatusState,
    },
    {
      field: "paymentDate",
      condition: paymentDetail.payment_date === "",
      setState: setPaymentDateState,
    },
  ];

  const paymentHandle = async () => {
    if (
      parseFloat(saleVoucher?.net_payable) !=
      parseFloat(paymentDetail.total_amount_paid)
    ) {
      setNotificationText("Total Amount Paid Doesn't Match with Net Payable");
      setShowNotificaion(true);
    } else {
      setPaymentMade(true);
      if (formValidation(validationConditions)) {
        let data = new FormData();
        data.append("attachment", paymentDetail.attachment);
        data.append("sale_voucher_id", paymentDetail.sale_voucher_id);
        data.append("receipt_no", paymentDetail.receipt_no);
        data.append("payment_mode", paymentDetail.payment_mode);
        data.append("reference_no", paymentDetail.reference_no);
        data.append("payment_status", paymentDetail.payment_status);
        data.append("bank", paymentDetail.bank);
        data.append("cash_amount_paid", paymentDetail.cash_amount_paid);
        data.append("online_amount_paid", paymentDetail.online_amount_paid);
        data.append("total_amount_paid", paymentDetail.total_amount_paid);
        data.append("amount_paid", paymentDetail.amount_paid);
        data.append("payment_date", paymentDetail.payment_date);
        data.append("remarks", paymentDetail.remarks);

        const response = await Route.uploadFile(
          "regional-payments",
          token,
          data
        );
        if (response?.status === 200) {
          setMakePayment(false);
          setResponseTitle(response?.data?.message);
          responseHandle(true);
        } else {
          setMakePayment(false);
          setResponseTitle(response?.message);
          responseHandle(false);
        }
      }
    }
  };
  return (
    <Container fluid>
      <Card>
        <Card.Header>
          <Card.Title as="h3">Make Payment</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md="6">
              <RegionalSaleVoucherInfo saleVoucher={saleVoucher} />
            </Col>
            <Col md="6">
              <RegionalSaleVoucherDetail saleVoucher={saleVoucher} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h5">Payment</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Row>
                      <Col md="3">
                        <Form.Group>
                          <label>
                            Receipt No. <span className="star">*</span>
                          </label>
                          <Form.Control
                            placeholder="Enter Receipt No."
                            type="text"
                            value={receiptNo}
                            readOnly
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <label>
                            Payment Mode <span className="star">*</span>
                          </label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="paymentModeSelect"
                            onChange={paymentModeHandle}
                            options={[
                              { value: "both", label: "Both" },
                              { value: "cash", label: "Cash" },
                              { value: "online", label: "Online" },
                            ]}
                            placeholder="Payment Mode"
                          />
                          {paymentModeState ? null : <RequiredLabel />}
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <label>Bank</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            isDisabled={!bankSelect}
                            onChange={bankHandle}
                            options={banks?.map((item) => ({
                              value: item?.id,
                              label: item?.name,
                              account_number: item?.account_number,
                            }))}
                            placeholder="Bank"
                          />
                          {bankState ? null : <RequiredLabel />}
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <label>Account No.</label>
                          <Form.Control
                            placeholder="Reference No."
                            type="text"
                            value={accountNo}
                            readOnly
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Form.Group>
                          <label>Cash Amount Paid</label>
                          <Form.Control
                            placeholder="Reference No."
                            type="number"
                            onChange={cashPaymentHandle}
                            value={paymentDetail.cash_amount_paid}
                            readOnly={!payCash}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <label>Online Amount Paid</label>
                          <Form.Control
                            placeholder="Reference No."
                            type="number"
                            onChange={onlinePaymentHandle}
                            value={paymentDetail.online_amount_paid}
                            readOnly={!payOnline}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <label>Total Amount Paid</label>
                          <Form.Control
                            placeholder="Reference No."
                            type="number"
                            readOnly
                            value={
                              parseFloat(paymentDetail.cash_amount_paid) +
                                parseFloat(paymentDetail.online_amount_paid) ===
                              NaN
                                ? 0
                                : parseFloat(paymentDetail.cash_amount_paid) +
                                  parseFloat(paymentDetail.online_amount_paid)
                            }
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <label>Reference No.</label>
                          <Form.Control
                            placeholder="Reference No."
                            type="text"
                            onChange={referenceNoHandle}
                            readOnly={!payOnline}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Form.Group>
                          <label>
                            Payment Date <span className="star">*</span>
                          </label>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Payment Date",
                            }}
                            timeFormat={false}
                            onChange={paymentDateHandle}
                            closeOnSelect
                            initialValue={new Date()}
                          ></ReactDatetime>
                          {paymentDateState ? null : <RequiredLabel />}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.File
                            id="uploadFile"
                            label="Attachment"
                            onChange={fileHandle}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Remarks</label>
                          <Form.Control
                            placeholder="Enter Remarks"
                            type="text"
                            as="textarea"
                            rows={3}
                            onChange={remarkHandle}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="card-category form-category">
                          <span className="star">*</span>
                          Required fields
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <RegionalPaymentHistory
                paymentHistory={paymentHistory}
                invoicePayment={invoicePayment}
              />
            </Col>
          </Row> */}
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="2">
              <Button
                type="button"
                onClick={paymentHandle}
                block
                isDisabled={paymentMade}
              >
                Make Payment
              </Button>
            </Col>
            <Col md="2">
              <Button
                type="button"
                onClick={() => setMakePayment(false)}
                variant="danger"
                className="btn-outline"
                block
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotificaion}
        />
      )}
    </Container>
  );
};

RegionalMakePayment.propType = {
  saleVoucher: PropTypes.object.isRequired,
  setMakePayment: PropTypes.func,
  paymentHistory: PropTypes.object,
  // banks: PropTypes.object,
  setResponseTitle: PropTypes.object,
  responseHandle: PropTypes.object,
  receiptNo: PropTypes.string,
  invoicePayment: PropTypes.number,
};

export default RegionalMakePayment;
