import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import PropTypes from "prop-types";

const NotificationAlert = ({title="You Don't Have Permission", setAlert }) => {
  return (
    <SweetAlert
      info
      confirmBtnText="Okay"
      cancelBtnBsStyle="default"
      title={title}
      onConfirm={() => setAlert(false)}
    >
    </SweetAlert>
  )
};
NotificationAlert.propType = {
  title: PropTypes.string,
  setAlert: PropTypes.func,
};

export default NotificationAlert;

