import React from "react";
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import Route from "routes/Route";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const PaymentTable = () => {
  const token = localStorage.getItem("token");
  const [paymentData, setPaymentData] = React.useState([]);
  const fetchPayment = async()=> {
    const response = await Route.fetch("payments", token);
    if (response?.status === 200) {
      console.log(response?.data?.payments)
      setPaymentData(
        response?.data?.payments?.map((item) => ({
          id: item?.id,
          receipt_no: item?.receipt_no,
          client:  item?.walk_in_customer !== null ? item?.walk_in_customer : item?.customer_name,
          payment_mode: item?.payment_mode,
          paid_at: item?.paid_at,
          total_amount_paid: item?.total_amount_paid,
        }))
      )
    };
  };
  React.useEffect(() => {
    fetchPayment();
  },[]);
  const columns = [
    {
      dataField: "receipt_no",
      text: "Receipt No.",
      sort: true,
    },
    {
      dataField: "client",
      text: "Client",
      sort: true,
    },
    {
      dataField: "payment_mode",
      text: "Payment Mode",
      sort: true,
    },
    {
      dataField: "total_amount_paid",
      text: "Total(Nu.)",
      sort: true,
    },
  ];
  const defaultSorted = [
    {
      dataField: "user",
      order: "desc",
    },
  ];
  return (
    <Row>
      <Col md="12">
        <Card>
          <Card.Header>
            <Card.Title as="h4">Payments</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md="12">
                <BootstrapTable
                  keyField="id"
                  data={paymentData}
                  columns={columns}
                  noDataIndication="Table is Empty"
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default PaymentTable;
