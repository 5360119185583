import React from "react";
import { Alert, Button, Card, Form, Container, Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import Route from "routes/Route";
import ReactTable from "components/ReactTable/ReactTable.js";
import { onHandItemTableHeader } from "data/dummy";
import { formatDateForReport } from "util/CommonUtil";
import { exportToExcel  } from "react-json-to-excel";

const OnHandItem = () => {
  const [onHandItems, setOnHandItems] = React.useState([]);
  const [exportData, setExportData] = React.useState([]);
  const [productTypes, setProductTypes] = React.useState([]);
  const [productTypeSelected, setProdutTypeSelected] = React.useState({
    value: "ALL",
    label: "ALL",
  });
  const [storeSelected, setStoreSelected] = React.useState({
    value: "ALL",
    label: "ALL",
  });
  const [regionSelected, setRegionSelected] = React.useState({
    value: "ALL",
    label: "ALL",
  });
  const [extensionSelected, setExtensionSelected] = React.useState({
    value: "ALL",
    label: "ALL",
  });
  const [showRegionSelect, setShowRegionSelect] = React.useState(false);
  const [regions, setRegions] = React.useState([]);
  const [extensions, setExtensions] = React.useState([]);
  const fromDateRef = React.useRef(null);
  const endDateRef = React.useRef(null);
  const token = localStorage.getItem("token");

  const [data, setData] = React.useState({
    category_id: "ALL",
    regional_id: "ALL",
    region_extension_id: "ALL",
    from_date: "2023-01-01",
    to_date: formatDateForReport(new Date()),
  });
  const storesData = [
    {
      value: "ALL",
      label: "ALL",
    },
    {
      value: "main",
      label: "Main",
    },
    {
      value: "Region",
      label: "Region",
    },
  ];
  const [view, setView] = React.useState(false);
  const [itemDetail, setItemDetail] = React.useState({});
  const viewHandle = (item) => {
    setItemDetail(item);
    setView(true);
  };
  const fetchOnHandItems = async () => {
    const response = await Route.fetchReports("onhanditems", token, data);
    if (response?.status === 200) {
      setExportData(response?.data?.onhand?.map((item) => {
        return {
          item_number: item?.item_number,
          serial_no: item?.serial_no,
          created_date:item?.created_date,
          subInventory: item?.subInventory,
          locator: item?.locator,
          iccid: item?.iccid,
          total_qty: parseInt(item?.store_qty),
          description: item?.description,
        };
      }));
      setOnHandItems(
        response?.data?.onhand?.map((item) => {
          return {
            item_number: item?.item_number,
            serial_no: item?.serial_no,
            created_date:item?.created_date,
            subInventory: item?.subInventory,
            locator: item?.locator,
            iccid: item?.iccid,
            total_qty: parseInt(item?.store_qty),
            description: item?.description,
            action: (
              <div className="actions-right">
                <Button variant="primary" size="sm" className="text-primary btn-link" onClick={() => viewHandle(item)}>
                  <i className="fa fa-eye"></i>
                </Button>
              </div>
            ),
          };
        })
      );
    }
  };
  const fetchProductTypes = async () => {
    const response = await Route.fetch("sale-types", token);
    if (response?.status === 200) {
      setProductTypes(response?.data?.saleType);
    }
  };
  const storeHandle = (e) => {
    setStoreSelected((prev) => ({
      ...prev,
      value: e.value,
      label: e.label,
    }));
    if (e.value === "ALL") {
      setShowRegionSelect(false);
      setData((prev) => ({
        ...prev,
        regional_id: e.value,
        region_extension_id: e.value,
      }));
    } else if (e.value === "main") {
      setShowRegionSelect(false);
      setData((prev) => ({
        ...prev,
        regional_id: "",
        region_extension_id: "",
      }));
    } else {
      setShowRegionSelect(true);
    }
  };
  const fetchRegions = async () => {
    const response = await Route.fetch("regions", token);
    if (response?.status === 200) {
      setRegions(response?.data?.region);
    }
  };
  React.useEffect(() => {
    fetchOnHandItems();
    fetchProductTypes();
    fetchRegions();
  }, []);
  const productTypeHandle = (e) => {
    setProdutTypeSelected({
      value: e.value,
      label: e.label,
    });
    setData((prev) => ({
      ...prev,
      category_id: e.value,
    }));
  };
  const regionHandle = (e) => {
    setRegionSelected((prev) => ({
      ...prev,
      value: e.value,
      label: e.label,
    }));
    setData((prev) => ({
      ...prev,
      regional_id: e.value,
    }));
  };
  const fetchExtensionBasedOnRegion = async () => {
    const response = await Route.fetchDetails(
      "edit-regions",
      token,
      data?.regional_id
    );
    if (response.status === 200) {
      setExtensions(response?.data?.Region?.extensions);
    }
  };
  React.useEffect(() => {
    if (data?.regional_id !== "" && data?.regional_id !== "ALL") {
      fetchExtensionBasedOnRegion();
    }
  }, [data?.regional_id]);
  const extensionHandle = (e) => {
    setExtensionSelected((prev) => ({
      ...prev,
      value: e.value,
      label: e.label,
    }));
    setData((prev) => ({
      ...prev,
      regional_id: "ALL",
      region_extension_id: e.value,
    }));
  };
  const startDateHandle = (e) => {
    setData((prev) => ({
      ...prev,
      from_date: formatDateForReport(e._d),
    }));
  };
  const endDateHandle = (e) => {
    setData((prev) => ({
      ...prev,
      to_date: formatDateForReport(e._d),
    }));
  };
  const clearHandle = () => {
    setProdutTypeSelected((prev) => ({
      ...prev,
      value: "ALL",
      label: "ALL",
    }));
    setStoreSelected((prev) => ({
      ...prev,
      value: "ALL",
      label: "ALL",
    }));
    setShowRegionSelect(false);
    setRegionSelected((prev) => ({
      ...prev,
      value: "ALL",
      label: "ALL",
    }));
    setExtensionSelected((prev) => ({
      ...prev,
      value: "ALL",
      label: "ALL",
    }));
    if (fromDateRef.current) {  
      fromDateRef.current.state.selectedDate = null;
      fromDateRef.current.setState({ inputValue: "" });
    }
    if (endDateRef.current) {
      endDateRef.current.state.selectedDate = null;
      endDateRef.current.setState({ inputValue: "" });
    }
    setData((prev) => ({
      ...prev,
      category_id: "ALL",
      regional_id: "ALL",
      region_extension_id: "ALL",
      from_date: "2023-01-01",
      to_date: formatDateForReport(new Date()),
    }));
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Body>
                <Card.Title as="h4">On Hand Item</Card.Title>
                <Form>
                  <Row>
                    <Col md="2">
                      <Form.Group>
                        <label>Product Type</label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="productTypeSelect"
                          value={productTypeSelected}
                          onChange={(e) => productTypeHandle(e)}
                          options={productTypes?.map((item) => ({
                            value: item?.id,
                            label: item?.name,
                          }))}
                          placeholder="Product Type"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <Form.Group>
                        <label>Stores</label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="storeSelect"
                          value={storeSelected}
                          onChange={(e) => storeHandle(e)}
                          options={storesData?.map((item) => ({
                            value: item?.value,
                            label: item?.label,
                          }))}
                          placeholder="Store"
                        />
                      </Form.Group>
                    </Col>
                    {showRegionSelect && (
                      <>
                        <Col md="2">
                          <Form.Group>
                            <label>Region</label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              name="regionSelect"
                              value={regionSelected}
                              onChange={(e) => regionHandle(e)}
                              options={regions?.map((item) => ({
                                value: item?.id,
                                label: item?.name,
                              }))}
                              placeholder="Region"
                            />
                          </Form.Group>
                        </Col>
                        <Col md="2">
                          <Form.Group>
                            <label>Extension</label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              name="regionSelect"
                              value={extensionSelected}
                              onChange={(e) => extensionHandle(e)}
                              options={extensions?.map((item) => ({
                                value: item?.id,
                                label: item?.name,
                              }))}
                              placeholder="Extension"
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}
                    <Col md="2">
                      <Form.Group>
                        <label>From Date</label>
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: "Select From Date",
                          }}
                          closeOnSelect
                          timeFormat={false}
                          ref={fromDateRef}
                          onChange={startDateHandle}
                        ></ReactDatetime>
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <Form.Group>
                        <label>To Date</label>
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: "Select To Date",
                          }}
                          closeOnSelect
                          timeFormat={false}
                          ref={endDateRef}
                          onChange={endDateHandle}
                        ></ReactDatetime>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Col md="2" style={{ marginTop: "28px" }}>
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="primary"
                        onClick={fetchOnHandItems}
                        block
                      >
                        Search
                      </Button>
                    </Col>
                    <Col md="2" style={{ marginTop: "28px" }}>
                      <Button
                        className="btn-outline"
                        type="button"
                        variant="info"
                        onClick={clearHandle}
                        block
                      >
                        Clear
                      </Button>
                    </Col>
                    <Col md="2" style={{ marginTop: "28px" }}>
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="success"
                        onClick={() => exportToExcel(exportData, "On Hand Item Report")}
                        block
                      >
                        Download
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    {
                    onHandItems?.length > 0 ? 
                    <ReactTable
                        data={onHandItems}
                        columns={onHandItemTableHeader}
                        className="-striped -highlight primary-pagination"
                      />
                    : <Alert variant="primary">
                    <span>No Information to Display</span>
                  </Alert>
                    }
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {view && (
        <Modal show={view} onHide={() => setView(false)}>
          <Modal.Header closeButton>
            <Modal.Title>On Hand Item Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md="6">
                <label>Item Number</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Item Number"
                    type="text"
                    value={itemDetail?.item_number}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="6">
                <label>Serial Number</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Serial No"
                    type="text"
                    value={itemDetail?.serial_no}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <label>Sub-Inventory</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Sub-Inventory"
                    type="text"
                    value={itemDetail?.sub_inventory}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="6">
                <label>Locator</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Locator"
                    type="text"
                    value={itemDetail?.locator}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <label>ICCID</label>
                <Form.Group>
                  <Form.Control
                    placeholder="ICCID"
                    type="text"
                    value={itemDetail?.iccid}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="6">
                <label>Qty</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Qty"
                    type="text"
                    value={parseInt(itemDetail?.store_qty)}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Description</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Description"
                    type="text"
                    value={itemDetail?.description}
                    readOnly
                    as="textarea"
                    rows={3}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "flex-end" }} className="my-4">
              <Col md="6">
                <Button
                  variant="danger"
                  className="btn-outline"
                  onClick={() => setView(false)}
                  block
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default OnHandItem;
