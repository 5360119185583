import Dashboard from "views/Dashboard.js";
import Permission from "views/Permission";
import Users from "views/Users";
import Role from "views/Role";
import ActivityLogs from "views/ActivityLogs";
import Regions from "views/Regions";
// import Brands from "views/Brands";
import Dzongkhag from "views/Dzongkhag";
import Gewogs from "views/Gewogs";
import Villages from "views/Villages";
import Assign from "views/Assign";
import Category from "views/Category";
import Banks from "views/Banks";
// import Store from "views/Store";
// import Employee from "views/Employee";
// import Unit from "views/Unit";
import SalesType from "views/SalesType";
import Product from "views/Product";
// import PromotionType from "views/PromotionType";
import CustomerType from "views/CustomerType";
import DiscountType from "views/DiscountType";
import Customers from "views/Customers";
import Color from "views/Color";
import Warranty from "views/Warranty";
import MainStoreTransaction from "views/MainStoreTransaction";
import MainStoreSaleVoucher from "views/MainStoreSaleVoucher";
import AcknowledgeRegionalTransfer from "views/AcknowledgeRegionalTransfer";
import RegionalStoreTransaction from "views/RegionalStoreTransaction";
import RegionStoreSaleVoucher from "views/RegionStoreSaleVoucher";
import AcknowledgeExtensionTransfer from "views/AcknowledgeExtensionTransfer";
// import ExtensionTransfer from "views/ExtensionTransfer";
import ExtensionStoreTransaction from "views/ExtensionStoreTransaction";
import ExtensionSaleVoucher from "views/ExtensionSaleVoucher";
import PostedSalesInvoice from "views/Reports/PostedSalesInvoice";
import OnHandItem from "views/Reports/OnHandItem";
import SalesAndStocks from "views/Reports/SalesAndStocks";
import SalesOrderList from "views/Reports/SalesOrderList";
import CashReceipt from "views/Reports/CashReceipt";
import OnlineReceipt from "views/Reports/OnlineReceipt";
import EmiReport from "views/Reports/EmiReport";
import TransferProductReport from "views/Reports/TransferProductReport";
// import SalesOrderReport from "views/SalesOrderReport";
// import SalesReturnReport from "views/SalesReturnReport";
// import SalesReport from "views/SalesReport";
import CheckWarranty from "views/CheckWarranty";
import Emi from "views/Emi";
import ApproveEmi from "views/ApproveEmi";
// import Wizard from "views/Forms/Wizard/Wizard.js";
import Requisition from "views/Requisition";
import ExtensionRequisition from "views/ExtensionRequisition";
// import ExtendedTables from "views/Tables/ExtendedTables.js";
// import ReactTables from "views/Tables/ReactTables.js";
import UserPage from "views/Pages/UserPage.js";
import ChangePassword from "views/ChangePassword";

// ================================ DEVELOPER MENU ===============================================
const developerRoutes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
  },
  {
    collapse: true,
    path: "/components",
    name: "System Setting",
    state: "openSystemSetting",
    icon: "nc-icon nc-settings-gear-64",
    views: [
      {
        path: "/permission",
        layout: "/admin",
        name: "Permission",
        mini: "fa fa-angle-right",
        component: Permission,
      },
      {
        path: "/roles",
        layout: "/admin",
        name: "Roles",
        mini: "fa fa-angle-right",
        component: Role,
      },
      {
        path: "/users",
        layout: "/admin",
        name: "Users",
        mini: "fa fa-angle-right",
        component: Users,
      },
      {
        path: "/activityLogs",
        layout: "/admin",
        name: "Activity Logs",
        mini: "fa fa-angle-right",
        component: ActivityLogs,
      },
    ],
  },
  {
    collapse: true,
    path: "/components",
    name: "Masters",
    state: "openMasters",
    icon: "nc-icon nc-bullet-list-67",
    views: [
      {
        path: "/regions",
        layout: "/admin",
        name: "Regions & Extensions",
        mini: "fa fa-angle-right",
        component: Regions,
      },
      {
        path: "/assign",
        layout: "/admin",
        name: "Assign",
        mini: "fa fa-angle-right",
        component: Assign,
      },
      {
        path: "/dzongkhag",
        layout: "/admin",
        name: "Dzongkhags",
        mini: "fa fa-angle-right",
        component: Dzongkhag,
      },
      {
        path: "/gewogs",
        layout: "/admin",
        name: "Gewogs",
        mini: "fa fa-angle-right",
        component: Gewogs,
      },
      {
        path: "/villages",
        layout: "/admin",
        name: "Villages",
        mini: "fa fa-angle-right",
        component: Villages,
      },
      {
        path: "/productType",
        layout: "/admin",
        name: "Product Type",
        mini: "fa fa-angle-right",
        component: SalesType,
      },
      {
        path: "/category",
        layout: "/admin",
        name: "Category & Sub-Category",
        mini: "fa fa-angle-right",
        component: Category,
      },
      {
        path: "/banks",
        layout: "/admin",
        name: "Banks",
        mini: "fa fa-angle-right",
        component: Banks,
      },
      {
        path: "/color",
        layout: "/admin",
        name: "Color",
        mini: "fa fa-angle-right",
        component: Color,
      },
      // {
      //   path: "/warranty",
      //   layout: "/admin",
      //   name: "Warranty",
      //   mini: "fa fa-angle-right",
      //   component: Warranty,
      // }
    ],
  },
  {
    collapse: true,
    path: "/components",
    name: "Dealer",
    state: "openDealer",
    icon: "nc-icon nc-delivery-fast",
    views: [
      {
        path: "/customer-type",
        layout: "/admin",
        name: "Customer Type",
        mini: "fa fa-angle-right",
        component: CustomerType,
      },
      {
        path: "/discount-type",
        layout: "/admin",
        name: "Discount Type",
        mini: "fa fa-angle-right",
        component: DiscountType,
      },
      {
        path: "/customers",
        layout: "/admin",
        name: "Customers",
        mini: "fa fa-angle-right",
        component: Customers,
      },
    ],
  },
  {
    collapse: true,
    path: "/components",
    name: "Inventory",
    state: "openInventory",
    icon: "nc-icon nc-app",
    views: [
      {
        path: "/product",
        layout: "/admin",
        name: "Product",
        mini: "fa fa-angle-right",
        component: Product,
      },
    ],
  },
  {
    collapse: true,
    path: "/requisition",
    name: "Requisition",
    state: "openRequisition",
    icon: "nc-icon nc-notes",
    views: [
      {
        path: "/requisition",
        layout: "/admin",
        name: "Requisition",
        mini: "fa fa-angle-right",
        component: Requisition,
      },
      {
        path: "/extension-requisition",
        layout: "/admin",
        name: "Ext. Requisition",
        mini: "fa fa-angle-right",
        component: ExtensionRequisition,
      },
    ],
  },
  {
    collapse: true,
    path: "/components",
    name: "Main Store",
    state: "openMainStore",
    icon: "nc-icon nc-bank",
    views: [
      {
        path: "/main-store-transaction",
        layout: "/admin",
        name: "Transfer Product",
        mini: "fa fa-angle-right",
        component: MainStoreTransaction,
      },
      {
        path: "/main-store-sale-voucher",
        layout: "/admin",
        name: "Sale Voucher",
        mini: "fa fa-angle-right",
        component: MainStoreSaleVoucher,
      },
    ],
  },
  {
    collapse: true,
    path: "/components",
    name: "Regional Store",
    state: "openRegionalStore",
    icon: "nc-icon nc-istanbul",
    views: [
      {
        path: "/regional-store-transaction",
        layout: "/admin",
        name: "Transfer Product",
        mini: "fa fa-angle-right",
        component: RegionalStoreTransaction,
      },
      {
        path: "/receive-product",
        layout: "/admin",
        name: "Recieve Product",
        mini: "fa fa-angle-right",
        component: AcknowledgeRegionalTransfer,
      },
      {
        path: "/region_store_sale_voucher",
        layout: "/admin",
        name: "Sale Voucher",
        mini: "fa fa-angle-right",
        component: RegionStoreSaleVoucher,
      },
    ],
  },
  {
    collapse: true,
    path: "/components",
    name: "Extension Store",
    state: "openExtensionStore",
    icon: "nc-icon nc-bag",
    views: [
      {
        path: "/extension-transfer-product",
        layout: "/admin",
        name: "Transfer Product",
        mini: "fa fa-angle-right",
        component: ExtensionStoreTransaction,
      },
      {
        path: "/acknowlege-product",
        layout: "/admin",
        name: "Receive Product",
        mini: "fa fa-angle-right",
        component: AcknowledgeExtensionTransfer,
      },
      {
        path: "/extension-sale-voucher",
        layout: "/admin",
        name: "Sale Voucher",
        mini: "fa fa-angle-right",
        component: ExtensionSaleVoucher,
      },
    ],
  },
  {
    collapse: true,
    path: "/components",
    name: "Sales & Order",
    state: "openSOMS",
    icon: "nc-icon nc-cart-simple",
    views: [
      {
        path: "/check-warranty",
        layout: "/admin",
        name: "Check Warranty",
        mini: "fa fa-angle-right",
        component: CheckWarranty,
      },
      {
        path: "/emi",
        layout: "/admin",
        name: "EMI",
        mini: "fa fa-angle-right",
        component: Emi,
      },
      {
        path: "/approve-emi",
        layout: "/admin",
        name: "Approve EMI",
        mini: "fa fa-angle-right",
        component: ApproveEmi,
      },
    ],
  },
  {
    collapse: true,
    path: "/components",
    name: "Reports",
    state: "openReports",
    icon: "nc-icon nc-chart-bar-32",
    views: [
      {
        path: "/posted-sales-invoice",
        layout: "/admin",
        name: "Posted Sales Invoice",
        mini: "fa fa-angle-right",
        component: PostedSalesInvoice,
      },
      {
        path: "/on-hand-item",
        layout: "/admin",
        name: "On Hand Item",
        mini: "fa fa-angle-right",
        component: OnHandItem,
      },
      {
        path: "/sales-and-stocks",
        layout: "/admin",
        name: "Stocks List",
        mini: "fa fa-angle-right",
        component: SalesAndStocks,
      },
      {
        path: "/sales-order-list",
        layout: "/admin",
        name: "Sales Order List",
        mini: "fa fa-angle-right",
        component: SalesOrderList,
      },
      {
        path: "/cash-receipt",
        layout: "/admin",
        name: "Cash Receipt",
        mini: "fa fa-angle-right",
        component: CashReceipt,
      },
      {
        path: "/online-receipt",
        layout: "/admin",
        name: "Online Receipt",
        mini: "fa fa-angle-right",
        component: OnlineReceipt,
      },
      {
        path: "/emi-report",
        layout: "/admin",
        name: "EMI Report",
        mini: "fa fa-angle-right",
        component: EmiReport,
      },
      {
        path: "/transfer-product-report",
        layout: "/admin",
        name: "Transfer Product Report",
        mini: "fa fa-angle-right",
        component: TransferProductReport
      },
      // {
      //   path: "/sales-return-reports",
      //   layout: "/admin",
      //   name: "Sales Return Report",
      //   mini: "fa fa-angle-right",
      //   component: SalesReturnReport
      // },
      // {
      //   path: "/sales-reports",
      //   layout: "/admin",
      //   name: "Sales Report",
      //   mini: "fa fa-angle-right",
      //   component: SalesReport
      // },
      // {
      //   path: "/emi-reports",
      //   layout: "/admin",
      //   name: "EMI Report",
      //   mini: "fa fa-angle-right",
      //   component: ExtendedTables
      // },
    ],
  },
  {
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "nc-icon nc-puzzle-10",
    views: [
      // {
      //   path: "/profile",
      //   layout: "/admin",
      //   name: "User Page",
      //   mini: "fa fa-angle-right",
      //   component: UserPage
      // },
      {
        path: "/change-password",
        layout: "/admin",
        name: "Change Password",
        mini: "fa fa-angle-right",
        component: ChangePassword
      },
    ]
  }
];

// ================================== MAIN MENU ====================================================
const dashboard = {
  path: "/dashboard",
  layout: "/admin",
  name: "Dashboard",
  icon: "nc-icon nc-chart-pie-35",
  component: Dashboard,
};
const systemSettingMenu = {
  collapse: true,
  path: "/components",
  name: "System Setting",
  state: "openSystemSetting",
  icon: "nc-icon nc-settings-gear-64",
  views: [],
};
const masterMenu = {
  collapse: true,
  path: "/components",
  name: "Masters",
  state: "openMasters",
  icon: "nc-icon nc-bullet-list-67",
  views: [],
};
const inventoryMenu = {
  collapse: true,
  path: "/components",
  name: "Inventory",
  state: "openInventory",
  icon: "nc-icon nc-app",
  views: [],
};
const requisitionMenu = {
  collapse: true,
  path: "/requisition",
  name: "Requisition",
  state: "openRequisition",
  icon: "nc-icon nc-notes",
  views: [],
};
const mainStoreMenu = {
  collapse: true,
  path: "/components",
  name: "Main Store",
  state: "openMainStore",
  icon: "nc-icon nc-bank",
  views: [],
};
const regionalStoreMenu = {
  collapse: true,
  path: "/components",
  name: "Regional Store",
  state: "openRegionalStore",
  icon: "nc-icon nc-istanbul",
  views: [],
};
const extensionStoreMenu = {
  collapse: true,
  path: "/components",
  name: "Extension Store",
  state: "openExtensionStore",
  icon: "nc-icon nc-bag",
  views: [],
};
const dealerMenu = {
  collapse: true,
  path: "/components",
  name: "Dealer",
  state: "openDealer",
  icon: "nc-icon nc-delivery-fast",
  views: [],
};
const somsMenu = {
  collapse: true,
  path: "/components",
  name: "Sales & Order",
  state: "openSOMS",
  icon: "nc-icon nc-cart-simple",
  views: [],
};
const reportsMenu = {
  collapse: true,
  path: "/components",
  name: "Reports",
  state: "openReports",
  icon: "nc-icon nc-chart-bar-32",
  views: [],
};
const pages = {
  collapse: true,
  path: "/pages",
  name: "Pages",
  state: "openPages",
  icon: "nc-icon nc-puzzle-10",
  views: [
    // {
    //   path: "/profile",
    //   layout: "/admin",
    //   name: "User Page",
    //   mini: "UP",
    //   component: UserPage
    // },
    {
      path: "/change-password",
      layout: "/admin",
      name: "Change Password",
      mini: "CP",
      component: ChangePassword
    },
  ]
}
// ================================================= SUB MENU ==========================================
const systemSettingSubMenu = {
  "activity-logs": {
    path: "/activityLogs",
    layout: "/admin",
    name: "Activity Logs",
    mini: "fa fa-angle-right",
    component: ActivityLogs,
  },
  permission: {
    path: "/permission",
    layout: "/admin",
    name: "Permission",
    mini: "fa fa-angle-right",
    component: Permission,
  },
  users: {
    path: "/users",
    layout: "/admin",
    name: "Users",
    mini: "fa fa-angle-right",
    component: Users,
  },
  roles: {
    path: "/roles",
    layout: "/admin",
    name: "Roles",
    mini: "fa fa-angle-right",
    component: Role,
  },
};
const mastersSubMenu = {
  regions: {
    path: "/regions",
    layout: "/admin",
    name: "Regions & Extensions",
    mini: "fa fa-angle-right",
    component: Regions,
  },
  assignings: {
    path: "/assign",
    layout: "/admin",
    name: "Assign",
    mini: "fa fa-angle-right",
    component: Assign,
  },
  // "brands": {
  //   path: "/brands",
  //   layout: "/admin",
  //   name: "Brands",
  //   mini: "fa fa-angle-right",
  //   component: Brands
  // },
  dzongkhags: {
    path: "/dzongkhag",
    layout: "/admin",
    name: "Dzongkhags",
    mini: "fa fa-angle-right",
    component: Dzongkhag,
  },
  gewogs: {
    path: "/gewogs",
    layout: "/admin",
    name: "Gewogs",
    mini: "fa fa-angle-right",
    component: Gewogs,
  },
  villages: {
    path: "/villages",
    layout: "/admin",
    name: "Villages",
    mini: "fa fa-angle-right",
    component: Villages,
  },
  "sale-types": {
    path: "/productType",
    layout: "/admin",
    name: "Product Type",
    mini: "fa fa-angle-right",
    component: SalesType,
  },
  categories: {
    path: "/category",
    layout: "/admin",
    name: "Category & Sub-Category",
    mini: "fa fa-angle-right",
    component: Category,
  },
  banks: {
    path: "/banks",
    layout: "/admin",
    name: "Banks",
    mini: "fa fa-angle-right",
    component: Banks,
  },
  colors: {
    path: "/color",
    layout: "/admin",
    name: "Color",
    mini: "fa fa-angle-right",
    component: Color,
  },
};
const inventorySubMenu = {
  products: {
    path: "/product",
    layout: "/admin",
    name: "Product",
    mini: "fa fa-angle-right",
    component: Product,
  },
};
const requisitionSubMenu = {
  requisitions: {
    path: "/requisition",
    layout: "/admin",
    name: "Requisition",
    mini: "fa fa-angle-right",
    component: Requisition,
  },
  "extension-requisitions": {
    path: "/extension-requisition",
    layout: "/admin",
    name: "Ext. Requisition",
    mini: "fa fa-angle-right",
    component: ExtensionRequisition,
  },
};
const mainStoreSubMenu = {
  "main-stores": {
    path: "/main-store-transaction",
    layout: "/admin",
    name: "Transfer Product",
    mini: "fa fa-angle-right",
    component: MainStoreTransaction,
  },
  "main-store-sales": {
    path: "/main-store-sale-voucher",
    layout: "/admin",
    name: "Sale Voucher",
    mini: "fa fa-angle-right",
    component: MainStoreSaleVoucher,
  },
};
const regionalStoreSubMenu = {
  "regional-transfers": {
    path: "/regional-store-transaction",
    layout: "/admin",
    name: "Transfer Product",
    mini: "fa fa-angle-right",
    component: RegionalStoreTransaction,
  },
  "regional-stores": {
    path: "/recieve-product",
    layout: "/admin",
    name: "Recieve Product",
    mini: "fa fa-angle-right",
    component: AcknowledgeRegionalTransfer,
  },
  "region-store-sales": {
    path: "/region_store_sale_voucher",
    layout: "/admin",
    name: "Sale Voucher",
    mini: "fa fa-angle-right",
    component: RegionStoreSaleVoucher,
  },
};
const extensionStoreSubMenu = {
  "extension-stores": {
    path: "/acknowlege-product",
    layout: "/admin",
    name: "Receive Product",
    mini: "fa fa-angle-right",
    component: AcknowledgeExtensionTransfer,
  },
  "extension-transfers": {
    path: "/extension-transfer-product",
    layout: "/admin",
    name: "Transfer Product",
    mini: "fa fa-angle-right",
    component: ExtensionStoreTransaction,
  },
  "extension-store-sales": {
    path: "/extension-sale-voucher",
    layout: "/admin",
    name: "Sale Voucher",
    mini: "fa fa-angle-right",
    component: ExtensionSaleVoucher,
  },
};
const dealerSubMenu = {
  "customer-types": {
    path: "/customer-type",
    layout: "/admin",
    name: "Customer Type",
    mini: "fa fa-angle-right",
    component: CustomerType,
  },
  "discount-types": {
    path: "/discount-type",
    layout: "/admin",
    name: "Discount Type",
    mini: "fa fa-angle-right",
    component: DiscountType,
  },
  customers: {
    path: "/customers",
    layout: "/admin",
    name: "Customers",
    mini: "fa fa-angle-right",
    component: Customers,
  },
};
const somsSubMenu = {
  warranties: {
    path: "/check-warranty",
    layout: "/admin",
    name: "Check Warranty",
    mini: "fa fa-angle-right",
    component: CheckWarranty,
  },
  "apply-emi": {
    path: "/emi",
    layout: "/admin",
    name: "EMI",
    mini: "fa fa-angle-right",
    component: Emi,
  },
  "approve-emi": {
    path: "/approve-emi",
    layout: "/admin",
    name: "Approve EMI",
    mini: "fa fa-angle-right",
    component: ApproveEmi,
  },
};
const reportsSubMenu = {
  postedsalesinvoice: {
    path: "/posted-sales-invoice",
    layout: "/admin",
    name: "Posted Sales Invoice",
    mini: "fa fa-angle-right",
    component: PostedSalesInvoice,
  },
  onhanditems: {
    path: "/on-hand-item",
    layout: "/admin",
    name: "On Hand Item",
    mini: "fa fa-angle-right",
    component: OnHandItem,
  },
  salesandstocks: {
    path: "/sales-and-stocks",
    layout: "/admin",
    name: "Stocks List",
    mini: "fa fa-angle-right",
    component: SalesAndStocks,
  },
  salesorderlist: {
    path: "/sales-order-list",
    layout: "/admin",
    name: "Sales Order List",
    mini: "fa fa-angle-right",
    component: SalesOrderList,
  },
  cashreceipt: {
    path: "/cash-receipt",
    layout: "/admin",
    name: "Cash Receipt",
    mini: "fa fa-angle-right",
    component: CashReceipt,
  },
  onlinereceipt: {
    path: "/online-receipt",
    layout: "/admin",
    name: "Online Receipt",
    mini: "fa fa-angle-right",
    component: OnlineReceipt,
  },
  "staff-emi": {
    path: "/emi-report",
    layout: "/admin",
    name: "EMI Report",
    mini: "fa fa-angle-right",
    component: EmiReport,
  },
};
// ============================ FUNCTION TO ASSIGN MENU & SUBMENU BASED ON ROLE & PERMISSION ======================
export const sideBarLink = (roles, permissionArray) => {
  let sideBarRoutes = [];
  if (localStorage.getItem("user")) {
    if (roles[0]?.name === "Developer") {
      sideBarRoutes = developerRoutes;
    } else {
      sideBarRoutes.push(dashboard);
      permissionArray.map((item) => {
        const system = systemSettingSubMenu[item];
        const master = mastersSubMenu[item];
        const inventory = inventorySubMenu[item];
        const requisition = requisitionSubMenu[item];
        const mainStore = mainStoreSubMenu[item];
        const regionalStore = regionalStoreSubMenu[item];
        const extensionStore = extensionStoreSubMenu[item];
        const dealer = dealerSubMenu[item];
        const soms = somsSubMenu[item];
        const reports = reportsSubMenu[item];
        if (system && !systemSettingMenu.views.includes(system)) {
          systemSettingMenu.views.push(system);
          if (system && !sideBarRoutes.includes(systemSettingMenu)) {
            sideBarRoutes.push(systemSettingMenu);
          }
        }
        if (master && !masterMenu.views.includes(master)) {
          masterMenu.views.push(master);
          if (master && !sideBarRoutes.includes(masterMenu)) {
            sideBarRoutes.push(masterMenu);
          }
        }
        if (dealer && !dealerMenu.views.includes(dealer)) {
          dealerMenu.views.push(dealer);
          if (dealer && !sideBarRoutes.includes(dealerMenu)) {
            sideBarRoutes.push(dealerMenu);
          }
        }
        if (inventory && !inventoryMenu.views.includes(inventory)) {
          inventoryMenu.views.push(inventory);
          if (inventory && !sideBarRoutes.includes(inventoryMenu)) {
            sideBarRoutes.push(inventoryMenu);
          }
        }
        if (requisition && !requisitionMenu.views.includes(requisition)) {
          requisitionMenu.views.push(requisition);
          if (requisition && !sideBarRoutes.includes(requisitionMenu)) {
            sideBarRoutes.push(requisitionMenu);
          }
        }
        if (mainStore && !mainStoreMenu.views.includes(mainStore)) {
          mainStoreMenu.views.push(mainStore);
          if (mainStore && !sideBarRoutes.includes(mainStoreMenu)) {
            sideBarRoutes.push(mainStoreMenu);
          }
        }
        if (regionalStore && !regionalStoreMenu.views.includes(regionalStore)) {
          regionalStoreMenu.views.push(regionalStore);
          if (regionalStore && !sideBarRoutes.includes(regionalStoreMenu)) {
            sideBarRoutes.push(regionalStoreMenu);
          }
        }
        if (
          extensionStore &&
          !extensionStoreMenu.views.includes(extensionStore)
        ) {
          extensionStoreMenu.views.push(extensionStore);
          if (extensionStore && !sideBarRoutes.includes(extensionStoreMenu)) {
            sideBarRoutes.push(extensionStoreMenu);
          }
        }
        if (soms && !somsMenu.views.includes(soms)) {
          somsMenu.views.push(soms);
          if (soms && !sideBarRoutes.includes(somsMenu)) {
            sideBarRoutes.push(somsMenu);
          }
        }
        if (reports && !reportsMenu.views.includes(reports)) {
          reportsMenu.views.push(reports);
          if (reports && !sideBarRoutes.includes(reportsMenu)) {
            sideBarRoutes.push(reportsMenu);
          }
        }
      });
      sideBarRoutes.push(pages);
    }
  }
  return sideBarRoutes;
};
