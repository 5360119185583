import React from "react";
import { Card, Container, Row, Col, Modal, Button } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import { receivedProductTableHeader } from "data/dummy";
import { useQuery } from "react-query";
import Route from "routes/Route";

const ReceivedProduct = () => {
  const token = localStorage.getItem("token");
  const [data, setData] = React.useState([]);
  const { isLoading, data: res } = useQuery(
    "regonalStoresData",
    () => Route.fetch("regional-stores", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );

  const [tempData, setTempData] = React.useState([]);
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.receiveProduct);
      if (tempData !== res?.data?.receiveProduct) {
        setData(
          res?.data?.receiveProduct?.map((item) => {
            return {
              id: item?.id,
              product: item?.product?.sale_type?.name,
              regional_id: item?.region?.name,
              received_date: item?.received_date,
              receive: parseInt(item?.receive),
              received_from: item?.regional_transfer_id,
              serial_no: item?.product?.serial_no,
              movement_date: item?.movement_date,
              status: (
                <div className="actions-right">
                    <Button
                      variant="info"
                      size="sm"
                      className="btn-outline"
                    >
                      {item?.status === "receive" ? "Received" : item?.status}
                    </Button>{" "}
                  </div>
              )
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  return (
    <>
      <Container fluid>
        <Row className="mt-4">
          <Col md="12">
            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={receivedProductTableHeader}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReceivedProduct;
