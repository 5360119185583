import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  Form,
  Modal,
} from "react-bootstrap";

const ViewRequisition = ({ view, setView, details }) => {
  React.useEffect(() => {
    console.log(details);
  }, []);
  return (
    <>
      <Modal
        show={view}
        onHide={() => setView(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Container fluid>
          <Card.Title as="h4">Requisition</Card.Title>
          <Form>
            <Row>
              <Col md="6">
                <Form.Group>
                  <label>Product Type</label>
                  <Form.Control
                    placeholder="Product Type"
                    type="text"
                    value={details?.requisition?.sale_type?.name}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <label>Product Item Number</label>
                  <Form.Control
                    placeholder="Product Item Number"
                    type="text"
                    value={details?.requisition?.product_item_number}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Form.Group>
                  <label>Request Date</label>
                  <Form.Control
                    placeholder="Request Date"
                    type="text"
                    value={details?.requisition?.request_date}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>Request Quantity</label>
                  <Form.Control
                    placeholder="Request Quantity"
                    type="text"
                    value={parseInt(details?.requisition?.request_quantity)}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>Status</label>
                  <Form.Control
                    placeholder="Status"
                    type="text"
                    value={details?.requisition?.status}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <label>Description</label>
                  <Form.Control
                    placeholder="Region"
                    type="text"
                    value={details?.requisition?.description}
                    readOnly
                    as="textarea"
                    rows={3}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row
              className="mt-2 mb-2"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Col md="3">
                <Button
                  variant="danger"
                  className="btn-outline"
                  onClick={() => setView(false)}
                  block
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal>
    </>
  );
};

ViewRequisition.propTypes = {
  setView: PropTypes.func,
  details: PropTypes.object.isRequired,
};

export default ViewRequisition;
