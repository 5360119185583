import React from "react";
import { Container, Form, Row, Col, Button, Card } from "react-bootstrap";
import NotificationAlert from "ui/NotificationAlert";
import RequiredLabel from "ui/RequiredLabel";
import PropTypes from "prop-types";
import { isEmailValid, formValidation } from "util/CommonUtil";
import Route from "routes/Route";

const AddNewUser = ({ responseHandle, setResponseTitle, setAddNewUser, fetchUsers }) => {
  const [role, setRole] = React.useState([]);
  const token = localStorage.getItem("token");
  const fetchRole = async () => {
    const response = await Route.fetch("roles", token);
    response?.status === 200 && setRole(response?.data?.role);
  };
  React.useState(() => {
    fetchRole();
  }, []);
  const [newUserDetails, setNewUserDetails] = React.useState({
    full_name: "",
    username: "",
    contact_no: "",
    email: "",
    password: "",
    designation: "",
    roles: [],
  });
  const [fullNameState, setFullNameState] = React.useState(true);
  const [userNameState, setUserNameState] = React.useState(true);
  const [contactNoState, setContactNoState] = React.useState(true);
  const [emailState, setEmailState] = React.useState(true);
  const [passwordState, setPasswordState] = React.useState(true);
  const [designationState, setDesignationState] = React.useState(true);
  const [userRolesState, setUserRolesState] = React.useState(true);
  const [showNotification, setShowNotification] = React.useState(false);
  const updateingState = (param) => {
    param === "full_name"
      ? setFullNameState(true)
      : param === "username"
      ? setUserNameState(true)
      : param === "contact_no"
      ? setContactNoState(true)
      : param === "email"
      ? setEmailState(true)
      : param === "password"
      ? setPasswordState(true)
      : setDesignationState(true);
  };

  const inputFieldHandle = (e) => {
    updateingState(e.target.name);
    setNewUserDetails({
      ...newUserDetails,
      [e.target.name]: e.target.value,
    });
  };
  const userRoleHandle = (e, id) => {
    setUserRolesState(true);
    if (e.target.checked) {
      setNewUserDetails((prevState) => ({
        ...prevState,
        roles: [...prevState.roles, id],
      }));
    } else {
      setNewUserDetails((prevState) => ({
        ...prevState,
        roles: prevState.roles.filter((prevItem) => prevItem !== id),
      }));
    }
  };
  const validationConditions = [
    {
      field: "full_name",
      condition: newUserDetails.full_name === "",
      setState: setFullNameState,
    },
    {
      field: "username",
      condition: newUserDetails.username === "",
      setState: setUserNameState,
    },
    {
      field: "contact_no",
      condition: newUserDetails.contact_no === "",
      setState: setContactNoState,
    },
    {
      field: "email",
      condition: newUserDetails.email === "",
      setState: setEmailState,
    },
    {
      field: "password",
      condition: newUserDetails.password === "",
      setState: setPasswordState,
    },
    {
      field: "designation",
      condition: newUserDetails.designation === "",
      setState: setDesignationState,
    },
    {
      field: "roles",
      condition: newUserDetails.roles.length === 0,
      setState: setUserRolesState,
    },
  ];
  const onConfirm = async () => {
    const token = localStorage.getItem("token");
    if (formValidation(validationConditions)) {
      if (!isEmailValid(newUserDetails.email)) {
        setShowNotification(true);
      } else {
        const response = await Route.addNew("users", token, newUserDetails);
        if (response.status === 200) {
          setResponseTitle("New User Created Successfully");
          responseHandle(true);
          fetchUsers();
          setAddNewUser(false);
        } else {
          setResponseTitle("Failed To Create New User!");
          responseHandle(false);
        }
      }
    }
  };
  return (
    <Container fluid>
      <Card>
        <Card.Body>
          <Card.Title>Add New User</Card.Title>
          <Row className="mt-2">
            <Col>
              <Form.Group>
                <label>
                  Full Name <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Full Name"
                  type="text"
                  name="full_name"
                  onChange={inputFieldHandle}
                ></Form.Control>
                {fullNameState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>
                  Employee ID <span className="star">*</span>
                </label>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  name="username"
                  onChange={inputFieldHandle}
                ></Form.Control>
                {userNameState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>
                  Contact No. <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Contact No."
                  type="text"
                  name="contact_no"
                  onChange={inputFieldHandle}
                ></Form.Control>
                {contactNoState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>
                  Email <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Email"
                  type="email"
                  name="email"
                  onChange={inputFieldHandle}
                ></Form.Control>
                {emailState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>
                  Password <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Password"
                  type="text"
                  name="password"
                  onChange={inputFieldHandle}
                ></Form.Control>
                {passwordState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>
                  Designation <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Designation"
                  type="text"
                  name="designation"
                  onChange={inputFieldHandle}
                ></Form.Control>
                {designationState ? null : <RequiredLabel />}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>
                  Roles <span className="star">*</span>
                </label>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
            {role?.map((item) => (
                  <Form.Check className="" key={item.id}>
                    <Form.Check.Label>
                      <Form.Check.Input
                        defaultValue={item.id}
                        type="checkbox"
                        onChange={(e) => userRoleHandle(e, item.id)}
                      ></Form.Check.Input>
                      <span className="form-check-sign"></span>
                      {item.name}
                    </Form.Check.Label>
                  </Form.Check>
                ))}
            </Col>
            </Row>
          <Row>
            <Col className="control-label" sm="5">
              {userRolesState ? null : (
                <RequiredLabel text="You need to assign atleast a role." />
              )}
            </Col>
          </Row>
          <div className="card-category form-category">
            <span className="star">*</span>
            Required fields
          </div>
          <Row className="mt-4 mr-1 ml-1" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col className="pl-1" md="2">
              <Button block onClick={onConfirm}>
                Add
              </Button>
            </Col>
            <Col className="pr-1" md="2">
              <Button
                block
                onClick={() => setAddNewUser(false)}
                className="btn-outline"
                variant="danger"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {showNotification && (
        <NotificationAlert
          title="Invalid Email Format"
          setAlert={setShowNotification}
        />
      )}
    </Container>
  );
};

AddNewUser.propTypes = {
  setAddNewUser: PropTypes.func,
  responseHandle: PropTypes.func,
  fetchUsers: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddNewUser;
