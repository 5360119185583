import React from "react";
import { Container, Form, Row, Col, Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import RequiredLabel from "ui/RequiredLabel";
import Route from "routes/Route";

const AddNewDzongkhag = ({
  addNewDzongkhag,
  setAddNewDzongkhag,
  responseHandle,
  setResponseTitle,
}) => {
  const [nameState, setNameState] = React.useState(true);
  const [newDetails, setNewDetails] = React.useState({
    name: "",
    code: "",
  });
  const inputFieldHandle = (e) => {
    e.target.name === "name" && setNameState(true);
    setNewDetails({
      ...newDetails,
      [e.target.name] : e.target.value,
    });
  };
  const onConfirm = async () => {
    if (newDetails.name === "") {
      setNameState(false);
    } else {
      const token = localStorage.getItem("token");
      const response = await Route.addNew("dzongkhags", token, newDetails);
      if (response.status === 200) {
        setResponseTitle(response.data.message);
        setAddNewDzongkhag(false);
        responseHandle(true);
      } else {
        setResponseTitle(response.data.message);
        setAddNewDzongkhag(false);
        responseHandle(false);
      }
    }
  };
  return (
    <Container fluid>
      <Modal show={addNewDzongkhag} onHide={() => setAddNewDzongkhag(false)}>
        <Modal.Body>
          <Modal.Title>Add New Dzongkhag</Modal.Title>
          <Row>
            <Col>
              <Form.Group>
                <label>
                  Name <span className="star">*</span>
                </label>
                <Form.Control
                  placeholder="Enter Name"
                  type="text"
                  name="name"
                  onChange={inputFieldHandle}
                ></Form.Control>
                 {nameState ? null : <RequiredLabel /> }
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <label>Code</label>
                <Form.Control
                  placeholder="Enter Code"
                  type="text"
                  name="code"
                  onChange={inputFieldHandle}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="control-label" md="6">
              <div className="card-category form-category">
                <span className="star">*</span>
                Required fields
              </div>
            </Col>
          </Row>
          <Row className="mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="3">
              <Button variant="primary" onClick={onConfirm} block>
                Add
              </Button>
            </Col>
            <Col md="3">
              <Button
                variant="danger"
                className="btn-outline"
                onClick={() => setAddNewDzongkhag(false)}
                block
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
AddNewDzongkhag.propType = {
  addNewDzongkhag: PropTypes.bool,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
  setAddNewDzongkhag: PropTypes.func,
};

export default AddNewDzongkhag;
