import React from "react";
import { Button, Card, Container, Row, Col, Modal } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import AddWarranty from "./Components/masters/warranty/AddWarranty";
import EditWarranty from "./Components/masters/warranty/EditWarranty";
import Alert from "ui/Alert";
import NotificationAlert from "ui/NotificationAlert";
import DeleteAlert from "ui/DeleteAlert";
import { warrantyTableHeader } from "data/dummy";
import Route from "routes/Route";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { checkPermission } from "util/MasterUtil";

const Warranty = () => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [showAlert, setShowAlert] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const [response, setResponse] = React.useState(false);
  const [warranty, setWarranty] = React.useState([]);
  const [addNew, setAddNew] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const token = localStorage.getItem("token");
  const editHandle = async (id) => {
    if (!checkPermission("master-warranties.edit-master-warranties", permissionsArray)) {
      return setShowNotification(true);
    }
    const response = await Route.fetchDetails("edit-master-warranties", token, id);
    if (response.status === 200) {
      setDetails(response?.data?.warranty);
      setEdit(true);
    } else {
      setNotificationText("Couldn't Fetch Details");
      setShowNotification(true);
    }
  };
  const { isLoading, data: res } = useQuery(
    "master-warranties",
    () => Route.fetch("master-warranties", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const [tempData, setTempData] = React.useState([]);
  const [warrantyId, setWarrantyId] = React.useState("");
  const [deleteWarranty, setDeleteWarranty] = React.useState(false);
  const deleteHandle = (id) => {
    setWarrantyId(id);
    setDeleteWarranty(true);
  };
  const deleteWarrantyHandle = async () => {
    setDeleteWarranty(false);
    const res = await Route.delete("master-warranties", token, warrantyId);
    if (res.status === 200) {
      setResponse(true);
      setResponseTitle(res?.data?.message);
      setShowAlert(true);
    } else {
      setResponse(false);
      setResponseTitle("Failed To Delete Warranty ❌");
      setShowAlert(true);
    }
  };
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.warranty);
      if (tempData !== res?.data?.warranty) {
        setWarranty(
          res?.data?.warranty?.map((item) => {
            return {
              id: item?.id,
              name: item?.sale_type?.name,
              no_of_years: item?.no_of_years,
              actions: (
                <div className="actions-right">
                  <Button
                    onClick={() => editHandle(item.id)}
                    variant="warning"
                    size="sm"
                    className="text-dark btn-link edit"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    onClick={() => deleteHandle(item.id)}
                    variant="danger"
                    size="sm"
                    className="btn-link remove text-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  const addNewHandle = () => {
    !checkPermission("banks.store", permissionsArray)
      ? setShowNotification(true)
      : setAddNew(true);
  };
  return (
    <>
      {addNew ? (
        <AddWarranty
          setAddNew={setAddNew}
          responseHandle={responseHandle}
          setResponseTitle={setResponseTitle}
        />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <h4 className="title">Warranty</h4>
              <Card>
                <Card.Body>
                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button className="btn-outline" onClick={addNewHandle}>
                      Add New Warranty <i className="fa fa-plus"></i>
                    </Button>
                  </Col>
                  <ReactTable
                    data={warranty}
                    columns={warrantyTableHeader}
                    className="-striped -highlight primary-pagination"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {showAlert && (
        <Alert
          response={response}
          setShowAlert={setShowAlert}
          responseTitle={responseTitle}
        />
      )}
      {edit && (
        <Modal
          show={edit}
          onHide={() => setEdit(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Body>
            <EditWarranty
              setEdit={setEdit}
              details={details}
              responseHandle={responseHandle}
              setResponseTitle={setResponseTitle}
            />
          </Modal.Body>
        </Modal>
      )}
      {deleteWarranty && (
        <DeleteAlert deleteHandle={deleteWarrantyHandle} setAlert={setDeleteWarranty} />
      )}
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      )}
    </>
  );
};

export default Warranty;
