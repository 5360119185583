import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "ui/NotificationAlert";
import { Container, Form, Row, Col, Button, Modal } from "react-bootstrap";
import RequiredLabel from "ui/RequiredLabel";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { isEmailValid, formValidation } from "util/CommonUtil";
import { checkPermission } from "util/MasterUtil";
import Route from "routes/Route";

const EditUserDetails = ({
  show,
  responseHandle,
  userDetail,
  setResponseTitle,
  setEditUserDetails,
  fetchUsers
}) => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [role, setRole] = React.useState([]);
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const token = localStorage.getItem("token");
  const fetchRole = async () => {
    const response = await Route.fetch("roles", token);
    response?.status === 200 && setRole(response?.data?.role);
  };
  React.useState(() => {
    fetchRole();
  }, []);
  const [newUserDetails, setNewUserDetails] = React.useState({
    full_name: "",
    username: "",
    email: "",
    designation: "",
    userRoles: [],
  });
  React.useEffect(() => {
    setNewUserDetails({
      full_name: userDetail?.name,
      username: userDetail?.username,
      contact_no: userDetail?.contact_no,
      email: userDetail?.email,
      designation: userDetail?.designation,
      userRoles: userDetail?.roles?.map((item) => item.id),
    });
  }, [userDetail]);
  const [fullNameState, setFullNameState] = React.useState(true);
  const [userNameState, setUserNameState] = React.useState(true);
  const [contactNoState, setContactNoState] = React.useState(true);
  const [emailState, setEmailState] = React.useState(true);
  const [designationState, setDesignationState] = React.useState(true);
  const [userRolesState, setUserRolesState] = React.useState(true);
  const updateingState = (param) => {
    param === "full_name"
      ? setFullNameState(true)
      : param === "username"
      ? setUserNameState(true)
      : param === "contact_no"
      ? setContactNoState(true)
      : param === "email"
      ? setEmailState(true)
      : setDesignationState(true);
  };
  const inputFieldHandle = (e) => {
    updateingState(e.target.name);
    setNewUserDetails({
      ...newUserDetails,
      [e.target.name]: e.target.value,
    });
  };
  const userRoleHandle = (e, id) => {
    setUserRolesState(true);
    if (e.target.checked) {
      setNewUserDetails((prevState) => ({
        ...prevState,
        userRoles: [...prevState.userRoles, id],
      }));
    } else {
      setNewUserDetails((prevState) => ({
        ...prevState,
        userRoles: prevState.userRoles.filter((prevItem) => prevItem !== id),
      }));
    }
  };
  const validationConditions = [
    {
      field: "fullName",
      condition: newUserDetails.full_name === "",
      setState: setFullNameState,
    },
    {
      field: "userName",
      condition: newUserDetails.username === "",
      setState: setUserNameState,
    },
    {
      field: "contact_no",
      condition: newUserDetails.contact_no === "",
      setState: setContactNoState,
    },
    {
      field: "email",
      condition: newUserDetails.email === "",
      setState: setEmailState,
    },
    {
      field: "designation",
      condition: newUserDetails.designation === "",
      setState: setDesignationState,
    },
    {
      field: "userRoles",
      condition: newUserDetails.userRoles.length === 0,
      setState: setUserRolesState,
    },
  ];
  const onConfirm = async () => {
    if (!checkPermission("users.update", permissionsArray)) {
      setNotificationText("You Don't Have Permission");
      setShowNotification(true);
    } else {
      if (formValidation(validationConditions)) {
        if (!isEmailValid(userDetail.email)) {
          setNotificationText("Invalid Email Format");
          setShowNotification(true);
        } else {
          const token = localStorage.getItem("token");
          const data = {
            full_name: newUserDetails.full_name,
            email: newUserDetails.email,
            username: newUserDetails.username,
            designation: newUserDetails.designation,
            roles: newUserDetails.userRoles,
          };
          const response = await Route.update(
            "users",
            token,
            userDetail.id,
            data
          );
          if (response.status === 200) {
            setEditUserDetails(false);
            fetchUsers();
            setResponseTitle("User Detail Updated Successfully");
            responseHandle(true);
          } else {
            setEditUserDetails(false);
            setResponseTitle("Failed To Update User Details!");
            responseHandle(false);
          }
        }
      }
    }
  };
  return (
    <>
      <Modal show={show} onHide={() => setEditUserDetails(false)}>
        <Modal.Body>
          <Modal.Title>Edit User Detail</Modal.Title>
          <Row>
            <Col>
              <Row>
                <Col className="pr-1" md="6">
                  <Form.Group>
                    <Form.Label
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        fontSize: "14px",
                        color: "#73777B",
                      }}
                    >
                      Full Name <span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      defaultValue={userDetail.name}
                      placeholder="Full Name"
                      type="text"
                      name="full_name"
                      onChange={inputFieldHandle}
                    ></Form.Control>
                    {fullNameState ? null : <RequiredLabel />}
                  </Form.Group>
                </Col>
                <Col className="pl-1" md="6">
                  <Form.Group>
                    <Form.Label
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        fontSize: "14px",
                        color: "#73777B",
                      }}
                    >
                      User Name <span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      defaultValue={userDetail.username}
                      placeholder="Usernme"
                      type="text"
                      name="username"
                      onChange={inputFieldHandle}
                    ></Form.Control>
                    {userNameState ? null : <RequiredLabel />}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="6">
                  <Form.Group>
                    <Form.Label
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        fontSize: "14px",
                        color: "#73777B",
                      }}
                    >
                      Contact No. <span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      defaultValue={userDetail.contact_no}
                      placeholder="Contact No"
                      type="text"
                      name="contact_no"
                      onChange={inputFieldHandle}
                    ></Form.Control>
                    {contactNoState ? null : <RequiredLabel />}
                  </Form.Group>
                </Col>
                <Col className="pr-1" md="6">
                  <Form.Group>
                    <Form.Label
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        fontSize: "14px",
                        color: "#73777B",
                      }}
                    >
                      Email <span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      defaultValue={userDetail.email}
                      placeholder="Email"
                      type="email"
                      name="email"
                      onChange={inputFieldHandle}
                    ></Form.Control>
                    {emailState ? null : <RequiredLabel />}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pl-1" md="6">
                  <Form.Group>
                    <Form.Label
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        fontSize: "14px",
                        color: "#73777B",
                      }}
                    >
                      Designation <span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      defaultValue={userDetail.designation}
                      placeholder="Designation"
                      type="text"
                      name="designation"
                      onChange={inputFieldHandle}
                    ></Form.Control>
                    {designationState ? null : <RequiredLabel />}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <Form.Group>
                    <Form.Label
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        fontSize: "14px",
                        color: "#73777B",
                      }}
                    >
                      Roles <span className="star">*</span>
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md="12">
                  {role?.map((item) => (
                    <Form.Check
                      key={item.id}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        fontSize: "14px",
                        color: "#73777B",
                      }}
                    >
                      <Form.Check.Label>
                        <Form.Check.Input
                          defaultChecked={userDetail?.roles?.some(
                            (element) => element.id === item.id
                          )}
                          defaultValue={item.id}
                          type="checkbox"
                          onChange={(e) => userRoleHandle(e, item.id)}
                        />
                        <span className="form-check-sign"></span>
                        {item.name}
                      </Form.Check.Label>
                    </Form.Check>
                  ))}
                </Col>
                <Col md="12">
                  {userRolesState ? null : (
                    <RequiredLabel text="You need to assign atleast a role." />
                  )}
                </Col>
              </Row>
              <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                <Col md="3">
                  <Button block onClick={onConfirm}>
                    Update
                  </Button>
                </Col>
                <Col md="3">
                  <Button
                    variant="danger"
                    block
                    onClick={() => setEditUserDetails(false)}
                    className="btn-outline"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {showNotification && (
        <NotificationAlert
          title={notificationText}
          setAlert={setShowNotification}
        />
      )}
    </>
  );
};

EditUserDetails.propTypes = {
  show: PropTypes.bool,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
  userDetail: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func,
  setEditUserDetails: PropTypes.func,
};

export default EditUserDetails;
