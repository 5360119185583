import React from "react";
import { Table, Form } from "react-bootstrap";
import Select from "react-select";
import PropTypes from "prop-types";

const ApprovePhoneRequisition = ({
  phoneRequestData,
  phoneSerialNoHandle,
  phoneApproveHandle,
}) => {
  return (
    <>
      <Table striped hover size="sm" responsive>
        <thead>
          <tr>
            <th>Sl.No.</th>
            <th>Product Item Number</th>
            <th>Description</th>
            <th style={{ width: "25%" }}>Serial No.</th>
            <th>Approve</th>
          </tr>
        </thead>
        <tbody>
          {phoneRequestData?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item?.product_item_number}</td>
              <td>{item?.description}</td>
              <td>
                <div className="mt-1">
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    name="serialNoSelect"
                    onChange={(e) =>
                      phoneSerialNoHandle(e, `${index} phone ${index}`, item)
                    }
                    options={item.serialNo?.map((item) => ({
                      value: `${item.serialNo}`,
                      label: `${item.serialNo}`,
                    }))}
                    placeholder="Select"
                  />
                </div>
              </td>
              <td>
                <div className="mt-1">
                  <Form.Check>
                    <Form.Check.Label>
                      <Form.Check.Input
                        defaultValue=""
                        type="checkbox"
                        onChange={(e) =>
                          phoneApproveHandle(e, `${index} phone ${index}`, item)
                        }
                      ></Form.Check.Input>
                      <span className="form-check-sign"></span>
                    </Form.Check.Label>
                  </Form.Check>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

ApprovePhoneRequisition.propTypes = {
    phoneRequestData: PropTypes.array,
    phoneSerialNoHandle: PropTypes.func,
    phoneApproveHandle: PropTypes.func,
  };

export default ApprovePhoneRequisition;
