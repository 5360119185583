import React from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

const RegionalSaleVoucherInfo = ({saleVoucher}) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Sale Voucher Info</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form.Group>
          <Row>
            <Col className="mt-2" md="4">
              Customer
            </Col>
            <Col md="8">
              <Form.Control
                placeholder="Customer"
                type="text"
                readOnly
                value={saleVoucher?.customer_id === null ? saleVoucher?.walk_in_customer : saleVoucher?.customer?.customer_name}
              ></Form.Control>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col className="mt-2" md="4">
              Invoice No.
            </Col>
            <Col md="8">
              <Form.Control
                placeholder="Invoice No."
                type="text"
                readOnly
                value={saleVoucher?.invoice_no}
              ></Form.Control>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col className="mt-2" md="4">
              Invoice Date
            </Col>
            <Col md="8">
              <Form.Control
                placeholder="Invoice Date"
                type="text"
                readOnly
                value={saleVoucher?.invoice_date}
              ></Form.Control>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col className="mt-2" md="4">
              Gross Payable
            </Col>
            <Col md="8">
              <Form.Control
                placeholder="Gross Payable"
                type="text"
                readOnly
                value={saleVoucher?.gross_payable}
              ></Form.Control>
            </Col>
          </Row>
        </Form.Group>
        {/* <Form.Group>
          <Row>
            <Col className="mt-2" md="4">
              Discount Type
            </Col>
            <Col md="8">
              <Form.Control
                placeholder="Discount Type"
                type="text"
                readOnly
                value={saleVoucher?.discount_type}
              ></Form.Control>
            </Col>
          </Row>
        </Form.Group> */}
        {/* <Form.Group>
          <Row>
            <Col className="mt-2" md="4">
              Discount Rate
            </Col>
            <Col md="8">
              <Form.Control
                placeholder="Discount Rate"
                type="text"
                readOnly
                value={saleVoucher?.discount_rate}
              ></Form.Control>
            </Col>
          </Row>
        </Form.Group> */}
        <Form.Group>
          <Row>
            <Col className="mt-2" md="4">
              Net Payable
            </Col>
            <Col md="8">
              <Form.Control
                placeholder="Net Payable"
                type="text"
                readOnly
                value={saleVoucher?.net_payable}
              ></Form.Control>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col className="mt-2" md="4">
              Status
            </Col>
            <Col md="8">
              <Form.Control
                placeholder="Status"
                type="text"
                readOnly
                value={saleVoucher?.status}
              ></Form.Control>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col className="mt-2" md="4">
              Remarks
            </Col>
            <Col md="8">
              <Form.Control
                placeholder="Remarks"
                type="text"
                as="textarea"
                rows={3}
                readOnly
                value={saleVoucher?.remarks}
              ></Form.Control>
            </Col>
          </Row>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

RegionalSaleVoucherInfo.propType = {
    saleVoucher: PropTypes.object.isRequired,
};

export default RegionalSaleVoucherInfo;
