import React from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import NotificationAlert from "ui/NotificationAlert";
import RequiredLabel from "ui/RequiredLabel";
import Route from "routes/Route";
import { useSelector } from "react-redux";
import { checkPermission } from "util/MasterUtil";
import { formValidation } from "util/CommonUtil";

const EditGewog = ({ setEdit, details, responseHandle, setResponseTitle }) => {
  const [showNotification, setShowNotification] = React.useState(false);
  const [notificationText, setNotificationText] = React.useState("");
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [newDetails, setNewDetails] = React.useState({
    name: "",
    code: "",
  });
  const [dzongkhags, setDzongkhags] = React.useState([]);
  const [gewogState, setGewogState] = React.useState(true);
  const [dzongkhagState, setDzongkhagState] = React.useState(true);
  const token = localStorage.getItem("token");
  const fetchDzongkhag = async () => {
    const response = await Route.fetch('dzongkhags', token);
    if (response.status === 200) {
      setDzongkhags(response?.data?.dzongkhag);
    }
  };
  React.useEffect(() => {
    fetchDzongkhag();
  }, []);
  React.useEffect(() => {
    setNewDetails({
      ...newDetails,
      id: details.id,
      name: details.name,
      dzongkhag_id: details.dzongkhag_id,
      dzongkhag: details.dzongkha,
    });
  }, [details]);
  const gewogHandle = (e) => {
    setGewogState(true);
    setNewDetails({
      ...newDetails,
      name: e.target.value,
    });
  };
  const dzongkhagHandle = (e) => {
    setDzongkhagState(true);
    setNewDetails({
      ...newDetails,
      dzongkhag_id: e.value,
    });
  };
  const codeHandle = (e) => {
    setNewDetails({
      ...newDetails,
      code: e.target.value,
    });
  };
  const validationConditions = [
    { field: 'gewog', condition: newDetails.name === '', setState: setGewogState },
    { field: 'dzongkhag', condition: newDetails.dzongkhag_id === '', setState: setDzongkhagState },
  ];
  const onConfirm = async () => {
    if (!checkPermission("gewogs.update", permissionsArray)) {
      setNotificationText("You Don't Have Permission");
      setShowNotification(true);
    } else {
      if (formValidation(validationConditions)) {
        const response = await Route.update(
          'gewogs',
          token,
          details.id,
          newDetails
        );
        if (response.status === 200) {
          setResponseTitle(response.data.message);
          setEdit(false);
          responseHandle(true);
        } else {
          setResponseTitle(response.data.message);
          setEdit(false);
          responseHandle(false);
        }
      }
    }
  };
  return (
    <div className="stacked-form">
      <Card.Title as="h4">Edit Gewog</Card.Title>
      <Form action="#" method="#">
        <Form.Group>
          <label>Gewog <span className="star">*</span></label>
          <Form.Control
            placeholder="Gewog"
            type="text"
            defaultValue={details.name}
            onChange={gewogHandle}
          ></Form.Control>
          { gewogState ? null : <RequiredLabel />}
        </Form.Group>
        <Form.Group>
          <label>Dzongkhag <span className="star">*</span></label>
          <Select
            className="react-select primary"
            classNamePrefix="react-select"
            onChange={dzongkhagHandle}
            defaultValue={{
              value: `${details?.dzongkhag_id}`,
              label: `${details?.dzongkhag}`,
            }}
            name="singleSelect"
            options={dzongkhags?.map((item) => ({
              value: `${item.id}`,
              label: `${item.name}`,
            }))}
            placeholder="Select Dzongkhag"
          />
          { dzongkhagState ? null : <RequiredLabel />}
        </Form.Group>
        <Form.Group>
          <label>Code</label>
          <Form.Control
            placeholder="Description"
            type="text"
            defaultValue={details?.code}
            onChange={codeHandle}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mt-4">
          <Row className="mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="3">
              <Button
                className="btn-fill"
                type="button"
                variant="primary"
                block
                onClick={onConfirm}
              >
                Update
              </Button>
            </Col>
            <Col md="3">
              <Button
                className="btn-outline"
                type="button"
                block
                variant="danger"
                onClick={() => setEdit(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </Form>
      { showNotification && <NotificationAlert text={notificationText} /> }
    </div>
  );
};
EditGewog.propTypes = {
  setEdit: PropTypes.func,
  details: PropTypes.object.isRequired,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default EditGewog;
