import React from "react";
import { Button, Form } from "react-bootstrap";
import Route from "routes/Route";
import Select from "react-select";
import NotificationAlert from "ui/NotificationAlert";
import PropTypes from "prop-types";
import { getDiscountsSaleTypeAndSub } from "util/CommonUtil";

const AddProduct = ({
  id,
  productListHandle,
  deleteRowHandle,
  products,
  discountData,
}) => {
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const token = localStorage.getItem("token");
  const [productDetail, setProductDetail] = React.useState([]);
  const [discount, setDiscount] = React.useState([]);
  const [showNotification, setShowNotificaion] = React.useState(false);
  const [detail, setDetail] = React.useState({
    price: "",
    quantity: "",
    total_cost: "",
    discount_type_id: "",
  });
  const fetchProduct = async (productId) => {
    const response = await Route.fetchDetails(
      "edit-products",
      token,
      productId
    );
    if (response.status === 200) {
      setProductDetail(response?.data?.product);
    }
  };
  const user = JSON.parse(localStorage.getItem('user'));
  const region = user?.assign_and_employee === null ? null : user?.assign_and_employee?.regional_id;
  const extension = user?.assign_and_employee === null ? null : user?.assign_and_employee?.extension_id;
  const productHandle = (id, e) => {
    fetchProduct(e.value);
    setDiscount(
      getDiscountsSaleTypeAndSub(
        discountData,
        e.sale_type_id,
        e.sub_category_id,
        region,
        extension
      )
    );
    setDetail({
      ...detail,
      price: productDetail?.price,
      quantity: "",
      actual_cost: "",
      total_cost: "",
      discount_type_id: "",
    });
    productListHandle(id, "product", e.value);
  };
  const quantityHandle = (e) => {
    if (parseFloat(e.target.value) > parseFloat(productDetail?.quantity)) {
      return setShowNotificaion(true);
    }
    setDetail({
      ...detail,
      quantity: parseFloat(e.target.value),
      actual_cost:
        parseFloat(productDetail?.price) * parseFloat(e.target.value),
      total_cost: parseFloat(productDetail?.price) * parseFloat(e.target.value),
    });
    productListHandle(id, "product_cost", productDetail?.price);
    productListHandle(id, "quantity", e.target.value);
    productListHandle(
      id,
      "actual_cost",
      parseFloat(productDetail?.price) * parseFloat(e.target.value)
    );
    productListHandle(
      id,
      "total_amount",
      parseFloat(productDetail?.price) * parseFloat(e.target.value)
    );
  };
  const discountHandle = (id, e) => {
    if (e.type === "Percentage") {
      setDetail({
        ...detail,
        discount_type_id: e.id,
        total_cost:
          productDetail?.price * detail?.quantity -
          productDetail?.price * detail?.quantity * (parseFloat(e.value) / 100),
      });
      productListHandle(id, "discount_type_id", e.id);
      productListHandle(
        id,
        "total_amount",
        productDetail?.price * detail?.quantity -
          productDetail?.price * detail?.quantity * (parseFloat(e.value) / 100)
      );
    } else {
      setDetail({
        ...detail,
        discount_type_id: e.id,
        total_cost:
          productDetail?.price * detail?.quantity - parseFloat(e.value),
      });
      productListHandle(id, "discount_type_id", e.id);
      productListHandle(
        id,
        "total_amount",
        productDetail?.price * detail?.quantity - parseFloat(e.value)
      );
    }
  };
  return (
    <>
      <tr key={id} id={id}>
        <td>
          <Form.Group>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="productSelect"
              onChange={(e) => productHandle(id, e)}
              options={products?.map((item) => ({
                value: item?.id,
                sale_type_id: item?.category?.sale_type_id,
                sub_category_id: item?.sub_category_id,
                label: `${item?.serial_no}`,
              }))}
              placeholder="Select"
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group>
            <Form.Control
              placeholder="Description"
              type="text"
              readOnly
              value={productDetail?.description}
            ></Form.Control>
          </Form.Group>
        </td>
        <td>
          <Form.Group>
            <Form.Control
              placeholder="Price"
              type="text"
              readOnly
              value={productDetail?.price}
            ></Form.Control>
          </Form.Group>
        </td>
        <td>
          <Form.Group>
            <Form.Control
              placeholder="Qty"
              type="number"
              onChange={quantityHandle}
              value={detail?.quantity}
              onKeyDown={(e) =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()
              }
            ></Form.Control>
          </Form.Group>
        </td>
        <td>
          <Form.Group>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="productSelect"
              onChange={(e) => discountHandle(id, e)}
              options={discount?.map((item) => ({
                id: item?.id,
                value: item?.discount_value,
                type: item?.discount_type,
                label: `${item?.discount_name}`,
              }))}
              placeholder="Select"
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group>
            <Form.Control
              placeholder="Total Price"
              type="text"
              value={detail.total_cost}
              readOnly
            ></Form.Control>
          </Form.Group>
        </td>
        <td>
          <Button
            variant="danger"
            className="btn-link remove text-danger"
            style={{ padding: "0.2rem", marginTop: "0.4em" }}
            onClick={() => deleteRowHandle(id)}
          >
            <i className="fa fa-trash" />
          </Button>
        </td>
      </tr>
      {showNotification ? (
        <NotificationAlert
          title="Quantity Enter is More Than in Store"
          setAlert={setShowNotificaion}
        />
      ) : null}
    </>
  );
};

AddProduct.propType = {
  id: PropTypes.number,
  products: PropTypes.object,
  productListHandle: PropTypes.func,
  deleteRowHandle: PropTypes.func,
};

export default AddProduct;
