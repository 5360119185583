import React from "react";
import { Container } from "react-bootstrap";

function AdminFooter() {
  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <nav>
            <p className="copyright text-center">
              © {new Date().getFullYear()}
              {"  "}
              <span style={{ color: "#3876BF" }}>
                Powered by Software & Application Section, TashiCell
              </span>
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
