import React from "react";
import { Card, Button, Row, Col, } from "react-bootstrap";
import PropTypes from "prop-types";
import Route from "routes/Route";
import AddItem from "./AddItem";
import { productsData } from "util/MasterUtil";
import { dynamicHandle } from "util/CommonUtil";

const AddNewProduct = ({
  setAddNew,
  responseHandle,
  setResponseTitle,
}) => {
  const [addedProductDetails, setAddedProductDetails] = React.useState([]);
  const [addedRow, setAddedRow] = React.useState([]);
  const [rows, setRows] = React.useState(0);
  const addNewRowHandle = () => {
    const newId = rows + 1;
    setRows(newId);
    setAddedRow((prev) => [...prev, row(newId)]);
  };
  const token = localStorage.getItem('token');
  const inputFieldHandle= (id, param, value) => {
    dynamicHandle(id, param, value, setAddedProductDetails)
  };
  const deleteRowHandle = (id) => {
    setAddedProductDetails((prevSubCatDetails) => {
      return prevSubCatDetails.filter((item) => item.id !== id);
    });
    setAddedRow((prev) => prev.filter((row) => row.props.id !== id));
  };
  const row = (id) => (<AddItem
    key={id}
    id={id}
    deleteRowHandle={deleteRowHandle}
    inputFieldHandle={inputFieldHandle}
  />)
  const onConfirm = async () => {
    const data = productsData(addedProductDetails);
    const response = await Route.addNew("products", token, data);
    if (response?.status === 200) {
      setResponseTitle(response?.data?.message);
      setAddNew(false);
      responseHandle(true);
    } else {
      setResponseTitle(response?.data?.message);
      setAddNew(false);
      responseHandle(false);
    }
  };
  return (
    <Card className="stacked-form">
      <Card.Header>
        <Card.Title as="h4">Add New Product</Card.Title>
      </Card.Header>
        <Card.Body>
          <Row>
            <Col>
            { addedRow.length > 0 && addedRow?.map((item) => item)}
            </Col>
          </Row>
          <Row>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className="btn-outline"
                type="button"
                variant="default"
                onClick={addNewRowHandle}
              >
                Add New <i className="fa fa-plus"></i>
              </Button>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="3">
              <Button
                className="btn-fill"
                type="submit"
                variant="primary"
                block
                onClick={onConfirm}
              >
                Upload
              </Button>
            </Col>
            <Col md="3">
              <Button
                className="btn-outline"
                type="button"
                variant="danger"
                block
                onClick={() => setAddNew(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Card.Footer>
    </Card>
  );
};
AddNewProduct.propType = {
  setAddNew: PropTypes.func,
  responseHandle: PropTypes.func,
  setResponseTitle: PropTypes.func,
};

export default AddNewProduct;
