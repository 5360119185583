import React from "react";
import { Card, Container, Row, Col, Button, Modal } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import { mainStoreSalesTableHeader } from "data/dummy";
import { useQuery } from "react-query";
import Route from "routes/Route";
import Alert from "ui/Alert";
import NotificationAlert from "ui/NotificationAlert";
import ExtensionSaleProduct from "./Components/extension_store/extension-sale-voucher/ExtensionSaleProduct";
import ExtensionMakePayment from "./Components/extension_store/extension-sale-voucher/ExtensionMakePayment";
import Invoice from "ui/Invoice";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const ExtensionSaleVoucher = () => {
  const permissionsArray = useSelector((state) => state.permission.permission);
  const token = localStorage.getItem("token");
  const [response, setResponse] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [data, setData] = React.useState([]);
  const [saleProduct, setSaleProduct] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [saleVoucher, setSaleVoucher] = React.useState([]);
  const [makePayment, setMakePayment] = React.useState(false);
  const [showInvoice, setShowInvoice] = React.useState(false);
  const [paymentHistory, setPaymentHistory] = React.useState([]);
  const [receiptNo, setReceiptNo] = React.useState("");
  const [invoicePayment, setInvoicePayment] = React.useState(0);
  const [notificationText, setNotificationText] = React.useState("");
  const [showNotification, setShowNotification] = React.useState(false);
  const [banks, setBanks] = React.useState([]);
  const { isLoading, data: res } = useQuery(
    "extensionStoresales",
    () => Route.fetch("extension-store-sales", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const { isLoadingState, data: discountData } = useQuery(
    "discount-types-for-region",
    () => Route.fetch("discount-types", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const fetchDetails = async (id, type) => {
    if (!checkPermission("extension-store-sales.update", permissionsArray)) {
      setNotificationText("You Don't Have Permission");
      setShowNotification(true);
    }
    const response = await Route.fetchDetails(
      "extension-store-sales",
      token,
      id
    );
    if (response.status === 200) {
      setSaleVoucher(response?.data?.saleVoucher);
      setPaymentHistory(response?.data?.paymentHistory);
      setBanks(response?.data?.bank);
      setReceiptNo(response?.data?.receiptNo);
      setInvoicePayment(response?.data?.invoicePayment);
      setMakePayment(true);
      if (type === "payment") {
        setMakePayment(true);
        setShowInvoice(false);
      } else {
        setMakePayment(false);
        setShowInvoice(true);
      }
    } else {
      setNotificationText("Failed To Fetch");
      setShowNotification(true);
    }
  };
  const paymentHandle = async (id) => {
    fetchDetails(id, "payment");
  };
  const invoiceHandle = async (id) => {
    fetchDetails(id, "invoice");
  };
  const [tempData, setTempData] = React.useState([]);
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.saleVouchers);
      setProducts(res?.data?.products);
      if (tempData !== res?.data?.saleVouchers) {
        setData(
          res?.data?.saleVouchers?.map((item) => {
            return {
              id: item?.id,
              invoice_no: item?.invoice_no,
              invoice_date: item?.invoice_date,
              net_payable: item?.net_payable,
              status: (
                <div className="">
                  <Button
                    variant={item?.status === "open" ? "danger" : "success"}
                    size="sm"
                    className="btn-outline"
                  >
                    {item?.status}
                  </Button>
                </div>
              ),
              action:
                item?.status === "open" ? (
                  <div className="actions-right">
                    <Button
                      onClick={() => paymentHandle(item?.id)}
                      variant="info"
                      size="sm"
                      className=""
                    >
                      Make Payment
                    </Button>{" "}
                  </div>
                ) : (
                  <div className="actions-right">
                    <Button
                      onClick={() => invoiceHandle(item?.id)}
                      variant="info"
                      size="sm"
                      className=""
                    >
                      View Invoice
                    </Button>
                  </div>
                ),
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  const saleProductHandle = () => {
    if (!checkPermission("extension-store-sales.store", permissionsArray)) {
      setNotificationText("You Don't Have Permission");
      setShowNotification(true);
    }
    setSaleProduct(true);
  };
  return (
    <>
      {makePayment ? (
        <ExtensionMakePayment
          saleVoucher={saleVoucher}
          setMakePayment={setMakePayment}
          paymentHistory={paymentHistory}
          banks={banks}
          setResponseTitle={setResponseTitle}
          responseHandle={responseHandle}
          receiptNo={receiptNo}
          invoicePayment={invoicePayment}
        />
      ) : saleProduct ? (
        <ExtensionSaleProduct
          setSaleProduct={setSaleProduct}
          setResponseTitle={setResponseTitle}
          responseHandle={responseHandle}
          products={products}
          discountData={discountData?.data?.discountType}
        />
      ) : (
        <Card>
          <Card.Header>
            <Card.Title as="h4">Sale Voucher</Card.Title>
          </Card.Header>
          <Card.Body>
            <Container fluid>
              <Row className="mt-4">
                <Col md="12">
                  <Card>
                    <Col
                      md="12"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      className="mt-4"
                    >
                      <Button
                        onClick={saleProductHandle}
                        className="btn-outline"
                      >
                        Sale Product <i className="fa fa-cart-plus"></i>
                      </Button>
                    </Col>
                    <Card.Body>
                      <ReactTable
                        data={data}
                        columns={mainStoreSalesTableHeader}
                        className="-striped -highlight primary-pagination"
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      )}
      {showInvoice && (
        <Modal show={showInvoice} onHide={() => setShowInvoice(false)} size="lg">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Invoice
              saleVoucher={saleVoucher}
              setShowInvoice={setShowInvoice}
              paymentHistory={paymentHistory}
              banks={banks}
              setResponseTitle={setResponseTitle}
              responseHandle={responseHandle}
              receiptNo={receiptNo}
              invoicePayment={invoicePayment}
            />
          </Modal.Body>
        </Modal>
      )}
      {showAlert && <Alert response={response} setShowAlert={setShowAlert} responseTitle={responseTitle} /> }
      { showNotification ? <NotificationAlert title={notificationText} setAlert={setShowNotification} /> : null }
    </>
  );
};

export default ExtensionSaleVoucher;
