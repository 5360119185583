import React from "react";
import { Card, Form, Table } from "react-bootstrap";
import PropTypes from 'prop-types';

const PermissionCard = ({ title, permissionType, checkboxHandle }) => {
  return (
    <Card>
      <Card.Title as="h6" style={{ backgroundColor: '#F0EEED' }} className="pb-3 pt-3 pl-3">{title}</Card.Title>
      <Table className="mb-2">
          <tbody>
            {
              permissionType?.map((item) => (
                <tr key={item.id}>
                  <td>
                    <Form.Check className="pl-0">
                      <Form.Check.Label>
                        <Form.Check.Input
                          defaultValue=""
                          type="checkbox"
                          onChange={(e) => checkboxHandle(e, item.id)}
                          defaultChecked={item.checked}
                        ></Form.Check.Input>
                        <span className="form-check-sign"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </td>
                  <td>{item.name}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
    </Card>
  );
};

PermissionCard.propTypes = {
  title: PropTypes.string.isRequired,
  permissionType: PropTypes.array,
  checkboxHandle: PropTypes.func,
};

export default PermissionCard;
