import React from "react";
import { Button, Card, Container, Row, Col, Modal } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import { gewogsTableHeader } from "data/dummy";
import { useQuery } from "react-query";
import Alert from "ui/Alert";
import DeleteAlert from "ui/DeleteAlert";
import NotificationAlert from "ui/NotificationAlert";
import Route from "routes/Route";
import AddNewGewog from "./Components/masters/gewog/AddNewGewog";
import EditGewog from "./Components/masters/gewog/EditGewog";
import { checkPermission } from "util/MasterUtil";
import { useSelector } from "react-redux";

const Gewogs = () => {
  const token = localStorage.getItem("token");
  const permissionsArray = useSelector((state) => state.permission.permission);
  const [addNewGewog, setAddNewGewog] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState("");
  const [showNotification, setShowNotification] = React.useState(false);
  // const [notificationText, setNotificationText] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [details, setDetails] = React.useState({
    id: "",
    name: "",
    dzongkhag_id: "",
    dzongkhag: "",
    code: "",
  });
  const responseHandle = (bool) => {
    setResponse(bool);
    setShowAlert(true);
  };
  const editGewogHandle = async (id) => {
    if (!checkPermission("gewogs.edit-gewogs", permissionsArray)) {
      return setShowNotification(true);
    };
    if (edit) {
      setEdit(false);
    }
    const response = await Route.fetchDetails("edit-gewogs", token, id);
    if (response.status === 200) {
      setDetails({
        ...details,
        id: response?.data?.gewog?.id,
        name: response?.data?.gewog?.name,
        dzongkhag_id: response?.data?.gewog?.dzongkhag?.id,
        dzongkhag: response?.data?.gewog?.dzongkhag?.name,
        code: response?.data?.gewog?.code,
      });
      setEdit(true);
    } else {
      setNotificationText("Error While Fetching Gewog Detail")
      setShowNotification(true);
    }
  };
  const [tempData, setTempData] = React.useState([]);
  const [gewogsData, setGewogsData] = React.useState([]);
  const { isLoading, data: res } = useQuery(
    "gewogsData",
    () => Route.fetch("gewogs", token),
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );
  const [gewogId, setGewogId] = React.useState("");
  const [deleteGewog, setDeleteGewog] = React.useState(false);
  const deleteHandle = (id) => {
    setGewogId(id);
    setDeleteGewog(true);
  };
  const deleteGewogHandle = async () => {
    setDeleteGewog(false);
    const res = await Route.delete("gewogs", token, gewogId);
    if (res.status === 200) {
      setShowAlert(true);
      setResponse(true);
      setResponseTitle(res?.data?.message);
    } else {
      setShowAlert(true);
      setResponse(false);
      setResponseTitle("Failed To Delete Gewog!");
    }
  };
  React.useEffect(() => {
    if (!isLoading && res?.status === 200) {
      setTempData(res?.data?.gewog);
      if (tempData !== res?.data?.gewog) {
        setGewogsData(
          res?.data?.gewog?.map((item) => {
            return {
              id: item.id,
              gewog: item.name,
              dzongkhag_id: item?.dzongkhag?.name,
              actions: (
                <div className="actions-right">
                  <Button
                    onClick={() => editGewogHandle(item.id)}
                    variant="warning"
                    size="sm"
                    className="text-dark btn-link edit"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    onClick={() => deleteHandle(item?.id)}
                    variant="danger"
                    size="sm"
                    className="btn-link remove text-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      }
    }
  }, [isLoading, res]);
  if (isLoading) return "Loading...";
  const addNewGewogHandle = () => {
    !checkPermission("gewogs.store", permissionsArray) ? setShowNotification(true) : setAddNewGewog(true);
  };
  return (
    <>
      <Container fluid>
        {addNewGewog ? (
          <AddNewGewog
            setAddNewGewog={setAddNewGewog}
            responseHandle={responseHandle}
            setResponseTitle={setResponseTitle}
          />
        ) : (
          <Row>
            <Col md="12">
              <h4 className="title">Gewogs</h4>
              <Card>
                <Card.Body>
                <Col md="12" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="primary" className="btn-outline" onClick={addNewGewogHandle}>
                Add New Gewog <i className="fa fa-plus"></i>
              </Button>
            </Col>
            <Col md="12">
            <ReactTable
                    data={gewogsData}
                    columns={gewogsTableHeader}
                    className="-striped -highlight primary-pagination"
                  />
            </Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        {edit && (
          <Modal
            show={edit}
            onHide={() => setEdit(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Body>
              <EditGewog
                setEdit={setEdit}
                details={details}
                responseHandle={responseHandle}
                setResponseTitle={setResponseTitle}
              />
            </Modal.Body>
          </Modal>
        )}
      </Container>
      { showAlert && <Alert response={response} setShowAlert={setShowAlert} responseTitle={responseTitle} /> }
      { deleteGewog && <DeleteAlert deleteHandle={deleteGewogHandle} setAlert={setDeleteGewog} /> }
      { showNotification && <NotificationAlert  title={notificationText} setAlert={setShowNotification}  /> }
    </>
  );
};

export default Gewogs;
